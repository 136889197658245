import watch__minute_hand from "assets/images/watch/minute-hand.png";

import watch_sber_hour_hand from "assets/images/watch/sber-hour-hand.png";
import watch_sber_layer_1 from "assets/images/watch/sber-layer_01.png";
import watch_sber_layer_2 from "assets/images/watch/sber-layer_02.png";
import watch_sber_layer_3 from "assets/images/watch/sber-layer_03.png";

import watch_eva_hour_hand from "assets/images/watch/eva-hour-hand.png";
import watch_eva_layer_1 from "assets/images/watch/eva-layer_01.png";
import watch_eva_layer_2 from "assets/images/watch/eva-layer_02.png";
import watch_eva_layer_3 from "assets/images/watch/eva-layer_03.png";

import watch_joy_hour_hand from "assets/images/watch/joy-hour-hand.png";
import watch_joy_layer_1 from "assets/images/watch/joy-layer_01.png";
import watch_joy_layer_2 from "assets/images/watch/joy-layer_02.png";
import watch_joy_layer_3 from "assets/images/watch/joy-layer_03.png";

export const WatchSber = {
    "--game-watch-logo-hour-hand": `url("${watch_sber_hour_hand}")`,
    "--game-watch-logo-minute-hand": `url("${watch__minute_hand}")`,
    "--game-watch-logo-layer-01": `url("${watch_sber_layer_1}")`,
    "--game-watch-logo-layer-02": `url("${watch_sber_layer_2}")`,
    "--game-watch-logo-layer-03": `url("${watch_sber_layer_3}")`,
};

export const WatchAthena = {
    "--game-watch-logo-hour-hand": `url("${watch_eva_hour_hand}")`,
    "--game-watch-logo-minute-hand": `url("${watch__minute_hand}")`,
    "--game-watch-logo-layer-01": `url("${watch_eva_layer_1}")`,
    "--game-watch-logo-layer-02": `url("${watch_eva_layer_2}")`,
    "--game-watch-logo-layer-03": `url("${watch_eva_layer_3}")`,
};

export const WatchJoy = {
    "--game-watch-logo-hour-hand": `url("${watch_joy_hour_hand}")`,
    "--game-watch-logo-minute-hand": `url("${watch__minute_hand}")`,
    "--game-watch-logo-layer-01": `url("${watch_joy_layer_1}")`,
    "--game-watch-logo-layer-02": `url("${watch_joy_layer_2}")`,
    "--game-watch-logo-layer-03": `url("${watch_joy_layer_3}")`,
};
