import { makeAutoObservable, runInAction } from "mobx";

import { LoadState } from "models/enums";

export default class TopOfRankWithPages {
    _app;
    _items;
    _offset;
    _limit;
    _state;
    _count;
    rank;

    constructor(app, { rank, items = [], offset = 0, limit = 8 }) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this.rank = rank;
        this._items = items || [];
        this._offset = Math.max(0, offset);
        this._limit = Math.max(1, limit);
        this._count = 0;
        this._state = LoadState.INIT;
    }

    get isLoading() {
        return this._state === LoadState.LOADING;
    }

    get isSpinner() {
        return this.isLoading || this._state === LoadState.INIT;
    }

    get items() {
        return (this._items || []).slice(0, this._limit);
    }

    get pageCount() {
        return Math.max(1, Math.ceil(this._count / this._limit));
    }

    get pageNumber() {
        if (this._count <= 0) {
            return 1;
        }
        return Math.max(1, Math.ceil((this._offset + 1) / this._limit));
    }

    get count() {
        return this._count;
    }

    hasNext() {
        return this._offset + this._limit < this._count;
    }

    hasPrev() {
        return this._offset > 0;
    }

    next() {
        this._loadFromServer({
            rank: this.rank,
            offset: this._offset + this._limit,
            limit: this._limit,
        });
    }

    prev() {
        this._loadFromServer({
            rank: this.rank,
            offset: this._offset - this._limit,
            limit: this._limit,
        });
    }

    reload() {
        this._loadFromServer({
            rank: this.rank,
            offset: this._offset,
            limit: this._limit,
        });
    }

    _loadFromServer({ rank, offset, limit }) {
        if (!this.isLoading) {
            this._state = LoadState.LOADING;
            this._app.server.rating
                .retrieve_top_by_rank({
                    rank,
                    offset: Math.max(0, offset),
                    limit: Math.max(1, limit),
                })
                .then((data) => this.updateState(data, LoadState.SUCCESS))
                .catch((error) =>
                    runInAction(() => {
                        this._state = LoadState.FAIL;
                        console.error(error);
                    }),
                );
        }
    }

    updateState({ offset, items, count }, state = LoadState.SUCCESS) {
        this._state = state;
        this._count = count;
        if (offset < count) {
            this._offset = offset;
            this._items.replace(items || []);
        }
    }
}
