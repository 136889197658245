/* eslint-disable */

import { makeAutoObservable } from "mobx";

import { ControlType } from "models/enums";

class Layer {
    _ok;
    _back;
    _name;
    _move;
    _move_filter;
    _find;
    _find_init_focus;
    _on_blur;

    constructor(
        controller,
        {
            ok = null,
            back = null,
            name = null,
            elements = {},
            move = null,
            move_filter = null,
            find = null,
            on_blur = null,
            find_init_focus = null,
        } = {},
    ) {
        makeAutoObservable(this);
        this._controller = controller;
        this._ok = ok;
        this._back = back;
        this._name = name;
        this._move = move;
        this._find = find ? find : (key) => elements[key] || null;
        this._move_filter = move_filter ? move_filter : ({ disabled }) => !disabled;
        this._on_blur = on_blur;
        this._find_init_focus = find_init_focus !== null ? find_init_focus : () => name;
    }

    get name() {
        return this._find(this._name) !== null ? this._name : null;
    }

    get element() {
        return this.find_by_name(this._name, false);
    }

    get meta() {
        return this.element.meta;
    }

    get type() {
        return this.element.type;
    }

    get disabled() {
        return this.element.disabled;
    }

    get focused() {
        return this.element.focused;
    }

    initFocus() {
        let name = null;
        if (this._find_init_focus) {
            name = this._find_init_focus();
        }
        if (name) {
            this.focus(name);
        }
    }

    find_by_name(name, use_default_value = true) {
        const default_value = {
            name: name,
            up: null,
            down: null,
            left: null,
            right: null,
            type: ControlType.NONE,
            disabled: false,
            focused: !this._controller.is_touch_mode && this._name && this._name === name,
            meta: {},
        };

        const element = this._find(name);
        if (name && element) {
            return { ...default_value, ...element };
        }

        return use_default_value ? default_value : null;
    }

    focus(name) {
        const prev = this._name !== null ? this._find(name) : null;
        const next = name !== null ? this._find(name) : null;

        if (next !== null) {
            if (this._on_blur !== null && prev !== null && this._name !== name) {
                this._on_blur({ name: this._name, ...prev }, next);
            }
            this._name = name;
        }
    }

    move(direction) {
        if (this.element === null) {
            return null;
        }

        let name = null;
        if (this._move) {
            name = this._move(this.element, direction);
        } else {
            name = this.element[direction] || null;
        }

        if (Array.isArray(name)) {
            name.find((item) => {
                const element = this.find_by_name(item, false);
                if (element && !!this._move_filter(element)) {
                    this.focus(element.name);
                    return true;
                }
            });
            return;
        }

        this.focus(name);
        // console.log("Controller", direction, this.name, this.element, this.element[direction], "/", value);
    }

    click(name) {
        const element = this.find_by_name(name, false);
        if (element !== null) {
            if (!element.disabled && this._ok) {
                this._ok({ name: name, type: element.type, meta: { ...element.meta } });
            }
        }
    }

    ok() {
        this.click(this.name);
    }

    back() {
        if (this._back) {
            return this._back({ name: this.name, type: this.type, meta: this.meta });
        }
        return true;
    }
}

// eslint-disable-next-line import/prefer-default-export
export class Controller {
    _layers;
    _unlocked;

    constructor({
        ok = null,
        back = null,
        name = null,
        elements = null,
        move = null,
        move_filter = null,
        find = null,
        has_touch_mode = null,
        on_blur = null,
        find_init_focus = null,
    } = {}) {
        makeAutoObservable(this);
        this._unlocked = true;
        this._layers = [
            new Layer(this, {
                name,
                elements,
                ok,
                back,
                move,
                find,
                move_filter,
                on_blur,
                find_init_focus,
            }),
        ];
        this._touch_mode = has_touch_mode ? has_touch_mode : () => false;
    }

    get focusName() {
        return this.name;
    }

    get focusedName() {
        return this.name;
    }

    get focusedElement() {
        return this.layer.element;
    }

    get is_touch_mode() {
        return (this._touch_mode && this._touch_mode()) || false;
    }

    get is_locked() {
        return !this._unlocked;
    }

    get is_default_layer() {
        return this._layers.length === 1;
    }

    get default_layer() {
        return this._layers[0];
    }

    get layer() {
        return this._layers[this._layers.length - 1];
    }

    get name() {
        return this.is_touch_mode ? null : this.layer.name;
    }

    get element() {
        return this.layer.element;
    }

    get meta() {
        return this.layer.meta;
    }

    get type() {
        return this.layer.type;
    }

    get disabled() {
        return this.layer.disabled;
    }

    get focused() {
        return !this.is_touch_mode && this.layer.focused;
    }

    initFocus() {
        return this.layer.initFocus();
    }

    lock() {
        this._unlocked = false;
    }

    unlock() {
        this._unlocked = true;
    }

    up() {
        if (this._unlocked && !this.is_touch_mode) {
            this.layer.move("up");
        }
    }

    down() {
        if (this._unlocked && !this.is_touch_mode) {
            this.layer.move("down");
        }
    }

    left() {
        if (this._unlocked && !this.is_touch_mode) {
            this.layer.move("left");
        }
    }

    right() {
        if (this._unlocked && !this.is_touch_mode) {
            this.layer.move("right");
        }
    }

    click(name) {
        this._unlocked && this.layer.click(name);
    }

    ok() {
        if (this._unlocked && !this.is_touch_mode) {
            this.layer.ok();
        }
    }

    back() {
        if (!this._unlocked) {
            return;
        }

        if (this.is_default_layer) {
            this.layer.back();
        } else {
            const result = this.layer.back();
            if (result !== false) {
                this.pop();
            }
        }
    }

    focus(name) {
        this._unlocked && this.layer.focus(name);
    }

    push(params) {
        this._layers.push(new Layer(this, { ...params }));
    }

    pop() {
        if (this._layers.length > 1) {
            return this._layers.pop();
        }
    }

    replace(params) {
        this._layers[this._layers.length - 1] = new Layer(this, { ...params });
    }

    findByName(name) {
        let element = null;
        for (let index = this._layers.length - 1; index >= 0; index--) {
            element = element || this._layers[index].find_by_name(name, false);
        }
        return element || undefined;
    }

    find_by_name(name) {
        let element = null;
        for (let index = this._layers.length - 1; index >= 0; index--) {
            element = element || this._layers[index].find_by_name(name, false);
        }
        return element || this.layer.find_by_name(name);
    }

    press_key(key) {
        switch (key) {
            case "ArrowUp":
            case "UP":
                this.up();
                break;

            case "ArrowDown":
            case "DOWN":
                this.down();
                break;

            case "ArrowLeft":
            case "LEFT":
                this.left();
                break;

            case "ArrowRight":
            case "RIGHT":
                this.right();
                break;

            case "Enter":
                this.ok();
                break;

            case "Escape":
            case "BACK":
                this.back();
                break;

            case "FORWARD":
                this.click("next");
                break;

            default:
                return false;
        }
        return true;
    }
}
