import { ControlType } from "models/enums";

const HISTORY_SCROLL_UP = "history:scroll:up";
const HISTORY_SCROLL_DOWN = "history:scroll:down";

export default {
    "1:1": { type: ControlType.SQUARE, meta: { x: 1, y: 1 } },
    "1:2": { type: ControlType.SQUARE, meta: { x: 1, y: 2 } },
    "1:3": { type: ControlType.SQUARE, meta: { x: 1, y: 3 } },
    "1:4": { type: ControlType.SQUARE, meta: { x: 1, y: 4 } },
    "1:5": { type: ControlType.SQUARE, meta: { x: 1, y: 5 } },
    "1:6": { type: ControlType.SQUARE, meta: { x: 1, y: 6 } },
    "1:7": { type: ControlType.SQUARE, meta: { x: 1, y: 7 } },
    "1:8": { type: ControlType.SQUARE, meta: { x: 1, y: 8 } },
    //
    "2:1": { type: ControlType.SQUARE, meta: { x: 2, y: 1 } },
    "2:2": { type: ControlType.SQUARE, meta: { x: 2, y: 2 } },
    "2:3": { type: ControlType.SQUARE, meta: { x: 2, y: 3 } },
    "2:4": { type: ControlType.SQUARE, meta: { x: 2, y: 4 } },
    "2:5": { type: ControlType.SQUARE, meta: { x: 2, y: 5 } },
    "2:6": { type: ControlType.SQUARE, meta: { x: 2, y: 6 } },
    "2:7": { type: ControlType.SQUARE, meta: { x: 2, y: 7 } },
    "2:8": { type: ControlType.SQUARE, meta: { x: 2, y: 8 } },
    //
    "3:1": { type: ControlType.SQUARE, meta: { x: 3, y: 1 } },
    "3:2": { type: ControlType.SQUARE, meta: { x: 3, y: 2 } },
    "3:3": { type: ControlType.SQUARE, meta: { x: 3, y: 3 } },
    "3:4": { type: ControlType.SQUARE, meta: { x: 3, y: 4 } },
    "3:5": { type: ControlType.SQUARE, meta: { x: 3, y: 5 } },
    "3:6": { type: ControlType.SQUARE, meta: { x: 3, y: 6 } },
    "3:7": { type: ControlType.SQUARE, meta: { x: 3, y: 7 } },
    "3:8": { type: ControlType.SQUARE, meta: { x: 3, y: 8 } },
    //
    "4:1": { type: ControlType.SQUARE, meta: { x: 4, y: 1 } },
    "4:2": { type: ControlType.SQUARE, meta: { x: 4, y: 2 } },
    "4:3": { type: ControlType.SQUARE, meta: { x: 4, y: 3 } },
    "4:4": { type: ControlType.SQUARE, meta: { x: 4, y: 4 } },
    "4:5": { type: ControlType.SQUARE, meta: { x: 4, y: 5 } },
    "4:6": { type: ControlType.SQUARE, meta: { x: 4, y: 6 } },
    "4:7": { type: ControlType.SQUARE, meta: { x: 4, y: 7 } },
    "4:8": { type: ControlType.SQUARE, meta: { x: 4, y: 8 } },
    //
    "5:1": { type: ControlType.SQUARE, meta: { x: 5, y: 1 } },
    "5:2": { type: ControlType.SQUARE, meta: { x: 5, y: 2 } },
    "5:3": { type: ControlType.SQUARE, meta: { x: 5, y: 3 } },
    "5:4": { type: ControlType.SQUARE, meta: { x: 5, y: 4 } },
    "5:5": { type: ControlType.SQUARE, meta: { x: 5, y: 5 } },
    "5:6": { type: ControlType.SQUARE, meta: { x: 5, y: 6 } },
    "5:7": { type: ControlType.SQUARE, meta: { x: 5, y: 7 } },
    "5:8": { type: ControlType.SQUARE, meta: { x: 5, y: 8 } },
    //
    "6:1": { type: ControlType.SQUARE, meta: { x: 6, y: 1 } },
    "6:2": { type: ControlType.SQUARE, meta: { x: 6, y: 2 } },
    "6:3": { type: ControlType.SQUARE, meta: { x: 6, y: 3 } },
    "6:4": { type: ControlType.SQUARE, meta: { x: 6, y: 4 } },
    "6:5": { type: ControlType.SQUARE, meta: { x: 6, y: 5 } },
    "6:6": { type: ControlType.SQUARE, meta: { x: 6, y: 6 } },
    "6:7": { type: ControlType.SQUARE, meta: { x: 6, y: 7 } },
    "6:8": { type: ControlType.SQUARE, meta: { x: 6, y: 8 } },
    //
    "7:1": { type: ControlType.SQUARE, meta: { x: 7, y: 1 } },
    "7:2": { type: ControlType.SQUARE, meta: { x: 7, y: 2 } },
    "7:3": { type: ControlType.SQUARE, meta: { x: 7, y: 3 } },
    "7:4": { type: ControlType.SQUARE, meta: { x: 7, y: 4 } },
    "7:5": { type: ControlType.SQUARE, meta: { x: 7, y: 5 } },
    "7:6": { type: ControlType.SQUARE, meta: { x: 7, y: 6 } },
    "7:7": { type: ControlType.SQUARE, meta: { x: 7, y: 7 } },
    "7:8": { type: ControlType.SQUARE, meta: { x: 7, y: 8 } },
    //
    "8:1": { type: ControlType.SQUARE, meta: { x: 8, y: 1 } },
    "8:2": { type: ControlType.SQUARE, meta: { x: 8, y: 2 } },
    "8:3": { type: ControlType.SQUARE, meta: { x: 8, y: 3 } },
    "8:4": { type: ControlType.SQUARE, meta: { x: 8, y: 4 } },
    "8:5": { type: ControlType.SQUARE, meta: { x: 8, y: 5 } },
    "8:6": { type: ControlType.SQUARE, meta: { x: 8, y: 6 } },
    "8:7": { type: ControlType.SQUARE, meta: { x: 8, y: 7 } },
    "8:8": { type: ControlType.SQUARE, meta: { x: 8, y: 8 } },
    //
    back: { type: ControlType.BUTTON },
    notification: {
        up: ["flip", "history", HISTORY_SCROLL_DOWN, HISTORY_SCROLL_UP],
        down: ["history", HISTORY_SCROLL_UP, HISTORY_SCROLL_DOWN, "flip"],
        left: ["surrender", "pause"],
        right: ["pause", "surrender"],
        type: ControlType.BUTTON,
    },
    pause: {
        up: ["board:d:bottom", "board:d:top"],
        down: ["board:d:top", "board:d:bottom"],
        left: ["notification", "surrender"],
        right: ["surrender", "notification"],
        type: ControlType.BUTTON,
    },
    surrender: {
        up: ["board:e:bottom", "board:e:top"],
        down: ["board:e:top", "board:e:bottom"],
        left: ["pause", "notification"],
        right: ["notification", "pause"],
        type: ControlType.BUTTON,
    },
    draw: { disabled: true },
    flip: {
        up: ["history", HISTORY_SCROLL_DOWN, HISTORY_SCROLL_UP, "notification"],
        down: ["notification", "history", HISTORY_SCROLL_UP, HISTORY_SCROLL_DOWN],
        left: ["board:right:bottom"],
        right: ["board:left:top"],
        type: ControlType.BUTTON,
    },
    [HISTORY_SCROLL_UP]: {
        up: ["notification", "flip", HISTORY_SCROLL_DOWN],
        down: [HISTORY_SCROLL_DOWN, "flip", "notification"],
        left: "history",
        right: null,
        type: ControlType.BUTTON,
    },
    [HISTORY_SCROLL_DOWN]: {
        up: [HISTORY_SCROLL_UP, "notification", "flip"],
        down: ["flip", "notification", HISTORY_SCROLL_UP],
        left: "history",
        right: null,
        type: ControlType.BUTTON,
    },
    // virtual
    history: { type: ControlType.VIRTUAL, disabled: true },
    "history:up": { type: ControlType.VIRTUAL, disabled: true },
    "history:down": { type: ControlType.VIRTUAL, disabled: true },
    "board:e:top": { type: ControlType.VIRTUAL, disabled: true },
    "board:e:bottom": { type: ControlType.VIRTUAL, disabled: true },
    "board:d:top": { type: ControlType.VIRTUAL, disabled: true },
    "board:d:bottom": { type: ControlType.VIRTUAL, disabled: true },
    "board:left:top": { type: ControlType.VIRTUAL, disabled: true },
    "board:left:bottom": { type: ControlType.VIRTUAL, disabled: true },
    "board:right:top": { type: ControlType.VIRTUAL, disabled: true },
    "board:right:bottom": { type: ControlType.VIRTUAL, disabled: true },
    "board:right:bottom2": { type: ControlType.VIRTUAL, disabled: true },
    // without type
    none: { disabled: true },
    axes_x: { disabled: true },
    axes_y: { disabled: true },
};
