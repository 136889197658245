import { action, makeAutoObservable } from "mobx";

import { Controller } from "models/controller";
import { ControlType, DialogType, InviteType, LoadState, SceneType } from "models/enums";
import { UserListWithPages } from "models/user-list-with-pages";

const DEFAULT_CONTROL_ELEMENTS = {
    back: {
        up: ["invites:page:prev", "invites:page:next", "invites:last"],
        down: ["invites:first", "invites:page:prev", "invites:page:next"],
        left: null,
        right: null,
        type: ControlType.BUTTON,
    },
    "invites:page:prev": {
        up: ["invites:last", "invites:first", "back"],
        down: ["back", "invites:first", "invites:last"],
        left: ["invites:page:next"],
        right: ["invites:page:next"],
        type: ControlType.BUTTON,
    },
    "invites:page:next": {
        up: ["invites:last", "invites:first", "back"],
        down: ["back", "invites:first", "invites:last"],
        left: ["invites:page:prev"],
        right: ["invites:page:prev"],
        type: ControlType.BUTTON,
    },
    "invites:first": { type: ControlType.VIRTUAL },
    "invites:last": { type: ControlType.VIRTUAL },
    "invites:item:0": { type: ControlType.ITEM, meta: { index: 0 } },
    "invites:item:1": { type: ControlType.ITEM, meta: { index: 1 } },
    "invites:item:2": { type: ControlType.ITEM, meta: { index: 2 } },
    "invites:item:3": { type: ControlType.ITEM, meta: { index: 3 } },
    "invites:item:4": { type: ControlType.ITEM, meta: { index: 4 } },
    "invites:item:5": { type: ControlType.ITEM, meta: { index: 5 } },
    "invites:item:6": { type: ControlType.ITEM, meta: { index: 6 } },
    "invites:item:7": { type: ControlType.ITEM, meta: { index: 7 } },
};

export class UsersInvitedScene {
    _scenes;
    _app;
    _first_activate;
    _isActive;
    controller;
    state;
    invites;

    constructor(scenes, app) {
        makeAutoObservable(this, { _scenes: false, _app: false, _loader: action.bound });
        this._scenes = scenes;
        this._app = app;
        this._first_activate = true;
        this._isActive = false;
        this.state = LoadState.INIT;
        this.invites = new UserListWithPages({
            loader: this._loader,
            items: [],
            offset: 0,
            limit: 8,
            count: 0,
        });
        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                if (type === ControlType.BUTTON) {
                    switch (name) {
                        case "back":
                            this.back();
                            break;

                        case "invites:page:next":
                            this.invites && this.invites.next();
                            break;

                        case "invites:page:prev":
                            this.invites && this.invites.prev();
                            break;
                    }
                } else if (type === ControlType.ITEM) {
                    this.tap_by_item(this.invites.items[meta.index]);
                }
            },
            back: () => this.back(),
            name: "back",
            find: (name) => {
                if (name === "invites:first") {
                    name = "invites:item:0";
                } else if (name === "invites:last") {
                    const count = this.invites.items.length;
                    name = `invites:item:${count > 0 ? count - 1 : 0}`;
                }

                const element = { ...DEFAULT_CONTROL_ELEMENTS[name], name };
                if (element) {
                    switch (name) {
                        case "invites:page:prev":
                            element.disabled = !this.invites || !this.invites.has_prev;
                            break;

                        case "invites:page:next":
                            element.disabled = !this.invites || !this.invites.has_next;
                            break;
                    }

                    if (element.type === ControlType.ITEM) {
                        const index = element.meta.index;
                        element.disabled = index < 0 || index >= this.invites.items.length;
                    }
                }

                return element || null;
            },
            move: ({ type, meta, ...params }, direction) => {
                if (type === ControlType.ITEM) {
                    const { index = 0 } = meta;
                    const count = 8;
                    return (
                        {
                            up: [
                                ...(index > 0 ? [`invites:item:${index - 1}`] : []),
                                "back",
                                "invites:page:prev",
                                "invites:page:next",
                                "invites:last",
                            ],
                            down: [
                                ...(index < count - 1 ? [`invites:item:${index + 1}`] : []),
                                "invites:page:prev",
                                "invites:page:next",
                                "back",
                                "invites:first",
                            ],
                        }[direction] || null
                    );
                }
                return params[direction];
            },
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
        });
        this.tutorial = null;
    }

    get isLoading() {
        return !this._isActive;
    }

    back() {
        this._scenes.change(SceneType.FRIENDS);
    }

    get assistantState() {
        return this.assistant_state;
    }

    get assistant_state() {
        return {
            is_auto_help: true,
        };
    }

    get _server() {
        return this._app.server;
    }

    get count() {
        return this.invites ? this.invites.count : 0;
    }

    _loader({ offset, limit }) {
        // return this._server.friend.invites({ type: null, offset, limit });
        return this._server.friend.invite.list({ type: InviteType.INCOMING, offset, limit });
        // return this._server.friend.invites({ type: InviteType.OUTCOMING, offset, limit });
    }

    tap_by_item(item) {
        if (item && item.id) {
            this._app.windows.open_dialog(DialogType.FRIEND_INVITE, {
                invite_id: item.id,
                buttons: ["apply", "cancel", "block"],
                on_success: () => this.invites.reload(),
            });
        }
    }

    onPreload() {
        this._isActive = false;
        this.state = LoadState.SUCCESS;
        this.invites = new UserListWithPages({
            loader: this._loader,
            items: [],
            offset: 0,
            limit: 8,
            count: 0,
        });
        this.invites.reload();
    }

    onActivate() {
        this._isActive = true;
        this._app.assistant.fire_event("event_activate_scene", {
            is_first_activate: this._first_activate,
        });
        this._first_activate = false;
    }
}
