import { makeAutoObservable } from "mobx";

export default class GraphMover {
    _graph;
    _default;

    constructor(graph, def = undefined) {
        makeAutoObservable(this);
        this._graph = graph;
        this._default = def;
    }

    move(direction, node, children) {
        let nodeName = node && node.name ? node.name : undefined;
        if (!nodeName && this._default) {
            nodeName = this._default instanceof Function ? this._default(children) : this._default;
        }

        if (!(nodeName in this._graph)) {
            return undefined;
        }

        const directionName = this._graph[nodeName][direction] || undefined;
        if (directionName) {
            return children.find((item) => item && item.name === directionName);
        }

        return undefined;
    }
}
