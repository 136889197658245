import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Button, TextBox } from "components";
import { IconPause } from "components/icons";
import { Timer } from "models/timer";

import { Dialog, DialogTitle, DialogHeader, DialogFooter, DialogCircle } from "./dialog";

export const DialogPause = observer(({ controller, pause = null, disabled = false }) => {
    const [timer] = useState(() => new Timer(0));
    useEffect(() => {
        timer.reset(
            pause && pause.until_at_time
                ? Math.ceil(pause.until_at_time - new Date().getTime() / 1000.0)
                : 0,
        );
        const handle = setInterval(() => {
            timer.decrease();
            if (timer.seconds < 0) {
                clearInterval(handle);
            }
        }, 1000);
        return () => {
            timer.reset();
            clearInterval(handle);
        };
    }, [pause && pause.until_at_time]);

    return (
        <Dialog disabled={disabled} theme="info">
            <DialogHeader>
                <DialogCircle>
                    {timer && timer.seconds > 0 ? (
                        <TextBox title={timer.seconds} color="inherit" size="l" />
                    ) : (
                        <IconPause size="s" color="inherit" />
                    )}
                </DialogCircle>
            </DialogHeader>

            <DialogTitle title="Пауза" />

            <DialogFooter>
                <Button
                    name="exit_cancel"
                    text="Продолжить игру"
                    controller={controller}
                    size="s"
                />
                <Button
                    name="exit_ok"
                    text="Выйти в меню"
                    view="secondary"
                    controller={controller}
                    size="s"
                />
            </DialogFooter>
        </Dialog>
    );
});
