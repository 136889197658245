import { makeAutoObservable, runInAction } from "mobx";

import { ControlType, DialogType } from "models/enums";
import {
    Controller,
    CarouselGroup,
    GraphMover,
    Group,
    SimpleElement,
    SwitchGroup,
    OutBoundsMover,
} from "models/controller/v2";

export default class NotificationDialog {
    _app;
    _locks;
    _emitter;
    controller;
    activeItemName;

    constructor(app, _, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._locks = [];
        this._emitter = emitter;
        this.activeItemName = null;

        this.carousel = new CarouselGroup("notifications", {
            nodes: () =>
                this.items.map(({ type, item }) => {
                    const name = `item-${type}-${item.id}`;
                    return new SimpleElement(name, {
                        type: ControlType.ITEM,
                        disabled: () => this._locks.indexOf(`object-${type}-${item.id}`) !== -1,
                        meta: { type, item },
                        onClick: () => {
                            if (this.activeItemName === name) {
                                this.activeItemName = null;
                            } else {
                                this.activeItemName = name;
                            }
                        },
                    });
                }),
            basis: 1,
            direction: "row",
        });

        const findActiveNotify = () => {
            const { meta, disabled = true } =
                this.carousel.find((item) => item && item.name === this.activeItemName) || {};
            if (disabled) {
                return undefined;
            }

            const { type, item } = meta;
            const { id } = item;

            return { type, id, item };
        };

        const lockAcquire = (type, id) => {
            this._locks.push(`object-${type}-${id}`);
        };
        const lockRelease = (type, id) => {
            const lockName = `object-${type}-${id}`;
            this._locks.replace(this._locks.filter((item) => item !== lockName));
        };

        this.controller = new Controller(
            new SwitchGroup("root", {
                nodes: [
                    this.carousel,
                    new Group("controls", {
                        nodes: [
                            {
                                type: ControlType.BUTTON,
                                name: "item:accept",
                                onClick: () => {
                                    const { type = null, id = null } = findActiveNotify() || {};

                                    if (type === "invite") {
                                        runInAction(() => lockAcquire(type, id));
                                        this._app.server.friend.invite
                                            .accept(id)
                                            .catch(() => runInAction(() => lockRelease(type, id)));
                                    } else if (type === "request") {
                                        this._app.windows.open_dialog(DialogType.WAITING_PLAYER, {
                                            requestId: id,
                                        });
                                        this.close();
                                    }
                                },
                            },
                            {
                                type: ControlType.BUTTON,
                                name: "item:cancel",
                                onClick: () => {
                                    const { type = null, id = null } = findActiveNotify() || {};

                                    if (type === "invite") {
                                        runInAction(() => lockAcquire(type, id));
                                        this._app.server.friend.invite
                                            .cancel(id)
                                            .catch(() => runInAction(() => lockRelease(type, id)));
                                    } else if (type === "request") {
                                        runInAction(() => lockAcquire(type, id));
                                        this._app.server.pvp
                                            .cancel_request(id)
                                            .catch(() => runInAction(() => lockRelease(type, id)));
                                    }
                                },
                            },
                        ],
                        mover: new OutBoundsMover(
                            new GraphMover({
                                "item:accept": { left: "item:cancel", right: "item:cancel" },
                                "item:cancel": { left: "item:accept", right: "item:accept" },
                            }),
                            (direction) => {
                                this.activeItemName = null;
                                this.carousel.move(direction);
                                return undefined;
                            },
                        ),
                        onCancel: () => {
                            if (this.activeItemName) {
                                this.activeItemName = null;
                                return true;
                            }
                            return false;
                        },
                    }),
                ],
                switcher: (children) => {
                    const name = this.activeItemName ? "controls" : this.carousel.name;
                    return children.find((item) => item && item.name === name);
                },
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasDialogKeyboard(),
            },
        );
    }

    get items() {
        return [
            ...this._notification.friend_invites.map((item) => ({ type: "invite", item })),
            ...this._notification.player_vs_player_requests.map((item) => ({
                type: "request",
                item,
            })),
        ];
    }

    get _notification() {
        return this._app.notification;
    }

    get is_empty() {
        return this._notification.is_empty;
    }

    get invites() {
        return this._notification.friend_invites;
    }

    get requests() {
        return this._notification.player_vs_player_requests;
    }

    get has_incoming_invites() {
        return this._notification.has_incoming_invites;
    }

    back() {
        if (this.activeItemName) {
            this.controller.focus(this.activeItemName);
            this.activeItemName = null;
        } else {
            this.close();
        }
    }

    close() {
        this._emitter.emit("close", this);
    }

    markFriendInviteAsViewed({ id }) {
        this._app.server.friend.invite.viewed(id).catch((error) => {
            console.error(error);
        });
    }
}
