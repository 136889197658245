import { makeAutoObservable, when } from "mobx";

import { Controller } from "models/controller";
import { SceneType } from "models/enums";

const StepType = Object.freeze({
    INIT_ASSISTANT: "init-assistant",
    SHOW_LOGO: "show-logo",
    AUTH_TOKEN: "auth-token",
    USER_INFO: "user-info",
    USER_PROFILE: "user-profile",
});

export class InitScene {
    _app;
    _scenes;
    _progress;
    _show_logo_time;
    controller;

    constructor(scenes, app, { use_assistant = false, show_logo_time = 1000 } = {}) {
        makeAutoObservable(this, { _app: false, _scenes: false });
        this._app = app;
        this._scenes = scenes;
        this._show_logo_time = Math.max(show_logo_time, 0);
        this._progress = {
            value: 0,
            steps: {
                [StepType.SHOW_LOGO]: Math.max(Math.trunc(this._show_logo_time / 1000), 1),
                [StepType.AUTH_TOKEN]: 1,
                [StepType.USER_INFO]: 1,
                [StepType.USER_PROFILE]: 1,
            },
        };

        if (use_assistant) {
            this._progress.steps[StepType.INIT_ASSISTANT] = 1;
        }
        this.controller = new Controller({
            ok: ({ name, type, meta }) => null,
            back: ({ type, meta }) => this.back(),
            elements: {},
            has_touch_mode: () => this._app.windows.hasSceneKeyboard(),
        });
    }

    get progress() {
        const common_weight = Object.values(this._progress.steps).reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            this._progress.value,
        );
        return { percent: Math.trunc((this._progress.value / common_weight) * 100) };
    }

    back() {
        this._app.assistant.close_app({ trigger: "back_click" });
    }

    apply_step(step) {
        if (step in this._progress.steps) {
            this._progress.value += this._progress.steps[step] || 0;
            delete this._progress.steps[step];

            if (Object.keys(this._progress.steps).length === 0) {
                this._scenes.change(SceneType.HOME);
            }
        }
    }

    _retrieve_user_profile() {
        when(
            () => this._app.user.profile.id !== null,
            () => this.apply_step(StepType.USER_PROFILE),
        );
        when(
            () => this._app.user.auth_token !== null,
            () => this._app.user.refreshProfile(),
        );
    }

    _retrieve_auth_token() {
        this._scenes.assistant
            .retrieve_auth_token()
            .catch(() => this._scenes.assistant.retrieve_auth_token())
            .then(() => this.apply_step(StepType.AUTH_TOKEN));
    }

    _retrieve_user_info() {
        this._scenes.assistant
            .retrieve_user_info()
            .catch(() => this._scenes.assistant.retrieve_user_info())
            .then(() => this.apply_step(StepType.USER_INFO));
    }

    on_activate() {
        setTimeout(() => this.apply_step(StepType.SHOW_LOGO), this._show_logo_time);

        if (this._scenes.assistant.is_inited) {
            this.apply_step(StepType.INIT_ASSISTANT);
            this._retrieve_auth_token();
            this._retrieve_user_info();
        } else {
            when(
                () => this._scenes.assistant.is_inited === true,
                () => {
                    this.apply_step(StepType.INIT_ASSISTANT);
                    this._retrieve_auth_token();
                    this._retrieve_user_info();
                },
            );
        }
        this._retrieve_user_profile();
    }
}
