import { TUTORIAL_CONTROL_SKIP, TUTORIAL_CONTROL_NEXT } from "models/tutorial.buttons";

export default (scene) => ({
    ok: ({ name }) => {
        if (name === TUTORIAL_CONTROL_SKIP) {
            scene.apply_tutorial({ is_skipped: true });
        }
    },
    back: () => false,
    name: TUTORIAL_CONTROL_SKIP,
    find: (name) => {
        if (name === null) {
            return null;
        }

        if (name.match(/^([1-8]):([1-8])$/i)) {
            return { disabled: true };
        }

        return (
            {
                back: { disabled: true },
                [TUTORIAL_CONTROL_NEXT]: { disabled: true },
                [TUTORIAL_CONTROL_SKIP]: {},
            }[name] || null
        );
    },
});
