import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    Button,
    CardButton,
    Col,
    Container,
    Header,
    NotificationButton,
    RectSkeleton,
    Row,
} from "components";

import styles from "./scene.module.pcss";

export const ChoicePVPModeScene = observer(({ scene, skeleton = false, notification = null }) => (
    <Container className={clsx(styles.view)}>
        <Header title="Игра по сети" controller={scene.controller} skeleton={skeleton}>
            {skeleton ? (
                <RectSkeleton height="2.5rem" width="6.5rem" roundness={12} />
            ) : (
                <Button
                    name="top_of_ranks"
                    controller={scene.controller}
                    text="Рейтинги"
                    view="secondary"
                    size="s"
                />
            )}

            <NotificationButton
                name="notification"
                notification={notification}
                controller={scene.controller}
                skeleton={skeleton}
            />
        </Header>

        <Row className={clsx(styles.content)}>
            <Col
                sizeXL={9}
                offsetXL={1.5}
                sizeL={6}
                offsetL={1}
                sizeM={5}
                offsetM={0.5}
                sizeS={4}
                offsetS={0}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        flexGrow: 1,
                        justifyContent: "space-around",
                        alignSelf: "center",
                    }}
                >
                    <CardButton
                        name="find_player"
                        text="Простая игра"
                        controller={scene.controller}
                        skeleton={skeleton}
                    >
                        <div className={clsx(styles.logo, styles.simple)} />
                    </CardButton>

                    <CardButton
                        name="find_player_rating"
                        text="Игра на рейтинг"
                        controller={scene.controller}
                        skeleton={skeleton}
                    >
                        <div className={clsx(styles.logo, styles.rating)} />
                    </CardButton>
                </div>
            </Col>
        </Row>
    </Container>
));
