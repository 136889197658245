import { makeAutoObservable, runInAction } from "mobx";

import { GameInviteState, LoadState } from "models/enums";

const RequestState = Object.freeze({
    INIT: "init",
    LOADING: "loading",
    CANCELING: "canceling",
    WAITING: "waiting",
    FAILED: "failed",
    APPROVED: "approved",
});

export class MatchRequest {
    _server;
    _state;
    id;
    state;
    game_id;
    owner_user_id;
    enemy_user_id;
    modified_at;

    constructor(
        server,
        {
            id,
            state = GameInviteState.WAITING,
            owner_user_id = null,
            enemy_user_id = null,
            game_id = null,
            modified_at = 0,
        },
    ) {
        makeAutoObservable(this, { _server: false });
        this._server = server;
        this._state = LoadState.INIT;
        this.id = id;
        this.state = state;
        this.game_id = game_id;
        this.owner_user_id = owner_user_id;
        this.enemy_user_id = enemy_user_id;
        this.modified_at = modified_at;
    }

    async reload() {
        if (
            this.id &&
            this._state !== RequestState.LOADING &&
            this._state !== RequestState.CANCELING
        ) {
            // && this.state !== "FAILED" && this.state !== "APPROVED"
            this._state = LoadState.LOADING;
            try {
                const data = await this._server.pvp.retrieve_request(this.id);
                runInAction(() => {
                    this._update(data);
                    this._state = LoadState.SUCCESS;
                });
            } catch (exception) {
                console.error(exception);
                runInAction(() => (this._state = LoadState.FAIL));
            }
        }
    }

    async cancel() {
        if (this.id) {
            this._state = RequestState.CANCELING;
            try {
                const data = await this._server.pvp.cancel_request(this.id);
                this._update(data);
            } catch (exception) {
                console.error(exception);
            }
        }
    }

    _update({
        id,
        state = GameInviteState.WAITING,
        game_id = null,
        owner_user_id = null,
        enemy_user_id = null,
        modified_at = 0,
    }) {
        if (this.id === id && this.modified_at < modified_at) {
            this.state = state;
            this.game_id = game_id;
            this.owner_user_id = owner_user_id;
            this.enemy_user_id = enemy_user_id;
            this.modified_at = modified_at;
        }
    }
}
