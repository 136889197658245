import {
    load_user_rank,
    load_user_relation,
    load_user_status,
    save_user_rank,
} from "providers/server/types";

export class RatingAPI {
    constructor(server) {
        this.server = server;
    }

    async retrieve_top_by_user({ user_id = null, limit = 1 }) {
        const response = await this.server.post("/rating/top/by_user", { user_id, limit });
        const { self = {}, top = {} } = response.data;
        return {
            self: this._load_top_item(self),
            top: this._load_top_of_rank(top),
        };
    }

    async retrieve_top_by_rank({ rank = null, offset = 0, limit = 10 }) {
        const response = await this.server.post("/rating/top/by_rank", {
            rank: save_user_rank(rank, null),
            offset,
            limit,
        });
        const { top = {} } = response.data;
        return this._load_top_of_rank(top);
    }

    _load_top_of_rank({ rank, items, offset = 1, limit = 1, count = 0, modified_at = 0 }) {
        return {
            rank: load_user_rank(rank),
            items: (items || []).map((item) => this._load_top_item(item)),
            offset,
            limit,
            count,
            modified_at,
        };
    }

    _load_top_item({ number, user }) {
        return { number, user: this._load_user(user) };
    }

    _load_user({
        id,
        nickname,
        avatar_preset_id = 1,
        rating,
        rank,
        status = null,
        relation = null,
        is_self = false,
        modified_at,
    }) {
        return {
            id,
            nickname,
            avatar_preset_id,
            rating,
            rank: load_user_rank(rank),
            status: load_user_status(status),
            relation: load_user_relation(relation),
            is_self,
            modified_at,
        };
    }
}
