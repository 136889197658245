import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { PieceColor } from "lib/chess/enums";
import { GameResult } from "models/enums";

import { Button, Footnote1 } from "components";

import { Dialog, DialogFooter, DialogHeader, DialogTitle } from "./dialog";
import styles from "./dialog-finished.module.pcss";

const FinishIcon = observer(({ state = "win" }) => {
    switch (state) {
        case "draw":
            return <div className={clsx(styles.logo, styles.draw)} />;

        case "win":
            return <div className={clsx(styles.logo, styles.win)} />;

        case "loose":
            return <div className={clsx(styles.logo, styles.lose)} />;

        default:
            return null;
    }
});

export default observer(({ controller, board, disabled = false }) => {
    const sendInvite = controller.find_by_name("send_invite");
    const sendInviteDisabled = sendInvite ? sendInvite.disabled : true;
    const { result, color } = board.finished || {};

    const isDraw = result === GameResult.DRAW;
    const isWin =
        !isDraw &&
        ((
            {
                [PieceColor.WHITE]: [GameResult.WHITE_WON, GameResult.BLACK_SURRENDERED],
                [PieceColor.BLACK]: [GameResult.BLACK_WON, GameResult.WHITE_SURRENDERED],
            }[board.side] || []
        ).indexOf(result) !== -1 ||
            (color !== null && color === board.side && GameResult.WIN));
    const userState = board.side === PieceColor.WHITE ? board.white : board.black;
    // eslint-disable-next-line no-nested-ternary
    const text = isDraw ? "Ничья" : isWin ? "Победа" : "Поражение";

    return (
        <Dialog disabled={disabled} theme={isWin ? "success" : "info"}>
            <DialogHeader>
                {/* eslint-disable-next-line no-nested-ternary */}
                <FinishIcon color={board.side} state={isDraw ? "draw" : isWin ? "win" : "loose"} />
            </DialogHeader>

            <DialogTitle title={text}>
                {userState.rating_diff !== 0 && (
                    <Footnote1 className={clsx(styles.rating)}>
                        Рейтинг: {userState.rating_from}
                        <span
                            className={clsx({
                                [styles.negative]: userState.rating_diff < 0,
                                [styles.positive]: userState.rating_diff >= 0,
                            })}
                        >{` ${(userState.rating_diff >= 0 && "+") || ""}${
                            userState.rating_diff
                        }`}</span>
                    </Footnote1>
                )}
            </DialogTitle>

            <DialogFooter three={!sendInviteDisabled}>
                {!sendInviteDisabled && (
                    <Button
                        name="send_invite"
                        text="Добавить в друзья"
                        view="secondary"
                        controller={controller}
                        size="s"
                    />
                )}
                <Button
                    name="close_game"
                    text="Меню"
                    view="primary"
                    controller={controller}
                    size="s"
                />
                <Button
                    name="close_dialog"
                    text="Доска"
                    view="secondary"
                    controller={controller}
                    size="s"
                />
            </DialogFooter>
        </Dialog>
    );
});
