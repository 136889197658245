import React from "react";
import { observer } from "mobx-react-lite";

import { DialogType, SceneType } from "models/enums";
import { MonkeyPatchFontSize } from "components/monkey-patch-font-size";
import {
    ChoiceBotScene,
    ChoiceColorScene,
    ChoiceModeScene,
    ChoicePVPModeScene,
    ChoiceSpeedScene,
    ContinueScene,
    FriendsScene,
    GameScene,
    HomeScene,
    InitScene,
    ProfileSettingsEditorScene,
    TechInfoScene,
    TopOfRanksScene,
    UsersBlockedScene,
    UsersInvitedScene,
    UsersSearchScene,
    UsersSearchResultScene,
} from "scenes";
import {
    NotificationDialog,
    ProfileDialog,
    ProfileSettingsDialog,
    FriendInviteDialog,
    WaitingPlayerDialog,
    GameSurrenderDialog,
    AskGameInviteDialog,
    AskUserInviteDialog,
} from "dialogs";

// import styles from "./router.module.pcss";

const Scene = observer(({ type, scene, isLoading = false, notification = null, assistant }) => {
    switch (type) {
        case SceneType.INIT:
            return (
                <MonkeyPatchFontSize>
                    <InitScene
                        scene={scene}
                        skeleton={isLoading || scene.isLoading}
                        notification={notification}
                    />
                </MonkeyPatchFontSize>
            );

        case SceneType.HOME:
            return (
                <HomeScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.CONTINUE:
            return (
                <ContinueScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.GAME:
            return (
                <GameScene
                    scene={scene}
                    skeleton={isLoading}
                    notification={notification}
                    assistant={assistant}
                />
            );

        case SceneType.CHOICE_MODE:
            return (
                <ChoiceModeScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.CHOICE_SPEED:
            return (
                <MonkeyPatchFontSize>
                    <ChoiceSpeedScene
                        scene={scene}
                        skeleton={isLoading}
                        notification={notification}
                    />
                </MonkeyPatchFontSize>
            );

        case SceneType.CHOICE_COLOR:
            return (
                <ChoiceColorScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.CHOICE_BOT:
            return (
                <ChoiceBotScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.CHOICE_PVP_MODE:
            return (
                <ChoicePVPModeScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.TECH_INFO:
            return (
                <TechInfoScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.TOP_OF_RANKS:
            return (
                <TopOfRanksScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.FRIENDS:
            return (
                <FriendsScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.USERS_BLOCKED:
            return (
                <MonkeyPatchFontSize>
                    <UsersBlockedScene
                        scene={scene}
                        skeleton={isLoading || scene.isLoading}
                        notification={notification}
                    />
                </MonkeyPatchFontSize>
            );

        case SceneType.USERS_SEARCH:
            return (
                <UsersSearchScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.USERS_SEARCH_RESULT:
            return (
                <UsersSearchResultScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        case SceneType.USERS_INVITED:
            return (
                <MonkeyPatchFontSize>
                    <UsersInvitedScene
                        scene={scene}
                        skeleton={isLoading || scene.isLoading}
                        notification={notification}
                    />
                </MonkeyPatchFontSize>
            );

        case SceneType.PROFILE_SETTINGS_EDITOR:
            return (
                <ProfileSettingsEditorScene
                    scene={scene}
                    skeleton={isLoading || scene.isLoading}
                    notification={notification}
                />
            );

        default:
            return null;
    }
});

const Dialog = observer(({ type, dialog, isLoading = false, visible = false }) => {
    switch (type) {
        case DialogType.NOTIFICATION:
            return <NotificationDialog dialog={dialog} skeleton={isLoading || dialog.isLoading} />;

        case DialogType.PROFILE_SETTINGS:
            return (
                <ProfileSettingsDialog dialog={dialog} skeleton={isLoading || dialog.isLoading} />
            );

        case DialogType.PROFILE:
            return <ProfileDialog dialog={dialog} skeleton={isLoading || dialog.isLoading} />;

        case DialogType.FRIEND_INVITE:
            return <FriendInviteDialog dialog={dialog} skeleton={isLoading || dialog.isLoading} />;

        case DialogType.WAITING_PLAYER:
            return <WaitingPlayerDialog dialog={dialog} skeleton={isLoading || dialog.isLoading} />;

        case DialogType.GAME_SURRENDER:
            return <GameSurrenderDialog dialog={dialog} skeleton={isLoading || dialog.isLoading} />;

        case DialogType.ASK_GAME_INVITE:
            return (
                <AskGameInviteDialog
                    dialog={dialog}
                    skeleton={isLoading || dialog.isLoading}
                    visible={visible}
                />
            );

        case DialogType.ASK_USER_INVITE:
            return (
                <AskUserInviteDialog
                    dialog={dialog}
                    skeleton={isLoading || dialog.isLoading}
                    visible={visible}
                />
            );

        default:
            return null;
    }
});

export default observer(({ app }) => (
    <>
        <Scene
            type={app.windows.scenes.type}
            scene={app.windows.scenes.current}
            isLoading={app.windows.scenes.isLoading}
            notification={app.notification}
            assistant={app.assistant}
        />

        {app.windows.dialogs.stack.map(({ id, type, data }) => (
            <Dialog key={id} type={type} dialog={data} />
        ))}

        {app.windows.questions.stack.map(({ id, type, data }, index) => (
            <Dialog key={id} type={type} dialog={data} visible={index === 0} />
        ))}
    </>
));
