import { PieceColor, PieceType } from "lib/chess/enums";

import {
    GameInviteState,
    GameResult,
    GameType,
    InviteStatus,
    InviteType,
    UserRank,
    UserRelation,
    UserStatus,
} from "models/enums";

export function loadUserRelation(relation, def = UserRelation.NONE) {
    return (
        {
            NONE: UserRelation.NONE,
            FRIEND: UserRelation.FRIEND,
            BLOCKER: UserRelation.BLOCKED,
        }[relation] || def
    );
}

export function saveUserRelation(relation, def = UserRelation.NONE) {
    return (
        {
            [UserRelation.NONE]: "NONE",
            [UserRelation.FRIEND]: "FRIEND",
            [UserRelation.BLOCKED]: "BLOCKER",
        }[relation] || def
    );
}

export function loadUserStatus(status, def = UserStatus.OFFLINE) {
    return (
        {
            ONLINE: UserStatus.ONLINE,
            OFFLINE: UserStatus.OFFLINE,
            BUSY: UserStatus.BUSY,
        }[status] || def
    );
}

export function saveUserStatus(status, def = UserStatus.OFFLINE) {
    return (
        {
            [UserStatus.ONLINE]: "ONLINE",
            [UserStatus.OFFLINE]: "OFFLINE",
            [UserStatus.BUSY]: "BUSY",
        }[status] || def
    );
}

export function loadUserRank(rank, def = UserRank.NEWBIE) {
    return (
        {
            GRANDMASTER: UserRank.GRANDMASTER,
            INTERNATIONAL_MASTER: UserRank.INTERNATIONAL_MASTER,
            NATIONAL_MASTER: UserRank.NATIONAL_MASTER,
            CANDIDATE_MASTER: UserRank.CANDIDATE_MASTER,
            FIRST_GRADE: UserRank.FIRST_GRADE,
            SECOND_GRADE: UserRank.SECOND_GRADE,
            THIRD_GRADE: UserRank.THIRD_GRADE,
            FOURTH_GRADE: UserRank.FOURTH_GRADE,
            FIFTH_GRADE: UserRank.FIFTH_GRADE,
            NEWBIE: UserRank.NEWBIE,
        }[rank] || def
    );
}

export function saveUserRank(rank, def = UserRank.NEWBIE) {
    return (
        {
            [UserRank.GRANDMASTER]: "GRANDMASTER",
            [UserRank.INTERNATIONAL_MASTER]: "INTERNATIONAL_MASTER",
            [UserRank.NATIONAL_MASTER]: "NATIONAL_MASTER",
            [UserRank.CANDIDATE_MASTER]: "CANDIDATE_MASTER",
            [UserRank.FIRST_GRADE]: "FIRST_GRADE",
            [UserRank.SECOND_GRADE]: "SECOND_GRADE",
            [UserRank.THIRD_GRADE]: "THIRD_GRADE",
            [UserRank.FOURTH_GRADE]: "FOURTH_GRADE",
            [UserRank.FIFTH_GRADE]: "FIFTH_GRADE",
            [UserRank.NEWBIE]: "NEWBIE",
        }[rank] || def
    );
}

export function loadInviteType(type, def = null) {
    return (
        {
            INCOMING: InviteType.INCOMING,
            OUTCOMING: InviteType.OUTCOMING,
        }[type] || def
    );
}

export function saveInviteType(type, def = null) {
    return (
        {
            [InviteType.INCOMING]: "INCOMING",
            [InviteType.OUTCOMING]: "OUTCOMING",
        }[type] || def
    );
}

export function loadInviteStatus(status, def = null) {
    return (
        {
            ACCEPTED: InviteStatus.ACCEPTED,
            BLOCKED: InviteStatus.BLOCKED,
            CANCELED: InviteStatus.CANCELED,
            INVITED: InviteStatus.INVITED,
            IGNORED: InviteStatus.IGNORED,
        }[status] || def
    );
}

export function saveInviteStatus(status, def = null) {
    return (
        {
            [InviteStatus.ACCEPTED]: "ACCEPTED",
            [InviteStatus.BLOCKED]: "BLOCKED",
            [InviteStatus.CANCELED]: "CANCELED",
            [InviteStatus.INVITED]: "INVITED",
            [InviteStatus.IGNORED]: "IGNORED",
        }[status] || def
    );
}

export function loadGameResult(gameResult, def = GameResult.NONE) {
    return (
        {
            NONE: GameResult.NONE,
            WHITE_WON: GameResult.WHITE_WON,
            BLACK_WON: GameResult.BLACK_WON,
            DRAW: GameResult.DRAW,
            WHITE_SURRENDERED: GameResult.WHITE_SURRENDERED,
            BLACK_SURRENDERED: GameResult.BLACK_SURRENDERED,
        }[gameResult] || def
    );
}

export function saveGameResult(gameResult, def = GameResult.NONE) {
    return (
        {
            [GameResult.NONE]: "NONE",
            [GameResult.WHITE_WON]: "WHITE_WON",
            [GameResult.BLACK_WON]: "BLACK_WON",
            [GameResult.DRAW]: "DRAW",
            [GameResult.WHITE_SURRENDERED]: "WHITE_SURRENDERED",
            [GameResult.BLACK_SURRENDERED]: "BLACK_SURRENDERED",
        }[gameResult] || def
    );
}

export function loadGameType(gameType, def = null) {
    return (
        {
            HOT_SEAT: GameType.HOT_SEAT,
            MULTIPLAYER: GameType.WITH_PLAYER,
            WITH_PLAYER: GameType.WITH_PLAYER,
            WITH_PLAYER_RATING: GameType.WITH_PLAYER_RATING,
            SINGLE: GameType.WITH_ASSISTANT,
            WITH_BOT: GameType.WITH_ASSISTANT,
            WITH_ASSISTANT: GameType.WITH_ASSISTANT,
        }[gameType] || def
    );
}

export function saveGameType(gameType, def = null) {
    return (
        {
            [GameType.HOT_SEAT]: "HOT_SEAT",
            [GameType.WITH_PLAYER]: "WITH_PLAYER",
            [GameType.WITH_PLAYER_RATING]: "WITH_PLAYER_RATING",
            [GameType.WITH_ASSISTANT]: "WITH_ASSISTANT",
        }[gameType] || def
    );
}

export function loadPieceColor(color, def = null) {
    return (
        {
            WHITE: PieceColor.WHITE,
            BLACK: PieceColor.BLACK,
        }[color] || def
    );
}

export function savePieceColor(color, def = null) {
    return (
        {
            [PieceColor.WHITE]: "WHITE",
            [PieceColor.BLACK]: "BLACK",
        }[color] || def
    );
}

export function loadPieceType(pieceType, def = null) {
    return (
        {
            KING: PieceType.KING,
            QUEEN: PieceType.QUEEN,
            BISHOP: PieceType.BISHOP,
            KNIGHT: PieceType.KNIGHT,
            ROOK: PieceType.ROOK,
            PAWN: PieceType.PAWN,
        }[pieceType] || def
    );
}

export function savePieceType(pieceType, def = null) {
    return (
        {
            [PieceType.KING]: "KING",
            [PieceType.QUEEN]: "QUEEN",
            [PieceType.BISHOP]: "BISHOP",
            [PieceType.KNIGHT]: "KNIGHT",
            [PieceType.ROOK]: "ROOK",
            [PieceType.PAWN]: "PAWN",
        }[pieceType] || def
    );
}

export function loadGameInviteState(state, def = GameInviteState.NONE) {
    return (
        {
            [null]: GameInviteState.NONE,
            WAITING: GameInviteState.WAITING,
            AGREEMENT: GameInviteState.AGREEMENT,
            FAILED: GameInviteState.FAILED,
            CANCELED: GameInviteState.CANCELED,
            APPROVED: GameInviteState.APPROVED,
        }[state] || def
    );
}

export function saveGameInviteState(state, def = GameInviteState.NONE) {
    return (
        {
            [GameInviteState.NONE]: null,
            [GameInviteState.WAITING]: "WAITING",
            [GameInviteState.AGREEMENT]: "AGREEMENT",
            [GameInviteState.FAILED]: "FAILED",
            [GameInviteState.CANCELED]: "CANCELED",
            [GameInviteState.APPROVED]: "APPROVED",
        }[state] || def
    );
}

/* eslint-disable camelcase */
export const load_user_relation = loadUserRelation;
export const save_user_relation = saveUserRelation;

export const load_user_status = loadUserStatus;
export const save_user_status = saveUserStatus;

export const load_user_rank = loadUserRank;
export const save_user_rank = saveUserRank;

export const load_invite_type = loadInviteType;
export const save_invite_type = saveInviteType;

export const load_invite_status = loadInviteStatus;
export const save_invite_status = saveInviteStatus;

export const load_game_result = loadGameResult;
export const save_game_result = saveGameResult;

export const load_game_type = loadGameType;
export const save_game_type = saveGameType;

export const load_piece_color = loadPieceColor;
export const save_piece_color = savePieceColor;

export const load_piece_type = loadPieceType;
export const save_piece_type = savePieceType;
/* eslint-enable camelcase */
