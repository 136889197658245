import { makeAutoObservable } from "mobx";

import { PieceColor } from "lib/chess/enums";

import { Controller } from "models/controller";
import { ControlType, DialogType, GameType, SceneType, TutorialType } from "models/enums";

export class HomeScene {
    _app;
    _scenes;
    _game;
    _first_activate;
    _isActive;
    controller;
    tutorial;

    constructor(scenes, app, game) {
        makeAutoObservable(this, { _scenes: false, _game: false, _app: false });
        this._app = app;
        this._scenes = scenes;
        this._game = game;
        this._first_activate = true;
        this._isActive = false;
        this.tutorial = null;
        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                switch (name) {
                    case "new":
                        this.new_game();
                        break;

                    case "continue":
                        this._app.windows.scenes.open(SceneType.CONTINUE);
                        break;

                    case "notification":
                        this._app.windows.dialogs.open(DialogType.NOTIFICATION);
                        break;

                    case "settings":
                        this._app.windows.dialogs.open(DialogType.PROFILE_SETTINGS);
                        break;
                }
            },
            back: (params) => this.back(params),
            name: "new",
            find: (name) => {
                return (
                    {
                        notification: {
                            left: ["settings"],
                            right: null,
                            up: ["continue", "new"],
                            down: "new",
                            disabled: this.tutorial === TutorialType.START_NEW_GAME,
                        },
                        settings: {
                            left: ["new"],
                            right: ["notification"],
                            up: ["continue", "new"],
                            down: "new",
                            disabled: this.tutorial === TutorialType.START_NEW_GAME,
                        },
                        new: {
                            right: ["settings"],
                            up: ["settings", "notification", "continue"],
                            down: ["continue", "settings", "notification"],
                        },
                        continue: {
                            right: ["settings"],
                            up: ["new", "settings", "notification"],
                            down: ["settings", "notification", "new"],
                            disabled: this.tutorial === TutorialType.START_NEW_GAME,
                        },
                    }[name] || null
                );
            },
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
        });
    }

    get isLoading() {
        return !this._isActive;
    }

    new_game() {
        this._game.options.reset();
        this._scenes.change(SceneType.CHOICE_MODE);
        this._scenes.assistant.fire_event("new_game");
    }

    back() {
        this._app.assistant.close_app({ trigger: "back_click" });
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
        this.tutorial = null;
        this.controller.unlock();
        if (!this._app.user.tutorial.start_new_game) {
            this.tutorial = TutorialType.START_NEW_GAME;
            // this._app.assistant.buttons();
            this._app.assistant.transition("/tutorial/start_new_game");
            this.controller.push({
                ok: ({ name, type }) => {
                    if (type === ControlType.BUTTON && name === "new") {
                        this.controller.lock();

                        this._app.game.options.reset();
                        this._app.game.options.set_game_type(GameType.WITH_ASSISTANT);
                        this._app.game.options.set_color(PieceColor.WHITE);
                        this._app.game.options.bot.set_level(10);
                        this._app.game
                            .create_game()
                            .then(() => this._app.user.apply_tutorial("start_new_game"))
                            .catch(() => {
                                this._app.windows.scenes.change(SceneType.HOME);
                            });

                        this._app.windows.scenes.change(SceneType.GAME);
                        this.controller.pop();
                    }
                },
                back: () => this.back(),
                name: "new",
                elements: { new: { type: ControlType.BUTTON } },
            });
        } else {
            this._app.assistant.fire_event("event_activate_scene", {
                is_first_activate: this._first_activate,
            });
        }
        this._first_activate = false;
    }
}
