import { GameType } from "models/enums";

import {
    load_piece_color,
    load_invite_status,
    load_invite_type,
    load_user_rank,
    loadUserRelation,
    loadPlayerVsPlayerRequestState,
    loadGameInviteState,
} from "./types";

export class NotificationAPI {
    constructor(server) {
        this.server = server;
    }

    async list({ color = null, game_type = GameType.WITH_PLAYER } = {}) {
        const response = await this.server.post("/notification/list", {});
        const { friend_invites = [], player_vs_player_requests } = response.data;
        return {
            friend_invites: (friend_invites || []).map((item) => this._load_invite_object(item)),
            player_vs_player_requests: (player_vs_player_requests || []).map((item) =>
                this._load_player_vs_player_object(item),
            ),
        };
    }

    _load_user_object({
        id,
        nickname = null,
        avatar_preset_id = 0,
        rating = 0,
        rank = null,
        modified_at = 0,
    }) {
        return { id, nickname, avatar_preset_id, rating, rank: load_user_rank(rank), modified_at };
    }

    _load_invite_object({
        id,
        type = null,
        status = null,
        from_user = {},
        from_viewed_at = null,
        to_user = {},
        to_viewed_at = null,
        modified_at = 0,
        created_at = 0,
    }) {
        return {
            id,
            type: load_invite_type(type),
            status: load_invite_status(status),
            from_user: this._load_user_object(from_user),
            from_viewed_at,
            to_user: this._load_user_object(to_user),
            to_viewed_at,
            modified_at,
            created_at,
        };
    }

    _load_player_vs_player_object({
        id,
        game_type: gameType,
        color,
        state,
        user = {},
        from_user: fromUser = null,
        from_viewed_at: fromViewedAt = null,
        to_user: toUser = null,
        to_viewed_at: toViewedAt = null,
        modified_at: modifiedAt = 0,
        created_at: createdAt = 0,
    }) {
        return {
            id,
            game_type: { WITH_PLAYER: GameType.WITH_PLAYER }[gameType] || null, // deprecated, should use gameType
            gameType: { WITH_PLAYER: GameType.WITH_PLAYER }[gameType] || null,
            color: load_piece_color(color),
            state: loadGameInviteState(state),
            user: this._load_user_object(user), // deprecated. Should use fromUser
            fromUser: fromUser ? this._load_user_object(fromUser) : null,
            fromViewedAt,
            toUser: toUser ? this._load_user_object(toUser) : null,
            toViewedAt,
            modified_at: modifiedAt, // deprecated
            modifiedAt,
            created_at: createdAt, // deprecated
            createdAt,
        };
    }
}
