import { makeAutoObservable } from "mobx";

import { AudioBuffer } from "./buffer";
import { AudioContext } from "./context";

export class SoundManager {
    _context;
    _volume_node;
    _volume;
    _buffers;

    constructor({ volume = 1, muted = true }) {
        makeAutoObservable(this);
        this._context = new AudioContext();
        this._volume = volume;
        this._volume_node = this._context.createGain();
        this._volume_node.gain.value = volume ? !muted : 0;
        this._volume_node.connect(this._context.destination);

        this._buffers = {};
    }

    get is_mute() {
        return this._volume_node.gain.value <= 0;
    }

    mute() {
        this._volume_node.gain.value = 0;
    }

    unmute() {
        this._volume_node.gain.value = this._volume;
    }

    add(name, url, { pre_load = false }) {
        this._buffers[name] = new AudioBuffer(this._context, { name, url });
        if (pre_load) {
            return this._buffers[name].load();
        }
        return Promise.resolve();
    }

    play(name) {
        if (name in this._buffers) {
            this._buffers[name].create_source().then((source) => {
                source.connect(this._volume_node);
                source.start();
            });
        } else {
            console.warn("Not found source for sound", name);
        }
    }
}
