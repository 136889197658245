import clsx from "clsx";
import React, { useRef } from "react";
import { observer } from "mobx-react-lite";

import { RectSkeleton } from "components";
import { IconBack } from "components/icons";
import { PieceColor, PieceType } from "lib/chess/enums";
import { ControlType } from "models/enums";

import styles from "./game-board.module.pcss";

export const BoardPiece = observer(
    ({
        piece,
        x,
        y,
        is_moving: isMoving = false,
        onPointerDown = null,
        flipped = false,
        animation = null,
    }) => {
        const style = {};
        if (isMoving) {
            style.zIndex = 2;
            style.transform = `translate(${x * 100 - 150}%, ${(8 - y) * 100 + 50}%) scale(1.5)`;
        }

        return (
            <div
                className={clsx(styles.cell, styles[`cell${Math.ceil(x)}${Math.ceil(y)}`], {
                    [styles.try_moving]:
                        !isMoving && animation && animation.toLowerCase() === "scale",
                })}
                style={style}
                onPointerDown={onPointerDown}
            >
                <div
                    style={{ width: "100%", height: "100%" }}
                    className={clsx(styles.piece, {
                        [styles.flipped]: flipped,
                        [styles.white]: piece.color === PieceColor.WHITE,
                        [styles.black]: piece.color === PieceColor.BLACK,
                        [styles.king]: piece.type === PieceType.KING,
                        [styles.queen]: piece.type === PieceType.QUEEN,
                        [styles.bishop]: piece.type === PieceType.BISHOP,
                        [styles.knight]: piece.type === PieceType.KNIGHT,
                        [styles.rook]: piece.type === PieceType.ROOK,
                        [styles.pawn]: piece.type === PieceType.PAWN,
                    })}
                    onPointerDown={onPointerDown}
                />
            </div>
        );
    },
);

export const BoardCell = observer(
    ({
        cell,
        mover,
        promotion,
        controller,
        flipped = false,
        try_moving = null,
        is_show_moves = true,
    }) => {
        let { piece, x, y } = cell,
            is_moving = false,
            animation = null;

        if (try_moving) {
            if (cell.square === try_moving.from.square) {
                piece = null;
            } else if (cell.square === try_moving.to.square) {
                piece = { color: try_moving.color, type: try_moving.promotion || try_moving.piece };
                animation = "scale";
            }
        }

        const element = controller.find_by_name(`${x}:${y}`) || {};
        const disabled = element.disabled;
        if (piece === null || (promotion.is_active && promotion.from_cell === cell)) {
            return disabled ? (
                <div className={clsx(styles.fog, styles.cell, styles[`cell${x}${y}`])} />
            ) : null;
        }

        if (mover.piece === piece) {
            is_moving = true;
            x = mover.x;
            y = mover.y;
        }

        return (
            <React.Fragment>
                {cell.is_alarm && (
                    <div
                        className={clsx(
                            styles.cell,
                            styles.alarm,
                            styles[`cell${cell.x}${cell.y}`],
                        )}
                    />
                )}

                <BoardPiece
                    piece={piece}
                    x={x}
                    y={y}
                    is_moving={is_moving}
                    flipped={flipped}
                    animation={animation}
                    onPointerDown={(event) => {
                        if (promotion.is_active) {
                            controller.click("close");

                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }}
                />

                {disabled && (
                    <div className={clsx(styles.fog, styles.cell, styles[`cell${x}${y}`])} />
                )}
            </React.Fragment>
        );
    },
);

export const BoardAxes = observer(({ flipped = false, fog = false, fog_lights = "" }) => {
    const color_white = clsx(styles.axe, { [styles.black]: flipped, [styles.white]: !flipped });
    const color_black = clsx(styles.axe, { [styles.white]: flipped, [styles.black]: !flipped });
    const font_size = 4,
        axe_x = {
            1: { x: 3.8 + 1, y: 99, fontSize: font_size },
            2: { x: 3.8 + 13.5, y: 99, fontSize: font_size },
            3: { x: 3.8 + 26, y: 99, fontSize: font_size },
            4: { x: 3.8 + 38.5, y: 99, fontSize: font_size },
            5: { x: 3.8 + 51, y: 99, fontSize: font_size },
            6: { x: 3.8 + 63.5, y: 99, fontSize: font_size },
            7: { x: 3.8 + 76, y: 99, fontSize: font_size },
            8: { x: 3.8 + 88.5, y: 99, fontSize: font_size },
            11: { x: 3.8 + 1, y: 4, fontSize: font_size },
            12: { x: 3.8 + 13.5, y: 4, fontSize: font_size },
            13: { x: 3.8 + 26, y: 4, fontSize: font_size },
            14: { x: 3.8 + 38.5, y: 4, fontSize: font_size },
            15: { x: 3.8 + 51, y: 4, fontSize: font_size },
            16: { x: 3.8 + 63.5, y: 4, fontSize: font_size },
            17: { x: 3.8 + 76, y: 4, fontSize: font_size },
            18: { x: 3.8 + 88.5, y: 4, fontSize: font_size },
        },
        axe_y = {
            1: { x: 1.5, y: 3.5 + 3.5, fontSize: font_size },
            2: { x: 1.5, y: 3.5 + 15.75, fontSize: font_size },
            3: { x: 1.5, y: 3.5 + 28.25, fontSize: font_size },
            4: { x: 1.5, y: 3.5 + 40.75, fontSize: font_size },
            5: { x: 1.5, y: 3.5 + 53.25, fontSize: font_size },
            6: { x: 1.5, y: 3.5 + 65.75, fontSize: font_size },
            7: { x: 1.5, y: 3.5 + 78.25, fontSize: font_size },
            8: { x: 1.5, y: 3.5 + 90.75, fontSize: font_size },
            11: { x: 96.25, y: 3.5 + 3.5, fontSize: font_size },
            12: { x: 96.25, y: 3.5 + 15.75, fontSize: font_size },
            13: { x: 96.25, y: 3.5 + 28.25, fontSize: font_size },
            14: { x: 96.25, y: 3.5 + 40.75, fontSize: font_size },
            15: { x: 96.25, y: 3.5 + 53.25, fontSize: font_size },
            16: { x: 96.25, y: 3.5 + 65.75, fontSize: font_size },
            17: { x: 96.25, y: 3.5 + 78.25, fontSize: font_size },
            18: { x: 96.25, y: 3.5 + 90.75, fontSize: font_size },
        };
    return (
        <>
            <div className={clsx(styles.axes, { [styles.fog]: fog, [styles.hidden]: flipped })}>
                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.vertical, { [styles.fog_light]: fog_lights === "y" })}
                >
                    <text {...axe_y[1]} className={color_black}>
                        8
                    </text>
                    <text {...axe_y[2]} className={color_white}>
                        7
                    </text>
                    <text {...axe_y[3]} className={color_black}>
                        6
                    </text>
                    <text {...axe_y[4]} className={color_white}>
                        5
                    </text>
                    <text {...axe_y[5]} className={color_black}>
                        4
                    </text>
                    <text {...axe_y[6]} className={color_white}>
                        3
                    </text>
                    <text {...axe_y[7]} className={color_black}>
                        2
                    </text>
                    <text {...axe_y[8]} className={color_white}>
                        1
                    </text>
                </svg>

                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.vertical, styles.right, {
                        [styles.fog_light]: fog_lights === "y",
                    })}
                >
                    <text {...axe_y[11]} className={color_black}>
                        8
                    </text>
                    <text {...axe_y[12]} className={color_white}>
                        7
                    </text>
                    <text {...axe_y[13]} className={color_black}>
                        6
                    </text>
                    <text {...axe_y[14]} className={color_white}>
                        5
                    </text>
                    <text {...axe_y[15]} className={color_black}>
                        4
                    </text>
                    <text {...axe_y[16]} className={color_white}>
                        3
                    </text>
                    <text {...axe_y[17]} className={color_black}>
                        2
                    </text>
                    <text {...axe_y[18]} className={color_white}>
                        1
                    </text>
                </svg>

                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.horizontal, { [styles.fog_light]: fog_lights === "x" })}
                >
                    <text {...axe_x[1]} className={color_white}>
                        a
                    </text>
                    <text {...axe_x[2]} className={color_black}>
                        b
                    </text>
                    <text {...axe_x[3]} className={color_white}>
                        c
                    </text>
                    <text {...axe_x[4]} className={color_black}>
                        d
                    </text>
                    <text {...axe_x[5]} className={color_white}>
                        e
                    </text>
                    <text {...axe_x[6]} className={color_black}>
                        f
                    </text>
                    <text {...axe_x[7]} className={color_white}>
                        g
                    </text>
                    <text {...axe_x[8]} className={color_black}>
                        h
                    </text>
                </svg>

                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.horizontal, styles.top, {
                        [styles.fog_light]: fog_lights === "x",
                    })}
                >
                    <text {...axe_x[11]} className={color_white}>
                        a
                    </text>
                    <text {...axe_x[12]} className={color_black}>
                        b
                    </text>
                    <text {...axe_x[13]} className={color_white}>
                        c
                    </text>
                    <text {...axe_x[14]} className={color_black}>
                        d
                    </text>
                    <text {...axe_x[15]} className={color_white}>
                        e
                    </text>
                    <text {...axe_x[16]} className={color_black}>
                        f
                    </text>
                    <text {...axe_x[17]} className={color_white}>
                        g
                    </text>
                    <text {...axe_x[18]} className={color_black}>
                        h
                    </text>
                </svg>
            </div>

            <div
                className={clsx(styles.axes, { [styles.fog]: fog, [styles.hidden]: !flipped })}
                style={{ transform: "rotate(180deg)" }}
            >
                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.vertical, { [styles.fog_light]: fog_lights === "y" })}
                >
                    <text {...axe_y[8]} className={color_black}>
                        8
                    </text>
                    <text {...axe_y[7]} className={color_white}>
                        7
                    </text>
                    <text {...axe_y[6]} className={color_black}>
                        6
                    </text>
                    <text {...axe_y[5]} className={color_white}>
                        5
                    </text>
                    <text {...axe_y[4]} className={color_black}>
                        4
                    </text>
                    <text {...axe_y[3]} className={color_white}>
                        3
                    </text>
                    <text {...axe_y[2]} className={color_black}>
                        2
                    </text>
                    <text {...axe_y[1]} className={color_white}>
                        1
                    </text>
                </svg>

                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.vertical, styles.right, {
                        [styles.fog_light]: fog_lights === "y",
                    })}
                >
                    <text {...axe_y[18]} className={color_black}>
                        8
                    </text>
                    <text {...axe_y[17]} className={color_white}>
                        7
                    </text>
                    <text {...axe_y[16]} className={color_black}>
                        6
                    </text>
                    <text {...axe_y[15]} className={color_white}>
                        5
                    </text>
                    <text {...axe_y[14]} className={color_black}>
                        4
                    </text>
                    <text {...axe_y[13]} className={color_white}>
                        3
                    </text>
                    <text {...axe_y[12]} className={color_black}>
                        2
                    </text>
                    <text {...axe_y[11]} className={color_white}>
                        1
                    </text>
                </svg>

                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.horizontal, { [styles.fog_light]: fog_lights === "x" })}
                >
                    <text {...axe_x[8]} className={color_white}>
                        a
                    </text>
                    <text {...axe_x[7]} className={color_black}>
                        b
                    </text>
                    <text {...axe_x[6]} className={color_white}>
                        c
                    </text>
                    <text {...axe_x[5]} className={color_black}>
                        d
                    </text>
                    <text {...axe_x[4]} className={color_white}>
                        e
                    </text>
                    <text {...axe_x[3]} className={color_black}>
                        f
                    </text>
                    <text {...axe_x[2]} className={color_white}>
                        g
                    </text>
                    <text {...axe_x[1]} className={color_black}>
                        h
                    </text>
                </svg>

                <svg
                    viewBox="0 0 100 100"
                    className={clsx(styles.horizontal, styles.top, {
                        [styles.fog_light]: fog_lights === "x",
                    })}
                >
                    <text {...axe_x[18]} className={color_white}>
                        a
                    </text>
                    <text {...axe_x[17]} className={color_black}>
                        b
                    </text>
                    <text {...axe_x[16]} className={color_white}>
                        c
                    </text>
                    <text {...axe_x[15]} className={color_black}>
                        d
                    </text>
                    <text {...axe_x[14]} className={color_white}>
                        e
                    </text>
                    <text {...axe_x[13]} className={color_black}>
                        f
                    </text>
                    <text {...axe_x[12]} className={color_white}>
                        g
                    </text>
                    <text {...axe_x[11]} className={color_black}>
                        h
                    </text>
                </svg>
            </div>
        </>
    );
});

export const BoardPromotion = observer(
    ({ promotion, controller, flipped = false, isPortal = false }) => {
        if (!promotion.is_active) {
            return null;
        }

        const color = promotion.from_cell.piece.color,
            // x = flipped ? 9 - promotion.to_cell.x : promotion.to_cell.x,
            x = promotion.to_cell.x,
            y = promotion.to_cell.y,
            reverse = color === PieceColor.BLACK;
        const style = { transform: `translateX(${x * 100 - 100}%)` };
        // const style = {};

        return (
            <div className={clsx(styles.promotion, { [styles.reverse]: reverse })} style={style}>
                {promotion.items.map((item, index) => (
                    <div
                        key={index}
                        className={clsx(styles.piece, {
                            [styles.flipped]: flipped,
                            [styles.selected]:
                                !controller.is_touch_mode &&
                                controller.focusedName === item.promotion,
                            [styles.white]: color === PieceColor.WHITE,
                            [styles.black]: color === PieceColor.BLACK,
                            [styles.king]: item.promotion === PieceType.KING,
                            [styles.queen]: item.promotion === PieceType.QUEEN,
                            [styles.bishop]: item.promotion === PieceType.BISHOP,
                            [styles.knight]: item.promotion === PieceType.KNIGHT,
                            [styles.rook]: item.promotion === PieceType.ROOK,
                            [styles.pawn]: item.promotion === PieceType.PAWN,
                        })}
                        onClick={() => controller.click(item.promotion)}
                    />
                ))}
                <div
                    className={clsx(styles.close, {
                        [styles.selected]:
                            !controller.is_touch_mode && controller.focusedName === "close",
                        [styles.flipped]: flipped,
                    })}
                    onClick={() => controller.click("close")}
                >
                    <IconBack
                        size={isPortal ? "xs" : "s"}
                        color={
                            color === PieceColor.BLACK
                                ? "var(--plasma-colors-overlay, rgba(255, 255, 255, 0.96))"
                                : "var(--plasma-colors-primary, rgba(255, 255, 255, 0.96))"
                        }
                    />
                </div>
            </div>
        );
    },
);

// eslint-disable-next-line react/prop-types
export const BoardCellSelected = observer(({ controller }) => {
    const element = controller.focusedElement;
    if (!element || element.type !== ControlType.SQUARE || controller.is_touch_mode) {
        return null;
    }
    const { x, y } = element.meta;
    return <div className={clsx(styles.cell, styles.selected, styles[`cell${x}${y}`])} />;
});

// eslint-disable-next-line react/prop-types
const LastMove = ({ move = null }) => {
    if (!move) {
        return null;
    }
    const { x: xf, y: yf } = move.from;
    const { x: xt, y: yt } = move.to;
    return (
        <>
            <div
                className={clsx(
                    styles.cell,
                    styles.last_move,
                    styles.from,
                    styles[`cell${xf}${yf}`],
                )}
            />
            <div
                className={clsx(styles.cell, styles.last_move, styles.to, styles[`cell${xt}${yt}`])}
            />
        </>
    );
};

const SquareSelect = ({ square = null, color = "from" }) => {
    if (!square) {
        return null;
    }
    const { x, y } = square;
    return (
        <div className={clsx(styles.cell, styles.select, styles[`cell${x}${y}`])}>
            <div
                style={{
                    backgroundColor:
                        {
                            from: "var(--board-square-last-move-from-color, hsla(0, 0%, 0%, 0.25))",
                            to: "var(--board-square-last-move-to-color, hsla(0, 0%, 0%, 0.25))",
                            [PieceColor.BLACK]:
                                "var(--board-square-dot-black-color, hsla(0, 0%, 0%, 0.25))",
                            [PieceColor.WHITE]:
                                "var(--board-square-dot-white-color, hsla(0, 0%, 0%, 0.25))",
                        }[color] || "var(--board-square-dot-color, hsla(0, 0%, 0%, 0.25))",
                }}
            />
        </div>
    );
};

const SquareDot = ({ square = null, type = "simple", color = "accent", flipped = false }) => {
    if (!square) {
        return null;
    }
    const { x, y } = square;
    const count = { simple: 1, king_side_castling: 2, queen_side_castling: 3 }[type];
    const items = new Array(count).fill();
    return (
        <div className={clsx(styles.cell, styles.dot, styles[`cell${x}${y}`])}>
            {items.map((_, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor:
                            {
                                [PieceColor.BLACK]:
                                    "var(--board-square-dot-black-color, hsla(0, 0%, 0%, 0.25))",
                                [PieceColor.WHITE]:
                                    "var(--board-square-dot-white-color, hsla(0, 0%, 0%, 0.25))",
                            }[color] || "var(--board-square-dot-color, hsla(0, 0%, 0%, 0.25))",
                    }}
                />
            ))}
        </div>
    );
};

export const GameBoard = observer(({ board, controller, skeleton, isPortal = false }) => {
    const startTap = useRef({ time: 0, finished: true, with_piece: false });

    const calcPositionOnBoard = (event) => {
        const board_element = event.currentTarget.getBoundingClientRect(),
            x = ((event.clientX - board_element.left) / board_element.width) * 8,
            y = ((event.clientY - board_element.top) / board_element.height) * 8;
        return {
            x: (board.flipped ? 9 : 1) + (board.flipped ? -1 : 1) * x,
            y: (board.flipped ? 1 : 9) + (board.flipped ? 1 : -1) * y,
        };
    };

    const on_moving = (event) => {
        board.mover.update(calcPositionOnBoard(event));
    };
    const on_cancel_moving = () => {
        if (!startTap.current.processed) {
            board.mover.cancel();
        }
    };
    const on_finish_moving = () => {
        if (board.mover.to_cell) {
            const { x, y } = board.mover.to_cell;
            controller.click(`${x}:${y}`);
        }
        board.mover.cancel();
    };

    const on_pointer_down = (event) => {
        const with_piece = board.mover.piece !== null;
        if (!with_piece) {
            const { x, y } = calcPositionOnBoard(event);
            controller.click(`${Math.floor(x)}:${Math.floor(y)}`);
        }

        startTap.current = { time: new Date().getTime(), processed: false, with_piece };
        on_moving(event);
    };
    const on_pointer_up = (event) => {
        const now = new Date().getTime();
        if (now - startTap.current.time > 200) {
            on_finish_moving(event);
        } else if (startTap.current.with_piece) {
            on_moving(event);
            on_finish_moving(event);
        }
        startTap.current.processed = true;
    };

    return (
        <div
            className={clsx("board-theme-003", "piece-theme-003", styles.root, {
                [styles.flipped]: board.flipped,
            })}
        >
            <div
                className={clsx(styles.view, {
                    // [styles.flipped]: board.flipped,
                    [styles.skeleton]: skeleton,
                })}
            >
                {skeleton ? (
                    <React.Fragment>
                        <RectSkeleton width="100%" height="100%" />
                        <div className={clsx(styles.preload)}>
                            <div className={clsx(styles.squares)} />

                            <div className={clsx(styles.piece, styles.white, styles.king)} />
                            <div className={clsx(styles.piece, styles.white, styles.queen)} />
                            <div className={clsx(styles.piece, styles.white, styles.bishop)} />
                            <div className={clsx(styles.piece, styles.white, styles.knight)} />
                            <div className={clsx(styles.piece, styles.white, styles.rook)} />
                            <div className={clsx(styles.piece, styles.white, styles.pawn)} />

                            <div className={clsx(styles.piece, styles.black, styles.king)} />
                            <div className={clsx(styles.piece, styles.black, styles.queen)} />
                            <div className={clsx(styles.piece, styles.black, styles.bishop)} />
                            <div className={clsx(styles.piece, styles.black, styles.knight)} />
                            <div className={clsx(styles.piece, styles.black, styles.rook)} />
                            <div className={clsx(styles.piece, styles.black, styles.pawn)} />
                        </div>
                    </React.Fragment>
                ) : (
                    <>
                        <BoardAxes flipped={board.flipped} />
                        <div
                            className={styles.squares}
                            onPointerDown={on_pointer_down}
                            onPointerMove={on_moving}
                            onPointerUp={on_pointer_up}
                            onPointerOut={on_cancel_moving}
                        >
                            {board.multiselect.fromSquares.map((item) => (
                                <SquareSelect key={item.square} square={item} color="from" />
                            ))}

                            <LastMove move={board.last_move} />
                            <BoardCellSelected controller={controller} />
                            {board.view_cells.map((cell) => (
                                <BoardCell
                                    key={cell.index}
                                    cell={cell}
                                    mover={board.mover}
                                    promotion={board.promotion}
                                    flipped={board.flipped}
                                    try_moving={board.try_moving}
                                    is_show_moves={board.is_can_move_piece}
                                    controller={controller}
                                />
                            ))}

                            {board.move_variants.map(({ to, meta }, index) => (
                                <SquareDot
                                    key={to.square}
                                    square={to}
                                    type={meta.type}
                                    color={meta.color}
                                />
                            ))}

                            <BoardPromotion
                                promotion={board.promotion}
                                flipped={board.flipped}
                                controller={controller}
                                isPortal={isPortal}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});
