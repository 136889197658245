import { action, makeAutoObservable } from "mobx";

import { Controller } from "models/controller";
import { ControlType, DialogType, LoadState, SceneType, UserRelation } from "models/enums";
import { UserListWithPages } from "models/user-list-with-pages";

const DEFAULT_CONTROL_ELEMENTS = {
    back: {
        up: ["users:page:prev", "users:page:next", "users:last"],
        down: ["users:first", "users:page:prev", "users:page:next"],
        left: null,
        right: null,
        type: ControlType.BUTTON,
    },
    "users:page:prev": {
        up: ["users:last", "users:first", "back"],
        down: ["back", "users:first", "users:last"],
        left: ["users:page:next"],
        right: ["users:page:next"],
        type: ControlType.BUTTON,
    },
    "users:page:next": {
        up: ["users:last", "users:first", "back"],
        down: ["back", "users:first", "users:last"],
        left: ["users:page:prev"],
        right: ["users:page:prev"],
        type: ControlType.BUTTON,
    },
    "users:first": { type: ControlType.VIRTUAL },
    "users:last": { type: ControlType.VIRTUAL },
    // "users:item": { type: ControlType.VIRTUAL },
    "users:item:0": { type: ControlType.ITEM, meta: { index: 0 } },
    "users:item:1": { type: ControlType.ITEM, meta: { index: 1 } },
    "users:item:2": { type: ControlType.ITEM, meta: { index: 2 } },
    "users:item:3": { type: ControlType.ITEM, meta: { index: 3 } },
    "users:item:4": { type: ControlType.ITEM, meta: { index: 4 } },
    "users:item:5": { type: ControlType.ITEM, meta: { index: 5 } },
    "users:item:6": { type: ControlType.ITEM, meta: { index: 6 } },
    "users:item:7": { type: ControlType.ITEM, meta: { index: 7 } },
};

export class UsersBlockedScene {
    _scenes;
    _app;
    _first_activate;
    _isActive;
    controller;
    state;
    users;

    constructor(scenes, app) {
        makeAutoObservable(this, { _scenes: false, _app: false, _loader: action.bound });
        this._scenes = scenes;
        this._app = app;
        this._first_activate = true;
        this._isActive = false;
        this.state = LoadState.INIT;
        this.users = new UserListWithPages({
            loader: this._loader,
            items: [],
            offset: 0,
            limit: 8,
            count: 0,
        });
        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                if (type === ControlType.BUTTON) {
                    switch (name) {
                        case "back":
                            this.back();
                            break;

                        case "users:page:next":
                            this.users && this.users.next();
                            break;

                        case "users:page:prev":
                            this.users && this.users.prev();
                            break;
                    }
                } else if (type === ControlType.ITEM) {
                    this.tap_by_item(this.users.items[meta.index]);
                }
            },
            back: () => this.back(),
            name: "back",
            find: (name) => {
                if (name === "users:first") {
                    name = "users:item:0";
                } else if (name === "users:last") {
                    const count = this.users.items.length;
                    name = `users:item:${count > 0 ? count - 1 : 0}`;
                }

                const element = { ...DEFAULT_CONTROL_ELEMENTS[name], name };
                if (element) {
                    switch (name) {
                        case "users:page:prev":
                            element.disabled = !this.users || !this.users.has_prev;
                            break;

                        case "users:page:next":
                            element.disabled = !this.users || !this.users.has_next;
                            break;
                    }

                    if (element.type === ControlType.ITEM) {
                        const index = element.meta.index;
                        element.disabled = index < 0 || index >= this.users.items.length;
                    }
                }

                return element || null;
            },
            move: ({ type, meta, ...params }, direction) => {
                if (type === ControlType.ITEM) {
                    const { index = 0 } = meta;
                    const count = 8;
                    return (
                        {
                            up: [
                                ...(index > 0 ? [`users:item:${index - 1}`] : []),
                                "back",
                                "users:page:prev",
                                "users:page:next",
                                "users:last",
                            ],
                            down: [
                                ...(index < count - 1 ? [`users:item:${index + 1}`] : []),
                                "users:page:prev",
                                "users:page:next",
                                "back",
                                "users:first",
                            ],
                        }[direction] || null
                    );
                }
                return params[direction];
            },
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
        });
        this.tutorial = null;
    }

    get isLoading() {
        return !this._isActive;
    }

    back() {
        this._scenes.change(SceneType.FRIENDS);
    }

    get assistantState() {
        return this.assistant_state;
    }

    get assistant_state() {
        return {
            is_auto_help: true,
        };
    }

    get _server() {
        return this._app.server;
    }

    get count() {
        return this.users ? this.users.count : 0;
    }

    _loader({ offset, limit }) {
        return this._server.friend.list({ relation: UserRelation.BLOCKED, offset, limit });
    }

    tap_by_item(item) {
        if (item && item.id) {
            this._app.windows.open_dialog(DialogType.PROFILE, {
                user_id: item.id,
                buttons: [
                    { name: "friend:invite", view: "primary" },
                    { name: "block:cancel", view: "secondary" },
                ],
                onDestroy: () => this.users.reload(),
            });
        }
    }

    onPreload() {
        this._isActive = false;
        this.state = LoadState.SUCCESS;
        this.users = new UserListWithPages({
            loader: this._loader,
            items: [],
            offset: 0,
            limit: 8,
            count: 0,
        });
        this.users.reload();
    }

    onActivate() {
        this._isActive = true;
        this._app.assistant.fire_event("event_activate_scene", {
            is_first_activate: this._first_activate,
        });
        this._first_activate = false;
    }
}
