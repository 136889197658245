import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { CardButton, Container, Header, Row, Col } from "components";

import styles from "./scene.module.pcss";

// import styles from "./scene.module.pcss";

export const ChoiceModeScene = observer(({ scene, skeleton = false, notification = null }) => (
    <Container className={clsx(styles.view)}>
        <Header
            title="Новая игра"
            controller={scene.controller}
            skeleton={skeleton}
            notification={notification}
        />

        <Row className={clsx(styles.content)}>
            <Col
                sizeXL={12}
                offsetXL={0}
                sizeL={8}
                offsetL={0}
                sizeM={6}
                offsetM={0}
                sizeS={4}
                offsetS={0}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        flexGrow: 1,
                        justifyContent: "space-around",
                        alignSelf: "center",
                    }}
                >
                    <CardButton
                        name="1player"
                        text="Игра с ассистентом"
                        controller={scene.controller}
                        skeleton={skeleton}
                    >
                        <div className={clsx(styles.logo, styles.assistant)} />
                    </CardButton>

                    <CardButton
                        name="2player"
                        text="На двоих"
                        controller={scene.controller}
                        skeleton={skeleton}
                    >
                        <div className={clsx(styles.logo, styles.hotSeat)} />
                    </CardButton>

                    <CardButton
                        name="multiplayer"
                        text="По сети"
                        controller={scene.controller}
                        skeleton={skeleton}
                    >
                        <div className={clsx(styles.logo, styles.pvp)} />
                    </CardButton>
                </div>
            </Col>
        </Row>
    </Container>
));
