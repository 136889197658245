import { makeAutoObservable, runInAction } from "mobx";

import { Controller, GraphMover, Group } from "models/controller/v2";
import { ControlType, SceneType } from "models/enums";

export default class AskUserInvite {
    _app;
    _emitter;
    _inviteId;
    _visible;
    controller;

    constructor(app, params = {}, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this._inviteId = params.invite.id;
        this._visible = true;
        this.controller = new Controller(
            new Group("root", {
                nodes: [
                    {
                        type: ControlType.BUTTON,
                        name: "accept",
                        onClick: () => {
                            this._app.server.friend.invite.accept(this._inviteId);
                            this.close();
                        },
                    },
                    {
                        type: ControlType.BUTTON,
                        name: "cancel",
                        onClick: () => {
                            this._app.server.friend.invite.cancel(this._inviteId);
                            this.close();
                        },
                    },
                ],
                mover: new GraphMover({
                    accept: { left: "cancel", right: "cancel" },
                    cancel: { left: "accept", right: "accept" },
                }),
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasQuestionKeyboard(),
            },
        );
    }

    get name() {
        return `ask-user-invite-${this._inviteId}`;
    }

    get visible() {
        const scene = this._app.windows.scenes.currentWindow;
        if (
            scene &&
            (scene.isLoading || scene.type === SceneType.GAME || scene.type === SceneType.INIT)
        ) {
            return false;
        }
        return this._visible && !!this.invite && !this.invite.to_viewed_at;
    }

    get invite() {
        return this._app.notification.friend_invites.find(
            (item) => item && item.id === this._inviteId,
        );
    }

    get fromUser() {
        return (this.invite && this.invite.from_user) || {};
    }

    close() {
        this._visible = false;

        if (this.invite) {
            this._app.server.friend.invite
                .viewed(this.invite.id)
                .then(() =>
                    runInAction(() => {
                        this._emitter.emit("close", this);
                    }),
                )
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this._emitter.emit("close", this);
        }
    }
}
