import { PieceColor, PieceType, PieceWeight } from "lib/chess/enums";
import { transform as transformPosition } from "lib/chess/position";
import { sleep } from "lib/helpers";

import { GameType } from "models/enums";
import {
    loadGameResult,
    loadGameType,
    loadPieceColor,
    loadPieceType,
    loadUserRank,
    saveGameType,
    savePieceType,
} from "providers/server/types";

export class GameAPI {
    constructor(server) {
        this.server = server;
    }

    async list() {
        const response = await this.server.get("/games");
        return (response.data.games || []).map((game) => this._parse_game(game || {}));
    }

    async create({ mode, bot, speed, invited_user_id: invitedUserId = null, color = null }) {
        const params = {};
        if (mode === GameType.WITH_PLAYER) {
            params.invitedUserId = invitedUserId;
        } else if (mode === GameType.HOT_SEAT) {
            // return new LocalGame();
        }
        const result = await this.server.post("/game/create", {
            ...params,
            name: "",
            botInfo: {
                level: {
                    [-1]: "NONE",
                    [0]: "NONE",
                    [10]: "EASY",
                    [20]: "MEDIUM",
                    [30]: "HARD",
                }[bot.level],
            },
            duration: speed.duration,
            additional: speed.additional,
            side: {
                white: "WHITE",
                black: "BLACK",
                [PieceColor.WHITE]: "WHITE",
                [PieceColor.BLACK]: "BLACK",
                [null]: Math.floor(Math.random() * 2) === 0 ? "WHITE" : "BLACK",
            }[color],
            typeName: saveGameType(mode),
            game_type: saveGameType(mode),
        });
        return new Game(this, result.data.data);
    }

    async delete(gameId) {
        const response = await this.server.post("/game/delete", { game_id: gameId });
        return this._parse_game(response.data.game);
    }

    async surrender(gameId) {
        const response = await this.server.post("/game/surrender", { game_id: gameId });
        return this._parse_game(response.data.game);
    }

    async moves(gameId) {
        const response = await this.server.get(`/game/${gameId}/moves`);
        return this._parse_moves(response.data.moves);
    }

    async move(gameId, { x: fromX, y: fromY }, { x: toX, y: toY }, promotion = null) {
        const response = await this.server.post("/game/move", {
            gameId,
            move:
                transformPosition.to_human({ x: fromX, y: fromY }) +
                transformPosition.to_human({ x: toX, y: toY }),
            promotion: savePieceType(promotion),
        });
        const {
            move = {},
            moves = [],
            game_over: gameOver = false,
            check = false,
            checkmate = false,
            stalemate = false,
            result = "NONE",
        } = response.data.data || {};
        return {
            done: true,
            game_over: gameOver || checkmate || stalemate,
            check,
            checkmate,
            stalemate,
            move: this._parse_move(move),
            moves: this._parse_moves(moves),
            result: loadGameResult(result),
        };
    }

    async state(gameId) {
        const response = await this.server.get(`/game/${gameId}`);
        return this._parse_game(response.data.data || {});
    }

    async wait(gameId, moveNumber = 0) {
        let response = await this.server.request("get", `/game/${gameId}/status/${moveNumber - 1}`);
        while (response.data.data === false || response.data.data === null) {
            if (response.data.errors.length !== 0) {
                response.data.errors.forEach((error) => console.error(error.message));
            }
            await sleep(1000);
            response = await this.server.get(`/game/${gameId}/status/${moveNumber - 1}`);
        }

        const {
            move = {},
            moves = [],
            gameOver = false,
            check = false,
            checkmate = false,
            stalemate = false,
            result = "NONE",
        } = response.data.data || {};

        return {
            done: true,
            game_over: gameOver || checkmate || stalemate,
            check,
            checkmate,
            stalemate,
            move: this._parse_move(move),
            moves: this._parse_moves(moves),
            result: loadGameResult(result),
        };
    }

    async set_time(gameId, time = null) {
        await this.server.post(`/game/${gameId}/setTime`, { time });
    }

    async retrieve_status(gameId) {
        const response = await this.server.post(`/game/status`, { game_id: gameId });
        return this._parse_game_status(response.data.game_status || {});
    }

    async pause(gameId) {
        const response = await this.server.post(`/game/pause`, { game_id: gameId });
        return this._parse_game_status(response.data.game_status || {});
    }

    async resume(gameId) {
        const response = await this.server.post(`/game/resume`, { game_id: gameId });
        return this._parse_game_status(response.data.game_status || {});
    }

    async leave(gameId) {
        const response = await this.server.post(`/game/leave`, { game_id: gameId });
        return this._parse_game_status(response.data.game_status || {});
    }

    // eslint-disable-next-line class-methods-use-this
    _parse_game_status_side({ id, is_active: isActive, rating = null }) {
        return { user: { id, is_active: isActive }, rating };
    }

    _parse_game_status({
        id,
        white_user: whiteUser,
        black_user: blackUser,
        result: gameResult,
        pause: gamePause,
        modified_at: modifiedAt,
    }) {
        return {
            id,
            result: loadGameResult(gameResult),
            white: this._parse_game_status_side(whiteUser),
            black: this._parse_game_status_side(blackUser),
            pause: {
                is_active: gamePause.is_active || false,
                can_continue: gamePause.can_continue,
                count: gamePause.count,
                until_at_time: gamePause.until_at_time,
                activated_at: gamePause.activated_at,
            },
            modified_at: modifiedAt,
        };
    }

    _parse_game({
        id = null,
        state = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
        history: stack = [],
        side: color = "WHITE",
        typeName: mode = "HOT_SEAT",
        moves = [],
        gameOver = false,
        check = false,
        checkmate = false,
        stalemate = false,
        time = 0,
        common_time_ms: commonTimeMs = null,
        white = {},
        black = {},
        result = "NONE",
    } = {}) {
        const history = stack
            .map((move) => this._parse_move(move))
            .sort(({ number: a }, { number: b }) => a - b);

        const score = { white: 0, black: 0 };
        history.forEach((move, index) => {
            const weight =
                {
                    [PieceType.PAWN]: PieceWeight.PAWN,
                    [PieceType.ROOK]: PieceWeight.ROOK,
                    [PieceType.KNIGHT]: PieceWeight.KNIGHT,
                    [PieceType.BISHOP]: PieceWeight.BISHOP,
                    [PieceType.QUEEN]: PieceWeight.QUEEN,
                }[move.drop] || 0;

            if (index % 2 === 0) {
                score.white += weight;
            } else {
                score.black += weight;
            }
        });

        return {
            id,
            state,
            game_over: gameOver || checkmate || stalemate,
            check,
            checkmate,
            stalemate,
            mode: loadGameType(mode),
            game_type: loadGameType(mode),
            color: loadPieceColor(color),
            history,
            score,
            moves: this._parse_moves(moves),
            time: commonTimeMs || time,
            white: this._parse_side_info(white || {}, score.white),
            black: this._parse_side_info(black || {}, score.black),
            result: loadGameResult(result),
        };
    }

    _parse_side_info(
        {
            user = null,
            score = null,
            is_assistant: isAssistant = false,
            is_self: isSelf = false,
        } = {},
        _score = 0,
    ) {
        return {
            user: user ? this._parse_user(user || {}) : null,
            score: score || _score,
            is_assistant: isAssistant,
            is_self: isSelf,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    _parse_user({
        id,
        nickname = null,
        avatar_preset_id: avatarPresetId = null,
        rating = 0,
        rank,
        is_friend: isFriend = false,
        modified_at: modifiedAt = 0,
    } = {}) {
        return {
            id,
            nickname,
            avatar_preset_id: avatarPresetId || (id % 25) + 1,
            rating,
            is_friend: isFriend,
            rank: loadUserRank(rank),
            modified_at: modifiedAt,
        };
    }

    _parse_moves(moves = []) {
        return moves.map((params) => this._parse_move(params));
    }

    // eslint-disable-next-line class-methods-use-this
    _parse_move({
        move,
        color,
        piece,
        san,
        lan = null,
        flags = 0,
        promotion = null,
        drop = null,
        number = 0,
        fen = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
        created_at: createdAt = 0,
    }) {
        const to = move.slice(2, 4);
        const toXY = transformPosition.from_human(to);
        const from = move.slice(0, 2);
        const fromXY = transformPosition.from_human(from);

        return {
            from: { square: transformPosition.to_index(fromXY), ...fromXY },
            to: { square: transformPosition.to_index(toXY), ...toXY },
            number: number || 0,
            color: loadPieceColor(color),
            piece: loadPieceType(piece),
            promotion: loadPieceType(promotion),
            drop: loadPieceType(drop),
            flags,
            san: san || `${from}${to}`,
            lan,
            state: fen,
            created_at: createdAt,
        };
    }
}

export class Game {
    constructor(api, gameId) {
        this.gameApi = api;
        this.gameId = gameId;
    }

    get game_api() {
        return this.gameApi;
    }

    get game_id() {
        return this.gameId;
    }

    async state() {
        return this.gameApi.state(this.gameId);
    }

    async delete() {
        return this.gameApi.delete(this.gameId);
    }

    async move({ x: fromX, y: fromY }, { x: toX, y: toY }, promotion = null) {
        return this.gameApi.move(
            this.gameId,
            { x: fromX, y: fromY },
            { x: toX, y: toY },
            promotion,
        );
    }

    async wait(moveNumber = 0) {
        return this.gameApi.wait(this.gameId, moveNumber);
    }

    async moves() {
        return this.gameApi.moves(this.gameId);
    }

    async surrender() {
        return this.gameApi.surrender(this.gameId);
    }

    async set_time(time = 0) {
        return this.gameApi.set_time(this.gameId, time);
    }

    async retrieve_status() {
        return this.gameApi.retrieve_status(this.gameId);
    }

    async pause() {
        return this.gameApi.pause(this.gameId);
    }

    async resume() {
        return this.gameApi.resume(this.gameId);
    }

    async leave() {
        return this.gameApi.leave(this.gameId);
    }
}
