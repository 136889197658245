import clsx from "clsx";
import styled from "styled-components";
import React from "react";
import { observer } from "mobx-react-lite";

import { PieceColor } from "lib/chess/enums";

import { Body2, Footnote1, UserAvatar } from "components";
import { rank_to_title, user_status_to_title } from "models/helpers";

import styles from "./user-card.module.pcss";
import { UserStatus } from "models/enums";
import { Badge, Body1 } from "@sberdevices/plasma-ui";

const Caption = styled(Footnote1)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    width: 100%;
    color: ${({ color }) => color || "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))"};
`;

const Status = ({ status }) => {
    if (status === UserStatus.BUSY) {
        return <Badge view="secondary" text={user_status_to_title(status)} />;
    }

    if (status === UserStatus.ONLINE) {
        return <div className={clsx(styles.online)} />;
    }

    return null;
};

export default observer(({ name, user, controller }) => {
    const focused = controller && controller.focusedName === name;
    return (
        <button
            className={clsx(styles.view, { [styles.outlined]: focused })}
            onClick={() => controller && controller.click(name)}
        >
            <div className={clsx(styles.header)}>
                <div className={clsx(styles.avatar)}>
                    <UserAvatar preset={user.avatar_preset_id} />
                </div>
                <Status status={user.status} />
            </div>

            <div className={clsx(styles.footer)}>
                <Body1>{user.nickname || `ID ${user.id}`}</Body1>
                <Caption>
                    {user.rating} &#183; {rank_to_title(user.rank)}
                </Caption>
            </div>
        </button>
    );
});
