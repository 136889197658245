import { makeAutoObservable } from "mobx";

import { Controller, Group } from "models/controller/v2";

export default class TechInfoScene {
    _app;
    _emitter;
    _isActive;
    _params;
    controller;

    constructor(app, params, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this._isActive = false;
        this._params = params;
        this.controller = new Controller(
            new Group("root", {
                nodes: [],
                init: () => undefined,
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasDialogKeyboard(),
            },
        );
    }

    // eslint-disable-next-line class-methods-use-this
    get assistantPath() {
        return "/scene/tech_info";
    }

    // eslint-disable-next-line class-methods-use-this
    get isLoading() {
        return false;
    }

    get subTitle() {
        const path = this._params && this._params.context && this._params.context.current_state;
        return path ? `состояние ${path}` : null;
    }

    get character() {
        const {
            id = null,
            name = null,
            gender = null,
            appeal = null,
        } = (this._params && this._params.character) || {};
        return { id, name, gender, appeal };
    }

    get device() {
        const { surface = null, surfaceVersion = null } =
            (this._params && this._params.device) || {};
        return {
            surface,
            surfaceVersion,
        };
    }

    close() {
        this._emitter.emit("close", this);
    }

    // onPreload() {}
    // onActivate() {}
    // onDeactivate() {}
    // onDestroy() {}
}
