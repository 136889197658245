import { makeAutoObservable, runInAction } from "mobx";

import { ControlType, DialogType, LoadState, UserRelation } from "models/enums";
import { Controller, CarouselGroup, OneOfGroup } from "models/controller/v2";

import img from "assets/images/list_empty.svg";

export default class UsersSearchResultScene {
    _app;
    _emitter;
    _users;
    _isActive;
    controller;
    state;
    users;
    nickname;

    constructor(app, { nickname = "" }, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this.state = LoadState.INIT;
        this._isActive = false;
        this.nickname = nickname;
        this._users = [];
        this.users = new CarouselGroup("users", {
            nodes: () =>
                this._users.map((user) => ({
                    type: ControlType.ITEM,
                    name: `user:id:${user.id}`,
                    meta: user,
                    onClick: () => {
                        this._app.windows.open_dialog(DialogType.PROFILE, {
                            user_id: user.id,
                            buttons: [
                                { name: "friend:invite", view: "primary" },
                                // { name: "user:block", view: "secondary" },
                            ],
                            onDestroy: () => this._reload(),
                        });
                    },
                })),
            direction: "column",
            basis: 2,
        });
        this.controller = new Controller(
            new OneOfGroup("content", {
                nodes: [
                    this.users,
                    {
                        type: ControlType.BUTTON,
                        name: "close",
                        onClick: () => {
                            this.close();
                        },
                    },
                    {
                        type: ControlType.BUTTON,
                        name: "reload",
                        onClick: () => {
                            this._reload();
                        },
                    },
                ],
                switcher: (nodes) => {
                    const { type, button = {} } = this.content;

                    let { name } = this.users;
                    if (type === "failure" || type === "empty") {
                        name = button.name;
                    }

                    return nodes.find((item) => item && item.name === name);
                },
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasSceneKeyboard(),
            },
        );
        this.tutorial = null;
    }

    get isLoading() {
        return this.state === LoadState.LOADING || !this._isActive;
    }

    get content() {
        if (this.isLoading) {
            return { type: "skeleton" };
        }

        if (this.state === LoadState.FAILURE) {
            return {
                type: "failure",
                title: "Что-то пошло не так",
                subTitle: "повторите попытку позже",
                button: { name: "reload", text: "Обновить" },
            };
        }

        if (this._users.length === 0) {
            return {
                type: "empty",
                isCenter: true,
                image: img,
                subTitle: "Пользователей с таким ником не найдено",
                button: { name: "close", view: "secondary", text: "Вернуться к поиску" },
            };
        }

        return { type: "carousel" };
    }

    close() {
        this._emitter.emit("close", this);
    }

    _reload() {
        if (this.state !== LoadState.LOADING) {
            this.state = LoadState.LOADING;
            this._app.server.friend
                .list({
                    relation: UserRelation.NONE,
                    nickname: this.nickname,
                    offset: 0,
                    limit: 100,
                })
                .then((users) =>
                    runInAction(() => {
                        this.state = LoadState.SUCCESS;
                        this._users.replace(users.items);
                    }),
                )
                .catch(() =>
                    runInAction(() => {
                        this.state = LoadState.FAILURE;
                    }),
                );
        }
    }

    onPreload() {
        this._reload();
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
    }

    // onDeactivate() {}
    // onDestroy() {}
}
