import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Col, Container, Row, TextBoxBigTitle } from "components";

import styles from "./dialog.module.pcss";

export const Dialog = observer(({ theme = null, children = [], disabled = false }) => (
    <Container
        className={clsx(
            styles.view,
            { info: styles.info, warning: styles.warning, success: styles.success }[theme],
            { [styles.disabled]: disabled },
        )}
    >
        <Row>
            <Col
                sizeXL={5}
                offsetXL={7}
                sizeL={5}
                offsetL={3}
                sizeM={3}
                offsetM={3}
                sizeS={4}
                offsetS={0}
            >
                <div className={clsx(styles.content)}>{children}</div>
            </Col>
        </Row>
    </Container>
));

export const DialogHeader = observer(({ children = null }) => (
    <div className={clsx(styles.header)}>{children}</div>
));

export const DialogCircle = observer(({ children = null }) => (
    <div className={clsx(styles.circle)}>{children}</div>
));

export const DialogTitle = observer(({ title = null, children = null }) => (
    <div className={clsx(styles.title)}>
        {title && <TextBoxBigTitle>{title}</TextBoxBigTitle>}
        {children}
    </div>
));

export const DialogFooter = observer(({ three = false, children = null }) => (
    <div className={clsx(styles.footer, { [styles.three]: !!three })}>{children}</div>
));
