import { makeAutoObservable } from "mobx";

import { ControlType } from "models/enums";

export default class OneOfGroup {
    _name;
    _nodes;
    _switcher;

    constructor(name, { nodes = [], switcher = null } = {}) {
        makeAutoObservable(this);
        this._name = name;
        this._nodes = nodes;
        this._switcher = switcher;
    }

    // eslint-disable-next-line class-methods-use-this
    get type() {
        return ControlType.GROUP;
    }

    get name() {
        return this._name;
    }

    get focusedElement() {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.focusedElement;
            }
            return this.current;
        }
        return undefined;
    }

    get children() {
        if (this._nodes instanceof Function) {
            return this._nodes() || [];
        }
        return this._nodes || [];
    }

    get current() {
        if (this._switcher instanceof Function) {
            return this._switcher(this.children);
        }
        return undefined;
    }

    click(name) {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.click(name);
            }

            if (this.current.name === name && this.current.onClick instanceof Function) {
                this.current.onClick();
                return true;
            }
        }
        return false;
    }

    focus(name) {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.focus(name);
            }

            if (this.current.name === name) {
                return true;
            }
        }
        return false;
    }

    find(predicate) {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.find(predicate);
            }
            return predicate(this.current) ? this.current : undefined;
        }
        return undefined;
    }

    move(direction) {
        if (this.current && this.current.type === ControlType.GROUP) {
            return this.current.move(direction);
        }
        return false;
    }

    cancel() {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.cancel();
            }

            if (this.current.onCancel instanceof Function) {
                return this.current.onCancel();
            }
        }
        return false;
    }
}
