import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";

import { TabItem, Tabs, addFocus, RectSkeleton } from "@sberdevices/plasma-ui";

import {
    Button,
    Col,
    Container,
    Header,
    Row,
    UserAvatar,
    TextField,
    Carousel,
    CarouselCol,
    CarouselGridWrapper,
} from "components";
import styles from "./scene.module.pcss";

const outlineSizes = {
    l: { radius: "1.125rem" },
    m: { radius: "0.875rem" },
    s: { radius: "0.875rem" },
};

const applyFocus = ({ size = "l", pilled, focused, outlined }) => css`
    ${addFocus({
        focused,
        outlined,
        outlineRadius: pilled ? "6.375rem" : outlineSizes[size].radius,
    })}
`;

const StyledTabItem = styled(TabItem)`
    ${applyFocus};
`;

const AvatarTab = observer(({ items = [], skeleton = false, controller }) => (
    <div className={clsx(styles.content)}>
        <CarouselGridWrapper>
            <Carousel
                as={Row}
                index={items.alternativeIndex}
                scrollSnapType="mandatory"
                onIndexChange={(x) => {
                    items.setAlternativeIndex(x);
                }}
            >
                {skeleton ? (
                    <CarouselCol sizeXL={3} sizeL={3} sizeM={2} sizeS={3}>
                        <div style={{ margin: "0.25rem" }}>
                            <RectSkeleton className={clsx(styles.avatar)} roundness={250} />
                        </div>
                    </CarouselCol>
                ) : (
                    items.children.map(({ name, disabled, meta: preset }) => (
                        <CarouselCol key={name} sizeXL={3} sizeL={3} sizeM={2} sizeS={3}>
                            <div style={{ margin: "0.25rem" }}>
                                <UserAvatar
                                    key={name}
                                    preset={preset}
                                    is_mono={disabled}
                                    disabled={disabled}
                                    outlined={name === controller.focusedName}
                                    onClick={() => controller.click(name)}
                                    className={clsx(styles.avatar)}
                                />
                            </div>
                        </CarouselCol>
                    ))
                )}
            </Carousel>
        </CarouselGridWrapper>
    </div>
));

const NicknameTab = observer(({ nickname, controller, skeleton = false }) => (
    <Row className={clsx(styles.content)}>
        <Col
            sizeXL={6}
            offsetXL={3}
            sizeL={6}
            offsetL={1}
            sizeM={4}
            offsetM={1}
            sizeS={4}
            offsetS={0}
        >
            {skeleton ? (
                <RectSkeleton width="100%" height="3rem" />
            ) : (
                <TextField
                    field={nickname}
                    name="field:nickname"
                    controller={controller}
                    label="Никнейм"
                    minLength={3}
                    maxLength={16}
                    onKeyDown={(event) => {
                        if (event && event.key === "Enter") {
                            controller.click("save:nickname");
                        }
                    }}
                />
            )}
        </Col>
    </Row>
));

const RouteTab = observer(({ name = null, scene, skeleton = false }) => {
    switch (name) {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        case "tab:nickname":
            return (
                <NicknameTab
                    nickname={scene.nickname}
                    controller={scene.controller}
                    skeleton={skeleton}
                />
            );

        // eslint-disable-next-line sonarjs/no-duplicate-string
        case "tab:avatar":
            return (
                <AvatarTab
                    items={scene.avatars}
                    controller={scene.controller}
                    skeleton={skeleton}
                />
            );

        default:
            return null;
    }
});

export default observer(({ scene, skeleton = false }) => {
    const isLoading = skeleton || scene.isLoading;
    const { controller, tab: tabActivated } = scene;
    const { focusedName } = controller;
    return (
        <Container className={clsx(styles.view)}>
            <Row>
                <Col
                    sizeXL={12}
                    offsetXL={0}
                    sizeL={8}
                    offsetL={0}
                    sizeM={6}
                    offsetM={0}
                    sizeS={4}
                    offsetS={0}
                >
                    <Header
                        title="Редактирование профиля"
                        controller={controller}
                        skeleton={isLoading}
                    >
                        <Tabs pilled size="s" view="clear">
                            {isLoading ? (
                                <RectSkeleton width="4rem" height="2.25rem" roundness={24} />
                            ) : (
                                <StyledTabItem
                                    isActive={tabActivated === "tab:nickname"}
                                    focused={focusedName === "tab:nickname"}
                                    onClick={() => controller && controller.click("tab:nickname")}
                                >
                                    Имя
                                </StyledTabItem>
                            )}
                            {isLoading ? (
                                <RectSkeleton width="5rem" height="2.25rem" roundness={24} />
                            ) : (
                                <StyledTabItem
                                    isActive={tabActivated === "tab:avatar"}
                                    focused={focusedName === "tab:avatar"}
                                    onClick={() => controller && controller.click("tab:avatar")}
                                >
                                    Аватар
                                </StyledTabItem>
                            )}
                        </Tabs>
                    </Header>
                </Col>
            </Row>

            <RouteTab
                name={tabActivated}
                scene={scene}
                controller={controller}
                skeleton={isLoading}
            />
        </Container>
    );
});
