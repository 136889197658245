import clsx from "clsx";
import React from "react";

import { PieceType, PieceColor } from "lib/chess/enums";

import styles from "./piece-captured.module.pcss";

// eslint-disable-next-line react/prop-types
export default ({ piece, color }) => (
    <div
        className={clsx(styles.view, "piece-theme-003", {
            [styles.white]: color === PieceColor.WHITE,
            [styles.black]: color === PieceColor.BLACK,
            [styles.pawn]: piece === PieceType.PAWN,
            [styles.bishop]: piece === PieceType.BISHOP,
            [styles.knight]: piece === PieceType.KNIGHT,
            [styles.rook]: piece === PieceType.ROOK,
            [styles.queen]: piece === PieceType.QUEEN,
            [styles.king]: piece === PieceType.KING,
        })}
    />
);
