import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    Button,
    Carousel,
    CarouselItem,
    RectSkeleton,
    Footnote1,
    Footnote2,
    ActionButton,
    Icon,
} from "components";
import { IconFlip } from "components/icons";

import { PieceType } from "lib/chess/enums";

import { FogLightsType } from "models/scenes/game";

import PieceCaptured from "./piece-captured";
import styles from "./right-panel.module.pcss";

const HistoryMove = observer(
    ({ move, onClick = null, selected = false, outlined = false, unselected = false }) => {
        let title = move.lan || move.san;
        if (
            title &&
            move.piece !== PieceType.PAWN &&
            ["p", "n", "b", "r", "q", "k", "P", "N", "B", "R", "Q", "K"].indexOf(title[0]) !== -1
        ) {
            title = title.substr(1);
        }
        const Footnote = selected ? Footnote2 : Footnote1;
        return (
            <div
                className={clsx(styles.move, {
                    [styles.selected]: selected,
                    [styles.unselected]: unselected,
                    [styles.outlined]: outlined,
                })}
                onClick={onClick}
                aria-hidden
            >
                <div style={{ height: "1.75rem", width: "1.75rem", flexWrap: "nowrap" }}>
                    <PieceCaptured color={move.color} piece={move.piece} />
                </div>
                <Footnote>{title}</Footnote>
            </div>
        );
    },
);

const History = observer(
    ({ controller, history: carousel, activeNumber = undefined, fogLights = false }) => {
        const focusedName = (controller && controller.focusedName) || undefined;
        return (
            <div className={clsx(styles.historyRoot)}>
                <Carousel
                    axis="y"
                    index={carousel.alternativeIndex}
                    scrollSnapType="mandatory"
                    onIndexChange={(index) => carousel.setAlternativeIndex(index)}
                    className={clsx(styles.history, { [styles.fog_lights]: fogLights })}
                    scrollAlign="top"
                    paddingEnd="100%"
                    detectActive
                >
                    {carousel.grid.map((row, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <CarouselItem key={index} className={clsx(styles.row)}>
                            {row.map(({ name, meta: move }) =>
                                carousel.width === 2 ? (
                                    <HistoryMove
                                        key={move.number}
                                        move={move}
                                        onClick={() => controller.click(name)}
                                        outlined={name === focusedName}
                                        selected={
                                            activeNumber !== null &&
                                            activeNumber !== undefined &&
                                            activeNumber === move.number
                                        }
                                        unselected={
                                            activeNumber !== null &&
                                            activeNumber !== undefined &&
                                            activeNumber !== move.number
                                        }
                                    />
                                ) : (
                                    <Button
                                        key={move.number}
                                        contentLeft={
                                            <div style={{ width: "1.75rem", height: "1.75rem" }}>
                                                <PieceCaptured
                                                    color={move.color}
                                                    piece={move.piece}
                                                />
                                            </div>
                                        }
                                        name={name}
                                        view={
                                            activeNumber !== null &&
                                            activeNumber !== undefined &&
                                            activeNumber === move.number
                                                ? "check"
                                                : "secondary"
                                        }
                                        size="s"
                                        controller={controller}
                                        text={move.lan}
                                        stretch
                                        style={{ justifyContent: "flex-start" }}
                                    />
                                ),
                            )}
                        </CarouselItem>
                    ))}
                </Carousel>
                <div
                    className={clsx(styles.historyControls, {
                        [styles.fog_lights]: fogLights,
                        [styles.hidden]: carousel.width !== 2,
                    })}
                >
                    <ActionButton name="history:scroll:up" size="l" controller={controller}>
                        <Icon icon="chevronUp" size="xs" color="inherit" />
                    </ActionButton>
                    <ActionButton name="history:scroll:down" size="l" controller={controller}>
                        <Icon icon="chevronDown" size="xs" color="inherit" />
                    </ActionButton>
                </div>
            </div>
        );
    },
);

export const RightPanel = observer(
    ({
        board,
        history,
        controller,
        skeleton = false,
        fog_lights = null,
        pause_count = -1,
        isPortal = false,
        scene,
    }) => (
        <div className={clsx(styles.view)}>
            {skeleton ? (
                <>
                    <div className={clsx(styles.history)} style={{ height: "100%" }}>
                        <div className={clsx(styles.row)}>
                            <div className={clsx(styles.move)}>
                                <RectSkeleton width="100%" height="100%" roundness={8} />
                            </div>
                        </div>
                    </div>
                    <RectSkeleton width="100%" height="2.25rem" roundness={8} />
                </>
            ) : (
                <>
                    <History
                        activeNumber={board.activeHistoryNumber}
                        controller={controller}
                        fogLights={fog_lights === FogLightsType.HISTORY}
                        history={scene.carouselHistory}
                    />

                    <div
                        className={clsx(styles.control, {
                            [styles.fog_lights]: fog_lights === FogLightsType.CONTROL_BUTTON,
                        })}
                    >
                        <Button
                            name="flip"
                            controller={controller}
                            contentLeft={
                                isPortal ? (
                                    <IconFlip
                                        size="s"
                                        color={
                                            board.flipped
                                                ? "var(--plasma-colors-background)"
                                                : "var(--plasma-colors-primary)"
                                        }
                                    />
                                ) : null
                            }
                            text={isPortal ? "Поворот" : "Повернуть доску"}
                            pin="square-square"
                            stretch
                            size="s"
                            /* eslint-disable-next-line no-nested-ternary */
                            view={board.flipped ? "checked" : isPortal ? "clear" : "secondary"}
                        />
                    </div>
                </>
            )}
        </div>
    ),
);
