import { ControlType, SceneType } from "models/enums";

export default (scene) => ({
    ok: ({ name, type, meta }) => {
        if (type === ControlType.BUTTON) {
            switch (name) {
                case "menu":
                    scene._close_dialog();
                    scene._game.close_game();
                    scene._scenes.change(SceneType.HOME);
                    break;

                case "pause":
                    scene._close_dialog();
                    scene._game.pause();
                    break;

                case "surrender":
                    scene._close_dialog();
                    scene.surrender_dialog();
                    break;

                case "back":
                    scene._close_dialog();
                    break;

                default:
                    return;
            }
        }
    },
    back: () => {
        scene._close_dialog(false);
    },
    name: "menu",
    elements: {
        pause: {
            left: ["surrender"],
            right: ["surrender"],
            up: ["menu"],
            down: ["menu"],
            type: ControlType.BUTTON,
        },
        surrender: {
            left: ["pause"],
            right: ["pause"],
            up: ["menu"],
            down: ["menu"],
            type: ControlType.BUTTON,
        },
        menu: {
            up: ["pause", "surrender"],
            down: ["pause", "surrender"],
            type: ControlType.BUTTON,
        },
    },
    has_touch_mode: () => scene._app.is_touch_mode,
});
