import { makeAutoObservable } from "mobx";

import { Controller } from "models/controller";
import { ControlType, DialogType, GameMode, SceneType, TutorialType } from "models/enums";

export class ChoiceModeScene {
    static DEFAULT_CONTROL_ELEMENT = Object.freeze({
        back: { type: ControlType.BUTTON },
        notification: {
            up: ["multiplayer"],
            down: ["multiplayer"],
            left: ["multiplayer"],
            right: ["1player"],
            type: ControlType.BUTTON,
        },
        "1player": {
            up: ["notification"],
            down: ["notification"],
            left: ["multiplayer"],
            right: ["2player"],
            type: ControlType.BUTTON,
        },
        "2player": {
            up: ["notification"],
            down: ["notification"],
            left: ["1player"],
            right: ["multiplayer"],
            type: ControlType.BUTTON,
        },
        multiplayer: {
            up: ["notification"],
            down: ["notification"],
            left: ["2player"],
            right: ["1player"],
            type: ControlType.BUTTON,
        },
    });

    _app;
    _scenes;
    _game;
    _first_activate;
    _isActive;
    controller;
    tutorial;

    constructor(scenes, app, game) {
        makeAutoObservable(this, { _app: false, _scenes: false, _game: false });
        this._app = app;
        this._scenes = scenes;
        this._game = game;
        this._first_activate = true;
        this._isActive = false;
        this.tutorial = null;
        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                if (type === ControlType.BUTTON) {
                    switch (name) {
                        case "1player":
                            this.game_with_bot();
                            break;

                        case "2player":
                            this.game_hot_seat();
                            break;

                        case "multiplayer":
                            this.game_with_player();
                            break;

                        case "back":
                            this.back();
                            break;

                        case "notification":
                            this._app.windows.open_dialog(DialogType.NOTIFICATION);
                            break;
                    }
                }
            },
            back: () => this.back(),
            name: "1player",
            elements: ChoiceModeScene.DEFAULT_CONTROL_ELEMENT,
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
        });
    }

    get isLoading() {
        return !this._isActive;
    }

    get assistant_state() {
        return {
            is_multiplayer: !this.controller.find_by_name("multiplayer").disabled,
            is_auto_help: this._app.user.has_scene_auto_help(SceneType.CHOICE_MODE),
        };
    }

    game_with_bot() {
        this._game.options.set_mode(GameMode.WITH_ASSISTANT);
        this._game.options.set_color(null);
        this._scenes.change(SceneType.CHOICE_BOT);
    }

    game_hot_seat() {
        this._game.options.set_mode(GameMode.HOT_SEAT);
        this._game.options.set_color(null);
        this._scenes.change(SceneType.CHOICE_COLOR);
    }

    game_with_player() {
        this._game.options.set_mode(GameMode.WITH_PLAYER);
        this._game.options.set_color(null);
        this._scenes.change(SceneType.CHOICE_PVP_MODE);
    }

    back() {
        this._scenes.change(SceneType.HOME);
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
        this.tutorial = null;
        this.controller.unlock();
        if (!this._app.user.tutorial.choice_game_mode) {
            this.tutorial = TutorialType.CHOICE_GAME_MODE;
            this._app.assistant.transition("/tutorial/choice_game_mode");
            this.controller.push({
                ok: ({ name, type, meta }) => {
                    if (type === ControlType.BUTTON) {
                        switch (name) {
                            case "1player":
                                this.controller.lock();
                                this._app.user.apply_tutorial("choice_game_mode").then(() => {
                                    this.game_with_bot();
                                    this.controller.pop();
                                });
                                break;

                            case "2player":
                                this.controller.lock();
                                this._app.user.apply_tutorial("choice_game_mode").then(() => {
                                    this.game_hot_seat();
                                    this.controller.pop();
                                });
                                break;

                            case "multiplayer":
                                this.controller.lock();
                                this._app.user.apply_tutorial("choice_game_mode").then(() => {
                                    this.game_with_player();
                                    this.controller.pop();
                                });
                                break;

                            case "back":
                                this.back();
                                break;
                        }
                    }
                },
                back: ({ type, meta }) => this.back(),
                name: "1player",
                elements: ChoiceModeScene.DEFAULT_CONTROL_ELEMENT,
            });
        } else {
            this._app.assistant.fire_event("event_activate_scene", {
                is_first_activate: this._first_activate,
            });
            if (this._first_activate) {
                this._app.user.increase_scene_auto_help(SceneType.CHOICE_MODE);
            }
        }
        this._first_activate = false;
    }
}
