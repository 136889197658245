import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import clsx from "clsx";

import { TextField as PlasmaTextField } from "@sberdevices/plasma-ui/components/TextField";

import styles from "./text-field.module.pcss";

const applySizes = ({ size }) => {
    switch (size) {
        case "s":
            return `
                height: 2.5rem;
            
                & input {
                    height: 2.5rem;
                    /* stylelint-disable-next-line number-max-precision */
                    padding: 0.35rem 0.9375rem;
                    font-size: 0.6em;
                    
                    &:not(:placeholder-shown) {
                        padding-top: 1rem;
                        padding-bottom: 0.375rem;
                    }
                
                    &::placeholder {
                        color: transparent;
                    }
                }
                
                & label {
                    top: 0.6rem;
                    left: 0.9375rem;
                    right: 0.9375rem;
                }
                
                & input:not(:placeholder-shown) ~ label {
                    transform: scale(0.6);
                    top: 0.375rem;
                }
            `;

        case "m":
            return `
                & input {
                    &:not(:placeholder-shown) {
                        padding-top: 1.5rem;
                        padding-bottom: 0.375rem;
                    }
                
                    &::placeholder {
                        color: transparent;
                    }
                }
                
                & label {
                    top: 1.125rem;
                    left: 0.9375rem;
                    right: 0.9375rem;
                }
                
                & input:not(:placeholder-shown) ~ label {
                    transform: scale(0.6);
                    top: 0.375rem;
                }
            `;

        default:
            return null;
    }
};

const StyledTextField = styled(PlasmaTextField)`
    ${applySizes}
`;

export default observer(({ name, controller, field, ...params }) => {
    const ref = useRef(null);
    const element = (controller && controller.find_by_name(name)) || {};
    const disabled = element ? element.disabled || element.disable : false;
    const focused = element
        ? element.focused || (name !== null && name === controller.focusName)
        : false;

    useEffect(() => {
        if (ref.current) {
            if (field.isEditing) {
                ref.current.focus();
            } else {
                ref.current.blur();
            }
        }
    }, [field.isEditing]);

    return (
        <StyledTextField
            ref={ref}
            value={field.value}
            status={field.status}
            helperText={field.helperText}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...params}
            onClick={() => controller.click(name)}
            onFocus={() => controller.focus(name)}
            onBlur={() => field.finishEdit()}
            onChange={(event) => {
                /* eslint-disable-next-line no-param-reassign */
                field.value = event.target.value;
            }}
            disabled={disabled}
            className={clsx({ [styles.outlined]: focused })}
        />
    );
});
