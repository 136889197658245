const initUrl = new URL(window.location.href);
// eslint-disable-next-line sonarjs/no-collapsible-if
if (process.env.DEBUG_CONSOLE) {
    if (
        initUrl.searchParams.get("debug-console") === "true" ||
        initUrl.searchParams.get("debug_console") === "true"
    ) {
        // eslint-disable-next-line global-require
        require("lib/debug-console")({ isPretty: true });
    }
}

/* eslint-disable import/first */
import "normalize.css";
import "regenerator-runtime";

import React from "react";
import { render } from "react-dom";

import { DeviceThemeProvider, ToastProvider, PlasmaTheme, PlasmaTypo } from "components";
import RouterScene from "scenes/router";
import { Application } from "models/app.store";

import "./common.pcss";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
/* eslint-enable import/first */

if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.SENTRY_ENVIRONMENT,
        debug: process.env.SENTRY_DEBUG,
    });
}

const isResetCache = () => {
    const now = new Date().getTime();
    const timeLoad = initUrl.searchParams.get("time-load");

    try {
        return timeLoad === null || timeLoad === undefined || now - Number(timeLoad) > 180000;
        // eslint-disable-next-line no-empty
    } catch (exception) {}

    return true;
};

if (isResetCache()) {
    initUrl.searchParams.delete("time-load");
    initUrl.searchParams.append("time-load", new Date().getTime().toString());
    document.location.replace(initUrl.href);
} else {
    const app = new Application({
        auth_token:
            initUrl.searchParams.get("auth_token") || initUrl.searchParams.get("token") || null,
        is_tester: initUrl.searchParams.get("is_tester") === "true",
    });
    app.init();

    render(
        <>
            <DeviceThemeProvider>
                <ToastProvider>
                    <PlasmaTypo device={app.device_type} />
                    <PlasmaTheme assistant={app.assistant} />
                    <RouterScene app={app} />
                </ToastProvider>
            </DeviceThemeProvider>

            <div
                style={{
                    position: "absolute",
                    right: "1rem",
                    bottom: "1rem",
                    fontSize: "2.25vh",
                    zIndex: 1100,
                }}
            >
                {app.version}
            </div>
        </>,
        document.querySelector("#app"),
    );
}

// const version = document.createElement("pre");
// Object.entries({
//     position: "absolute",
//     top: 0,
//     left: 0,
//     margin: 0,
//     // "max-height": "30%",
//     "font-size": "1rem",
//     opacity: 0.5,
// }).forEach(([key, value]) => {
//     version.style[key] = value;
// });
// document.body.appendChild(version);
// autorun(() => {
//     version.innerText = [app.assistant.state.user_id, app.assistant.state.sber_id].join("\n");
// });

// setInterval(() => runInAction(() => {
//     const as = [AssistantType.JOY, AssistantType.SBER, AssistantType.EVA];
//     let index = as.indexOf(app.assistant.name) + 1;
//     if (index < 0 || index >= as.length) {
//         index = 0;
//     }
//     app.assistant.name = as[index];
// }), 5000);
