import clsx from "clsx";
import React from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";

import { mediaQuery, RectSkeleton, TextSkeleton } from "@sberdevices/plasma-ui";

import { Button, TextBoxTitle } from "components";

import styles from "./card-button.module.pcss";

const CardSizes = {
    sberBox: css`
        width: 12rem;
        height: 12rem;
    `,
    sberPortal: css`
        width: 8rem;
        height: 8rem;
    `,
    mobile: css`
        width: 8rem;
        height: 8rem;
    `,
};

const CardRoot = styled.div`
    ${({ size, theme }) =>
        size
            ? CardSizes[size]
            : css`
                  ${mediaQuery("S", theme.deviceScale)(CardSizes.mobile)}
                  ${mediaQuery("M", theme.deviceScale)(CardSizes.sberPortal)}
                  ${mediaQuery("L", theme.deviceScale)(CardSizes.sberBox)}
                  ${mediaQuery("XL", theme.deviceScale)(CardSizes.sberBox)}
              `}
`;

export const CardButton = observer(
    ({ name, text, controller, view = "secondary", children = null, skeleton = false }) => {
        if (skeleton) {
            return (
                <div className={clsx(styles.view)}>
                    <CardRoot>
                        <RectSkeleton width="100%" height="100%" />
                    </CardRoot>
                    <TextSkeleton lines={1} width={100} />
                </div>
            );
        }

        const element = (controller && controller.find_by_name(name)) || null;
        const disabled = element ? element.disabled || element.disable : false;
        const focused = element
            ? element.focused || (name !== null && name === controller.focusName)
            : false;
        const onClick =
            element && element.click
                ? () => element.click()
                : () => controller && controller.click(name);

        return (
            <div className={clsx(styles.view)}>
                <CardRoot>
                    <Button
                        style={{ width: "100%", height: "100%", padding: "1rem" }}
                        view={view}
                        focused={focused}
                        outlined={focused}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {children}
                    </Button>
                </CardRoot>
                <TextBoxTitle>{text}</TextBoxTitle>
            </div>
        );
    },
);
