import { makeAutoObservable, reaction, runInAction, when } from "mobx";

import { MoveFlag, PieceColor, PieceType } from "lib/chess/enums";

import { BoardState } from "models/board";
import { Controller as ControllerV1 } from "models/controller";
import { CarouselGroup, Controller, Group } from "models/controller/v2";
import {
    ControlType,
    DeviceType,
    DialogType,
    GameMode,
    GameResult,
    GameType,
    SceneType,
    TutorialType,
} from "models/enums";
import { Pages } from "models/pages";

import game_elements from "./elements";
import exit_dialog_layer from "./exit.dialog.layer";
import finished_dialog_layer from "./finished.dialog.layer";
import pause_dialog_layer from "./pause.dialog.layer";
import skipTutorialLayer from "./skip.tutorial.layer";
import waitFinishTutorialLayer from "./wait-finish.tutorial.layer";

import board_move_tutorial_layer from "./board.move.tutorial.layer";
import history_tutorial_layer from "./history.tutorial.layer";
import controls_tutorial_layer from "./controls.tutorial.layer";

const DIRECT_SQUARE_WEIGHT_MAP = [
    [-1, -1, -1, -1, -1, -1, -1, 73],
    [-1, -1, -1, -1, -1, -1, 69, 71],
    [-1, -1, -1, -1, -1, 61, 65, 67],
    [-1, -1, -1, -1, 49, 55, 59, 63],
    [-1, -1, -1, 34, 41, 47, 53, 57],
    [-1, -1, 22, 27, 32, 39, 45, 51],
    [-1, 14, 17, 20, 25, 30, 37, 43],
    [-1, 11, 12, 15, 18, 23, 28, 35],
    [-1, 13, 16, 19, 24, 29, 36, 42],
    [-1, -1, 21, 26, 31, 38, 44, 50],
    [-1, -1, -1, 33, 40, 46, 52, 56],
    [-1, -1, -1, -1, 48, 54, 58, 62],
    [-1, -1, -1, -1, -1, 60, 64, 66],
    [-1, -1, -1, -1, -1, -1, 68, 70],
    [-1, -1, -1, -1, -1, -1, -1, 72],
];

const find_square_element_name = ({ x: x0, y: y0 }, direct, position_list) => {
    const result = { weight: Number.MAX_SAFE_INTEGER, element: null };
    position_list.forEach(({ x: x1, y: y1 }) => {
        let b = -1,
            s = -1;
        switch (direct) {
            case "up":
                b = y1 - y0;
                s = x0 - x1 + 7;
                break;

            case "down":
                b = y0 - y1;
                s = x0 - x1 + 7;
                break;

            case "left":
                b = x0 - x1;
                s = y0 - y1 + 7;
                break;

            case "right":
                b = x1 - x0;
                s = y0 - y1 + 7;
                break;
        }

        if (0 <= b <= 7 && 0 <= s <= 14) {
            const weight = DIRECT_SQUARE_WEIGHT_MAP[s][b];
            if (weight !== -1 && weight < result.weight) {
                result.weight = weight;
                result.element = `${x1}:${y1}`;
            }
        }
    });
    return result.element;
};

const move_for_square = (direction, meta, flipped = false) => {
    const shift = flipped ? -1 : 1;
    let { x, y } = meta;

    switch (direction) {
        case "left":
            x -= shift;
            break;

        case "right":
            x += shift;
            break;

        case "up":
            y += shift;
            break;

        case "down":
            y -= shift;
            break;
    }

    if (!flipped) {
        if (y < 1) {
            return x < 5 ? "pause" : "surrender";
        } else if (y > 8) {
            return x < 5 ? "pause" : "surrender";
        }
    } else {
        if (y < 1) {
            return x < 5 ? "surrender" : "pause";
        } else if (y > 8) {
            return x < 5 ? "surrender" : "pause";
        }
    }

    if (!flipped) {
        if (x < 1) {
            return ["history:up", "history:down", "flip"];
        } else if (x > 8) {
            return ["history:up", "history:down", "flip"];
        }
    } else {
        if (x < 1) {
            return ["history:up", "history:down", "flip"];
        } else if (x > 8) {
            return ["history:up", "history:down", "flip"];
        }
    }

    return `${x}:${y}`;
};

export const FogLightsType = Object.freeze({
    BOARD: "board",
    HISTORY: "history",
    CONTROL_BUTTON: "control_button",
});

export const AssistantReactionType = Object.freeze({
    MOVE_PIECE: "move",
    DROP_PIECE: "drop",
    PROMOTION_PAWN: "promotion",
    EN_PASSANT_CAPTURE: "en_passant_capture",
    CASTLING: "castling",
    GAME_LONG_TIME: "game_long_time",
    WAIT_MOVE_TIME: "wait_move_time",
    GAME_FINISHED: "game_finished",
    GAME_STATUS: "game_status",
});

export class GameScene {
    _app;
    _scenes;
    _game;
    dialog;
    _controller;
    is_fog;
    fog_lights;
    tutorial;
    tutorial_step;
    tutorial_skipped;
    _friend_invite;
    params;
    carouselHistory;

    _when_need_history_tutorial;

    constructor(scenes, app, game) {
        makeAutoObservable(this, { _scenes: false, _game: false, _app: false });
        this._app = app;
        this._scenes = scenes;
        this._game = game;
        this.dialog = null;
        this.is_fog = false;
        this.fog_lights = null;
        this.tutorial = null;
        this.tutorial_step = null;
        this.tutorial_skipped = false;
        this._friend_invite = null;
        this.carouselHistory = new CarouselGroup(`history`, {
            nodes: () =>
                (this._game.board &&
                    this._game.board.history.map((item) => ({
                        type: ControlType.ITEM,
                        name: `history:number:${item.number}`,
                        meta: item,
                        onClick: () => {
                            this._game.board.update_view_round(item.number);
                        },
                    }))) ||
                [],
            direction: "row",
            basis: this._app.device_type === DeviceType.PORTAL ? 1 : 2,
            reverseColumn: true,
        });
        this._controller = new ControllerV1({
            ok: ({ name, type, meta, ...params }) => {
                if (type === ControlType.SQUARE) {
                    this._tap_on_square(meta);
                } else if (type === ControlType.BUTTON) {
                    // eslint-disable-next-line default-case
                    switch (name) {
                        case "back":
                            this.back({ type, name, meta, ...params });
                            break;

                        case "notification":
                            this._app.windows.open_dialog(DialogType.NOTIFICATION);
                            break;

                        case "pause":
                            this._game.pause();
                            break;

                        case "flip":
                            this._game.board.flip();
                            break;

                        case "draw":
                            this._game.board.draw();
                            break;

                        case "history:scroll:up":
                            this.carouselHistory.setAlternativeIndex(
                                this.carouselHistory.alternativeIndex - 1,
                            );
                            this._fixHistoryFocus();
                            break;

                        case "history:scroll:down":
                            this.carouselHistory.setAlternativeIndex(
                                this.carouselHistory.alternativeIndex + 1,
                            );
                            this._fixHistoryFocus();
                            break;

                        case "surrender":
                            this.surrender_dialog();
                            break;
                    }
                } else if (type === ControlType.HISTORY_ROUND) {
                    this.controller.focus(name);
                    this.carouselHistory.focus(name);
                    this.carouselHistory.click(name);
                }
            },
            back: (params) => this.back(params),
            name: "5:2",
            find: (name) => {
                if (name === null || name === undefined) {
                    return null;
                }
                const board = (this._game && this._game.board) || null;

                let element = name in game_elements ? { name, ...game_elements[name] } : null;
                if (element && element.type === ControlType.VIRTUAL) {
                    if (["history", "history:up", "history:down"].indexOf(name) !== -1) {
                        const historyElement = this.carouselHistory.focusedElement;
                        if (historyElement) {
                            return {
                                type: ControlType.HISTORY_ROUND,
                                name: historyElement.name,
                                meta: historyElement.meta,
                                disabled: false,
                            };
                        }
                        return null;
                    }

                    const flipped = !!(board && board.flipped);
                    name = {
                        "board:left:top": flipped ? "8:1" : "1:8",
                        "board:left:bottom": flipped ? "8:8" : "1:1",
                        "board:right:top": flipped ? "1:1" : "8:8",
                        "board:right:bottom": flipped ? "1:8" : "8:1",
                        "board:right:bottom2": flipped ? "1:7" : "8:2",
                        "board:d:top": flipped ? "5:1" : "4:8",
                        "board:d:bottom": flipped ? "5:8" : "4:1",
                        "board:e:top": flipped ? "4:1" : "5:8",
                        "board:e:bottom": flipped ? "4:8" : "5:1",
                    }[name];
                    element = name in game_elements ? { name, ...game_elements[name] } : null;
                }

                if (element) {
                    if (name === "pause") {
                        const pause_count = this._game.pause_status.count;
                        element.disabled = !(pause_count === -1 || pause_count > 0);
                    }

                    if (
                        ["pause", "surrender", "draw"].indexOf(name) !== -1 &&
                        this._game.board &&
                        this.game.board.finished
                    ) {
                        element.disabled = true;
                    }
                    return element;
                }

                const roundMatch = name.match(/^round:([0-9]+):(black|white)$/i);
                if (roundMatch) {
                    const roundNumber = Number(roundMatch[1]);
                    const roundColor = roundMatch[2];
                    const index =
                        (roundNumber - 1) * 2 +
                        { [PieceColor.WHITE]: 0, [PieceColor.BLACK]: 1 }[roundColor];

                    // eslint-disable-next-line no-param-reassign
                    name = `history:number:${index}`;
                }

                const historyMatch = name.match(/^history:number:([0-9]+)$/i);
                if (historyMatch) {
                    const historyElement = this.carouselHistory.find(
                        (item) => item && item.name === name,
                    );
                    if (historyElement) {
                        return {
                            type: ControlType.HISTORY_ROUND,
                            name: historyElement.name,
                            meta: historyElement.meta,
                            disabled: false,
                        };
                    }
                    return null;
                }

                return null;
            },
            move: ({ name, type, meta = {}, ...params }, direction) => {
                if (type === ControlType.HISTORY_ROUND) {
                    if (this.carouselHistory.move(direction)) {
                        const historyElement = this.carouselHistory.focusedElement;
                        return historyElement ? historyElement.name : null;
                    }
                    return (
                        {
                            left: "board:right:top",
                            right: "history:scroll:up",
                            up: "notification",
                            down: "flip",
                        }[direction] || null
                    );
                }

                const board = (this._game && this._game.board) || null;
                const flipped = !!(board && board.flipped);
                let element = params[direction] || null;

                if (type === ControlType.SQUARE) {
                    element = move_for_square(direction, meta, flipped);
                }

                if (element === "history") {
                    const historyElement = this.carouselHistory.focusedElement;
                    return historyElement
                        ? historyElement.name
                        : {
                              left: "board:right:top",
                              right: "history:scroll:up",
                              up: "notification",
                              down: "flip",
                          }[direction];
                }
                return element;
            },
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
            on_blur: () => {
                if (this._game.board && this._game.board.activeHistoryNumber !== undefined) {
                    this._game.board.update_view_round(-1);
                }
            },
        });
        this._controllerMultiSelect = new Controller(
            new Group("root", {
                nodes: () =>
                    this._game.board.multiselect.fromSquares.map((item) => ({
                        type: ControlType.SQUARE,
                        name: `${item.x}:${item.y}`,
                        meta: item,
                        onClick: () => {
                            this._game.board.mover.start(item);
                        },
                    })),
                mover: (direction, element, children) => {
                    let square = element ? element.meta : null;
                    if (!square) {
                        if (children.length <= 0) {
                            return undefined;
                        }
                        square = children[0].meta;
                    }

                    const flipped = !!(this._game && this._game.board && this._game.board.flipped);
                    const elementName = find_square_element_name(
                        square,
                        flipped
                            ? { down: "up", up: "down", left: "right", right: "left" }[direction]
                            : direction,
                        children.map((item) => item.meta),
                    );

                    return children.find((item) => item.name === elementName);
                },
                onCancel: () => {
                    if (this._game && this._game.board) {
                        this._game.board.multiselect.cancel();
                    }
                    return false;
                },
            }),
            { hasKeyboard: () => this._app.windows.hasSceneKeyboard() },
        );
        this.params = null;

        // При переходе игры в завершенный режим, нужно показать диалог
        // окончания игры
        reaction(
            () => this._game.board && this._game.board.finished,
            (value, prev) => {
                if (value && prev !== value) {
                    const { finished } = this._game.board;

                    let result = null;
                    // eslint-disable-next-line default-case
                    switch (finished.result) {
                        case GameResult.DRAW:
                            result = "draw";
                            break;

                        case GameResult.WHITE_WON:
                        case GameResult.BLACK_SURRENDERED:
                            result = this._game.board.white.is_self ? "win" : "loose";
                            break;

                        case GameResult.BLACK_WON:
                        case GameResult.WHITE_SURRENDERED:
                            result = this._game.board.black.is_self ? "win" : "loose";
                            break;
                    }

                    if (
                        result !== null &&
                        [
                            GameType.WITH_ASSISTANT,
                            GameType.WITH_PLAYER,
                            GameType.WITH_PLAYER_RATING,
                        ].indexOf(finished.game_type) !== -1
                    ) {
                        this._app.assistant.fire_event("event_assistant_comment", {
                            type: AssistantReactionType.GAME_FINISHED,
                            result,
                            is_self: true,
                            game_type: finished.game_type,
                            playerColor: this._game.board.side,
                            currentColor: this._game.board.current_color,
                        });
                    }
                    this.finished_dialog();
                }
            },
        );

        // При изменении длинны истории позиционируемся на ее конце,
        // что-бы всегда показывать актуальные ходы игры
        reaction(
            () => (this._game.board && this._game.board.history.length) || 0,
            (value) => {
                if (value > 0) {
                    this.carouselHistory.focus(`history:number:${value - 1}`);
                    this._fixHistoryFocus();
                }
            },
        );
        this._when_need_history_tutorial = null;

        // Реакция на выставление шаха и мата игроку
        reaction(
            () => {
                const { board } = this._game;
                if (board) {
                    return {
                        checkmate: board.checkmate,
                        check: board.check,
                        side: board.enemy_side,
                    };
                }
                return { checkmate: false, check: false, side: null };
            },
            ({ checkmate, check, side }, prev) => {
                const { board } = this._game;
                if (board && board.game_type === GameType.WITH_ASSISTANT && side) {
                    if (checkmate && checkmate !== prev.checkmate) {
                        this._app.assistant.fire_event("event_assistant_comment", {
                            type: AssistantReactionType.GAME_STATUS,
                            is_checkmate: checkmate,
                            is_check: false,
                            is_assistant: side.is_assistant,
                            playerColor: board.side,
                            currentColor: board.current_color,
                        });
                    } else if (check && check !== prev.check) {
                        this._app.assistant.fire_event("event_assistant_comment", {
                            type: AssistantReactionType.GAME_STATUS,
                            is_checkmate: false,
                            is_check: check,
                            is_assistant: side.is_assistant,
                            playerColor: board.side,
                            currentColor: board.current_color,
                        });
                    }
                }
            },
        );

        // При загрузке игры мы хотим фокусироваться всегда на
        // координате E2
        reaction(
            () => (this._game.board && this._game.board.is_inited) || false,
            (value, prev) => {
                if (value && !prev) {
                    if (this._game.board && this._game.board.flipped) {
                        this.controller.default_layer.focus("4:7");
                    } else {
                        this.controller.default_layer.focus("5:2");
                    }
                }
            },
        );

        // Нужно генерировать реплики ассистента при изменении
        // состояния доски
        let assistant_reactions = { game_id: -1 };
        let assistantTimerReactions = { game_id: -1 };
        reaction(
            () => {
                const { board } = this.game;
                if (
                    board &&
                    [BoardState.PLAYING, BoardState.MOVING].indexOf(board.state) !== -1 &&
                    [
                        GameMode.WITH_ASSISTANT,
                        GameMode.WITH_PLAYER_RATING,
                        GameMode.WITH_PLAYER,
                    ].indexOf(board.mode) !== -1 &&
                    board.game_server &&
                    (board.common_time > 0 || board.wait_time > 0)
                ) {
                    return { board };
                }
                return { board: null };
            },
            ({ board }) => {
                if (board) {
                    const actions = [];
                    const historyLength = board.history.length;
                    const playerOrder = board.side === PieceColor.WHITE ? 0 : 1;
                    const isPlayerMove = historyLength % 2 === playerOrder;
                    const commonTime = board.common_time;
                    const waitTime = board.wait_time;
                    const isWithAssistant = board.game_type === GameType.WITH_ASSISTANT;
                    const isMultiplayer =
                        [GameType.WITH_PLAYER, GameType.WITH_PLAYER_RATING].indexOf(
                            board.game_type,
                        ) !== -1;
                    const isNeedComment = isMultiplayer || isWithAssistant;

                    if (board.game_server.game_id !== assistantTimerReactions.game_id) {
                        assistantTimerReactions = {
                            game_id: board.game_server.game_id,
                            game_long_5min: commonTime >= 5 * 60000,
                            game_long_10min: commonTime >= 10 * 60000,
                            game_long_15min: commonTime >= 15 * 60000,
                            game_long_20min: commonTime >= 20 * 60000,
                            game_long_30min: commonTime >= 30 * 60000,
                            game_long_40min: commonTime >= 40 * 60000,
                            game_long_60min: commonTime >= 60 * 60000,
                            wait_move_30sec: waitTime >= 0.5 * 60000 ? historyLength : -1,
                            wait_move_1min: waitTime >= 1 * 60000 ? historyLength : -1,
                            wait_move_2min: waitTime >= 2 * 60000 ? historyLength : -1,
                            wait_move_3min: waitTime >= 3 * 60000 ? historyLength : -1,
                            wait_move_5min: waitTime >= 5 * 60000 ? historyLength : -1,
                            wait_move_10min: waitTime >= 10 * 60000 ? historyLength : -1,
                        };
                    }

                    const timer = assistantTimerReactions;

                    // 2. Реплики по таймеру игры
                    [
                        { time: 5, name: "game_long_5min", isValid: isNeedComment },
                        { time: 10, name: "game_long_10min", isValid: isNeedComment },
                        { time: 15, name: "game_long_15min", isValid: isWithAssistant },
                        { time: 20, name: "game_long_20min", isValid: isNeedComment },
                        { time: 30, name: "game_long_30min", isValid: isWithAssistant },
                        { time: 40, name: "game_long_40min", isValid: isNeedComment },
                        { time: 60, name: "game_long_60min", isValid: isWithAssistant },
                    ].forEach(({ time, name, isValid }) => {
                        if (isValid && !timer[name] && commonTime >= time * 60000) {
                            actions.push({
                                type: AssistantReactionType.GAME_LONG_TIME,
                                time,
                                is_player: isPlayerMove,
                                playerColor: board.side,
                                currentColor: board.current_color,
                            });
                            timer[name] = true;
                        }
                    });

                    // 9. Промедление
                    [
                        { time: 0.5, name: "wait_move_30sec", isValid: isMultiplayer },
                        { time: 1, name: "wait_move_1min", isValid: isMultiplayer },
                        { time: 2, name: "wait_move_2min", isValid: isWithAssistant },
                        { time: 3, name: "wait_move_3min", isValid: isMultiplayer },
                        { time: 5, name: "wait_move_5min", isValid: isWithAssistant },
                        { time: 10, name: "wait_move_10min", isValid: isWithAssistant },
                    ].forEach(({ time, name, isValid }) => {
                        if (isValid && timer[name] < historyLength && waitTime >= time * 60000) {
                            actions.push({
                                type: AssistantReactionType.WAIT_MOVE_TIME,
                                time,
                                number: historyLength,
                                is_player: isPlayerMove,
                                playerColor: board.side,
                                currentColor: board.current_color,
                            });
                            timer[name] = historyLength;
                        }
                    });

                    if (actions.length > 0) {
                        this._app.assistant.fire_event("event_assistant_comment", actions[0]);
                    }
                }
            },
        );

        reaction(
            () => {
                const board = this.game.board;
                if (
                    board &&
                    board.state === BoardState.PLAYING &&
                    [
                        GameType.WITH_ASSISTANT,
                        GameType.WITH_PLAYER,
                        GameType.WITH_PLAYER_RATING,
                    ].indexOf(board.game_type) !== -1 &&
                    board.game_server &&
                    // eslint-disable-next-line sonarjs/no-collection-size-mischeck
                    board.history.length >= 0
                ) {
                    return board;
                }
                return null;
            },
            (board) => {
                if (board) {
                    const is_flag = (flag, mask) => (flag & mask) === flag;
                    const actions = [],
                        history_length = board.history.length,
                        move = history_length > 0 ? board.history[history_length - 1] : {},
                        is_simple_move = move.flags === MoveFlag.NO_CAPTURE,
                        player_order = board.side === PieceColor.WHITE ? 0 : 1,
                        engine_order = 1 - player_order,
                        // ход совершенный игроком, при этом ход уже в истории присутствует
                        // из-за этого приходиться считать, что остаток от деления не равен
                        // очередности хода игроком
                        is_player_move = history_length % 2 !== player_order,
                        // ход совершенный ассистентом, при этом ход уже в истории присутствует
                        // из-за этого приходиться считать, что остаток от деления не равен
                        // очередности хода ассистента
                        is_engine_move = history_length % 2 !== engine_order,
                        is_promotion = (move.flags & MoveFlag.PROMOTION) === MoveFlag.PROMOTION,
                        is_en_passant_capture = is_flag(MoveFlag.EN_PASSANT_CAPTURE, move.flags),
                        is_king_side_castling = is_flag(MoveFlag.KING_SIDE_CASTLING, move.flags),
                        is_queen_side_castling = is_flag(MoveFlag.QUEEN_SIDE_CASTLING, move.flags),
                        is_castling = is_king_side_castling || is_queen_side_castling;
                    if (board.game_server.game_id !== assistant_reactions.game_id) {
                        const turn_number = Math.trunc(history_length / 2);
                        assistant_reactions = {
                            game_id: board.game_server.game_id,
                            player_items: [],
                            player_step_4: turn_number * 2 + player_order,
                            engine_items: [],
                            engine_step_3: turn_number * 2 + engine_order,
                            history_length: history_length,
                        };
                    }

                    if (history_length === assistant_reactions.history_length) {
                        return;
                    }

                    // 3. Особые события
                    if (move.promotion && is_promotion) {
                        actions.push({
                            type: AssistantReactionType.PROMOTION_PAWN,
                            piece: move.promotion,
                            is_player: is_player_move,
                            playerColor: board.side,
                            currentColor: board.current_color,
                        });
                    }

                    if (move.drop === PieceType.PAWN && is_en_passant_capture) {
                        actions.push({
                            type: AssistantReactionType.EN_PASSANT_CAPTURE,
                            is_player: is_player_move,
                            playerColor: board.side,
                            currentColor: board.current_color,
                        });
                    }

                    if (is_castling) {
                        actions.push({
                            type: AssistantReactionType.CASTLING,
                            side: is_king_side_castling ? PieceType.KING : PieceType.QUEEN,
                            is_player: is_player_move,
                            playerColor: board.side,
                            currentColor: board.current_color,
                        });
                    }

                    // 4. Состояние доски (противника)
                    // TODO: GSCHESS-3

                    // 6. Взятие фигуры (реакция)
                    if (is_player_move && move.drop) {
                        actions.push({
                            type: AssistantReactionType.DROP_PIECE,
                            piece: move.drop,
                            is_player: is_player_move,
                            playerColor: board.side,
                            currentColor: board.current_color,
                        });
                    }

                    // 5. Взятие фигуры (действие)
                    if (is_engine_move && move.drop) {
                        actions.push({
                            type: AssistantReactionType.DROP_PIECE,
                            piece: move.drop,
                            is_player: is_player_move,
                            playerColor: board.side,
                            currentColor: board.current_color,
                        });
                    }

                    const {
                            player_step_4,
                            engine_step_3,
                            items = [],
                            player_items = [],
                            engine_items = [],
                        } = assistant_reactions,
                        player_diff_4 = history_length - 1 - player_step_4,
                        engine_diff_3 = history_length - 1 - engine_step_3;
                    // 7. Ординарный ход (действие)
                    // Наступает каждый 3й ход бота
                    // TODO: GSCHESS-7
                    if (engine_diff_3 > 0 && engine_diff_3 % 6 === 4) {
                        let has_block_rule =
                            // Проверяем, что в последних 3х ходах не возникло условий 3, 4, 5, 6
                            !!engine_items.slice(-3).find((reaction) => {
                                return (
                                    reaction &&
                                    reaction.type !== null &&
                                    [
                                        AssistantReactionType.DROP_PIECE,
                                        AssistantReactionType.PROMOTION_PAWN,
                                        AssistantReactionType.EN_PASSANT_CAPTURE,
                                        AssistantReactionType.CASTLING,
                                    ].indexOf(reaction.type) !== -1
                                );
                            });

                        if (!has_block_rule && is_simple_move) {
                            actions.push({
                                type: AssistantReactionType.MOVE_PIECE,
                                is_player: is_player_move,
                                playerColor: board.side,
                                currentColor: board.current_color,
                            });
                        }
                    }

                    // 8. Ординарный ход (реакция)
                    // Наступает каждый 4й ход игрока
                    // TODO: GSCHESS-8
                    if (player_diff_4 > 0 && player_diff_4 % 8 === 6) {
                        const last_engine_reaction =
                            (engine_items.length > 0
                                ? engine_items[engine_items.length - 1] || {}
                                : {}
                            ).type || null;

                        let has_block_rule =
                            // Проверяем, что в последних 4х ходах не возникло условий 3, 4, 5, 6
                            // А так же последняя реакция на ходе ассистента не была
                            // на перемещение фигуры
                            !!player_items.slice(-4).find((reaction) => {
                                return (
                                    reaction &&
                                    reaction.type !== null &&
                                    [
                                        AssistantReactionType.DROP_PIECE,
                                        AssistantReactionType.PROMOTION_PAWN,
                                        AssistantReactionType.EN_PASSANT_CAPTURE,
                                        AssistantReactionType.CASTLING,
                                    ].indexOf(reaction.type) !== -1
                                );
                            }) && last_engine_reaction !== AssistantReactionType.MOVE_PIECE;

                        if (!has_block_rule && is_simple_move) {
                            actions.push({
                                type: AssistantReactionType.MOVE_PIECE,
                                is_player: is_player_move,
                                playerColor: board.side,
                                currentColor: board.current_color,
                            });
                        }
                    }

                    let reaction = null;
                    assistant_reactions.history_length = history_length;
                    if (actions.length > 0) {
                        reaction = actions[0];
                        this._app.assistant.fire_event("event_assistant_comment", actions[0]);
                    }

                    if (is_player_move) {
                        assistant_reactions.player_items.push(reaction);
                    }
                    if (is_engine_move) {
                        assistant_reactions.engine_items.push(reaction);
                    }
                }
            },
        );

        reaction(
            () => this._game.is_paused,
            (is_paused, prev_value) => {
                if (prev_value !== is_paused) {
                    if (is_paused) {
                        this._scenes.assistant.fire_event("open_dialog_pause");
                    } else {
                        switch (this.dialog) {
                            case "exit":
                                this._scenes.assistant.transition("/game/exit", { deferred: true });
                                break;

                            case "surrender":
                                this._scenes.assistant.transition("/game/surrender", {
                                    deferred: true,
                                });
                                break;

                            case "finished":
                                this._scenes.assistant.transition("/game/finished", {
                                    deferred: true,
                                });
                                break;

                            default:
                                this._scenes.assistant.fire_event("close_dialog");
                        }
                    }
                }
            },
        );

        this._timer = setInterval(() => this._update_timer(), 1000);
    }

    get controller() {
        if (this._game.is_paused) {
            return new ControllerV1(pause_dialog_layer(this));
        }

        if (this._game && this._game.board && this._game.board.multiselect.isActive) {
            if (!this._controllerMultiSelect.focusedName) {
                const square = this._game.board.multiselect.fromSquares[0];
                this._controllerMultiSelect.focus(`${square.x}:${square.y}`);
            }
            return this._controllerMultiSelect;
        }

        const game_board = (this._game && this._game.board) || null;
        if (game_board && game_board.mover.is_active) {
            const from_cell = game_board.mover.from_cell;
            const current_element = `${from_cell.x}:${from_cell.y}`;
            const position_list = from_cell.can_move_to.map(({ to }) => ({ x: to.x, y: to.y }));
            position_list.push({ x: from_cell.x, y: from_cell.y });

            const elements = {},
                flipped = !!(this._game && this._game.board && this._game.board.flipped),
                up_direction = flipped ? "down" : "up",
                down_direction = flipped ? "up" : "down",
                left_direction = flipped ? "right" : "left",
                right_direction = flipped ? "left" : "right";
            position_list.forEach((to) => {
                elements[`${to.x}:${to.y}`] = {
                    [up_direction]: find_square_element_name(
                        { x: to.x, y: to.y },
                        "up",
                        position_list,
                    ),
                    [down_direction]: find_square_element_name(
                        { x: to.x, y: to.y },
                        "down",
                        position_list,
                    ),
                    [left_direction]: find_square_element_name(
                        { x: to.x, y: to.y },
                        "left",
                        position_list,
                    ),
                    [right_direction]: find_square_element_name(
                        { x: to.x, y: to.y },
                        "right",
                        position_list,
                    ),
                    type: ControlType.SQUARE,
                    meta: { x: to.x, y: to.y },
                };
            });

            return new ControllerV1({
                ok: ({ name, type, meta, ...params }) => {
                    if (type === ControlType.SQUARE) {
                        this._tap_on_square(meta);
                    }
                },
                back: (params) => this.back(params),
                name: current_element,
                elements,
                find: (name) => {
                    if (name === null) {
                        return null;
                    }

                    const element = elements[name];
                    if (element) {
                        return element;
                    }

                    const base_element = this._controller.find_by_name(name, false);
                    if (base_element && base_element.type !== ControlType.SQUARE) {
                        return { disabled: true };
                    }

                    return null;
                },
                has_touch_mode: () => this._app.is_touch_mode,
            });
        }
        return this._controller;
    }

    get assistantState() {
        const moves = (this._game && this._game.board && this._game.board.moves) || [];
        return {
            type: (this._game.board && this._game.board.game_type) || null,
            side: (this._game.board && this._game.board.side) || PieceColor.WHITE,
            moves: moves.map(({ piece, from, to, drop, promotion }) => ({
                piece,
                from,
                to,
                drop,
                promotion,
            })),
        };
    }

    get game_id() {
        if (this.game && this.game.board && this.game.board.game_server) {
            return this.game.board.game_server.game_id;
        }
        return null;
    }

    get game() {
        return this._game;
    }

    get history() {
        // console.log("GameScene", "generate page of history", this._game.board);
        const items = (this._game.board && this._game.board.history) || [];
        return new Pages({
            size: 2,
            items,
            visible: this._app.device_type === DeviceType.BOX ? 6 : 5,
        });
    }

    get can_send_friend_invite() {
        if (this._friend_invite) {
            return false;
        }

        const game_board = (this._game && this._game.board) || null;
        if (game_board) {
            const game_type = game_board.game_type;
            if ([GameType.WITH_PLAYER_RATING, GameType.WITH_PLAYER].indexOf(game_type) !== -1) {
                const side = [game_board.white, game_board.black].find(
                    (item) =>
                        !!(
                            item &&
                            item.user_id &&
                            !item.is_self &&
                            !item.is_assistant &&
                            !item.is_friend
                        ),
                );
                return !!(side && side.user_id);
            }
        }
        return false;
    }

    back({ type }) {
        if (type === ControlType.HISTORY_ROUND) {
            if (this.carouselHistory.cancel()) {
                return;
            }

            if (this._game.board.view_round_index !== -1) {
                this._game.board.view_round_index = -1;
                return;
            }
        }

        if (this._game.board.mover.is_active) {
            this._game.board.mover.cancel();
        } else if (this._game.board.promotion.is_active) {
            this._game.board.promotion.cancel();
        } else if (this._game.board.finished === null) {
            this.exit_dialog();
        } else if (this._game.board.finished) {
            this.finished_dialog();
        } else {
            this._game.close_game();
            this._scenes.change(SceneType.HOME);
        }
    }

    _fixHistoryFocus() {
        if (
            this._controller.focusedElement &&
            this._controller.focusedElement.type === ControlType.HISTORY_ROUND &&
            this._controller.focusedElement.name !== this.carouselHistory.focusedElement.name
        ) {
            this._controller.focus(this.carouselHistory.focusedElement.name);
        }
    }

    _tap_on_square({ x, y }) {
        // нельзя разрешать управлять фигурами во время хождения по истории
        if (this._game.board.view_round_index === -1) {
            if (this._game.board.mover.piece === null) {
                this._game.board.mover.update({ to: { x, y } });
                this._game.board.mover.start(this._game.board.cell_by_xy({ x, y }));
            } else {
                this._game.board.mover.update({ to: { x, y } });
                this._game.board.mover.finish();
            }
        } else {
            this._game.board.mover.cancel();
        }
    }

    _open_dialog(name) {}

    _close_dialog(release_controller = true) {
        this.dialog = null;
        if (release_controller) {
            this.controller.pop();
        }
        this._scenes.assistant.fire_event("close_dialog");
    }

    send_friend_invite() {
        const game_board = (this._game && this._game.board) || null;
        if (this.can_send_friend_invite && game_board) {
            const side = [game_board.white, game_board.black].find(
                (item) =>
                    !!(
                        item &&
                        item.user_id &&
                        !item.is_self &&
                        !item.is_assistant &&
                        !item.is_friend
                    ),
            );

            if (side && side.user_id) {
                const invite_user_id = side.user_id;
                this._app.server.friend.invite.send(side.user_id).then((invite) =>
                    runInAction(() => {
                        const { user } = invite || {};
                        this._friend_invite = invite;
                        this.controller.focus("close_dialog");
                        if (invite.status === "ACCEPTED") {
                            [game_board.white, game_board.black].forEach((item) => {
                                if (invite_user_id && item && item.user_id === invite_user_id) {
                                    item.user.is_friend = true;
                                }
                            });
                        }
                    }),
                );
            }
        }
    }

    finished_dialog() {
        if (this.dialog) {
            return;
        }

        this.game.board.pause();
        this._scenes.assistant.fire_event("open_dialog_finished", this.game.board.finished);
        this.dialog = "finished";
        this.controller.push(finished_dialog_layer(this));
    }

    exit_dialog() {
        if (this.dialog) {
            return;
        }

        this._scenes.assistant.fire_event("open_dialog_exit");
        this.dialog = "exit";
        this.controller.push(exit_dialog_layer(this));
    }

    surrender_dialog() {
        if (this.game && this.game.board) {
            this._app.windows.open_dialog(DialogType.GAME_SURRENDER, { board: this.game.board });
        }
    }

    apply_tutorial({ is_skipped = false } = {}) {
        this.controller.pop();
        this.controller.push(waitFinishTutorialLayer(this));

        if (!this.tutorial_skipped) {
            // Специально отключена финальная фраза.
            // Этот костыль сделан, для того что-бы на новой прошивке
            // избавиться от зависания обучения. Так как событие после
            // этого до ассистента не доходят
            this._app.assistant.fire_event(
                is_skipped ? "event_tutorial_skipped" : "event_tutorial_finished",
                this.tutorial,
            );
            runInAction(() => {
                this.tutorial_skipped = true;
            });
        }
        this._app.user.apply_tutorial(this.tutorial).then(() =>
            runInAction(() => {
                this.is_fog = false;
                this.fog_lights = null;
                this.tutorial = null;
                this.tutorial_step = null;
                this.controller.pop();
                this._scenes.assistant.transition("/scene/game");
            }),
        );
    }

    next_step_tutorial() {
        if (this.tutorial === TutorialType.GAME_HOT_SEAT_BASIS) {
            this.is_fog = true;
            this.fog_lights = FogLightsType.BOARD;

            if (this.tutorial_step === null) {
                //     this.tutorial_step = "axes";
                //     this._app.assistant.transition("/tutorial/game/board/axes");
                //     this.controller.pop();
                //     this.controller.push(board_axes_tutorial_layer(this));
                // } else if (this.tutorial_step === "axes") {
                this.tutorial_step = "step_white";
                this._app.assistant.transition("/tutorial/hot_seat_basis/step_white");
                this.controller.pop();
                this.controller.push(board_move_tutorial_layer(this, PieceColor.WHITE));
                when(
                    () => this._game.board && this._game.board.history.length > 0,
                    () => this.next_step_tutorial(),
                );
            } else if (this.tutorial_step === "step_white") {
                this.tutorial_step = "step_black";
                this._app.assistant.transition("/tutorial/hot_seat_basis/step_black");
                this.controller.pop();
                this.controller.push(board_move_tutorial_layer(this, PieceColor.BLACK));
                when(
                    () => this._game.board && this._game.board.history.length > 1,
                    () => this.next_step_tutorial(),
                );
            } else if (this.tutorial_step === "step_black") {
                this.tutorial_step = "history";
                this.fog_lights = FogLightsType.HISTORY;
                this._app.assistant.transition("/tutorial/game/history");
                this.controller.pop();
                this.controller.push(history_tutorial_layer(this));
            } else if (this.tutorial_step === "history") {
                this.tutorial_step = "controls";
                this.fog_lights = FogLightsType.CONTROL_BUTTON;
                this._app.assistant.transition("/tutorial/game/controls");
                this.controller.pop();
                this.controller.push(controls_tutorial_layer(this));
            } else if (this.tutorial_step === "controls") {
                this.fog_lights = null;
                this.apply_tutorial();
            }
        } else if (this.tutorial === TutorialType.GAME_WITH_BOT_BASIS) {
            this.is_fog = true;
            this.fog_lights = FogLightsType.BOARD;

            if (this.tutorial_step === null) {
                this.tutorial_step = "waitAssistantStep";
                this._app.assistant.transition("/tutorial/game/board/wait_assistant_step");
                this.controller.pop();
                this.controller.push(skipTutorialLayer(this));
                when(
                    () => this._game.board && this._game.board.history.length > 0,
                    () => this.next_step_tutorial(),
                );
            } else if (this.tutorial_step === "waitAssistantStep") {
                //     this.tutorial_step = "axes";
                //     this._app.assistant.transition("/tutorial/game/board/axes");
                //     this.controller.pop();
                //     this.controller.push(board_axes_tutorial_layer(this));
                // } else if (this.tutorial_step === "axes") {
                this.tutorial_step = "first_step";
                this._app.assistant.transition("/tutorial/with_bot_basis/first_step");
                this.controller.pop();
                this.controller.push(board_move_tutorial_layer(this, this.game.board.side));
                when(
                    () => this._game.board && this._game.board.history.length > 1,
                    () => this.next_step_tutorial(),
                );
            } else if (this.tutorial_step === "first_step") {
                this.tutorial_step = "history";
                this.fog_lights = FogLightsType.HISTORY;
                this._app.assistant.transition("/tutorial/game/history");
                this.controller.pop();
                this.controller.push(history_tutorial_layer(this));
            } else if (this.tutorial_step === "history") {
                this.tutorial_step = "controls";
                this.fog_lights = FogLightsType.CONTROL_BUTTON;
                this._app.assistant.transition("/tutorial/game/controls");
                this.controller.pop();
                this.controller.push(controls_tutorial_layer(this));
            } else if (this.tutorial_step === "controls") {
                this.fog_lights = null;
                this.apply_tutorial();
            }
        }
    }

    _update_timer(time = 1000) {
        if (
            !this._scenes.is_loading &&
            this.game.board &&
            [BoardState.MOVING, BoardState.PLAYING].indexOf(this.game.board.state) !== -1 &&
            this.tutorial === null
        ) {
            this.game.board.increase_time(time);
        }
    }

    on_preload() {
        if (this.params && this.params.game_id) {
            const gameId = this.params.game_id;
            this._game
                .open_game({ id: gameId })
                .then(() => console.log(`Игра ${gameId} успешно открыта`));
        }
    }

    on_activate() {
        this.is_fog = false;
        this.tutorial = null;
        this.tutorial_step = null;
        this.tutorial_skipped = false;
        this._friend_invite = null;

        if (this._game.board) {
            const run_tutorial = () =>
                runInAction(() => {
                    const board = this._game.board,
                        history_length = board.history.length,
                        game_mode = board.mode,
                        game_side = board.side,
                        tutorial = this._app.user.tutorial;

                    if (tutorial.game_hot_seat_basis || tutorial.game_with_bot_basis) {
                        // Не заставляем игрока проходит два обучения
                        return;
                    }

                    if (
                        !tutorial.game_hot_seat_basis &&
                        game_mode === GameMode.HOT_SEAT &&
                        history_length < 3
                    ) {
                        this.tutorial = TutorialType.GAME_HOT_SEAT_BASIS;
                        this.tutorial_step = {
                            [0]: null,
                            [1]: "step_white",
                            [2]: "step_black",
                        }[history_length];

                        this.next_step_tutorial();
                        return;
                    }

                    if (!tutorial.game_with_bot_basis && game_mode === GameMode.WITH_BOT) {
                        if (
                            history_length === 0 ||
                            (history_length === 1 && game_side === PieceColor.BLACK)
                        ) {
                            this.tutorial = TutorialType.GAME_WITH_BOT_BASIS;
                            this.tutorial_step =
                                history_length === 0 && game_side === PieceColor.BLACK
                                    ? null
                                    : "waitAssistantStep";
                        }

                        if (
                            (history_length === 2 && game_side === PieceColor.WHITE) ||
                            (history_length === 3 && game_side === PieceColor.BLACK)
                        ) {
                            this.tutorial = TutorialType.GAME_WITH_BOT_BASIS;
                            this.tutorial_step = "first_step";
                        }

                        if (this.tutorial === TutorialType.GAME_WITH_BOT_BASIS) {
                            this.next_step_tutorial();
                        }
                    }
                });

            if (this._game.board.mode === null) {
                when(
                    () => this._game.board && this._game.board.mode !== null,
                    () => {
                        // Возможно не правильное выставление состояние голосового
                        // бекенда, если мы закроем игровую сцену, до того момента
                        // как нам ответит игровой сервер
                        run_tutorial();
                    },
                );
            } else {
                run_tutorial();
            }
        }
    }
}
