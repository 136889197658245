import clsx from "clsx";
import React from "react";

import styles from "./icons.module.pcss";

export const IconPause = ({ size = "s" }) => (
    <div className={clsx(styles.icon, styles[size])}>
        <svg width="100%" version="1.1" fill="var(--plasma-colors-primary)" viewBox="0 -25 254 304">
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="ico-pauza.eps">
                    <path d="M90 31c-8,0 -14,6 -14,14l0 163c0,8 6,14 14,14 8,0 14,-6 14,-14l0 -163c0,-8 -6,-14 -14,-14zm74 0c-8,0 -14,6 -14,14l0 163c0,8 6,14 14,14 8,0 14,-6 14,-14l0 -163c0,-8 -6,-14 -14,-14z" />
                </g>
            </g>
        </svg>
    </div>
);
