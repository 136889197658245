import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Container, Col, Row, Button, UserAvatar, Footnote1, Headline3 } from "components";
import { IconBack } from "components/icons";

import { rankToTitle } from "models/helpers";

import styles from "./dialog.module.pcss";

export default observer(({ dialog, visible = false }) => {
    const closeDialog = (event) => {
        dialog.close();
        event.preventDefault();
        event.stopPropagation();
    };

    const { fromUser: user } = dialog;

    return (
        <div
            className={clsx(styles.view, { [styles.hidden]: !visible || !dialog.visible })}
            onClick={closeDialog}
            aria-hidden="true"
        >
            <Container>
                <Row>
                    <Col
                        sizeXL={9}
                        offsetXL={1.5}
                        sizeL={7}
                        offsetL={0.5}
                        sizeM={6}
                        offsetM={0}
                        sizeS={4}
                        offsetS={0}
                    >
                        <div
                            className={clsx(styles.content)}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            aria-hidden="true"
                        >
                            <div className={clsx(styles.invite)}>
                                <div className={clsx(styles.avatar)}>
                                    <UserAvatar preset={user.avatar_preset_id} />
                                </div>
                                <div style={{ minWidth: 0 }}>
                                    <Headline3 className={clsx(styles.ellipsis)}>
                                        Приглашение на игру
                                    </Headline3>
                                    <Footnote1
                                        className={clsx(styles.ellipsis)}
                                        style={{
                                            color: "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))",
                                        }}
                                    >
                                        {[
                                            user.nickname || `ID ${user.id}`,
                                            user.rating,
                                            rankToTitle(user.rank),
                                        ].join(" \u00B7 ")}
                                    </Footnote1>
                                </div>
                            </div>

                            <div className={clsx(styles.controls)}>
                                <Button
                                    name="accept"
                                    view="secondary"
                                    size="s"
                                    text="Принять"
                                    controller={dialog.controller}
                                />
                                <Button
                                    name="cancel"
                                    view="secondary"
                                    size="s"
                                    text="Отклонить"
                                    controller={dialog.controller}
                                />
                            </div>

                            <div
                                className={clsx(styles.closeInfo)}
                                onClick={closeDialog}
                                aria-hidden="true"
                            >
                                <IconBack color="var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))" />
                                <Footnote1>Скрыть уведомление</Footnote1>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
});
