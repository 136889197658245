import { action, makeAutoObservable, runInAction } from "mobx";

import { DialogType, GameInviteState, InviteStatus, InviteType, LoadState } from "models/enums";

export class NotificationStore {
    _app;
    _state;
    friend_invites;
    player_vs_player_requests;

    constructor(app, { refresh_interval = 1000 } = {}) {
        makeAutoObservable(this, { _app: false, _update_success: action.bound });
        this._app = app;
        this._state = LoadState.INIT;
        this._refresh_interval = refresh_interval;
        this.friend_invites = [];
        this.player_vs_player_requests = [];
    }

    __init__() {
        setInterval(() => this.refresh(), this._refresh_interval);
    }

    refresh() {
        if (this._state !== LoadState.LOADING) {
            this._app.server.notification
                .list()
                .then(this._update_success)
                .catch((error) =>
                    runInAction(() => {
                        this._state = LoadState.FAIL;
                        console.error(error);
                    }),
                );
        }
    }

    get is_visible() {
        return true;
    }

    get is_empty() {
        return this.player_vs_player_requests.length === 0 && this.friend_invites.length === 0;
    }

    get has_notify_for_viewing() {
        return (
            !this.is_empty &&
            (!!this.friend_invites.find(
                ({ type, status, from_viewed_at = null }) =>
                    (type === InviteType.INCOMING && status === InviteStatus.INVITED) ||
                    (type === InviteType.OUTCOMING &&
                        status !== InviteStatus.INVITED &&
                        from_viewed_at === null),
            ) ||
                this.player_vs_player_requests.length > 0)
        );
    }

    get has_incoming_invites() {
        return !!this.friend_invites.find(
            ({ type, status }) => type === InviteType.INCOMING && status === InviteStatus.INVITED,
        );
    }

    _update_success({
        friend_invites: friendsInvites = [],
        player_vs_player_requests: playerVsPlayerRequests = [],
    }) {
        this.player_vs_player_requests.replace(playerVsPlayerRequests);
        this.friend_invites.replace(friendsInvites);

        const now = new Date().getTime() / 1000.0 - 60.0;
        friendsInvites.forEach((userInvite) => {
            if (
                userInvite &&
                userInvite.status === InviteStatus.INVITED &&
                !userInvite.to_viewed_at &&
                userInvite.created_at > now
            ) {
                this._app.windows.ask(DialogType.ASK_USER_INVITE, { invite: userInvite });
            }
        });

        playerVsPlayerRequests.forEach((gameInvite) => {
            if (
                gameInvite &&
                gameInvite.state === GameInviteState.WAITING &&
                !gameInvite.toViewedAt &&
                gameInvite.createdAt > now
            ) {
                this._app.windows.ask(DialogType.ASK_GAME_INVITE, { invite: gameInvite });
            }
        });
        // const max_friends = 1 - Math.min(1, friend_invites.length);
        // this.player_vs_player_requests.replace(player_vs_player_requests.slice(0, 2 + max_friends));
        // this.friend_invites.replace(
        //     friend_invites.slice(0, 4 - this.player_vs_player_requests.length),
        // );
    }
}
