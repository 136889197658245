import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    Button,
    Col,
    Container,
    RectSkeleton,
    Row,
    TextBoxBigTitle,
    TextBoxSubTitle,
    TextBoxTitle,
    TextSkeleton,
    UserAvatar,
} from "components";

import { UserStatus } from "models/enums";
import { rank_to_title, user_status_to_title } from "models/helpers";

import styles from "./dialog.module.pcss";

const Buttons = observer(({ buttons = [], skeleton = false, controller }) => (
    <React.Fragment>
        {buttons.map(({ name, text, view, size, roundness = 12 }) =>
            skeleton ? (
                <RectSkeleton
                    key={name}
                    width="100%"
                    height={{ xs: "2.25rem" }[size || "xs"] || "1rem"}
                    roundness={roundness}
                />
            ) : (
                <Button
                    key={name}
                    name={name}
                    text={text || ""}
                    controller={controller}
                    view={view || "secondary"}
                    size={size || "xs"}
                />
            ),
        )}
    </React.Fragment>
));

export const FriendInviteDialog = observer(({ dialog, skeleton = false }) => {
    const controller = dialog.controller;
    const is_skeleton = skeleton || dialog.is_loading;
    const is_error = dialog.is_error;
    const buttons = dialog.buttons;
    const { nickname, id: user_id, rating, rank, status, avatar_preset_id } = dialog.from_user;

    return (
        <Container
            className={clsx(styles.view, { [styles.portal]: dialog.is_portal })}
            onClick={() => dialog.close()}
        >
            <Row>
                <Col
                    sizeXL={5}
                    offsetXL={7}
                    sizeL={5}
                    offsetL={3}
                    sizeM={3.25}
                    offsetM={2.75}
                    sizeS={4}
                    offsetS={0}
                >
                    <div
                        className={clsx(styles.content, styles.panel)}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                    >
                        {is_error ? (
                            <React.Fragment>
                                <TextBoxBigTitle>Что-то пошло не так</TextBoxBigTitle>
                                <TextBoxSubTitle>повторите попытку позже</TextBoxSubTitle>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className={clsx(styles.info)}>
                                    {!is_skeleton && (
                                        <div
                                            className={clsx(
                                                styles.status,
                                                {
                                                    [UserStatus.ONLINE]: styles.success,
                                                    [UserStatus.BUSY]: styles.warning,
                                                    [UserStatus.OFFLINE]: styles.critical,
                                                }[status],
                                            )}
                                        >
                                            <TextBoxTitle color={"var(--element-status-color)"}>
                                                {user_status_to_title(dialog.status)}
                                            </TextBoxTitle>
                                        </div>
                                    )}

                                    <div className={clsx(styles.avatar)}>
                                        <UserAvatar
                                            preset={avatar_preset_id}
                                            skeleton={is_skeleton}
                                        />
                                    </div>

                                    <div className={clsx(styles.text)}>
                                        {is_skeleton ? (
                                            <React.Fragment>
                                                <TextSkeleton lines={1} size="body1" />
                                                <div style={{ width: "10rem" }}>
                                                    <TextSkeleton lines={1} size="footnote1" />
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <TextBoxTitle>
                                                    {nickname ? nickname : `ID ${user_id}`}
                                                </TextBoxTitle>
                                                <TextBoxSubTitle>
                                                    {rating} &#183; {rank_to_title(rank)}
                                                </TextBoxSubTitle>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={clsx(styles.controls, {
                                        [styles.three]: buttons.length === 3,
                                    })}
                                >
                                    <Buttons
                                        buttons={buttons}
                                        controller={controller}
                                        skeleton={is_skeleton}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
