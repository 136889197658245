import { TUTORIAL_CONTROL_SKIP, TUTORIAL_CONTROL_NEXT } from "models/tutorial.buttons";

export default () => ({
    ok: () => {},
    back: () => false,
    name: null,
    find: (name) => {
        if (name === null) {
            return null;
        }

        if (name.match(/^([1-8]):([1-8])$/i)) {
            return { disabled: true };
        }

        return (
            {
                back: { disabled: true },
                [TUTORIAL_CONTROL_NEXT]: { disabled: true },
                [TUTORIAL_CONTROL_SKIP]: { disabled: true },
            }[name] || null
        );
    },
});
