import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    Header,
    Container,
    Carousel,
    Row,
    CarouselCol,
    CarouselGridWrapper,
    Button,
    Col,
    Headline1,
    Footnote1,
    RectSkeleton,
} from "components";
import UserCard from "scenes/friends/user-card";

import styles from "./scene.module.pcss";

const Content = observer(({ scene }) => {
    const { type } = scene.content;
    if (type === "failure" || type === "empty") {
        const {
            title,
            subTitle = null,
            button = null,
            image = null,
            isCenter = false,
        } = scene.content;
        return (
            <Row className={clsx(styles.content, styles.middle)}>
                <Col
                    sizeXL={12}
                    sizeL={8}
                    sizeM={6}
                    sizeS={4}
                    className={clsx({ [styles.center]: isCenter })}
                >
                    {image && (
                        <img
                            src={image}
                            style={{
                                width: "10rem",
                                height: "10rem",
                                marginBottom: "-2rem",
                            }}
                        />
                    )}
                    <div style={{ marginBottom: "2rem" }}>
                        {title && <Headline1>{title}</Headline1>}
                        {subTitle && (
                            <Footnote1
                                style={{
                                    marginTop: "0.25rem",
                                    color: "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))",
                                }}
                            >
                                {subTitle}
                            </Footnote1>
                        )}
                    </div>
                    {button && (
                        <Button
                            name={button.name}
                            view={button.view || "primary"}
                            controller={scene.controller}
                            text={button.text}
                        />
                    )}
                </Col>
            </Row>
        );
    }

    if (scene.isLoading || type === "skeleton") {
        return (
            <Row className={clsx(styles.content, styles.middle)}>
                <Col sizeXL={3} sizeL={3} sizeM={2} sizeS={3}>
                    <div className={clsx(styles.column)}>
                        <RectSkeleton width="100%" height="8rem" />
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <CarouselGridWrapper className={clsx(styles.content, styles.middle)}>
            <Carousel
                as={Row}
                index={scene.users.alternativeIndex}
                scrollSnapType="mandatory"
                onIndexChange={(x) => {
                    scene.users.setAlternativeIndex(x);
                }}
            >
                {scene.users.grid.map((col, x) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <CarouselCol key={x} sizeXL={3} sizeL={3} sizeM={2} sizeS={3}>
                        <div className={clsx(styles.column)}>
                            {col.map(({ name, meta: user }) => (
                                <React.Fragment key={name}>
                                    <UserCard
                                        key={name}
                                        name={name}
                                        user={user}
                                        controller={scene.controller}
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                    </CarouselCol>
                ))}
            </Carousel>
        </CarouselGridWrapper>
    );
});

export default observer(({ scene, skeleton = false }) => {
    const isSkeleton = skeleton || scene.isLoading;

    return (
        <Container style={{ width: "100vw", height: "100vh", display: "flex" }}>
            <Header
                title={scene.nickname}
                sub_title="Поиск"
                controller={scene.controller}
                skeleton={isSkeleton}
            />

            <Content scene={scene} />
        </Container>
    );
});
