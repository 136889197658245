import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Col, Container, Row, Button, TextBoxBigTitle, RectSkeleton } from "components";
import { IconSurrender } from "components/icons";

import styles from "./dialog.module.pcss";

export default observer(({ dialog }) => {
    return (
        <Container className={clsx(styles.view, styles.warning)}>
            <Row>
                <Col
                    sizeXL={5}
                    offsetXL={7}
                    sizeL={5}
                    offsetL={3}
                    sizeM={3}
                    offsetM={3}
                    sizeS={4}
                    offsetS={0}
                >
                    <div className={clsx(styles.content)}>
                        <div className={clsx(styles.header)}>
                            <div className={clsx(styles.circle)}>
                                <IconSurrender size="s" color="inherit" />
                            </div>
                        </div>

                        <div className={clsx(styles.title)}>
                            <TextBoxBigTitle>Вы уверены, что хотите сдаться?</TextBoxBigTitle>
                        </div>

                        <div className={clsx(styles.footer)}>
                            {dialog.isLoading ? (
                                <>
                                    <RectSkeleton width="100%" height="2.5rem" />
                                    <RectSkeleton width="100%" height="2.5rem" />
                                </>
                            ) : (
                                <>
                                    <Button
                                        name="surrender_cancel"
                                        text="Продолжить игру"
                                        controller={dialog.controller}
                                        size="s"
                                    />
                                    <Button
                                        name="surrender_ok"
                                        text="Сдаться"
                                        view="secondary"
                                        controller={dialog.controller}
                                        size="s"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
