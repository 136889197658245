import styled from "styled-components";
import React from "react";
import { observer } from "mobx-react-lite";

import { Button } from "@sberdevices/plasma-ui/components/Button/Button";

const StyledThinkButton = styled(Button)`
    height: 2.25em;
    padding: 0.25em 1.1em;
    font-size: 0.7rem;
    border-radius: 0.75em 0.75em 0.75em 0.75em;

    &::before {
        border-radius: 0.875em 0.875em 0.875em 0.875em;
    }
`;

export const ThinkButton = observer(
    ({
        name,
        controller,
        disabled = false,
        view = "secondary",
        pin = "square-square",
        focused = false,
        outlined = false,
        ...params
    }) => {
        const element = (controller && controller.find_by_name(name)) || {};
        return (
            <StyledThinkButton
                onClick={() => controller && controller.click(name)}
                disabled={element.disabled || disabled}
                outlined={element.focused || outlined}
                focused={element.focused || focused}
                view={view}
                pin={pin}
                {...params}
            />
        );
    },
);
