import clsx from "clsx";
import styled, { css } from "styled-components";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useToast } from "@sberdevices/plasma-ui";

import {
    Button,
    Col,
    Container,
    Row,
    TextBoxBigTitle,
    TextBoxSubTitle,
    TextBoxTitle,
    UserAvatar,
    mediaQuery,
    CarouselItem,
    Carousel,
} from "components";
import { Timer } from "models/timer";
import { InviteStatus } from "models/enums";

import styles from "./dialog.module.pcss";

const ItemRoot = styled.div`
    ${({ theme }) =>
        css`
            ${mediaQuery(
                "S",
                theme.deviceScale,
            )(css`
                margin-left: -1rem;
                margin-right: -1rem;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
            `)}
            ${mediaQuery(
                "M",
                theme.deviceScale,
            )(css`
                margin-left: -1rem;
                margin-right: -1rem;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
            `)}
            ${mediaQuery(
                "L",
                theme.deviceScale,
            )(css`
                margin-left: -1rem;
                margin-right: -1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
            `)}
            ${mediaQuery(
                "XL",
                theme.deviceScale,
            )(css`
                margin-left: -1rem;
                margin-right: -1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
            `)}
        `}
`;

const Item = observer(
    ({
        name,
        id,
        title = null,
        user = null,
        avatarIcon = null,
        children = null,
        activated = false,
        onViewed = null,
        timeDetectView = 1,
        controller = null,
    }) => {
        const [timer] = useState(() => new Timer(0));
        useEffect(() => {
            timer.reset(timeDetectView);
            const handle = setInterval(() => {
                timer.decrease();
                if (timer.seconds <= 0) {
                    clearInterval(handle);
                    if (onViewed) {
                        onViewed({ id });
                    }
                }
            }, 1000);
            return () => {
                timer.reset();
                clearInterval(handle);
            };
        }, [timeDetectView]);

        const element = (controller && controller.findByName(name)) || null;
        const disabled = element ? element.disabled : false;
        const focused = element
            ? element.focused || (name !== null && name === controller.focusedName)
            : false;

        const nickname = user.nickname ? user.nickname : `ID ${user.id}`;
        return (
            <ItemRoot
                className={clsx(styles.item, {
                    [styles.activated]: activated,
                    [styles.disabled]: disabled,
                    [styles.outlined]: focused,
                })}
                onClick={(event) => {
                    if (controller && controller.click(name)) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                }}
            >
                <div className={clsx(styles.info)}>
                    <div className={clsx(styles.avatar)}>
                        <UserAvatar preset={user.avatar_preset_id || 1} />
                        <div>{avatarIcon}</div>
                    </div>
                    <div>
                        <TextBoxTitle>{title}</TextBoxTitle>
                        <TextBoxSubTitle>
                            {user.rating} &#183; {nickname}
                        </TextBoxSubTitle>
                    </div>
                </div>
                {children !== null && children.length > 0 ? (
                    <div className={clsx(styles.controls)}>{children}</div>
                ) : null}
            </ItemRoot>
        );
    },
);

const GameInviteCard = observer(
    ({
        name,
        id,
        color,
        user,
        status = null,
        onViewed = null,
        activated = false,
        controller = null,
    }) => {
        return (
            <Item
                id={id}
                name={name}
                title="Приглашение в игру"
                user={user}
                onViewed={onViewed}
                activated={activated}
                controller={controller}
            >
                <Button
                    name="item:accept"
                    text="Принять"
                    view="primary"
                    controller={controller}
                    size="xs"
                />
                <Button
                    name="item:cancel"
                    text="Отклонить"
                    controller={controller}
                    view="secondary"
                    size="xs"
                />
            </Item>
        );
    },
);

const FriendInviteCard = observer(
    ({
        name,
        id,
        from_user,
        to_user,
        status = null,
        controller = null,
        onViewed = null,
        activated = false,
    }) => {
        if (status !== InviteStatus.INVITED) {
            const resolution =
                {
                    [InviteStatus.ACCEPTED]: "Запрос в друзья принят",
                    [InviteStatus.IGNORED]: "Запрос в друзья проигнорирован",
                    [InviteStatus.CANCELED]: "Запрос в друзья отклонен",
                    [InviteStatus.BLOCKED]: "Запрос в друзья отклонен",
                }[status] || `Запрос в друзья ${status}`;
            return (
                <Item
                    name={name}
                    id={id}
                    title={resolution}
                    user={to_user}
                    onViewed={onViewed}
                    controller={controller}
                />
            );
        }

        return (
            <Item
                id={id}
                name={name}
                title="Добавление в друзья"
                user={from_user}
                onViewed={onViewed}
                activated={activated}
                controller={controller}
            >
                <Button
                    name={"item:accept"}
                    text="Принять"
                    view="primary"
                    controller={controller}
                    size="xs"
                />
                <Button
                    name={"item:cancel"}
                    text="Отклонить"
                    controller={controller}
                    view="secondary"
                    size="xs"
                />
            </Item>
        );
    },
);

export const ItemCard = observer(({ name, type, item, dialog, controller }) => {
    if (type === "invite") {
        const { id, from_user, to_user, status } = item;
        // const name = `item-${type}-${id}`;
        return (
            <FriendInviteCard
                id={id}
                name={name}
                from_user={from_user}
                to_user={to_user}
                status={status}
                controller={controller}
                activated={name === dialog.activeItemName}
                onViewed={() =>
                    dialog.markFriendInviteAsViewed({
                        id,
                        status,
                    })
                }
            />
        );
    }

    if (type === "request") {
        const { id, status, color, user } = item;
        // const name = `item-${type}-${id}`;
        return (
            <GameInviteCard
                id={id}
                name={name}
                color={color}
                user={user}
                status={status}
                activated={name === dialog.activeItemName}
                controller={controller}
            />
        );
    }

    return null;
});

export const NotificationDialog = observer(({ dialog }) => {
    if (dialog.is_empty) {
        const { showToast } = useToast();
        showToast("Нет уведомлений", "bottom", 3000);
        dialog.close();
        return null;
    }

    const { controller, carousel } = dialog;
    return (
        <Container className={clsx(styles.view)} onClick={() => dialog.close()}>
            <Row style={{ display: "flex", flexGrow: 1 }}>
                <Col
                    sizeXL={5.5}
                    offsetXL={6.5}
                    sizeL={5}
                    offsetL={3}
                    sizeM={3.75}
                    offsetM={2.25}
                    sizeS={4}
                    offsetS={0}
                    style={{ display: "flex", flexGrow: 1 }}
                >
                    <div
                        className={clsx(styles.content, styles.panel)}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                    >
                        <TextBoxBigTitle>Оповещения</TextBoxBigTitle>
                        <div className={clsx(styles.list)}>
                            <Carousel
                                axis="y"
                                index={carousel.alternativeIndex}
                                scrollSnapType="mandatory"
                                onIndexChange={(index) => carousel.setAlternativeIndex(index)}
                                style={{
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem",
                                    marginLeft: "-1rem",
                                    marginRight: "-1rem",
                                }}
                                paddingStart="0.5rem"
                                paddingEnd="0.5rem"
                            >
                                {carousel.children.map(({ name, meta: { type, item } }) => (
                                    <CarouselItem key={name} className={clsx(styles.item)}>
                                        <ItemCard
                                            key={name}
                                            name={name}
                                            type={type}
                                            item={item}
                                            dialog={dialog}
                                            controller={controller}
                                        />
                                    </CarouselItem>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
