import { makeAutoObservable } from "mobx";

export class TextField {
    _value;
    _editing;
    _errors;

    constructor({ value = "", editing = false, errors = [] }) {
        makeAutoObservable(this, {});
        this._value = value;
        this._editing = editing;
        this._errors = errors || [];
    }

    get isEditing() {
        return this._editing;
    }

    get status() {
        if (this._errors.length > 0) {
            return "error";
        }
        return undefined;
    }

    get value() {
        return this._value;
    }

    get helperText() {
        if (this._errors.length > 0) {
            return this._errors[0];
        }
        return undefined;
    }

    set value(value) {
        this._value = value;
    }

    toggleEdit() {
        this._editing = !this._editing;
    }

    startEdit() {
        this._editing = true;
    }

    finishEdit() {
        this._editing = false;
    }

    set errors(value) {
        this._errors.replace(value);
    }
}
