import { makeAutoObservable, runInAction } from "mobx";

import { ControlType } from "models/enums";

const initFirstEnabled = (children) => {
    if (!children || children.length === 0) {
        return undefined;
    }
    return children.find((item) => item && !item.disabled);
};

export default class Group {
    _name;
    _node;
    _nodes;
    _mover;
    _init;
    _checkEqual;
    _disabled;

    _onCancel;

    constructor(
        name,
        {
            nodes = [],
            mover = null,
            init = null,
            checkEqual = null,
            onCancel = null,
            disabled = null,
        } = {},
    ) {
        makeAutoObservable(this);
        this._name = name;
        this._nodes = nodes;
        this._mover = mover;
        this._init = init || initFirstEnabled;
        this._node = this._init(nodes);
        this._checkEqual =
            checkEqual || ((first, second) => first && second && first.name === second.name);
        this._disabled = disabled;

        this._onCancel = onCancel instanceof Function ? onCancel : null;
    }

    // eslint-disable-next-line class-methods-use-this
    get type() {
        return ControlType.GROUP;
    }

    get name() {
        return this._name;
    }

    get _children() {
        if (this._nodes instanceof Function) {
            return this._nodes();
        }
        return this._nodes || [];
    }

    get focusedElement() {
        let node;
        if (this._node && this._node.type === ControlType.GROUP) {
            node = this._node.focusedElement;
        }

        if (node) {
            return node;
        }

        if (
            !this._children.find((item) => this._checkEqual(item, this._node)) &&
            this._init instanceof Function
        ) {
            runInAction(() => {
                this._node = this._init(this._children);
            });
        }

        return this._node;
    }

    get disabled() {
        if (this._disabled instanceof Function) {
            return this._disabled();
        }
        return !!this._disabled;
    }

    click(name) {
        if (this._node && this._node.type === ControlType.GROUP && this._node.click(name)) {
            return true;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of this._children) {
            if (
                child &&
                child !== this._node &&
                child.type === ControlType.GROUP &&
                child.click(name)
            ) {
                return true;
            }
        }

        const child = this._children.find((item) => item && item.name === name);
        if (child && child.onClick instanceof Function) {
            child.onClick();
            return true;
        }

        return false;
    }

    focus(name) {
        if (this._node && this._node.type === ControlType.GROUP && this._node.focus(name)) {
            return true;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of this._children) {
            if (
                child &&
                child !== this._node &&
                child.type === ControlType.GROUP &&
                child.focus(name)
            ) {
                this._node = child;
                return true;
            }
        }

        const child = this._children.find((item) => item && item.name === name);
        if (child) {
            this._node = child;
            return true;
        }

        return false;
    }

    find(predicate) {
        if (this._node && this._node.type === ControlType.GROUP) {
            const node = this._node.find(predicate);
            if (node !== undefined) {
                return node;
            }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of this._children) {
            if (child && child !== this._node && child.type === ControlType.GROUP) {
                const node = child.find(predicate);
                if (node !== undefined) {
                    return node;
                }
            }
        }

        return this._children.find(predicate);
    }

    move(direction) {
        if (this._node && this._node.type === ControlType.GROUP && this._node.move(direction)) {
            return true;
        }

        if (this._mover instanceof Function) {
            const node = this._mover(direction, this._node, this._children);
            if (node) {
                this._node = node;
                return true;
            }
            return false;
        }

        if (this._mover && this._mover.move instanceof Function) {
            const node = this._mover.move(direction, this._node, this._children);
            if (node) {
                this._node = node;
                return true;
            }
            return false;
        }

        return false;
    }

    cancel() {
        if (this._node && this._node.type === ControlType.GROUP && this._node.cancel()) {
            return true;
        }

        if (this._node && this._node.onCancel instanceof Function && this._node.onCancel()) {
            return true;
        }

        if (this._onCancel instanceof Function) {
            return !!this._onCancel();
        }
        return false;
    }
}
