import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Button, Header, RectSkeleton, TextBoxTitle, TextSkeleton } from "components";

import styles from "./scene.module.pcss";

export const ChoiceSpeedScene = observer(({ scene, skeleton = false, notification = null }) => (
    <div className={clsx(styles.view)}>
        <Header
            title="Настройка партии"
            controller={scene.controller}
            skeleton={skeleton}
            notification={notification}
        />

        <div className={clsx(styles.content)}>
            {skeleton ? (
                <React.Fragment>
                    <div className={clsx(styles.title, styles.skeleton)}>
                        <TextSkeleton width="6rem" lines={1} size="headline2" />
                    </div>
                    <div className={clsx(styles.speeds, styles.bullet)}>
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                    </div>
                    <div />

                    <div className={clsx(styles.title, styles.skeleton)}>
                        <TextSkeleton width="6rem" lines={1} size="headline2" />
                    </div>
                    <div className={clsx(styles.speeds)}>
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                    </div>
                    <div />

                    <div className={clsx(styles.title, styles.skeleton)}>
                        <TextSkeleton width="6rem" lines={1} size="headline2" />
                    </div>
                    <div className={clsx(styles.speeds)}>
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />

                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                        <RectSkeleton width="100%" height="2.5rem" />
                    </div>
                    <div />

                    <div />
                    <div className={clsx(styles.speeds, styles.play)}>
                        <RectSkeleton width="100%" height="2.5rem" roundness={32} />
                    </div>
                    <div />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className={clsx(styles.title)}>
                        <TextBoxTitle>Пуля</TextBoxTitle>
                    </div>
                    <div className={clsx(styles.speeds, styles.bullet)}>
                        <Button name="1m" title="1 мин." controller={scene.controller} size="s" />
                        <Button name="1m+1s" title="1|1" controller={scene.controller} size="s" />
                        <Button name="2m+1s" title="2|1" controller={scene.controller} size="s" />
                    </div>
                    <div />

                    <div className={clsx(styles.title)}>
                        <TextBoxTitle>Блиц</TextBoxTitle>
                    </div>
                    <div className={clsx(styles.speeds)}>
                        <Button name="3m" title="3 мин." controller={scene.controller} size="s" />
                        <Button name="5m" title="5 мин." controller={scene.controller} size="s" />
                        <Button name="3m+2s" title="3|2" controller={scene.controller} size="s" />
                        <Button name="5m+5s" title="5|5" controller={scene.controller} size="s" />
                    </div>
                    <div />

                    <div className={clsx(styles.title)}>
                        <TextBoxTitle>Быстрые</TextBoxTitle>
                    </div>
                    <div className={clsx(styles.speeds)}>
                        <Button name="10m" title="10 мин." controller={scene.controller} size="s" />
                        <Button name="20m" title="20 мин." controller={scene.controller} size="s" />
                        <Button name="30m" title="30 мин." controller={scene.controller} size="s" />
                        <Button
                            name="15m+10s"
                            title="15|10"
                            controller={scene.controller}
                            size="s"
                        />

                        <Button name="45m" title="45 мин." controller={scene.controller} size="s" />
                        <Button name="60m" title="60 мин." controller={scene.controller} size="s" />
                        <Button
                            name="30m+30s"
                            title="30|30"
                            controller={scene.controller}
                            size="s"
                        />
                        <Button
                            name="45m+45s"
                            title="45|45"
                            controller={scene.controller}
                            size="s"
                        />
                    </div>
                    <div />

                    <div />
                    <div className={clsx(styles.speeds, styles.play)}>
                        <Button
                            name="play"
                            title="Простая игра"
                            controller={scene.controller}
                            secondary
                            size="s"
                        />
                    </div>
                    <div />
                </React.Fragment>
            )}
        </div>
    </div>
));
