import { makeAutoObservable } from "mobx";

export class Pages {
    _items;
    _visible;
    _scrolled;
    _number;
    size;

    constructor({ size = 1, items = [], number = 1, visible = 1, scrolled = true }) {
        makeAutoObservable(this);
        this.size = size;
        this._scrolled = scrolled;
        this._visible = visible;
        this._items = items;
        this._number = 0;
        this.select(number);
    }

    get count() {
        return Math.ceil(this._items.length / this.size);
    }

    get items() {
        const start = (this.number - 1) * this.size;
        return this._items.slice(start, start + this.size * this._visible);
    }

    get number() {
        return this._number;
    }

    get visible() {
        return this._visible;
    }

    get has_prev() {
        return this.number - 1 > 0;
    }

    get has_next() {
        return this.number + this._visible <= this.count;
    }

    focus(index) {
        const page_of_index = Math.trunc(index / this.size) + 1;
        if (page_of_index > this.number + this._visible - 1) {
            this.select(page_of_index - this._visible + 1);
        } else if (page_of_index < this.number) {
            this.select(page_of_index);
        }
    }

    select(number) {
        this._number = Math.max(Math.min(number, this.count), 1);
    }

    next() {
        this.select(this.number + 1);
    }

    prev() {
        this.select(this.number - 1);
    }
}
