import { makeAutoObservable, reaction } from "mobx";

import { AnalyticsEventType } from "models/enums";

const dev_to_dev_transform = {
    [AnalyticsEventType.CREATE_GAME]: ({
        scene,
        game_id,
        game_mode,
        side,
        bot_level,
        speed_duration,
        speed_additional,
    }) => [
        { name: "scene", type: "string", value: scene },
        { name: "game_id", type: "double", value: game_id },
        { name: "game_mode", type: "string", value: game_mode },
        { name: "side", type: "string", value: side },
        { name: "bot_level", type: "double", value: bot_level },
        { name: "speed_duration", type: "double", value: speed_duration },
        { name: "speed_additional", type: "double", value: speed_additional },
    ],
    [AnalyticsEventType.OPEN_GAME]: ({ scene, game_id }) => [
        { name: "scene", type: "string", value: scene },
        { name: "game_id", type: "double", value: game_id },
    ],
    [AnalyticsEventType.CLOSE_GAME]: ({ scene, game_id, finished, duration }) => [
        { name: "scene", type: "string", value: scene },
        { name: "game_id", type: "double", value: game_id },
        { name: "duration", type: "double", value: duration },
    ],
    [AnalyticsEventType.CHANGE_SCENE]: ({ from, to, duration }) => [
        { name: "from", type: "string", value: from },
        { name: "to", type: "string", value: to },
        { name: "duration", type: "double", value: duration },
    ],
    [AnalyticsEventType.CLOSE_APP]: ({ scene, trigger }) => [
        { name: "scene", type: "string", value: scene },
        { name: "trigger", type: "string", value: trigger },
    ],
};

export class AnalyticsStore {
    _app;
    _user;
    _dev_to_dev;
    _use_console;

    constructor(app, user, { use_console = false } = {}) {
        makeAutoObservable(this, { _app: false, _dev_to_dev: false });
        this._app = app;
        this._user = user;
        this._dev_to_dev = null;
        this._use_console = use_console;
    }

    __init__() {
        if (process.env.DEV_TO_DEV_API_KEY) {
            this._dev_to_dev = devtodev;
            devtodev.setDebugLog(process.env.IS_DEV_MODE);
            devtodev.setAppData({ appVersion: this._app.version });
            devtodev.init(process.env.DEV_TO_DEV_API_KEY, this._user.uid);
            reaction(
                () => this._user.uid,
                (user_id, prev_user_id) => {
                    devtodev.init(process.env.DEV_TO_DEV_API_KEY, user_id, prev_user_id);
                },
            );
        }
    }

    _send_dev_to_dev_custom_event(name, params) {
        if (this._dev_to_dev) {
            const transform = dev_to_dev_transform[name] || null;
            if (transform) {
                this._dev_to_dev.customEvent(name, transform(params));
            }
            this._dev_to_dev.customEvent(name);
        }
    }

    _send_to_console(name, params) {
        if (this._use_console) {
            console.log("Analytics", "event", name, params);
        }
    }

    send_event(name, params = {}) {
        const data = { scene: this._app.windows.scenes.type, ...(params || {}) };
        this._send_dev_to_dev_custom_event(name, data);
        this._send_to_console(name, data);
    }

    send_close_game({ game_id = null, finished = null, duration = 0 }) {
        this.send_event(AnalyticsEventType.CLOSE_GAME, { game_id, finished, duration });
    }

    send_open_game({ game_id = null }) {
        this.send_event(AnalyticsEventType.OPEN_GAME, { game_id });
    }

    send_create_game({
        game_id = null,
        game_mode = null,
        side = null,
        bot_level = 0,
        speed_duration = 0,
        speed_additional = 0,
    }) {
        this.send_event(AnalyticsEventType.CREATE_GAME, {
            game_id,
            game_mode,
            side,
            bot_level,
            speed_duration,
            speed_additional,
        });
    }

    send_change_scene({ from = null, to = null, duration = 0 }) {
        this.send_event(AnalyticsEventType.CHANGE_SCENE, { from, to, duration });
    }

    send_close_app({ trigger = null } = {}) {
        this.send_event(AnalyticsEventType.CLOSE_APP, { trigger });
    }
}
