import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    TextBoxBigTitle,
    TextBoxSubTitle,
    Headline1,
    Container,
    Row,
    Col,
    Header,
} from "components";
import { WaitingPlayerState } from "models/dialogs/waiting-player";

import styles from "./dialog.module.pcss";

export default observer(({ dialog }) => {
    const { state } = dialog;
    const title = {
        // eslint-disable-next-line sonarjs/no-all-duplicated-branches
        [WaitingPlayerState.INIT]: dialog.is_personally
            ? "" // ? "Подготовка приглашения оппонента"
            : "", // : "Подготовка к поиску оппонента",
        [WaitingPlayerState.WAITING]: dialog.is_personally
            ? "Ждем приема приглашения оппонентом..."
            : "Ищем оппонента...",
        [WaitingPlayerState.FAILED]: "Что-то пошло не так",
        [WaitingPlayerState.OPENING_GAME]: "Открытие игры...",
        [WaitingPlayerState.CANCELING]: dialog.is_personally
            ? "Отменяется приглашение на партию..."
            : "Отменяется запрос на поиск игрока...",
        [WaitingPlayerState.CANCELED]: dialog.is_personally
            ? "Приглашение отменено"
            : "Поиск отменен",
        [WaitingPlayerState.APPROVED]: "Подтверждено",
    }[state];
    const subTitle = { [WaitingPlayerState.FAILED]: "повторите попытку позже" }[state] || null;

    return (
        <Container className={clsx(styles.view)}>
            <div className={styles.header}>
                <Header controller={dialog.controller} />
            </div>

            <div className={clsx(styles.content)}>
                <Row>
                    <Col style={{ textAlign: "center" }}>
                        {!subTitle ? (
                            <Headline1>{title}</Headline1>
                        ) : (
                            <TextBoxBigTitle>{title}</TextBoxBigTitle>
                        )}
                        {subTitle && <TextBoxSubTitle>{subTitle}</TextBoxSubTitle>}
                    </Col>
                </Row>
            </div>
        </Container>
    );
});
