import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    ActionButton,
    Button,
    Header,
    Icon,
    NotificationButton,
    Container,
    Row,
    Col,
    RectSkeleton,
} from "components";

import styles from "./scene.module.pcss";

export const HomeScene = observer(({ scene, skeleton = false, notification = null }) => {
    const { controller } = scene;
    return (
        <Container className={clsx(styles.view)}>
            <Header controller={controller} skeleton={skeleton} without_back>
                <ActionButton
                    name="settings"
                    controller={controller}
                    size="l"
                    pin="square-square"
                    skeleton={skeleton}
                >
                    <Icon icon="persone" size="s" color="inherit" />
                </ActionButton>

                <NotificationButton
                    name="notification"
                    notification={notification}
                    controller={controller}
                    skeleton={skeleton}
                />
            </Header>

            <Row className={clsx(styles.content)}>
                <Col
                    sizeXL={4}
                    offsetXL={4}
                    sizeL={4}
                    offsetL={2}
                    sizeM={2.5}
                    offsetM={1.75}
                    sizeS={4}
                    offsetS={0}
                    className={clsx(styles.gap)}
                >
                    {skeleton ? (
                        <React.Fragment>
                            <RectSkeleton width="100%" height="3rem" />
                            <RectSkeleton width="100%" height="3rem" />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button
                                name="new"
                                text="Новая игра"
                                controller={scene.controller}
                                size="m"
                                skeleton={skeleton}
                            />
                            <Button
                                name="continue"
                                text="Продолжить игру"
                                controller={scene.controller}
                                size="m"
                                skeleton={skeleton}
                                view="secondary"
                            />
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        </Container>
    );
});
