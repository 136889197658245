import { makeAutoObservable, reaction, runInAction } from "mobx";

import { uuid4 } from "lib/helpers";

import ScenesStore from "models/scenes.store";
import { ASK_DIALOG_CLASSES, DIALOG_CLASSES } from "models/dialogs";
import WindowStack from "models/window-stack";
import { SimpleTimer } from "models/simple-timer";

export default class WindowsStore {
    _app;
    _onSceneTimer;
    _syncAssistantKey;
    scenes;
    dialogs;
    questions;

    constructor(app) {
        makeAutoObservable(this, { _app: false, _syncAssistantPath: false });
        this._app = app;
        this.scenes = new ScenesStore(this._app);
        this.dialogs = new WindowStack(this._app, { classes: DIALOG_CLASSES });
        this.questions = new WindowStack(this._app, { classes: ASK_DIALOG_CLASSES, reverse: true });
        this._onSceneTimer = new SimpleTimer({ auto_start: true });
        this._syncAssistantKey = null;

        reaction(
            () => this._currentWindow && this._currentWindow.assistantPath,
            (path, prev) => {
                if (path !== prev) {
                    this._syncAssistantPath();
                }
            },
        );

        reaction(
            () => this.questions.currentWindow || this.dialogs.currentWindow,
            (window, prev) => {
                if (window && window !== prev) {
                    window.preload();
                    if (prev && prev.id !== window.id) {
                        prev.deactivate();
                    }
                }
            },
        );

        reaction(
            () => this.scenes.currentWindow,
            (scene, prev) => {
                if (scene && scene !== prev) {
                    scene.preload();
                    if (prev && prev.id !== scene.id) {
                        prev.deactivate();
                    }
                }

                if (prev && scene && prev.type !== scene.type) {
                    this._app.analytics.send_change_scene({
                        from: prev.type,
                        to: scene.type,
                        duration: this._onSceneTimer.time,
                    });
                }
                this._onSceneTimer.restart();
            },
        );
    }

    __init__() {
        // eslint-disable-next-line no-underscore-dangle
        this.scenes.__init__();
    }

    get _currentStack() {
        if (this.questions.currentWindow) {
            return this.questions;
        }

        if (this.dialogs.currentWindow) {
            return this.dialogs;
        }

        return this.scenes;
    }

    get _currentWindow() {
        if (this._currentStack) {
            return this._currentStack.currentWindow;
        }
        return this.scenes.currentWindow;
    }

    get controller() {
        if (this._currentWindow) {
            return this._currentWindow.controller;
        }
        return null;
    }

    get dialog() {
        if (this.dialogs.currentWindow) {
            return this.dialogs.currentWindow.data;
        }
        return undefined;
    }

    get assistant_state() {
        const ask = this.questions.type
            ? {
                  type: this.questions.type,
                  [this.questions.type]: this.questions.assistantState,
              }
            : { type: null };

        const dialog = this.dialogs.type
            ? {
                  type: this.dialogs.type,
                  [this.dialogs.type]: this.dialogs.assistantState,
              }
            : { type: null };

        const scene = this.scenes.type
            ? {
                  type: this.scenes.type,
                  [this.scenes.type]: this.scenes.assistantState,
              }
            : { type: null };

        const window = this._currentWindow
            ? {
                  type: this._currentWindow.type,
                  [this._currentWindow.type]: this._currentWindow.assistantState,
              }
            : { type: null };

        return { scene, dialog, ask, window };
    }

    _syncAssistantPath(key = null) {
        const window = this._currentWindow;
        if (this._syncAssistantKey === key && window && window.assistantPath) {
            const syncKey = uuid4();
            runInAction(() => {
                this._syncAssistantKey = syncKey;
            });
            return this._app.assistant
                .transition(window.assistantPath, { deferred: false, uid: window.id })
                .then(() => {
                    if (
                        this._currentWindow &&
                        window.assistantPath !== this._currentWindow.assistantPath
                    ) {
                        return this._syncAssistantPath(syncKey);
                    }
                    runInAction(() => {
                        this._syncAssistantKey = null;
                        window.activate();
                    });
                    return null;
                })
                .catch(() => this._syncAssistantPath(syncKey));
        }
        return Promise.resolve();
    }

    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    apply_path(path, params = {}, uid = null) {
        // this.scenes.apply_path(path, params);
    }

    change_scene(type, params = {}, deferred = false) {
        this.scenes.change(type, params, deferred);
    }

    open_scene(type, params = {}) {
        this.scenes.open(type, params);
    }

    open_dialog(type, params = {}) {
        this.dialogs.open(type, params);
    }

    ask(type, params = {}, options = {}) {
        this.questions.open(type, { ...params, ...options });
    }

    hasQuestion() {
        return this.questions.stack.length > 0;
    }

    hasDialog() {
        return this.dialogs.stack.length > 0;
    }

    hasQuestionKeyboard() {
        return !this._app.is_touch_mode;
    }

    hasDialogKeyboard() {
        return !this._app.is_touch_mode && !this.hasQuestion();
    }

    hasSceneKeyboard() {
        return !this._app.is_touch_mode && !this.hasQuestion() && !this.hasDialog();
    }
}
