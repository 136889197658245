import React from "react";
import clsx from "clsx";

import { RectSkeleton } from "components";

import styles from "./user-avatar.module.pcss";

const AVATAR_PRESETS = {
    [1]: styles.preset_001,
    [2]: styles.preset_002,
    [3]: styles.preset_003,
    [4]: styles.preset_004,
    [5]: styles.preset_005,
    [6]: styles.preset_006,
    [7]: styles.preset_007,
    [8]: styles.preset_008,
    [9]: styles.preset_009,
    [10]: styles.preset_010,
    [11]: styles.preset_011,
    [12]: styles.preset_012,
    [13]: styles.preset_013,
    [14]: styles.preset_014,
    [15]: styles.preset_015,
    [16]: styles.preset_016,
    [17]: styles.preset_017,
    [18]: styles.preset_018,
    [19]: styles.preset_019,
    [20]: styles.preset_020,
    [21]: styles.preset_021,
    [22]: styles.preset_022,
    [23]: styles.preset_023,
    [24]: styles.preset_024,
    [25]: styles.preset_025,
};

export const UserAvatar = ({
    preset = null,
    is_assistant = false,
    is_mono = false,
    disabled = false,
    outlined = false,
    onClick = null,
    className = null,
    skeleton = false,
}) => {
    if (skeleton) {
        return <RectSkeleton width="100%" height="100%" roundness={250} />;
    }
    return (
        <div
            className={clsx(
                styles.view,
                is_assistant
                    ? styles.preset_assistant
                    : AVATAR_PRESETS[preset] || styles.preset_001,
                {
                    [styles.mono]: is_mono,
                    [styles.outlined]: outlined,
                    [styles.disabled]: disabled,
                },
                className,
            )}
            onClick={onClick}
        />
    );
};
