import { InviteType, UserRelation, UserStatus } from "models/enums";

import {
    load_invite_status,
    load_invite_type,
    load_user_rank,
    load_user_relation,
    load_user_status,
} from "./types";

function load_friend_object({
    id,
    status,
    rating = 0,
    rank,
    nickname = null,
    avatar_preset_id = 1,
    relation = null,
    modified_at = 0,
}) {
    return {
        id,
        status: load_user_status(status),
        rating,
        nickname,
        avatar_preset_id,
        relation: load_user_relation(relation),
        rank: load_user_rank(rank),
        modified_at,
    };
}

function load_invite_object({ id, type, from_user = {}, to_user = {}, status, modified_at = 0 }) {
    return {
        id,
        type: load_invite_type(type),
        from_user: load_friend_object(from_user),
        to_user: load_friend_object(to_user),
        status: load_invite_status(status),
        modified_at,
    };
}

function load_invite_item_object({ id, type, user = {}, status, modified_at = 0 }) {
    return {
        id,
        type: load_invite_type(type),
        user: load_friend_object(user),
        status: load_invite_status(status),
        modified_at,
    };
}

export class FriendAPI {
    constructor(server) {
        this.server = server;
        this.invite = new FriendInviteAPI(server);
    }

    async list({ relation = null, status = null, nickname = null, offset = 0, limit = 10 } = {}) {
        // await sleep(3000);

        const params = { offset, limit };
        if (nickname !== null) {
            params.nickname = nickname;
        }
        if (relation) {
            params.relation = {
                [UserRelation.FRIEND]: "FRIEND",
                [UserRelation.NONE]: "NONE",
                [UserRelation.BLOCKED]: "BLOCKED",
            }[relation];
        }
        if (status) {
            params.status = {
                [UserStatus.ONLINE]: "ONLINE",
                [UserStatus.OFFLINE]: "OFFLINE",
                [UserStatus.BUSY]: "BUSY",
            }[status];
        }

        const response = await this.server.post("/friend/list", params);
        const { friends = [] } = response.data;

        if (Array.isArray(friends)) {
            return {
                relation: UserRelation.FRIEND,
                status: null,
                items: friends
                    .slice(offset, offset + limit)
                    .map((item) => load_friend_object(item)),
                offset,
                limit,
                count: friends.length,
                modified_at: new Date().getTime() / 1000.0,
            };
        }
        const items = friends.items || [];

        return {
            relation: load_user_status(friends.relation, null),
            status: load_user_status(friends.status, null),
            items: items.map((item) => load_friend_object(item)),
            offset: friends.offset,
            limit: friends.limit,
            count: friends.count,
            modified_at: friends.modified_at,
        };
    }

    async exclude({ user_id }) {
        const response = await this.server.post("/friend/exclude", { user_id });
        const { profile = {} } = response.data;
        return load_friend_object(profile);
    }

    async block({ user_id }) {
        const response = await this.server.post("/friend/block", { user_id });
        const { profile = {} } = response.data;
        return load_friend_object(profile);
    }

    async unblock({ user_id }) {
        const response = await this.server.post("/friend/unblock", { user_id });
        const { profile = {} } = response.data;
        return load_friend_object(profile);
    }

    accept_invite(invite_id) {
        console.warn(
            'Deprecated using "accept_invite" method of FriendsAPI, you should use "accept" method from invite property.',
        );
        return this.invite.accept(invite_id);
    }

    cancel_invite(invite_id) {
        console.warn(
            'Deprecated using "cancel_invite" method of FriendsAPI, you should use "cancel" method from invite property.',
        );
        return this.invite.cancel(invite_id);
    }

    block_invite(invite_id) {
        console.warn(
            'Deprecated using "block_invite" method of FriendsAPI, you should use "block" method from invite property.',
        );
        return this.invite.block(invite_id);
    }

    send_invite(invite_user_id) {
        console.warn(
            'Deprecated using "send_invite" method of FriendsAPI, you should use "send" method from invite property.',
        );
        return this.invite.send(invite_user_id);
    }
}

class FriendInviteAPI {
    constructor(server) {
        this.server = server;
    }

    async list({ type = null, offset = 0, limit = 10 } = {}) {
        // await sleep(3000);

        const params = { offset, limit };
        if (type) {
            params.type = {
                [InviteType.INCOMING]: "incoming",
                [InviteType.OUTCOMING]: "outcoming",
            }[type];
        }

        const response = await this.server.post("/friend/invite/list", params);
        const { invites = [] } = response.data;

        const items = invites.items || [];

        return {
            items: items.map((item) => load_invite_item_object(item)),
            offset: invites.offset,
            limit: invites.limit,
            count: invites.count,
            modified_at: invites.modified_at,
        };
    }

    async viewed(invite_id) {
        const response = await this.server.post("/friend/invite/viewed", { invite_id });
        const { invite = {} } = response.data;
        return load_invite_object(invite);
    }

    async retrieve({ invite_id }) {
        const response = await this.server.post("/friend/invite/retrieve", { invite_id });
        const { invite = {} } = response.data;
        return load_invite_object(invite);
    }

    async accept(invite_id) {
        const response = await this.server.post("/friend/invite/accept", { invite_id });
        const { invite = {} } = response.data;
        return load_invite_object(invite);
    }

    async cancel(invite_id) {
        const response = await this.server.post("/friend/invite/cancel", { invite_id });
        const { invite = {} } = response.data;
        return load_invite_object(invite);
    }

    async block(invite_id) {
        const response = await this.server.post("/friend/invite/block", { invite_id });
        const { invite = {} } = response.data;
        return load_invite_object(invite);
    }

    async send(invite_user_id) {
        const response = await this.server.post("/friend/invite/send", { invite_user_id });
        const { invite = {} } = response.data;
        return load_invite_object(invite);
    }
}
