import { makeAutoObservable, action } from "mobx";

export class Timer {
    seconds;

    constructor(seconds = 0) {
        makeAutoObservable(this, { useEffect: action.bound });
        this.seconds = seconds;
    }

    reset(seconds = 0) {
        this.seconds = seconds;
    }

    increase() {
        this.seconds += 1;
    }

    decrease() {
        this.seconds -= 1;
    }
}
