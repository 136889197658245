import { makeAutoObservable, runInAction } from "mobx";

import { Controller, Group, GraphMover, CarouselGroup, OneOfGroup } from "models/controller/v2";
import { ControlType, DialogType, LoadState, SceneType, UserRelation } from "models/enums";

export default class FriendsScene {
    _app;
    _emitter;
    _friends;
    _isActive;
    controller;
    state;
    friends;

    constructor(app, _, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this.state = LoadState.INIT;
        this._isActive = false;
        this._friends = [];
        this.friends = new CarouselGroup("friends", {
            nodes: () =>
                this._friends.map((friend) => ({
                    type: ControlType.ITEM,
                    name: `friend:id:${friend.id}`,
                    meta: friend,
                    onClick: () => {
                        const dialog = this._app.windows.dialogs.open(DialogType.PROFILE, {
                            user_id: friend.id,
                            buttons: [
                                { name: "game:invite", view: "primary" },
                                { name: "friend:exclude", view: "secondary" },
                            ],
                            onDestroy: () => this._reload(),
                        });
                        dialog.once("game.invite.apply", () => {
                            this.close();
                        });
                    },
                })),
            direction: "column",
            basis: 2,
        });
        this.controller = new Controller(
            new Group("scene", {
                nodes: [
                    {
                        type: ControlType.BUTTON,
                        name: "notification",
                        onClick: () => {
                            this._app.windows.dialogs.open(DialogType.NOTIFICATION);
                        },
                    },
                    {
                        type: ControlType.BUTTON,
                        name: "search",
                        onClick: () => {
                            this._app.windows.scenes.open(SceneType.USERS_SEARCH);
                        },
                    },
                    new OneOfGroup("content", {
                        nodes: [
                            this.friends,
                            {
                                type: ControlType.BUTTON,
                                name: "search:2",
                                onClick: () => {
                                    this._app.windows.scenes.open(SceneType.USERS_SEARCH);
                                },
                            },
                            {
                                type: ControlType.BUTTON,
                                name: "reload",
                                onClick: () => {
                                    this._reload();
                                },
                            },
                        ],
                        switcher: (nodes) => {
                            const { type, button = {} } = this.content;

                            let { name } = this.friends;
                            if (type === "info") {
                                name = button.name;
                            }

                            return nodes.find((item) => item && item.name === name);
                        },
                    }),
                ],
                mover: new GraphMover({
                    notification: { down: "content", left: "search", right: "search" },
                    search: { down: "content", left: "notification", right: "notification" },
                    content: { up: "search" },
                }),
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasSceneKeyboard(),
            },
        );
    }

    get isLoading() {
        return this.state === LoadState.LOADING || !this._isActive;
    }

    get content() {
        if (this.isLoading) {
            return { type: "skeleton" };
        }

        if (this.state === LoadState.FAILURE) {
            return {
                type: "info",
                title: "Что-то пошло не так",
                subTitle: "попробуйте обновить список еще раз",
                button: { name: "reload", text: "Обновить" },
            };
        }

        if (this._friends.length === 0) {
            return {
                type: "info",
                title: "Друзей пока нет",
                button: { name: "search:2", text: "Найти друзей" },
            };
        }

        return { type: "carousel" };
    }

    close() {
        this._emitter.emit("close", this);
    }

    _reload() {
        if (this.state !== LoadState.LOADING) {
            this.state = LoadState.LOADING;
            this._app.server.friend
                .list({ relation: UserRelation.FRIEND, offset: 0, limit: 1000 })
                .then((friends) =>
                    runInAction(() => {
                        this.state = LoadState.SUCCESS;
                        this._friends.replace(friends.items);
                    }),
                )
                .catch(() =>
                    runInAction(() => {
                        this.state = LoadState.FAILURE;
                    }),
                );
        }
    }

    onPreload() {
        this._reload();
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
    }

    // onDeactivate() {}
    // onDestroy() {}
}
