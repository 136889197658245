import avatar_sber from "assets/images/avatars/sber.png";
import avatar_sber_mono from "assets/images/avatars/sber-mono.png";
import avatar_sber_preset_001_mono from "assets/images/avatars/avatar-01-mono-sber.png";
import avatar_sber_preset_002_mono from "assets/images/avatars/avatar-02-mono-sber.png";
import avatar_sber_preset_003_mono from "assets/images/avatars/avatar-03-mono-sber.png";
import avatar_sber_preset_004_mono from "assets/images/avatars/avatar-04-mono-sber.png";
import avatar_sber_preset_005_mono from "assets/images/avatars/avatar-05-mono-sber.png";
import avatar_sber_preset_006_mono from "assets/images/avatars/avatar-06-mono-sber.png";
import avatar_sber_preset_007_mono from "assets/images/avatars/avatar-07-mono-sber.png";
import avatar_sber_preset_008_mono from "assets/images/avatars/avatar-08-mono-sber.png";
import avatar_sber_preset_009_mono from "assets/images/avatars/avatar-09-mono-sber.png";
import avatar_sber_preset_010_mono from "assets/images/avatars/avatar-10-mono-sber.png";
import avatar_sber_preset_011_mono from "assets/images/avatars/avatar-11-mono-sber.png";
import avatar_sber_preset_012_mono from "assets/images/avatars/avatar-12-mono-sber.png";
import avatar_sber_preset_013_mono from "assets/images/avatars/avatar-13-mono-sber.png";
import avatar_sber_preset_014_mono from "assets/images/avatars/avatar-14-mono-sber.png";
import avatar_sber_preset_015_mono from "assets/images/avatars/avatar-15-mono-sber.png";
import avatar_sber_preset_016_mono from "assets/images/avatars/avatar-16-mono-sber.png";
import avatar_sber_preset_017_mono from "assets/images/avatars/avatar-17-mono-sber.png";
import avatar_sber_preset_018_mono from "assets/images/avatars/avatar-18-mono-sber.png";
import avatar_sber_preset_019_mono from "assets/images/avatars/avatar-19-mono-sber.png";
import avatar_sber_preset_020_mono from "assets/images/avatars/avatar-20-mono-sber.png";
import avatar_sber_preset_021_mono from "assets/images/avatars/avatar-21-mono-sber.png";
import avatar_sber_preset_022_mono from "assets/images/avatars/avatar-22-mono-sber.png";
import avatar_sber_preset_023_mono from "assets/images/avatars/avatar-23-mono-sber.png";
import avatar_sber_preset_024_mono from "assets/images/avatars/avatar-24-mono-sber.png";
import avatar_sber_preset_025_mono from "assets/images/avatars/avatar-25-mono-sber.png";

import avatar_athena from "assets/images/avatars/athena.png";
import avatar_athena_mono from "assets/images/avatars/athena-mono.png";
import avatar_athena_preset_001_mono from "assets/images/avatars/avatar-01-mono-athena.png";
import avatar_athena_preset_002_mono from "assets/images/avatars/avatar-02-mono-athena.png";
import avatar_athena_preset_003_mono from "assets/images/avatars/avatar-03-mono-athena.png";
import avatar_athena_preset_004_mono from "assets/images/avatars/avatar-04-mono-athena.png";
import avatar_athena_preset_005_mono from "assets/images/avatars/avatar-05-mono-athena.png";
import avatar_athena_preset_006_mono from "assets/images/avatars/avatar-06-mono-athena.png";
import avatar_athena_preset_007_mono from "assets/images/avatars/avatar-07-mono-athena.png";
import avatar_athena_preset_008_mono from "assets/images/avatars/avatar-08-mono-athena.png";
import avatar_athena_preset_009_mono from "assets/images/avatars/avatar-09-mono-athena.png";
import avatar_athena_preset_010_mono from "assets/images/avatars/avatar-10-mono-athena.png";
import avatar_athena_preset_011_mono from "assets/images/avatars/avatar-11-mono-athena.png";
import avatar_athena_preset_012_mono from "assets/images/avatars/avatar-12-mono-athena.png";
import avatar_athena_preset_013_mono from "assets/images/avatars/avatar-13-mono-athena.png";
import avatar_athena_preset_014_mono from "assets/images/avatars/avatar-14-mono-athena.png";
import avatar_athena_preset_015_mono from "assets/images/avatars/avatar-15-mono-athena.png";
import avatar_athena_preset_016_mono from "assets/images/avatars/avatar-16-mono-athena.png";
import avatar_athena_preset_017_mono from "assets/images/avatars/avatar-17-mono-athena.png";
import avatar_athena_preset_018_mono from "assets/images/avatars/avatar-18-mono-athena.png";
import avatar_athena_preset_019_mono from "assets/images/avatars/avatar-19-mono-athena.png";
import avatar_athena_preset_020_mono from "assets/images/avatars/avatar-20-mono-athena.png";
import avatar_athena_preset_021_mono from "assets/images/avatars/avatar-21-mono-athena.png";
import avatar_athena_preset_022_mono from "assets/images/avatars/avatar-22-mono-athena.png";
import avatar_athena_preset_023_mono from "assets/images/avatars/avatar-23-mono-athena.png";
import avatar_athena_preset_024_mono from "assets/images/avatars/avatar-24-mono-athena.png";
import avatar_athena_preset_025_mono from "assets/images/avatars/avatar-25-mono-athena.png";

import avatar_joy from "assets/images/avatars/joy.png";
import avatar_joy_mono from "assets/images/avatars/joy-mono.png";
import avatar_joy_preset_001_mono from "assets/images/avatars/avatar-01-mono-joy.png";
import avatar_joy_preset_002_mono from "assets/images/avatars/avatar-02-mono-joy.png";
import avatar_joy_preset_003_mono from "assets/images/avatars/avatar-03-mono-joy.png";
import avatar_joy_preset_004_mono from "assets/images/avatars/avatar-04-mono-joy.png";
import avatar_joy_preset_005_mono from "assets/images/avatars/avatar-05-mono-joy.png";
import avatar_joy_preset_006_mono from "assets/images/avatars/avatar-06-mono-joy.png";
import avatar_joy_preset_007_mono from "assets/images/avatars/avatar-07-mono-joy.png";
import avatar_joy_preset_008_mono from "assets/images/avatars/avatar-08-mono-joy.png";
import avatar_joy_preset_009_mono from "assets/images/avatars/avatar-09-mono-joy.png";
import avatar_joy_preset_010_mono from "assets/images/avatars/avatar-10-mono-joy.png";
import avatar_joy_preset_011_mono from "assets/images/avatars/avatar-11-mono-joy.png";
import avatar_joy_preset_012_mono from "assets/images/avatars/avatar-12-mono-joy.png";
import avatar_joy_preset_013_mono from "assets/images/avatars/avatar-13-mono-joy.png";
import avatar_joy_preset_014_mono from "assets/images/avatars/avatar-14-mono-joy.png";
import avatar_joy_preset_015_mono from "assets/images/avatars/avatar-15-mono-joy.png";
import avatar_joy_preset_016_mono from "assets/images/avatars/avatar-16-mono-joy.png";
import avatar_joy_preset_017_mono from "assets/images/avatars/avatar-17-mono-joy.png";
import avatar_joy_preset_018_mono from "assets/images/avatars/avatar-18-mono-joy.png";
import avatar_joy_preset_019_mono from "assets/images/avatars/avatar-19-mono-joy.png";
import avatar_joy_preset_020_mono from "assets/images/avatars/avatar-20-mono-joy.png";
import avatar_joy_preset_021_mono from "assets/images/avatars/avatar-21-mono-joy.png";
import avatar_joy_preset_022_mono from "assets/images/avatars/avatar-22-mono-joy.png";
import avatar_joy_preset_023_mono from "assets/images/avatars/avatar-23-mono-joy.png";
import avatar_joy_preset_024_mono from "assets/images/avatars/avatar-24-mono-joy.png";
import avatar_joy_preset_025_mono from "assets/images/avatars/avatar-25-mono-joy.png";

export const AvatarsSber = {
    "--assistant-avatar": `url("${avatar_sber}")`,
    "--assistant-avatar-mono": `url("${avatar_sber_mono}")`,
    "--avatar-assistant": `url("${avatar_sber}")`,
    "--avatar-assistant-mono": `url("${avatar_sber_mono}")`,
    "--avatar-preset-001-mono": `url("${avatar_sber_preset_001_mono}")`,
    "--avatar-preset-002-mono": `url("${avatar_sber_preset_002_mono}")`,
    "--avatar-preset-003-mono": `url("${avatar_sber_preset_003_mono}")`,
    "--avatar-preset-004-mono": `url("${avatar_sber_preset_004_mono}")`,
    "--avatar-preset-005-mono": `url("${avatar_sber_preset_005_mono}")`,
    "--avatar-preset-006-mono": `url("${avatar_sber_preset_006_mono}")`,
    "--avatar-preset-007-mono": `url("${avatar_sber_preset_007_mono}")`,
    "--avatar-preset-008-mono": `url("${avatar_sber_preset_008_mono}")`,
    "--avatar-preset-009-mono": `url("${avatar_sber_preset_009_mono}")`,
    "--avatar-preset-010-mono": `url("${avatar_sber_preset_010_mono}")`,
    "--avatar-preset-011-mono": `url("${avatar_sber_preset_011_mono}")`,
    "--avatar-preset-012-mono": `url("${avatar_sber_preset_012_mono}")`,
    "--avatar-preset-013-mono": `url("${avatar_sber_preset_013_mono}")`,
    "--avatar-preset-014-mono": `url("${avatar_sber_preset_014_mono}")`,
    "--avatar-preset-015-mono": `url("${avatar_sber_preset_015_mono}")`,
    "--avatar-preset-016-mono": `url("${avatar_sber_preset_016_mono}")`,
    "--avatar-preset-017-mono": `url("${avatar_sber_preset_017_mono}")`,
    "--avatar-preset-018-mono": `url("${avatar_sber_preset_018_mono}")`,
    "--avatar-preset-019-mono": `url("${avatar_sber_preset_019_mono}")`,
    "--avatar-preset-020-mono": `url("${avatar_sber_preset_020_mono}")`,
    "--avatar-preset-021-mono": `url("${avatar_sber_preset_021_mono}")`,
    "--avatar-preset-022-mono": `url("${avatar_sber_preset_022_mono}")`,
    "--avatar-preset-023-mono": `url("${avatar_sber_preset_023_mono}")`,
    "--avatar-preset-024-mono": `url("${avatar_sber_preset_024_mono}")`,
    "--avatar-preset-025-mono": `url("${avatar_sber_preset_025_mono}")`,
};

export const AvatarsAthena = {
    "--assistant-avatar": `url("${avatar_athena}")`,
    "--assistant-avatar-mono": `url("${avatar_athena_mono}")`,
    "--avatar-assistant": `url("${avatar_athena}")`,
    "--avatar-assistant-mono": `url("${avatar_athena_mono}")`,
    "--avatar-preset-001-mono": `url("${avatar_athena_preset_001_mono}")`,
    "--avatar-preset-002-mono": `url("${avatar_athena_preset_002_mono}")`,
    "--avatar-preset-003-mono": `url("${avatar_athena_preset_003_mono}")`,
    "--avatar-preset-004-mono": `url("${avatar_athena_preset_004_mono}")`,
    "--avatar-preset-005-mono": `url("${avatar_athena_preset_005_mono}")`,
    "--avatar-preset-006-mono": `url("${avatar_athena_preset_006_mono}")`,
    "--avatar-preset-007-mono": `url("${avatar_athena_preset_007_mono}")`,
    "--avatar-preset-008-mono": `url("${avatar_athena_preset_008_mono}")`,
    "--avatar-preset-009-mono": `url("${avatar_athena_preset_009_mono}")`,
    "--avatar-preset-010-mono": `url("${avatar_athena_preset_010_mono}")`,
    "--avatar-preset-011-mono": `url("${avatar_athena_preset_011_mono}")`,
    "--avatar-preset-012-mono": `url("${avatar_athena_preset_012_mono}")`,
    "--avatar-preset-013-mono": `url("${avatar_athena_preset_013_mono}")`,
    "--avatar-preset-014-mono": `url("${avatar_athena_preset_014_mono}")`,
    "--avatar-preset-015-mono": `url("${avatar_athena_preset_015_mono}")`,
    "--avatar-preset-016-mono": `url("${avatar_athena_preset_016_mono}")`,
    "--avatar-preset-017-mono": `url("${avatar_athena_preset_017_mono}")`,
    "--avatar-preset-018-mono": `url("${avatar_athena_preset_018_mono}")`,
    "--avatar-preset-019-mono": `url("${avatar_athena_preset_019_mono}")`,
    "--avatar-preset-020-mono": `url("${avatar_athena_preset_020_mono}")`,
    "--avatar-preset-021-mono": `url("${avatar_athena_preset_021_mono}")`,
    "--avatar-preset-022-mono": `url("${avatar_athena_preset_022_mono}")`,
    "--avatar-preset-023-mono": `url("${avatar_athena_preset_023_mono}")`,
    "--avatar-preset-024-mono": `url("${avatar_athena_preset_024_mono}")`,
    "--avatar-preset-025-mono": `url("${avatar_athena_preset_025_mono}")`,
};

export const AvatarsJoy = {
    "--assistant-avatar": `url("${avatar_joy}")`,
    "--assistant-avatar-mono": `url("${avatar_joy_mono}")`,
    "--avatar-assistant": `url("${avatar_joy}")`,
    "--avatar-assistant-mono": `url("${avatar_joy_mono}")`,
    "--avatar-preset-001-mono": `url("${avatar_joy_preset_001_mono}")`,
    "--avatar-preset-002-mono": `url("${avatar_joy_preset_002_mono}")`,
    "--avatar-preset-003-mono": `url("${avatar_joy_preset_003_mono}")`,
    "--avatar-preset-004-mono": `url("${avatar_joy_preset_004_mono}")`,
    "--avatar-preset-005-mono": `url("${avatar_joy_preset_005_mono}")`,
    "--avatar-preset-006-mono": `url("${avatar_joy_preset_006_mono}")`,
    "--avatar-preset-007-mono": `url("${avatar_joy_preset_007_mono}")`,
    "--avatar-preset-008-mono": `url("${avatar_joy_preset_008_mono}")`,
    "--avatar-preset-009-mono": `url("${avatar_joy_preset_009_mono}")`,
    "--avatar-preset-010-mono": `url("${avatar_joy_preset_010_mono}")`,
    "--avatar-preset-011-mono": `url("${avatar_joy_preset_011_mono}")`,
    "--avatar-preset-012-mono": `url("${avatar_joy_preset_012_mono}")`,
    "--avatar-preset-013-mono": `url("${avatar_joy_preset_013_mono}")`,
    "--avatar-preset-014-mono": `url("${avatar_joy_preset_014_mono}")`,
    "--avatar-preset-015-mono": `url("${avatar_joy_preset_015_mono}")`,
    "--avatar-preset-016-mono": `url("${avatar_joy_preset_016_mono}")`,
    "--avatar-preset-017-mono": `url("${avatar_joy_preset_017_mono}")`,
    "--avatar-preset-018-mono": `url("${avatar_joy_preset_018_mono}")`,
    "--avatar-preset-019-mono": `url("${avatar_joy_preset_019_mono}")`,
    "--avatar-preset-020-mono": `url("${avatar_joy_preset_020_mono}")`,
    "--avatar-preset-021-mono": `url("${avatar_joy_preset_021_mono}")`,
    "--avatar-preset-022-mono": `url("${avatar_joy_preset_022_mono}")`,
    "--avatar-preset-023-mono": `url("${avatar_joy_preset_023_mono}")`,
    "--avatar-preset-024-mono": `url("${avatar_joy_preset_024_mono}")`,
    "--avatar-preset-025-mono": `url("${avatar_joy_preset_025_mono}")`,
};
