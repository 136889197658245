import { ControlType, SceneType } from "models/enums";

const ELEMENTS_WITHOUT_INVITE = {
    close_game: {
        left: null,
        right: null,
        up: "close_dialog",
        down: "close_dialog",
        type: ControlType.BUTTON,
    },
    close_dialog: {
        left: null,
        right: null,
        up: "close_game",
        down: "close_game",
        type: ControlType.BUTTON,
    },
    back: {
        left: "close_game",
        right: "close_dialog",
        up: "close_game",
        down: "close_game",
        type: ControlType.BUTTON,
    },
    send_invite: { disabled: true },
};

const ELEMENTS_WITH_INVITE = {
    close_game: {
        left: "close_dialog",
        right: "close_dialog",
        up: "send_invite",
        down: "send_invite",
        type: ControlType.BUTTON,
    },
    close_dialog: {
        left: "close_game",
        right: "close_game",
        up: "send_invite",
        down: "send_invite",
        type: ControlType.BUTTON,
    },
    send_invite: {
        left: "close_game",
        right: "close_dialog",
        up: "close_game",
        down: "close_game",
        type: ControlType.BUTTON,
    },
    back: {
        left: "close_game",
        right: "close_dialog",
        up: "close_game",
        down: "close_game",
        type: ControlType.BUTTON,
    },
};

export default (scene) => ({
    ok: ({ name, type, meta }) => {
        if (type === ControlType.BUTTON) {
            switch (name) {
                case "close_game":
                    scene._game.close_game();
                    scene._scenes.change(SceneType.HOME);
                    break;

                case "back":
                case "close_dialog":
                    break;

                case "send_invite":
                    scene.send_friend_invite();
                    return;

                default:
                    return;
            }

            scene._close_dialog();
        }
    },
    back: () => {
        scene._game.board.resume();
        scene._close_dialog(false);
    },
    name: "close_game",
    find: (name) => {
        const is_send_invite = scene.can_send_friend_invite;
        if (is_send_invite) {
            return ELEMENTS_WITH_INVITE[name];
        }
        return ELEMENTS_WITHOUT_INVITE[name];
    },
    has_touch_mode: () => scene._app.is_touch_mode,
});
