import { makeAutoObservable } from "mobx";

import { Controller } from "models/controller";
import { ControlType, DialogType, SceneType, TutorialType } from "models/enums";

export class ChoiceBotScene {
    _scenes;
    _game;
    _app;
    _first_activate;
    _isActive;
    controller;
    tutorial;

    constructor(scenes, app, game) {
        makeAutoObservable(this, { _scenes: false, _game: false, _app: false });
        this._scenes = scenes;
        this._game = game;
        this._app = app;
        this._first_activate = true;
        this._isActive = false;
        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                if (type === ControlType.BUTTON) {
                    if (
                        ["easy", "medium", "hard"].indexOf(name) !== -1 &&
                        this.tutorial === TutorialType.CHOICE_BOT
                    ) {
                        this._app.assistant.tutorial_apply(TutorialType.CHOICE_BOT);
                        this.tutorial = null;
                    }

                    switch (name) {
                        case "easy":
                            this._game.options.bot.set_level(10);
                            this._scenes.change(SceneType.CHOICE_COLOR);
                            break;

                        case "medium":
                            this._game.options.bot.set_level(20);
                            this._scenes.change(SceneType.CHOICE_COLOR);
                            break;

                        case "hard":
                            this._game.options.bot.set_level(30);
                            this._scenes.change(SceneType.CHOICE_COLOR);
                            break;

                        case "back":
                            this.back();
                            break;

                        case "notification":
                            this._app.windows.open_dialog(DialogType.NOTIFICATION);
                            break;
                    }
                }
            },
            back: () => this.back(),
            name: "easy",
            elements: {
                back: { type: ControlType.BUTTON },
                notification: {
                    up: ["hard"],
                    down: ["hard"],
                    left: ["hard"],
                    right: ["easy"],
                    type: ControlType.BUTTON,
                },
                easy: {
                    up: ["notification"],
                    down: ["notification"],
                    left: ["hard"],
                    right: ["medium"],
                    type: ControlType.BUTTON,
                },
                medium: {
                    up: ["notification"],
                    down: ["notification"],
                    left: ["easy"],
                    right: ["hard"],
                    type: ControlType.BUTTON,
                },
                hard: {
                    up: ["notification"],
                    down: ["notification"],
                    left: ["medium"],
                    right: ["easy"],
                    type: ControlType.BUTTON,
                },
            },
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
        });
    }

    get isLoading() {
        return !this._isActive;
    }

    back() {
        this._scenes.change(SceneType.CHOICE_MODE);
    }

    get assistant_state() {
        return {
            is_auto_help: this._app.user.has_scene_auto_help(SceneType.CHOICE_BOT),
        };
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
        this.tutorial = null;
        if (!this._app.user.tutorial.choice_bot) {
            this.tutorial = TutorialType.CHOICE_BOT;
            this._app.assistant.transition("/tutorial/choice_bot");
        } else {
            this._app.assistant.fire_event("event_activate_scene", {
                is_first_activate: this._first_activate,
            });
            if (this._first_activate) {
                this._app.user.increase_scene_auto_help(SceneType.CHOICE_BOT);
            }
        }
        this._first_activate = false;
    }
}
