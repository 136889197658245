import { observer } from "mobx-react-lite";
import clsx from "clsx";
import styles from "scenes/top-of-ranks/scene.module.pcss";
import { Footnote1 } from "components";
import React from "react";

export default observer(({ name, number, user, controller = null, tutorial = null }) => {
    const focused =
        (controller && name === controller.focusedName) ||
        (tutorial && tutorial.step === "position" && !!user.is_self);
    return (
        <div
            className={clsx(styles.position, {
                [styles.button]: !tutorial,
                [styles.outlined]: focused,
                [styles.white]: tutorial,
            })}
            onClick={() => {
                if (controller) {
                    controller.click(name);
                }
            }}
            aria-hidden="true"
        >
            <Footnote1 className={clsx(styles.number)} style={{ textAlign: "end" }}>
                {number}
            </Footnote1>
            <Footnote1 className={clsx(styles.nickname)}>
                {user.nickname || `ID ${user.id}`}
            </Footnote1>
            <Footnote1 className={clsx(styles.rating)}>{user.rating}</Footnote1>
        </div>
    );
});
