import { makeAutoObservable } from "mobx";

import { Controller, GraphMover, Group, SimpleElement } from "models/controller/v2";
import { ControlType } from "models/enums";

export default class GameSurrenderDialog {
    _app;
    _emitter;
    _board;
    _isActive;
    _isSurrending;
    controller;

    constructor(app, params = {}, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this._board = params.board;
        this._isActive = true;
        this._isSurrending = false;
        this.controller = new Controller(
            new Group("root", {
                nodes: [
                    new SimpleElement("surrender_cancel", {
                        type: ControlType.BUTTON,
                        disabled: () => this.isLoading,
                        onClick: (self) => {
                            if (!self.disabled) {
                                this.cancel();
                            }
                        },
                    }),
                    new SimpleElement("surrender_ok", {
                        type: ControlType.BUTTON,
                        disabled: () => this.isLoading,
                        onClick: (self) => {
                            if (!self.disabled) {
                                this._isSurrending = true;
                                this._board.surrender().then(() => {
                                    this.close();
                                });
                            }
                        },
                    }),
                ],
                mover: new GraphMover({
                    surrender_ok: { up: "surrender_cancel", down: "surrender_cancel" },
                    surrender_cancel: { up: "surrender_ok", down: "surrender_ok" },
                }),
            }),
            {
                onCancel: () => {
                    if (!this.isLoading) {
                        this.cancel();
                    }
                },
                hasKeyboard: () => this._app.windows.hasQuestionKeyboard(),
            },
        );
    }

    // eslint-disable-next-line class-methods-use-this
    get assistantPath() {
        return "/scene/game/surrender";
    }

    get isLoading() {
        return !this._isActive || this._isSurrending;
    }

    cancel() {
        return this.close();
    }

    close() {
        this._emitter.emit("close", this);
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
    }
}
