import { action, makeAutoObservable, runInAction } from "mobx";

import { Controller, Group, SimpleElement } from "models/controller/v2";
import { ControlType, SceneType, GameType, GameInviteState } from "models/enums";
import { MatchRequest } from "models/match-request";

export const WaitingPlayerState = Object.freeze({
    INIT: "init",
    WAITING: "waiting",
    CANCELING: "canceling",
    CANCELED: "canceled",
    OPENING_GAME: "opening-game",
    FAILED: "failed",
});

export class WaitingPlayerDialog {
    _app;
    _emitter;
    _state;
    _update_interval;
    request;
    params;
    controller;

    constructor(app, params = {}, { emitter } = {}) {
        makeAutoObservable(this, { _app: false, _updateFailed: action });
        this._app = app;
        this._emitter = emitter;
        this._state = WaitingPlayerState.INIT;
        this.request = null;
        this.params = params;
        this.controller = new Controller(
            new Group("root", {
                nodes: [
                    { type: ControlType.NONE, name: "none" },
                    new SimpleElement("back", {
                        type: ControlType.BUTTON,
                        onClick: (self) => {
                            if (!self.disabled) {
                                this.back();
                            }
                        },
                        disabled: () => {
                            const statusList = [
                                WaitingPlayerState.CANCELING,
                                WaitingPlayerState.OPENING_GAME,
                            ];
                            return statusList.indexOf(this._state) !== -1;
                        },
                    }),
                ],
                init: () => undefined,
            }),
            {
                onCancel: () => this.back(),
                hasKeyboard: () => this._app.windows.hasDialogKeyboard(),
            },
        );

        this.__updateFailed = this._updateFailed.bind(this);
        this.onCreate();
    }

    get _server() {
        return this._app.server;
    }

    get state() {
        if (this.request && this.request.state === GameInviteState.FAILED) {
            return WaitingPlayerState.FAILED;
        }
        return this._state;
    }

    get is_personally() {
        return (
            this.request &&
            this.request.owner_user_id !== null &&
            this.request.enemy_user_id !== null
        );
    }

    _update() {
        console.log("WaitingPlayerDialog", "update");
        if (this.request && this._state === WaitingPlayerState.WAITING) {
            this.request.reload().then(() =>
                runInAction(() => {
                    // eslint-disable-next-line default-case
                    switch (this.request.state) {
                        case GameInviteState.APPROVED:
                            this._open_game(this.request.game_id);
                            break;

                        case GameInviteState.CANCELED:
                            this._state = WaitingPlayerState.CANCELED;
                            setTimeout(() => this.close(), 5000);
                            break;
                    }
                }),
            );
        }
    }

    _updateFailed(error) {
        console.error(error);
        this._state = WaitingPlayerState.FAILED;
    }

    _open_game(gameId) {
        if (this._state !== WaitingPlayerState.OPENING_GAME) {
            this._state = WaitingPlayerState.OPENING_GAME;
            this._app.game.open_game({ id: gameId }).then(() =>
                runInAction(() => {
                    this.request = null;
                    this._app.windows.change_scene(SceneType.GAME, { game_id: gameId });
                    this.close();
                }),
            );
        }
    }

    back() {
        const statusList = [WaitingPlayerState.CANCELING, WaitingPlayerState.OPENING_GAME];
        if (statusList.indexOf(this._state) !== -1) {
            return;
        }

        if (this.request === null) {
            this.request = null;
            this._state = WaitingPlayerState.CANCELED;
            this.close();
        } else {
            this._cancel_match_request({ onSuccess: () => this.close() });
        }
    }

    close() {
        this.onDestroy();
        this._emitter.emit("close", this);
    }

    _cancel_match_request({ onSuccess = null } = {}) {
        if (this.request && this._state !== WaitingPlayerState.CANCELING) {
            this._state = WaitingPlayerState.CANCELING;
            this.request
                .cancel()
                .then(() => {
                    switch (this.request.state) {
                        case GameInviteState.FAILED:
                        case GameInviteState.CANCELED:
                            runInAction(() => {
                                this.request = null;
                                this._state = WaitingPlayerState.CANCELED;
                                if (onSuccess) {
                                    onSuccess();
                                }
                            });
                            break;

                        case GameInviteState.APPROVED:
                            this._open_game(this.request.game_id);
                            break;

                        default:
                            runInAction(() => {
                                this._state = WaitingPlayerState.WAITING;
                            });
                    }
                })
                .catch(this._updateFailed);
        }
    }

    onCreate() {
        this._update_interval = setInterval(() => this._update(), 1000);

        const { requestId = null } = this.params || {};
        if (requestId !== null) {
            this._server.pvp
                .accept_request(requestId)
                .then((response) =>
                    runInAction(() => {
                        this._state = WaitingPlayerState.WAITING;
                        this.request = new MatchRequest(this._server, response);
                    }),
                )
                .catch(this._updateFailed);
        } else {
            const {
                gameType = GameType.WITH_PLAYER,
                enemyUserId = null,
                color = null,
            } = this.params || {};

            this._server.pvp
                .create_request({
                    color,
                    game_type: gameType,
                    enemy_user_id: enemyUserId,
                })
                .then((response) => {
                    runInAction(() => {
                        this._state = WaitingPlayerState.WAITING;
                        this.request = new MatchRequest(this._server, response);
                    });
                })
                .catch(this._updateFailed);
        }
    }

    onDestroy() {
        if (this._update_interval) {
            clearInterval(this._update_interval);
            this._update_interval = null;
        }
        this._cancel_match_request();
    }
}
