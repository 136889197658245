import { ControlType } from "models/enums";
import { makeAutoObservable } from "mobx";

export default class SimpleElement {
    type;
    name;
    meta;

    _disabled;
    _onClick;
    _onCancel;

    constructor(
        name,
        { type = ControlType.NONE, meta = null, onClick = null, onCancel = null, disabled = false },
    ) {
        makeAutoObservable(this);
        this.type = type;
        this.name = name;
        this.meta = meta;

        this._disabled = disabled;
        this._onClick = onClick;
        this._onCancel = onCancel;
    }

    get disabled() {
        if (this._disabled instanceof Function) {
            return this._disabled();
        }
        return this._disabled;
    }

    get onClick() {
        if (this._onClick) {
            return () => this._onClick(this);
        }
        return undefined;
    }

    get onCancel() {
        if (this._onCancel) {
            return () => this._onCancel(this);
        }
        return undefined;
    }
}
