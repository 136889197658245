export class NanoEvents {
    constructor() {
        this.events = {};
    }

    emit(event, ...args) {
        (this.events[event] || []).forEach((listener) => listener(...args));
    }

    on(event, listener) {
        (this.events[event] = this.events[event] || []).push(listener);
        return () => {
            this.events[event] = (this.events[event] || []).filter((item) => item !== listener);
        };
    }

    once(event, listener) {
        const off = this.on(event, (...args) => {
            listener(...args);
            off();
        });
        return off;
    }

    clear() {
        this.events = {};
    }
}
