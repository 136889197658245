import React from "react";
import { observer } from "mobx-react-lite";

import { Header, Container, Row, Col, TextBoxTitle, TextBoxSubTitle } from "components";

export default observer(({ scene, skeleton = false }) => {
    const { character, device, controller } = scene;
    return (
        <Container>
            <Header
                title="Техническая информация"
                sub_title={scene.subTitle}
                controller={controller}
                skeleton={skeleton}
            />
            <Row>
                <Col>
                    <TextBoxTitle>Ассистент</TextBoxTitle>{" "}
                    <TextBoxSubTitle>
                        {`${character.id} / ${character.name} / ${character.gender} / ${character.appeal}`}
                    </TextBoxSubTitle>
                    <TextBoxTitle>Поверхность</TextBoxTitle>{" "}
                    <TextBoxSubTitle>
                        {`${device.surface} / ${device.surfaceVersion}`}
                    </TextBoxSubTitle>
                </Col>
            </Row>
        </Container>
    );
});
