export const PieceType = Object.freeze({
    NONE: "none",
    KING: "king",
    QUEEN: "queen",
    BISHOP: "bishop",
    KNIGHT: "knight",
    ROOK: "rook",
    PAWN: "pawn",
    // NONE: 0,
    // KING: 1,
    // QUEEN: 2,
    // BISHOP: 3,
    // KNIGHT: 4,
    // ROOK: 5,
    // PAWN: 6,
});

export const PieceColor = Object.freeze({
    BLACK: "black",
    WHITE: "white",
    // BLACK: 0,
    // WHITE: 1,
});

export const PieceWeight = Object.freeze({
    QUEEN: 10,
    BISHOP: 5,
    KNIGHT: 3,
    ROOK: 3,
    PAWN: 1,
});

export const MoveFlag = Object.freeze({
    NO_CAPTURE: 0x1, // 0 : 'n' - без захвата
    PAWN_TWO_SQUARES: 0x2, // 1 : 'b' - пешка ходин на две клетки
    EN_PASSANT_CAPTURE: 0x4, // 2 : 'e' - an en passant capture
    STANDARD_CAPTURE: 0x8, // 3 : 'c' - стандартный хахват
    PROMOTION: 0x10, // 4 : 'p' - продвижение / улучшение
    KING_SIDE_CASTLING: 0x20, // 5 : 'k' - королевская(короткая) рокировка
    QUEEN_SIDE_CASTLING: 0x40, // 6 : 'q' - ферзевая(длинная) рокировка
});
