import { makeAutoObservable, runInAction } from "mobx";

import { Controller } from "models/controller";
import { DeviceType, LoadState, SceneType, UserRank } from "models/enums";

export class ProfileSettingsDialog {
    _app;
    _emitter;
    _state;
    controller;
    _nickname = null;
    user_id = 0;
    avatar_preset_id = 1;
    rating = 0;
    rank = UserRank.NEWBIE;
    friend_count = 0;

    constructor(app, _, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._state = LoadState.INIT;
        this._emitter = emitter;
        this.controller = new Controller({
            ok: ({ name }) => {
                switch (name) {
                    case "back":
                        break;

                    case "edit":
                        this._app.windows.open_scene(SceneType.PROFILE_SETTINGS_EDITOR, {
                            nickname: this._nickname,
                            avatar_preset_id: this.avatar_preset_id,
                        });
                        break;

                    case "friends":
                        this._app.windows.scenes.open(SceneType.FRIENDS);
                        break;

                    default:
                        return;
                }
                this.close();
            },
            back: () => this.close(),
            name: "friends",
            elements: {
                edit: { up: ["friends"], down: ["friends"] },
                friends: { up: ["edit"], down: ["edit"] },
            },
            has_touch_mode: () => !this._app.windows.hasDialogKeyboard(),
        });
    }

    get is_portal() {
        return this._app.device_type === DeviceType.PORTAL;
    }

    get is_loading() {
        // return true;
        return this._state === LoadState.LOADING || this._state === LoadState.INIT;
    }

    get is_error() {
        return this._state === LoadState.FAILURE;
    }

    get nickname() {
        return this._nickname || `ID ${this.user_id}`;
    }

    close() {
        this._emitter.emit("close", this);
    }

    onPreload() {
        this._state = LoadState.LOADING;
        this._app.server.profile
            .retrieve()
            .then((profile) => {
                this._update_profile(profile);
                runInAction(() => (this._state = LoadState.SUCCESS));
            })
            .catch((error) => {
                runInAction(() => (this._state = LoadState.FAILURE));
                console.error(error);
            });
    }

    _update_profile({
        id,
        nickname = null,
        avatar_preset_id = 1,
        rating = 0,
        rank = UserRank.NEWBIE,
        friend_count = 0,
    }) {
        this.user_id = id;
        this._nickname = nickname;
        this.avatar_preset_id = avatar_preset_id;
        this.rating = rating || 0;
        this.rank = rank || UserRank.NEWBIE;
        this.friend_count = friend_count || 0;
    }
}
