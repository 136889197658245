import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";
import styled, { css } from "styled-components";

import {
    Header,
    Container,
    Row,
    Col as ColBase,
    ActionButton,
    Icon,
    NotificationButton,
    Button,
    gridSizes,
    mediaQuery,
} from "components";
import { IconSurrender } from "components/icons";

import { DeviceType } from "models/enums";
import { FogLightsType } from "models/scenes/game";
import { TUTORIAL_CONTROL_SKIP, TUTORIAL_CONTROL_NEXT } from "models/tutorial.buttons";

import DialogFinished from "./dialog-finished";

import { GameBoard } from "./game-board";
import { LeftPanel } from "./left-panel";
import { RightPanel } from "./right-panel";
import { DialogExit } from "./dialog-exit";
import { DialogPause } from "./dialog-pause";

import styles from "./scene.module.pcss";

const hides = {
    S: "hideS",
    M: "hideM",
    L: "hideL",
    XL: "hideXL",
};

const Col = styled(ColBase)`
    ${({ theme, hide = false, ...props }) =>
        gridSizes.map((breakpoint) => {
            const bpHide = hides[breakpoint];
            const colHide = props[bpHide] || hide;
            return mediaQuery(
                breakpoint,
                theme.deviceScale,
            )(
                css`
                    ${colHide ? "display: none;" : ""}
                `,
            );
        })}
`;

const HeaderCenterControls = observer(({ children, fogLights = false }) => (
    <div className={clsx(styles.center, { [styles.fog_lights]: fogLights })}>{children}</div>
));

export const GameScene = observer(({ scene, skeleton = false, notification = null, assistant }) => {
    return (
        <>
            <Container
                style={{
                    width: "100vw",
                    height: "calc(100vh - var(--assistant-height, 0))",
                    display: "flex",
                }}
            >
                <Header
                    title={scene.game_id ? "Партия" : null}
                    sub_title={scene.game_id ? `№ ${scene.game_id}` : null}
                    controller={scene.controller}
                    skeleton={skeleton}
                >
                    <HeaderCenterControls
                        fogLights={scene.fog_lights === FogLightsType.CONTROL_BUTTON}
                    >
                        <ActionButton
                            name="pause"
                            controller={scene.controller}
                            size="l"
                            pin="square-square"
                            skeleton={skeleton}
                        >
                            <Icon icon="pause" size="s" color="inherit" />
                        </ActionButton>

                        <ActionButton
                            name="surrender"
                            controller={scene.controller}
                            size="l"
                            pin="square-square"
                            skeleton={skeleton}
                        >
                            <IconSurrender size="s" color="inherit" />
                        </ActionButton>
                    </HeaderCenterControls>

                    {scene.tutorial !== null ? (
                        <div
                            className={clsx(styles.tutorial_controls, styles.fog_lights, {
                                [styles.visible]: true,
                            })}
                        >
                            <Button
                                name={TUTORIAL_CONTROL_NEXT}
                                controller={scene.controller}
                                view="secondary"
                                size="xs"
                            >
                                <span>Далее</span>
                            </Button>

                            <Button
                                name={TUTORIAL_CONTROL_SKIP}
                                controller={scene.controller}
                                view="secondary"
                                size="xs"
                            >
                                <span>Пропустить</span>
                            </Button>
                        </div>
                    ) : (
                        <NotificationButton
                            name="notification"
                            notification={notification}
                            controller={scene.controller}
                            skeleton={skeleton}
                        />
                    )}
                </Header>

                <Row style={{ flexGrow: 1, minHeight: 0, height: "100%", paddingTop: "2.25rem" }}>
                    <Col
                        sizeXL={3}
                        offsetXL={0}
                        sizeL={3}
                        offsetL={0}
                        sizeM={2.125}
                        offsetM={-0.375}
                        sizeS={0}
                        offsetS={0}
                        hideS
                        style={{ height: "100%", display: "flex", justifyContent: "center" }}
                    >
                        <LeftPanel
                            board={scene.game.board}
                            fog_lights={scene.fog_lights}
                            skeleton={skeleton}
                            assistant={assistant}
                            use_dialog_mode={
                                (scene.dialog && scene.dialog !== "exit") || scene.game.is_paused
                            }
                        />
                    </Col>

                    <Col
                        sizeXL={5}
                        offsetXL={0.5}
                        sizeL={5}
                        offsetL={0.5}
                        sizeM={2.5}
                        offsetM={0}
                        sizeS={4}
                        offsetS={0}
                    >
                        <div
                            className={clsx({
                                [styles.fog_lights]: scene.fog_lights === FogLightsType.BOARD,
                            })}
                        >
                            <GameBoard
                                board={scene.game.board}
                                controller={scene.controller}
                                skeleton={skeleton}
                                isPortal={scene._app.device_type === DeviceType.PORTAL}
                            />
                        </div>
                    </Col>

                    <Col
                        sizeXL={3}
                        offsetXL={0.5}
                        sizeL={3}
                        offsetL={0}
                        sizeM={1.75}
                        offsetM={0}
                        sizeS={0}
                        offsetS={0}
                        hideS
                        style={{ minHeight: 0, height: "100%" }}
                    >
                        <RightPanel
                            board={scene.game.board}
                            history={scene.history}
                            controller={scene.controller}
                            fog_lights={scene.fog_lights}
                            skeleton={skeleton}
                            pause_count={scene._game.pause_status.count}
                            isPortal={scene._app.device_type === DeviceType.PORTAL}
                            scene={scene}
                        />
                    </Col>
                </Row>

                <div className={clsx({ [styles.fog]: scene.is_fog })} />
            </Container>

            <DialogFinished
                board={scene.game.board}
                controller={scene.controller}
                disabled={scene.dialog !== "finished"}
                assistant={assistant}
            />

            <DialogExit
                board={scene.game.board}
                controller={scene.controller}
                disabled={scene.dialog !== "exit"}
                assistant={assistant}
            />

            <DialogPause
                board={scene.game.board}
                controller={scene.controller}
                disabled={!scene.game.is_paused}
                assistant={assistant}
                pause={scene.game.pause_status}
            />
        </>
    );
});
