import { makeAutoObservable } from "mobx";

import { uuid4 } from "lib/helpers";

import { ControlType } from "models/enums";

const DIRECTION_TO_VECTOR = {
    left: { x: -1, y: 0 },
    right: { x: 1, y: 0 },
    up: { x: 0, y: -1 },
    down: { x: 0, y: +1 },
};

export default class CarouselGroup {
    _name;
    _nodes;
    _currentIndex;
    _direction;
    _basis;
    _reverseRow;
    _reverseColumn;

    _onChangeFocus;

    constructor(
        name,
        {
            x = 0,
            y = 0,
            nodes = [],
            basis = 1,
            direction = "row",
            reverseColumn = false,
            reverseRow = false,
            onChangeFocus = null,
        } = {},
    ) {
        makeAutoObservable(this);
        this._name = name === null ? uuid4() : name;
        this._basis = Math.max(1, Math.trunc(basis));
        this._nodes = nodes;
        this._currentIndex = this._positionToIndex({ x, y });
        this._direction = direction;
        this._reverseRow = reverseRow;
        this._reverseColumn = reverseColumn;

        if (this._direction !== "row" && this._direction !== "column") {
            throw new Error(`Unknown grid direction: ${this._direction}`);
        }

        this._onChangeFocus = onChangeFocus;
    }

    // eslint-disable-next-line class-methods-use-this
    get type() {
        return ControlType.GROUP;
    }

    get name() {
        return this._name;
    }

    get focusedElement() {
        if (this.children.length > 0) {
            return this.children[this.index];
        }
        return null;
    }

    get children() {
        if (this._nodes instanceof Function) {
            return this._nodes();
        }
        return this._nodes || [];
    }

    click(name) {
        const element = this.children.find((item) => name && item && item.name === name);
        if (element && element.onClick instanceof Function) {
            element.onClick();
            return true;
        }
        return false;
    }

    focus(name) {
        return this._changeFocus(
            this.children.findIndex((item) => name && item && item.name === name),
        );
    }

    find(predicate) {
        return this.children.find(predicate);
    }

    move(direction) {
        const { x: vectorX = 0, y: vectorY = 0 } = DIRECTION_TO_VECTOR[direction] || {};
        if (vectorX === 0 && vectorY === 0) {
            // Сообщаем, что корректно обработали ситуацию с перемещением
            // но при этом нам ни куда не нужно двигаться
            return true;
        }

        const { x, y } = this._positionFromIndex(this.index);
        const newX = x + vectorX * (this._reverseRow ? -1 : 1);
        const newY = y + vectorY * (this._reverseColumn ? -1 : 1);
        if (newX < 0 || newX >= this.width || newY < 0 || newY >= this.height) {
            return false;
        }

        return this._changeFocus(this._positionToIndex({ x: newX, y: newY }));
    }

    // eslint-disable-next-line class-methods-use-this
    cancel() {
        return false;
    }

    // ====================================================
    get basis() {
        return this._basis;
    }

    get direction() {
        return this._direction;
    }

    get index() {
        return Math.max(0, Math.min(this._currentIndex, this.children.length - 1));
    }

    get alternativeIndex() {
        const { x, y } = this._positionFromIndex(this.index);
        if (this._direction === "row") {
            return this._reverseColumn
                ? Math.max(this.height - y - 1, 0)
                : Math.min(y, this.height);
        }
        return this._reverseRow ? Math.max(this.width - x - 1, 0) : Math.min(x, this.width);
    }

    get width() {
        return this._direction === "row"
            ? this._basis
            : Math.ceil(this.children.length / this._basis);
    }

    get height() {
        return this._direction === "row"
            ? Math.ceil(this.children.length / this._basis)
            : this._basis;
    }

    get grid() {
        const lines = [];
        this.children.forEach((item, index) => {
            const num = Math.trunc(index / this._basis);
            if (num >= lines.length) {
                if (this._reverseRow && lines.length > 0) {
                    lines[lines.length - 1].reverse();
                }
                lines.push([]);
            }
            lines[lines.length - 1].push(item);
        });
        if (this._reverseRow && lines.length > 0) {
            lines[lines.length - 1].reverse();
        }
        if (this._reverseColumn) {
            return lines.reverse();
        }
        return lines;
    }

    setAlternativeIndex(value) {
        const { x, y } = this._positionFromIndex(this.index);
        const newIndex = Math.ceil(value);
        if (this._direction === "row") {
            this._changeFocus(
                this._positionToIndex({
                    x,
                    y: this._reverseColumn ? this.height - newIndex - 1 : newIndex,
                }),
            );
        } else if (this._direction === "column") {
            this._changeFocus(
                this._positionToIndex({
                    x: this._reverseRow ? this.width - newIndex - 1 : newIndex,
                    y,
                }),
            );
        }
    }

    _changeFocus(index) {
        const newIndex = Math.max(Math.min(index, this.children.length - 1), 0);
        const next = this.children[newIndex];

        if (next && this._currentIndex !== index) {
            const prev =
                this._currentIndex > 0 && this._currentIndex < this.children.length
                    ? this.children[this._currentIndex]
                    : undefined;
            this._currentIndex = index;
            if (this._onChangeFocus instanceof Function) {
                this._onChangeFocus(next, prev);
            }
            return true;
        }

        return false;
    }

    _positionToIndex({ x = null, y = null }) {
        return this._direction === "row" ? y * this._basis + x : x * this._basis + y;
    }

    _positionFromIndex(index) {
        return {
            x: this._direction === "row" ? index % this._basis : Math.trunc(index / this._basis),
            y: this._direction === "row" ? Math.trunc(index / this._basis) : index % this._basis,
        };
    }
}
