import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    ActionButton,
    Header,
    Icon,
    Container,
    Carousel,
    Row,
    CarouselCol,
    CarouselGridWrapper,
    Button,
    Col,
    Headline1,
    Footnote1,
    RectSkeleton,
} from "components";

import styles from "./scene.module.pcss";
import UserCard from "./user-card";

const Content = observer(({ scene }) => {
    const { type } = scene.content;
    if (type === "info") {
        const { title, subTitle = null, button = null } = scene.content;
        return (
            <Row className={clsx(styles.content, styles.middle)}>
                <Col>
                    <div style={{ marginBottom: "2rem" }}>
                        <Headline1>{title}</Headline1>
                        {subTitle && (
                            <Footnote1
                                style={{
                                    marginTop: "0.25rem",
                                    color: "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))",
                                }}
                            >
                                {subTitle}
                            </Footnote1>
                        )}
                    </div>
                    {button && (
                        <Button
                            name={button.name}
                            view="primary"
                            controller={scene.controller}
                            text={button.text}
                        />
                    )}
                </Col>
            </Row>
        );
    }

    if (scene.isLoading || type === "skeleton") {
        return (
            <Row className={clsx(styles.content, styles.middle)}>
                <Col sizeXL={3} sizeL={3} sizeM={2} sizeS={3}>
                    <div className={clsx(styles.column)}>
                        <RectSkeleton width="100%" height="8rem" />
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <CarouselGridWrapper className={clsx(styles.content, styles.middle)}>
            <Carousel
                as={Row}
                index={scene.friends.alternativeIndex}
                scrollSnapType="mandatory"
                onIndexChange={(x) => {
                    scene.friends.setAlternativeIndex(x);
                }}
            >
                {scene.friends.grid.map((col, x) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <CarouselCol key={x} sizeXL={3} sizeL={3} sizeM={2} sizeS={3}>
                        <div className={clsx(styles.column)}>
                            {col.map(({ name, meta: user }) => (
                                <React.Fragment key={name}>
                                    <UserCard
                                        key={name}
                                        name={name}
                                        user={user}
                                        controller={scene.controller}
                                    />
                                </React.Fragment>
                            ))}
                        </div>
                    </CarouselCol>
                ))}
            </Carousel>
        </CarouselGridWrapper>
    );
});

export const FriendsScene = observer(({ scene, skeleton = false, notification = null }) => {
    const isSkeleton = skeleton || scene.isLoading;
    const hasInvites = notification && notification.has_incoming_invites;

    return (
        <Container style={{ width: "100vw", height: "100vh", display: "flex" }}>
            <Header title="Друзья" controller={scene.controller} skeleton={isSkeleton}>
                <ActionButton
                    name="search"
                    controller={scene.controller}
                    size="l"
                    pin="square-square"
                    skeleton={isSkeleton}
                >
                    <Icon icon="search" size="s" color="inherit" />
                </ActionButton>

                {notification && notification.is_visible && (
                    <ActionButton
                        name="notification"
                        controller={scene.controller}
                        size="l"
                        pin="square-square"
                        skeleton={isSkeleton}
                    >
                        <Icon icon={hasInvites ? "bellActive" : "bell"} size="s" color="inherit" />
                    </ActionButton>
                )}
            </Header>

            <Content scene={scene} />
        </Container>
    );
});
