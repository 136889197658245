import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Row, Col, Container, Header, Button, NotificationButton } from "components";

import { rankToTitle } from "models/helpers";
import { TUTORIAL_CONTROL_SKIP, TUTORIAL_CONTROL_NEXT } from "models/tutorial.buttons";

import Ranks from "./ranks";
import RatingList from "./rating-list";
import styles from "./scene.module.pcss";

export default observer(({ scene, skeleton = false, notification = null }) => {
    const isLoading = skeleton || scene.isLoading;
    const isTutorial = !!scene.tutorial;
    const { step: tutorialStep = null } = scene.tutorial || {};
    return (
        <Container
            className={clsx({ [styles.tutorial]: isTutorial })}
            style={{
                width: "100vw",
                height: "calc(100vh - var(--assistant-height, 0))",
                display: "flex",
            }}
        >
            <Header
                title="Рейтинг"
                without_back={isTutorial}
                sub_title={
                    <div
                        className={clsx({
                            [styles.tutorialElement]: tutorialStep === "self_rating",
                        })}
                    >
                        {scene.user
                            ? [
                                  rankToTitle(scene.user.rank),
                                  `${scene.user.number}`,
                                  `${scene.user.rating}`,
                              ].join(" \u00B7 ")
                            : " "}
                    </div>
                }
                controller={scene.controller}
                skeleton={isLoading}
            >
                {isTutorial ? (
                    <div className={clsx(styles.tutorialControls)}>
                        <Button
                            name={TUTORIAL_CONTROL_NEXT}
                            controller={scene.controller}
                            view="secondary"
                            size="xs"
                        >
                            <span>Далее</span>
                        </Button>

                        <Button
                            name={TUTORIAL_CONTROL_SKIP}
                            controller={scene.controller}
                            view="secondary"
                            size="xs"
                        >
                            <span>Пропустить обучение</span>
                        </Button>
                    </div>
                ) : (
                    <NotificationButton
                        name="notification"
                        notification={notification}
                        controller={scene.controller}
                        skeleton={isLoading}
                    />
                )}
            </Header>

            <Row style={{ flexGrow: 1, minHeight: 0 }}>
                <Col
                    sizeXL={4}
                    sizeL={3}
                    sizeM={2}
                    sizeS={1}
                    hideS
                    style={{ minHeight: 0, height: "100%" }}
                    className={clsx({
                        [styles.tutorialElement]: tutorialStep === "rank_tabs",
                    })}
                >
                    <Ranks
                        carousel={scene.ranks}
                        skeleton={isLoading}
                        controller={scene.controller}
                    />
                </Col>
                <Col
                    sizeXL={8}
                    sizeL={5}
                    sizeM={4}
                    sizeS={3}
                    style={{ minHeight: 0, height: "100%" }}
                    className={clsx({
                        [styles.tutorialElement]: tutorialStep === "rating_list",
                    })}
                >
                    <RatingList
                        type={scene.top.type}
                        rank={scene.top.rank}
                        carousel={scene.top.carousel}
                        skeleton={isLoading}
                        controller={scene.controller}
                        tutorial={scene.tutorial}
                    />
                </Col>
            </Row>
        </Container>
    );
});
