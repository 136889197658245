import { action, makeAutoObservable, runInAction } from "mobx";

import { Controller } from "models/controller";
import {
    ControlType,
    DialogType,
    GameType,
    LoadState,
    UserRank,
    UserRelation,
    UserStatus,
} from "models/enums";

const BUTTONS_PARAMS = {
    "game:invite": { text: "Предложить игру", view: "success" },
    "friend:invite": { text: "Добавить в друзья", view: "success" },
    "friend:invite:apply": { text: "Принять", view: "success" },
    "friend:invite:cancel": { text: "Отказать", view: "warning" },
    "friend:invite:block": { text: "Заблокировать", view: "critical" },
    "friend:exclude": { text: "Убрать из списка друзей", view: "critical" },
    "user:block": { text: "Заблокировать", view: "critical" },
    "block:cancel": { text: "Исключить из списка", view: "warning" },
};

export class ProfileDialog {
    _app;
    _emitter;
    user_id;
    _nickname;
    avatar_preset_id;
    rating;
    rank;
    relation;
    status;
    controller;
    buttons;
    _state;
    _onDestroy;

    constructor(app, params = {}, { emitter } = {}) {
        makeAutoObservable(this, { _app: false, _action_error: action.bound });
        this._app = app;
        this._emitter = emitter;
        this.user_id = params.user_id;
        this._nickname = "";
        this.avatar_preset_id = 1;
        this.rating = 0;
        this.rank = UserRank.NEWBIE;
        this.relation = UserRelation.NONE;
        this.status = UserStatus.OFFLINE;
        this.buttons = (params.buttons || [])
            .map((item) => {
                if (typeof item === "string") {
                    // eslint-disable-next-line no-param-reassign
                    item = { name: item };
                }

                if (!(item.name in BUTTONS_PARAMS)) {
                    return null;
                }

                return { ...BUTTONS_PARAMS[item.name], ...item };
            })
            .filter((item) => item !== null);
        this._state = LoadState.INIT;
        this._onDestroy = params.onDestroy || null;

        let buttons = {};
        if (this.buttons.length === 1) {
            const btn1 = this.buttons[0].name;
            buttons = {
                [btn1]: { type: ControlType.BUTTON },
            };
        } else if (this.buttons.length === 2) {
            const btn1 = this.buttons[0].name;
            const btn2 = this.buttons[1].name;
            buttons = {
                [btn1]: { up: [btn2], down: [btn2], type: ControlType.BUTTON },
                [btn2]: { up: [btn1], down: [btn1], type: ControlType.BUTTON },
            };
        } else {
            const btn1 = this.buttons[0].name;
            const btn2 = this.buttons[1].name;
            const btn3 = this.buttons[2].name;
            buttons = {
                [btn1]: {
                    up: [btn2, btn3],
                    down: [btn2, btn3],
                    left: [btn2],
                    right: [btn3],
                    type: ControlType.BUTTON,
                },
                [btn2]: {
                    up: [btn1],
                    down: [btn1],
                    left: [btn3],
                    right: [btn3],
                    type: ControlType.BUTTON,
                },
                [btn3]: {
                    up: [btn1],
                    down: [btn1],
                    left: [btn2],
                    right: [btn2],
                    type: ControlType.BUTTON,
                },
            };
        }

        let initName = null;
        this.buttons.forEach(({ name, autoFocus = false }) => {
            if (initName === null && autoFocus === true) {
                initName = name;
            }
        });
        if (initName === null && this.buttons.length > 0) {
            initName = this.buttons[0].name;
        }

        this.controller = new Controller({
            ok: ({ name, type }) => {
                if (type === ControlType.BUTTON) {
                    // eslint-disable-next-line default-case
                    switch (name) {
                        case "back":
                            this.back();
                            break;

                        case "game:invite":
                            this._app.windows.dialogs.open(DialogType.WAITING_PLAYER, {
                                gameType: GameType.WITH_PLAYER,
                                color: null,
                                enemyUserId: this.user_id,
                            });
                            this._emitter.emit("game.invite.apply");
                            this.close();
                            break;

                        case "friend:invite":
                            this._state = LoadState.LOADING;
                            this._app.server.friend.invite
                                .send(this.user_id)
                                .then(() => {
                                    this.close();
                                })
                                .catch(this._action_error);
                            break;

                        case "friend:exclude":
                            this._state = LoadState.LOADING;
                            this._app.server.friend
                                .exclude({ user_id: this.user_id })
                                .then(() => {
                                    this.close();
                                })
                                .catch(this._action_error);
                            break;

                        case "friend:block":
                        case "user:block":
                            this._state = LoadState.LOADING;
                            this._app.server.friend
                                .block({ user_id: this.user_id })
                                .then(() => {
                                    this.close();
                                })
                                .catch(this._action_error);
                            break;

                        case "friend:unblock":
                        case "user:unblock":
                        case "block:cancel":
                            this._state = LoadState.LOADING;
                            this._app.server.friend
                                .unblock({ user_id: this.user_id })
                                .then(() => {
                                    this.close();
                                })
                                .catch(this._action_error);
                            break;
                    }
                }
            },
            back: () => this.back(),
            name: initName,
            find: (name) => {
                if (!(name in buttons)) {
                    return null;
                }

                const element = { ...buttons[name] };

                // eslint-disable-next-line default-case
                switch (name) {
                    case "game:invite":
                        element.disabled = this.status !== UserStatus.ONLINE;
                        break;

                    case "friend:invite":
                        element.disabled = this.relation === UserRelation.FRIEND;
                        break;
                }

                if (this._state !== LoadState.SUCCESS) {
                    element.disabled = true;
                }

                return element;
            },
            has_touch_mode: () => !this._app.windows.hasDialogKeyboard(),
        });
        this._preload();
    }

    get is_loading() {
        // return true;
        return this._state === LoadState.LOADING || this._state === LoadState.INIT;
    }

    get is_error() {
        return this._state === LoadState.FAILURE;
    }

    get is_friend() {
        return this.relation === UserRelation.FRIEND;
    }

    get is_blocked() {
        return this.relation === UserRelation.BLOCKED;
    }

    get nickname() {
        return this._nickname || `ID ${this.user_id}`;
    }

    _action_error(error) {
        this._state = LoadState.FAIL;
        console.error(error);
    }

    back() {
        this.close();
    }

    close() {
        this._emitter.emit("close", this);
    }

    _preload() {
        this._state = LoadState.LOADING;
        this._app.server.profile
            .retrieve({ user_id: this.user_id })
            .then((profile) => {
                this._update_profile(profile);
                runInAction(() => {
                    if (this.status === UserStatus.OFFLINE) {
                        const element = this.controller.focusedElement;
                        if (element && element.disabled) {
                            const elementName = this.controller.focusedName;

                            let initName = null;
                            this.buttons.forEach(({ name }) => {
                                if (initName === null && elementName !== name) {
                                    initName = name;
                                }
                            });

                            if (initName) {
                                this.controller.focus(initName);
                            }
                        }
                    }
                    this._state = LoadState.SUCCESS;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this._state = LoadState.FAILURE;
                });
                console.error(error);
            });
    }

    _update_profile({
        id,
        nickname,
        avatar_preset_id: avatarPresetId,
        rating,
        rank,
        relation = UserRelation.NONE,
        status = UserStatus.OFFLINE,
        // modified_at: modifiedAt,
    }) {
        this.user_id = id;
        this._nickname = nickname;
        this.avatar_preset_id = avatarPresetId;
        this.rating = rating || 0;
        this.rank = rank || UserRank.NEWBIE;
        this.relation = relation;
        this.status = status;
    }

    onDestroy() {
        if (this._onDestroy) {
            try {
                this._onDestroy();
            } catch (exception) {
                console.error(exception);
            }
        }
    }
}
