import { makeAutoObservable, runInAction } from "mobx";

import { Controller } from "models/controller";
import {
    ControlType,
    InviteStatus,
    LoadState,
    UserRank,
    UserRelation,
    UserStatus,
} from "models/enums";

const BUTTONS_PARAMS = {
    apply: { text: "Принять", view: "primary", autoFocus: true },
    cancel: { text: "Отказать", view: "secondary" },
    block: { text: "Заблокировать", view: "secondary" },
};

class User {
    id;
    nickname;
    avatar_preset_id;
    rating;
    rank;
    relation;
    status;

    constructor() {
        makeAutoObservable(this);
        this.id = null;
        this.nickname = "";
        this.avatar_preset_id = 1;
        this.rating = 0;
        this.rank = UserRank.NEWBIE;
        this.relation = UserRelation.NONE;
        this.status = UserStatus.OFFLINE;
    }

    update({
        id,
        nickname,
        avatar_preset_id,
        rating,
        rank,
        relation = UserRelation.NONE,
        status = UserStatus.OFFLINE,
        modified_at,
    }) {
        this.id = id;
        this.nickname = nickname;
        this.avatar_preset_id = avatar_preset_id;
        this.rating = rating || 0;
        this.rank = rank || UserRank.NEWBIE;
        this.relation = relation;
        this.status = status;
    }
}

export class FriendInviteDialog {
    _app;
    _emitter;
    invite_id;
    invite_status;
    from_user;
    to_user;
    controller;
    buttons;
    _state;
    _on_success;

    constructor(app, params = {}, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this.invite_id = params.invite_id;
        this.invite_status = params.invite_status || null;
        this._on_success = params.on_success || null;
        this.from_user = new User();
        this.to_user = new User();
        this.buttons = (params.buttons || [])
            .map((item) => {
                if (typeof item === "string") {
                    item = { name: item };
                }

                if (!(item.name in BUTTONS_PARAMS)) {
                    return null;
                }

                return { ...BUTTONS_PARAMS[item.name], ...item };
            })
            .filter((item) => item !== null);

        this._state = LoadState.INIT;

        let buttons = {};
        if (this.buttons.length === 2) {
            const btn1 = this.buttons[0].name;
            const btn2 = this.buttons[1].name;
            buttons = {
                [btn1]: { up: [btn2], down: [btn2], type: ControlType.BUTTON },
                [btn2]: { up: [btn1], down: [btn1], type: ControlType.BUTTON },
            };
        } else {
            const btn1 = this.buttons[0].name;
            const btn2 = this.buttons[1].name;
            const btn3 = this.buttons[2].name;
            buttons = {
                [btn1]: {
                    up: [btn2, btn3],
                    down: [btn2, btn3],
                    left: [btn2],
                    right: [btn3],
                    type: ControlType.BUTTON,
                },
                [btn2]: {
                    up: [btn1],
                    down: [btn1],
                    left: [btn3],
                    right: [btn3],
                    type: ControlType.BUTTON,
                },
                [btn3]: {
                    up: [btn1],
                    down: [btn1],
                    left: [btn2],
                    right: [btn2],
                    type: ControlType.BUTTON,
                },
            };
        }

        let initName = null;
        this.buttons.forEach(({ name, autoFocus = false }) => {
            if (initName === null && autoFocus === true) {
                initName = name;
            }
        });
        if (initName === null && this.buttons.length > 0) {
            initName = this.buttons[0].name;
        }

        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                if (type === ControlType.BUTTON) {
                    switch (name) {
                        case "back":
                            this.back();
                            break;

                        case "apply":
                            this._app.server.friend.invite
                                .accept(this.invite_id)
                                .then((invite) => {
                                    this._update_invite(invite || {});
                                    this.close(true);
                                })
                                .catch(() => runInAction(() => (this._state = LoadState.FAILURE)));
                            break;

                        case "cancel":
                            this._app.server.friend.invite
                                .cancel(this.invite_id)
                                .then((invite) => {
                                    this._update_invite(invite || {});
                                    this.close(true);
                                })
                                .catch(() => runInAction(() => (this._state = LoadState.FAILURE)));
                            break;

                        case "block":
                            this._app.server.friend.invite
                                .block(this.invite_id)
                                .then((invite) => {
                                    this._update_invite(invite || {});
                                    this.close(true);
                                })
                                .catch(() => runInAction(() => (this._state = LoadState.FAILURE)));
                            break;
                    }
                }
            },
            back: () => this.back(),
            name: initName,
            find: (name) => {
                if (!(name in buttons)) {
                    return null;
                }

                const element = { ...buttons[name] };
                if (this._state !== LoadState.SUCCESS || !this.is_actual) {
                    element.disabled = true;
                }

                return element;
            },
            has_touch_mode: () => !this._app.windows.hasDialogKeyboard(),
        });
        this._preload();
    }

    get is_loading() {
        // return true;
        return this._state === LoadState.LOADING || this._state === LoadState.INIT;
    }

    get is_error() {
        return this._state === LoadState.FAILURE;
    }

    get is_actual() {
        return this.invite_status === InviteStatus.INVITED;
    }

    back() {
        this.close();
    }

    close(with_result = false) {
        this._emitter.emit("close", this);
        if (with_result && this._on_success) {
            this._on_success();
        }
    }

    _preload() {
        this._state = LoadState.LOADING;
        this._app.server.friend.invite
            .retrieve({ invite_id: this.invite_id })
            .then((invite) => this._update_invite(invite || {}))
            .catch((error) => {
                runInAction(() => (this._state = LoadState.FAILURE));
                console.error(error);
            });
    }

    _update_invite({ id, status, from_user, to_user }, state = LoadState.SUCCESS) {
        this.from_user.update(from_user || {});
        this.to_user.update(to_user || {});
        this.invite_id = id;
        this.invite_status = status;
        this._state = state;
    }
}
