import { makeAutoObservable } from "mobx";

import { NanoEvents } from "lib/nanoevents";

import { LoadState } from "models/enums";
import Window from "models/window";

export default class WindowStack {
    _app;
    _state;
    _stack;
    _classes;
    _prefixName;
    _isReverse;

    constructor(app, { classes = {}, prefixName = "window-", reverse = false } = {}) {
        makeAutoObservable(this, { app: false, _classes: false, _prefixName: false });
        this._app = app;
        this._stack = [];
        this._classes = classes;
        this._prefixName = prefixName;
        this._isReverse = reverse;
    }

    get stack() {
        return this._stack.filter((item) => item && item.visible);
    }

    get currentWindow() {
        const { stack } = this;
        if (stack.length > 0) {
            return this._isReverse ? stack[0] : stack[stack.length - 1];
        }
        return undefined;
    }

    get controller() {
        const window = this.currentWindow;
        return window ? window.controller : null;
    }

    get type() {
        const { type = undefined } = this.currentWindow || {};
        return type;
    }

    get isLoading() {
        return this._state === LoadState.LOADING;
    }

    get assistantState() {
        if (this.currentWindow) {
            return this.currentWindow.assistantState;
        }
        return {};
    }

    open(type, params = {}) {
        if (type in this._classes) {
            const DataClass = this._classes[type];
            const emitter = new NanoEvents();
            const window = new Window({
                type,
                data: new DataClass(this._app, params, { emitter }),
                prefixName: this._prefixName,
                emitter,
            });
            if (!this._stack.find((item) => item && item.name === window.name)) {
                emitter.on("close", () => this.remove(window.id));
                this._stack.push(window);
            }
            return window;
        }
        return undefined;
    }

    remove(id) {
        const window = this._stack.find((item) => item.id === id);
        if (window) {
            window.destroy();
        }
        this._stack.replace(this._stack.filter((item) => item.id !== id));
    }
}
