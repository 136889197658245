import React from "react";
import { observer } from "mobx-react-lite";

import { Button as PlasmaButton } from "@sberdevices/plasma-ui/components/Button";

export const Button = observer(
    ({ name, controller, pin = "square-square", view = "primary", size = "l", ...params }) => {
        const element = (controller && controller.find_by_name(name)) || null;
        const disabled = element ? element.disabled || element.disable : false;
        const focused = element
            ? element.focused || (name !== null && name === controller.focusName)
            : false;
        const onClick =
            element && element.click
                ? (event) => {
                      if (element.click()) {
                          event.preventDefault();
                          event.stopPropagation();
                      }
                  }
                : (event) => {
                      if (controller && controller.click(name)) {
                          event.preventDefault();
                          event.stopPropagation();
                      }
                  };

        return (
            <PlasmaButton
                onClick={onClick}
                disabled={disabled}
                outlined={focused}
                view={view}
                pin={pin}
                size={size}
                focused={focused}
                {...params}
            />
        );
    },
);
