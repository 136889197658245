import { PieceColor, PieceType } from "./enums";

const fen_regexp =
    /^\s*([kqbnrp1-8]{1,8})\/([kqbnrp1-8]{1,8})\/([kqbnrp1-8]{1,8})\/([kqbnrp1-8]{1,8})\/([kqbnrp1-8]{1,8})\/([kqbnrp1-8]{1,8})\/([kqbnrp1-8]{1,8})\/([kqbnrp1-8]{1,8})\s+([wb])\s+([kq]{1,4}|-)\s+([a-h1-8]+|-)\s+([0-9]+)\s+([0-9]+)\s*/i;
const fen_empty_number = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8 };
const fen_piece_map = {
    k: { type: PieceType.KING, color: PieceColor.BLACK },
    K: { type: PieceType.KING, color: PieceColor.WHITE },
    q: { type: PieceType.QUEEN, color: PieceColor.BLACK },
    Q: { type: PieceType.QUEEN, color: PieceColor.WHITE },
    b: { type: PieceType.BISHOP, color: PieceColor.BLACK },
    B: { type: PieceType.BISHOP, color: PieceColor.WHITE },
    n: { type: PieceType.KNIGHT, color: PieceColor.BLACK },
    N: { type: PieceType.KNIGHT, color: PieceColor.WHITE },
    r: { type: PieceType.ROOK, color: PieceColor.BLACK },
    R: { type: PieceType.ROOK, color: PieceColor.WHITE },
    p: { type: PieceType.PAWN, color: PieceColor.BLACK },
    P: { type: PieceType.PAWN, color: PieceColor.WHITE },
};

const fen_parse_row = (num, row) => {
    const pieces = [];

    for (let pos = 0, column = 1; pos < row.length && column <= 8; pos++, column++) {
        const char = row.charAt(pos);
        const empty = fen_empty_number[char];
        if (empty) {
            column += empty - 1;
        } else {
            pieces.push({ x: column, y: num, ...fen_piece_map[char] });
        }
    }

    return pieces;
};

export const fen_parser = (data) => {
    const [
        _,
        row8,
        row7,
        row6,
        row5,
        row4,
        row3,
        row2,
        row1,
        active_color,
        castling,
        en_passant,
        half_moves,
        moves,
    ] = data.match(fen_regexp);

    const pieces = [];

    pieces.push(...fen_parse_row(1, row1));
    pieces.push(...fen_parse_row(2, row2));
    pieces.push(...fen_parse_row(3, row3));
    pieces.push(...fen_parse_row(4, row4));
    pieces.push(...fen_parse_row(5, row5));
    pieces.push(...fen_parse_row(6, row6));
    pieces.push(...fen_parse_row(7, row7));
    pieces.push(...fen_parse_row(8, row8));

    return {
        half_moves: half_moves,
        full_moves: moves,
        active_color,
        castling,
        en_passant,
        pieces,
        moves: [],
    };
};
