export const normalizeControlXY = ({ x, y }, flipped = false) => ({
    x: flipped ? 9 - x : x,
    y: flipped ? 9 - y : y,
});

// eslint-disable-next-line camelcase
export const normalize_control_xy = normalizeControlXY;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const number_to_string = (number, length) => {
    const value = number.toString();
    return new Array(Math.max(length - value.length, 0)).fill(0).join("") + value;
};

export const time_to_human = (ms) => {
    const seconds = Math.trunc(ms / 1000),
        minutes = Math.trunc(seconds / 60),
        hours = Math.trunc(minutes / 60);
    return (
        (hours > 0 ? number_to_string(hours, 2) + ":" : "") +
        number_to_string(minutes % 60, 2) +
        ":" +
        number_to_string(seconds % 60, 2)
    );
};

export const uuid4 = () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
