import React, { useEffect } from "react";

export const MonkeyPatchFontSize = ({ children }) => {
    useEffect(() => {
        document.documentElement.style.setProperty("font-size", "1.66vw");
        return () => {
            document.documentElement.style.removeProperty("font-size");
        };
    });

    return children;
};
