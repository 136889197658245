import { action, makeAutoObservable, runInAction } from "mobx";
import {
    createAssistant,
    createSmartappDebugger as createAssistantDebug,
} from "@sberdevices/assistant-client";

import { uuid4 } from "lib/helpers";
import { AssistantType, SceneType } from "models/enums";

export default class AssistantStore {
    _app;
    _inited;
    _requests;
    assistant;
    name;

    constructor(app) {
        makeAutoObservable(this, {
            _app: false,
            on_data: action.bound,
            on_start: action.bound,
            get_state: action.bound,
            get_state_recovery: action.bound,
        });
        this._app = app;
        this._inited = false;
        this._requests = {};
        this.assistant = null;
        this.name = null;
    }

    __init__() {
        try {
            const url = new URL(window.location.href);
            let assistant = null;
            if (!process.env.SMARTAPP_DEV || url.searchParams.get("assistant") === "production") {
                // Только для среды production
                assistant = createAssistant({
                    getState: this.get_state,
                    getStateRecovery: this.get_state_recovery,
                });
            } else {
                assistant = createAssistantDebug({
                    // Токен из Кабинета разработчика
                    token: process.env.SMARTAPP_TOKEN,
                    // Пример фразы для запуска приложения
                    initPhrase: `Запусти ${process.env.SMARTAPP_NAME}`,
                    // Функция, которая возвращает текущее состояние приложения
                    getState: this.get_state,
                    // Функция, возвращающая состояние приложения, с которым приложение будет восстановлено при следующем запуске
                    getStateRecovery: this.get_state_recovery,
                });
            }
            assistant.on("start", this.on_start);
            assistant.on("data", this.on_data);

            this.assistant = assistant;
        } catch (exception) {
            console.error(exception);
            setTimeout(() => this.__init__(), 1000);
        }
    }

    get is_inited() {
        return this.assistant !== null && this._inited;
    }

    get_state() {
        return {
            ...this._app.windows.assistant_state,
            settings: {
                assistant_channel: this._app.user.profile.settings.assistant_channel || {},
            },
        };
    }

    get_state_recovery() {
        return { assistant: this.name };
    }

    on_start() {
        if (!this.is_inited) {
            try {
                console.log("Assistant", "start");

                const recoveryState = this.assistant.getRecoveryState() || {};
                console.log("Assistant", "getRecoveryState", JSON.stringify(recoveryState));

                const initData = this.assistant.getInitialData();
                initData.forEach((item) => this.on_data(item));
                this._inited = true;
            } catch (exception) {
                setTimeout(() => this.on_start(), 1000);
            }
        }
    }

    on_data(event) {
        if (event.type === "smart_app_data") {
            return this.on_data(event.smart_app_data);
        }

        console.log("Assistant", "data", event);
        switch (event.type) {
            case "controller":
                this.on_controller(event.controller);
                break;

            case "user_info":
                this._app.user.update_info(event.user_info.is_tester || null);
                this._app.user.update_tutorial(event.user_info.tutorial || {});
                this._app.user.update_auto_help_counter(
                    event.user_info.scene_auto_help_counter || {},
                );
                break;

            case "auth_token":
                this._app.user.auth_token = event.auth_token.token;
                break;

            case "tutorial":
                this._app.user.update_tutorial(event.tutorial || {});
                break;

            case "path":
                this._app.windows.apply_path(
                    event.path.path,
                    event.path.params || {},
                    event.path.uid || null,
                );
                break;

            case "response":
                // eslint-disable-next-line no-case-declarations
                const requestId = event.request_id || null;
                if (requestId in this._requests) {
                    const responseHandler = this._requests[requestId];
                    delete this._requests[requestId];
                    responseHandler(event);
                }
                break;

            case "move":
                if (this._app.windows.scenes.type === SceneType.GAME && this._app.game.board) {
                    this._app.game.board.try_move(event.move);
                }
                break;

            case "settings":
                this._app.user.updateProfile({ settings: event.settings });
                break;

            case "system":
                if (event.system.command === "BACK") {
                    this.on_controller({
                        type: "press",
                        press: { key: event.system.command },
                    });
                }
                break;

            case "navigation":
                this.on_controller({ type: "press", press: { key: event.navigation.command } });
                break;

            case "insets":
                document.documentElement.style.setProperty(
                    "--assistant-height",
                    event.insets && event.insets.bottom ? `${event.insets.bottom}px` : "4.5rem",
                );
                break;

            case "character":
                this.name =
                    {
                        sber: AssistantType.SBER,
                        eva: AssistantType.ATHENA,
                        athena: AssistantType.ATHENA,
                        joy: AssistantType.JOY,
                    }[event.character.id] || AssistantType.SBER;
                break;

            case "scene_auto_help_counter":
                this._app.user.update_auto_help_counter(event.scene_auto_help_counter || {});
                break;

            case "debug":
                break;

            case "show_tech_info":
                this._app.windows.scenes.open(SceneType.TECH_INFO, event.show_tech_info || {});
                break;

            case "app_context":
            case "theme":
            case "visibility":
                // do nothing
                break;

            default:
                console.error("Unknown assistant event type", event.type, event);
        }
        return null;
    }

    on_controller(event) {
        console.log("Assistant", "controller", event);
        switch (event.type) {
            case "click":
                this._app.windows.controller.click(event.click.by);
                break;

            case "press":
                this._app.windows.controller.press_key(event.press.key);
                break;

            case "focus":
                this._app.windows.controller.focus(event.focus.on);
                break;

            default:
                console.error("Unknown controller event type", event.type, event);
        }
    }

    fire_event(eventName, params = {}) {
        if (this.assistant) {
            this.assistant.sendData({ action: { action_id: eventName, [eventName]: params } });
        }
    }

    async request(path, data = {}) {
        if (this.assistant) {
            return new Promise((resolve, reject) => {
                const requestId = uuid4();
                const responseHandler = (event) => {
                    try {
                        let responseEvent = event;
                        if (responseEvent.type === "smart_app_data") {
                            responseEvent = event.smart_app_data;
                        }

                        if (responseEvent.type === "response") {
                            return resolve({ path, data: responseEvent.response || {} });
                        }

                        resolve(event);
                    } catch (exception) {
                        reject(exception);
                    } finally {
                        runInAction(() => {
                            if (requestId in this._requests) {
                                delete this._requests[requestId];
                            }
                        });
                    }
                    return undefined;
                };
                runInAction(() => {
                    if (!(requestId in this._requests)) {
                        this._requests[requestId] = responseHandler;
                    }
                });

                this.assistant.sendData(
                    {
                        action: {
                            action_id: "event_async_request",
                            request_id: requestId,
                            path,
                            data,
                        },
                    },
                    responseHandler,
                );
            });
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("Assistant no inited");
    }

    async retrieve_auth_token({ refresh = false } = {}) {
        const response = (await this.request(`/auth/token${refresh ? "/refresh" : ""}`)) || {};
        const { token = null } = response.data || {};
        this.on_data({ type: "auth_token", auth_token: { token } });
        return token;
    }

    async retrieve_user_info() {
        const response = (await this.request("/user/info")) || {};
        const userInfo = response.data || {};
        this.on_data({ type: "user_info", user_info: userInfo });
        return userInfo;
    }

    async tutorial_apply(name) {
        try {
            const response = (await this.request("/tutorial/apply", { name })) || {};
            this.on_data({ type: "tutorial", tutorial: response.data || {} });
        } catch (exception) {
            console.error(exception);
        }
    }

    async update_scene_auto_help_counter(scene, count = 1) {
        try {
            const response = await this.request("/scene/auto-help-counter/update", {
                scene,
                count,
            });
            this.on_data({
                type: "scene_auto_help_counter",
                scene_auto_help_counter: response && response.data ? response.data : {},
            });
        } catch (exception) {
            console.error(exception);
        }
    }

    speech_text(text) {
        this.fire_event("event_assistant_speech_text", text);
    }

    async transition(path, { deferred = false, params = {}, uid = null } = {}) {
        try {
            const response =
                (await this.request("/transition", { path, deferred, params, uid })) || {};
            this.on_data({ type: "path", path: response.data || {} });
        } catch (exception) {
            console.error(exception);
        }
    }

    close_app({ trigger = null }) {
        this._app.analytics.send_close_app({ trigger });
        this.assistant.close();
    }
}
