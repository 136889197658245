import { makeAutoObservable } from "mobx";

import { Controller } from "models/controller";
import { ControlType, DialogType, GameMode, SceneType } from "models/enums";

const DEFAULT_CONTROL_ELEMENT = Object.freeze({
    back: { type: ControlType.BUTTON },
    notification: {
        up: ["find_player_rating", "find_player"],
        down: ["find_player_rating", "find_player"],
        left: ["top_of_ranks"],
        right: ["top_of_ranks"],
        type: ControlType.BUTTON,
    },
    top_of_ranks: {
        up: ["find_player_rating", "find_player"],
        down: ["find_player_rating", "find_player"],
        left: ["notification"],
        right: ["notification"],
        type: ControlType.BUTTON,
    },
    find_player: {
        up: ["top_of_ranks", "notification"],
        down: ["top_of_ranks", "notification"],
        left: ["find_player_rating"],
        right: ["find_player_rating"],
        type: ControlType.BUTTON,
    },
    find_player_rating: {
        up: ["top_of_ranks", "notification"],
        down: ["top_of_ranks", "notification"],
        left: ["find_player"],
        right: ["find_player"],
        type: ControlType.BUTTON,
    },
});

export class ChoicePVPModeScene {
    _app;
    _scenes;
    _game;
    _first_activate;
    _isActive;
    controller;
    tutorial;

    constructor(scenes, app, game) {
        makeAutoObservable(this, { _app: false, _scenes: false, _game: false });
        this._app = app;
        this._scenes = scenes;
        this._game = game;
        this._first_activate = true;
        this._isActive = false;
        this.tutorial = null;
        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                if (type === ControlType.BUTTON) {
                    switch (name) {
                        case "find_player":
                            this.find_player();
                            break;

                        case "find_player_rating":
                            this.find_player_rating();
                            break;

                        case "back":
                            this.back();
                            break;

                        case "notification":
                            this._app.windows.dialogs.open(DialogType.NOTIFICATION);
                            break;

                        case "top_of_ranks":
                            this._app.windows.scenes.open(SceneType.TOP_OF_RANKS);
                            break;
                    }
                }
            },
            back: () => this.back(),
            name: "find_player",
            find: (name) => {
                if (!(name in DEFAULT_CONTROL_ELEMENT)) {
                    return null;
                }
                return { name, ...DEFAULT_CONTROL_ELEMENT[name] };
            },
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
        });
    }

    get isLoading() {
        return !this._isActive;
    }

    find_player() {
        this._game.options.set_mode(GameMode.WITH_PLAYER);
        this._game.options.set_friend(null);
        this._game.options.set_color(null);
        this._app.windows.dialogs.open(DialogType.WAITING_PLAYER, {
            gameType: GameMode.WITH_PLAYER,
            color: null,
            enemyUserId: null,
        });
    }

    find_player_rating() {
        this._game.options.set_mode(GameMode.WITH_PLAYER_RATING);
        this._game.options.set_friend(null);
        this._game.options.set_color(null);
        this._app.windows.dialogs.open(DialogType.WAITING_PLAYER, {
            gameType: GameMode.WITH_PLAYER_RATING,
            color: null,
            enemyUserId: null,
        });
    }

    create_match() {
        this._game.options.set_mode(GameMode.WITH_PLAYER);
        this._game.options.set_friend(null);
        this._game.options.set_color(null);
        this._scenes.change(SceneType.CHOICE_COLOR);
    }

    back() {
        this._scenes.change(SceneType.CHOICE_MODE);
    }

    get assistant_state() {
        return {
            is_auto_help: this._app.user.has_scene_auto_help(SceneType.CHOICE_PVP_MODE),
        };
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
        this.tutorial = null;
        this.controller.unlock();
        this._app.assistant.fire_event("event_activate_scene", {
            is_first_activate: this._first_activate,
        });
        if (this._first_activate) {
            this._app.user.increase_scene_auto_help(SceneType.CHOICE_PVP_MODE);
        }
        this._first_activate = false;
    }
}
