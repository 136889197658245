import { makeAutoObservable, runInAction } from "mobx";

import { LoadState } from "models/enums";

export class UserListWithPages {
    _items;
    _offset;
    _limit;
    _load_state;
    _count;
    _loader;

    constructor({ loader, items = [], offset = 0, limit = 8 }) {
        makeAutoObservable(this);
        this._items = items;
        this._offset = Math.max(0, offset);
        this._limit = Math.max(1, limit);
        this._count = 0;
        this._load_state = LoadState.INIT;
        this._loader = loader;
    }

    get is_loading() {
        return this._load_state === LoadState.LOADING;
    }

    get is_spinner() {
        return this.is_loading || this._load_state === LoadState.INIT;
    }

    get items() {
        return (this._items || []).slice(0, this._limit);
    }

    get count() {
        return this._count;
    }

    get page_count() {
        return Math.max(1, Math.ceil(this._count / this._limit));
    }

    get page_number() {
        if (this._count <= 0) {
            return 1;
        }
        return Math.max(1, Math.ceil((this._offset + 1) / this._limit));
    }

    next() {
        this._load_from_server({ offset: this._offset + this._limit, limit: this._limit });
    }

    prev() {
        this._load_from_server({ offset: this._offset - this._limit, limit: this._limit });
    }

    get has_next() {
        return this._offset + this._limit < this._count;
    }

    get has_prev() {
        return this._offset > 0;
    }

    reload({ offset = null } = {}) {
        this._load_from_server({
            offset: offset === null ? this._offset : offset,
            limit: this._limit,
        });
    }

    _load_from_server({ offset, limit }) {
        if (!this.is_loading) {
            this._load_state = LoadState.LOADING;
            this._loader({ offset: Math.max(0, offset), limit: Math.max(1, limit) })
                .then((data) => this.update_state(data, LoadState.SUCCESS))
                .catch((error) =>
                    runInAction(() => {
                        this._load_state = LoadState.FAIL;
                        console.error(error);
                    }),
                );
        }
    }

    update_state({ offset, limit, items, count }, load_state = LoadState.SUCCESS) {
        this._load_state = load_state;
        this._count = count;
        if (offset < count) {
            this._offset = offset;
            this._items.replace(items);
        }
    }
}
