import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Button, Carousel, CarouselItem, Footnote1, Headline2, RectSkeleton } from "components";
import emptyLogo from "assets/images/list_empty.svg";

import RatingListItem from "./rating-list-item";
import styles from "./scene.module.pcss";

export default observer(
    ({ type, carousel, skeleton = false, controller = null, tutorial = null }) => {
        if (skeleton || type === "skeleton" || type === "spinner") {
            return <RectSkeleton width="100%" height="2.5rem" roundness={12} />;
        }

        if (type === "empty") {
            return (
                <div
                    className={clsx(styles.ratingList)}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "var(--assistant-height, 0)",
                        }}
                    >
                        <img
                            alt=""
                            src={emptyLogo}
                            style={{
                                width: "10rem",
                                height: "10rem",
                                marginBottom: "-2rem",
                            }}
                        />
                        <Headline2
                            style={{
                                marginTop: "0.25rem",
                                marginBottom: "1rem",
                                color: "var(--plasma-colors-tertiary, rgba(255, 255, 255, 0.28))",
                                textAlign: "center",
                            }}
                        >
                            Пока нет игроков с таким титулом
                        </Headline2>
                    </div>
                </div>
            );
        }

        if (type === "failure") {
            return (
                <div style={{ textAlign: "center" }}>
                    <Headline2>Что-то пошло не так</Headline2>
                    <Footnote1
                        style={{
                            marginTop: "0.25rem",
                            marginBottom: "1rem",
                            color: "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))",
                        }}
                    >
                        повторите попытку позже
                    </Footnote1>

                    <Button
                        name="reload"
                        view="secondary"
                        text="Обновить"
                        size="m"
                        controller={controller}
                    />
                </div>
            );
        }

        return (
            <div className={styles.ratingList}>
                <div className={clsx(styles.item)}>
                    <div className={clsx(styles.position, styles.header)}>
                        <Footnote1 className={clsx(styles.number)} />
                        <Footnote1 className={clsx(styles.nickname)}>Никнейм</Footnote1>
                        <Footnote1 className={clsx(styles.rating)}>Рейтинг</Footnote1>
                    </div>
                </div>

                <Carousel
                    axis="y"
                    index={carousel.alternativeIndex}
                    scrollSnapType="mandatory"
                    onIndexChange={(index) => carousel.setAlternativeIndex(index)}
                    style={{ height: "100%" }}
                    className={clsx({
                        [styles.tutorialElement]: tutorial && tutorial.step === "position",
                    })}
                    paddingEnd="var(--assistant-height, 0)"
                >
                    {carousel.children.map(({ name, meta: position }) => (
                        <CarouselItem key={position.user.id} className={clsx(styles.item)}>
                            <RatingListItem
                                name={name}
                                number={position.number}
                                user={position.user}
                                controller={controller}
                                tutorial={tutorial}
                            />
                        </CarouselItem>
                    ))}
                </Carousel>
            </div>
        );
    },
);
