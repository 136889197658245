import React from "react";
import styled from "styled-components";
import {
    TextBoxRoot as PlasmaTextBoxRoot,
    TextBoxTitle as PlasmaTextBoxTitle,
    TextBoxBigTitle as PlasmaTextBoxBigTitle,
    TextBoxCaption,
    TextBoxLabel,
    TextBoxSubTitle,
} from "@sberdevices/plasma-ui/components/TextBox";
import { primary } from "@sberdevices/plasma-tokens";

export { TextBoxCaption, TextBoxLabel, TextBoxSubTitle };

export const TextBoxTitle = styled(PlasmaTextBoxTitle)`
    color: ${({ color }) => color || primary};
`;

export const TextBoxBigTitle = styled(PlasmaTextBoxBigTitle)`
    color: ${({ color }) => color || primary};
`;

export const TextBox = ({
    label,
    title,
    subTitle,
    caption,
    size = "m",
    children,
    color,
    ...rest
}) => {
    if (children) {
        return <PlasmaTextBoxRoot>{children}</PlasmaTextBoxRoot>;
    }

    const T = size === "m" ? TextBoxTitle : TextBoxBigTitle;

    return (
        <PlasmaTextBoxRoot {...rest}>
            {label && <TextBoxLabel color={color}>{label}</TextBoxLabel>}
            {title && <T color={color}>{title}</T>}
            {subTitle && <TextBoxSubTitle color={color}>{subTitle}</TextBoxSubTitle>}
            {caption && <TextBoxCaption color={color}>{caption}</TextBoxCaption>}
        </PlasmaTextBoxRoot>
    );
};
