import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    Button,
    Col,
    Container,
    Row,
    TextBoxTitle,
    UserAvatar,
    TextBoxSubTitle,
    Cell,
    CellDisclosure,
    TextBoxBigTitle,
    RectSkeleton,
    TextSkeleton,
} from "components";

import { rank_to_title } from "models/helpers";

import styles from "./dialog.module.pcss";

const FriendsButton = observer(({ name, count = 0, controller, skeleton = false }) => {
    if (skeleton) {
        return (
            <div
                style={{
                    width: "100%",
                    height: "7rem",
                    background: "var(--plasma-colors-background-primary, #1a1a1a)",
                    borderRadius: "2rem",
                }}
            >
                <RectSkeleton width="100%" height="100%" roundness={32} />
            </div>
        );
    }
    const element = (controller && controller.find_by_name(name)) || {};
    return (
        <div
            className={clsx(styles.friends, {
                [styles.outlined]: element.focused,
                [styles.disabled]: element.disabled,
            })}
            onClick={() => controller && controller.click(name)}
        >
            <Cell
                contentLeft={
                    <div
                        style={{
                            background: "var(--plasma-colors-button-checked)",
                            width: "2.5rem",
                            height: "2.5rem",
                            borderRadius: "2.5rem",
                            marginRight: "1rem",
                        }}
                    />
                }
                content={<TextBoxTitle>Друзья</TextBoxTitle>}
                contentRight={<CellDisclosure text={count} />}
            />
        </div>
    );
});

export const ProfileSettingsDialog = observer(({ dialog, skeleton = false }) => {
    const { controller, is_error } = dialog;
    const is_skeleton = skeleton || dialog.is_loading;
    return (
        <Container
            className={clsx(styles.view, { [styles.portal]: dialog.is_portal })}
            onClick={() => dialog.close()}
        >
            <Row>
                <Col
                    sizeXL={5}
                    offsetXL={7}
                    sizeL={5}
                    offsetL={3}
                    sizeM={3.25}
                    offsetM={2.75}
                    sizeS={4}
                    offsetS={0}
                >
                    <div
                        className={clsx(styles.content)}
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                    >
                        {is_error ? (
                            <div className={clsx(styles.profile)}>
                                <div className={clsx(styles.info)}>
                                    <TextBoxBigTitle>Что-то пошло не так</TextBoxBigTitle>
                                    <TextBoxSubTitle>повторите попытку позже</TextBoxSubTitle>
                                </div>
                            </div>
                        ) : (
                            <div className={clsx(styles.profile)}>
                                <div className={clsx(styles.info)}>
                                    <div className={clsx(styles.avatar)}>
                                        <UserAvatar
                                            preset={dialog.avatar_preset_id}
                                            skeleton={is_skeleton}
                                        />
                                    </div>
                                    <div className={clsx(styles.text)}>
                                        {is_skeleton ? (
                                            <React.Fragment>
                                                <TextSkeleton lines={1} size="body1" />
                                                <div style={{ width: "10rem" }}>
                                                    <TextSkeleton lines={1} size="footnote1" />
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <TextBoxTitle>{dialog.nickname}</TextBoxTitle>
                                                <TextBoxSubTitle>
                                                    {dialog.rating} &#183;{" "}
                                                    {rank_to_title(dialog.rank)}
                                                </TextBoxSubTitle>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>

                                {is_skeleton ? (
                                    <RectSkeleton width="100%" height="2.5rem" roundness={12} />
                                ) : (
                                    <Button
                                        name="edit"
                                        text="Редактировать профиль"
                                        controller={controller}
                                        size="s"
                                        skeleton={is_skeleton}
                                        view="secondary"
                                    />
                                )}
                            </div>
                        )}

                        <FriendsButton
                            name="friends"
                            count={dialog.friend_count}
                            controller={controller}
                            skeleton={is_skeleton}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
