import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Button, Carousel, CarouselItem, RectSkeleton } from "components";
import { rankToTitle } from "models/helpers";

import styles from "./scene.module.pcss";

export default observer(({ carousel, skeleton = false, controller = null }) => {
    if (skeleton) {
        return <RectSkeleton width="100%" height="3rem" roundness={12} />;
    }
    return (
        <Carousel
            axis="y"
            index={carousel.alternativeIndex}
            scrollSnapType="mandatory"
            scaleCallback={(item, slot) => {
                // eslint-disable-next-line no-param-reassign
                item.style.opacity = `${1 - Math.abs(slot) * 0.4}`;
            }}
            scaleResetCallback={(item) => {
                // eslint-disable-next-line no-param-reassign
                item.style.opacity = "";
            }}
            detectActive
            onIndexChange={(index) => carousel.setAlternativeIndex(index)}
            scrollAlign="center"
            className={clsx(styles.ranks)}
            paddingStart="50%"
            paddingEnd="50%"
        >
            {carousel.children.map(({ name, meta: rank }) => (
                <CarouselItem key={rank} className={clsx(styles.item)}>
                    <Button
                        name={name}
                        view="secondary"
                        controller={controller}
                        text={rankToTitle(rank, true)}
                        stretch
                    />
                </CarouselItem>
            ))}
        </Carousel>
    );
});
