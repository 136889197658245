import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Button, CardButton, Col, Container, Header, Row } from "components";

import styles from "./scene.module.pcss";

export const ChoiceColorScene = observer(({ scene, skeleton = false, notification = null }) => (
    <Container className={clsx(styles.view)}>
        <Header
            title="Новая игра"
            controller={scene.controller}
            skeleton={skeleton}
            notification={notification}
        />

        <Row style={{ flexGrow: 1, alignContent: "center" }}>
            <Col
                sizeXL={12}
                offsetXL={0}
                sizeL={8}
                offsetL={0}
                sizeM={6}
                offsetM={0}
                sizeS={4}
                offsetS={0}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        flexGrow: 1,
                        justifyContent: "space-around",
                        alignSelf: "center",
                    }}
                >
                    <CardButton
                        name="white"
                        text="Белые"
                        controller={scene.controller}
                        skeleton={skeleton}
                        view={scene.color === "white" ? "primary" : "secondary"}
                    >
                        <div className={clsx(styles.logo, styles.white)} />
                    </CardButton>

                    <CardButton
                        name="random"
                        text="Случайно"
                        controller={scene.controller}
                        skeleton={skeleton}
                    >
                        <div className={clsx(styles.logo, styles.random)} />
                    </CardButton>

                    <CardButton
                        name="black"
                        text="Чёрные"
                        controller={scene.controller}
                        skeleton={skeleton}
                        view={scene.color === "black" ? "primary" : "secondary"}
                    >
                        <div className={clsx(styles.logo, styles.black)} />
                    </CardButton>
                </div>
            </Col>
        </Row>

        <Row>
            <Col
                sizeXL={3}
                offsetXL={4.5}
                sizeL={3}
                offsetL={2.5}
                sizeM={2}
                offsetM={2}
                sizeS={4}
                offsetS={0}
                style={{ marginBottom: "var(--assistant-height, 0)" }}
            >
                <Button
                    name="play"
                    text="Играть"
                    controller={scene.controller}
                    view="primary"
                    size="m"
                    stretch
                />
            </Col>
        </Row>
    </Container>
));
