import { makeAutoObservable, runInAction } from "mobx";

import { LoadState } from "models/enums";

import sound_check_ogg from "assets/sounds/check.ogg";
import sound_piece_drop_ogg from "assets/sounds/piece-drop.ogg";
import sound_piece_up_ogg from "assets/sounds/piece-up.ogg";
import sound_piece_down_ogg from "assets/sounds/piece-down.ogg";
import sound_win_ogg from "assets/sounds/win.ogg";
import sound_lose_ogg from "assets/sounds/lose.ogg";
import sound_cant_move_ogg from "assets/sounds/cant-move.ogg";

import { SoundManager } from "./manager";

export class AudioStore {
    sounds;
    state;

    constructor() {
        makeAutoObservable(this);
        this.sounds = new SoundManager({ volume: 0.6, muted: false });
        this.state = LoadState.INIT;
    }

    __init__() {
        this.state = LoadState.LOADING;
        Promise.all([
            this.sounds.add("game_win", sound_win_ogg, { pre_load: true }),
            this.sounds.add("game_lose", sound_lose_ogg, { pre_load: true }),
            this.sounds.add("check", sound_check_ogg, { pre_load: true }),
            this.sounds.add("piece_drop", sound_piece_drop_ogg, { pre_load: true }),
            this.sounds.add("piece_up", sound_piece_up_ogg, { pre_load: true }),
            this.sounds.add("piece_down", sound_piece_down_ogg, { pre_load: true }),
            this.sounds.add("cant_move", sound_cant_move_ogg, { pre_load: true }),
        ])
            .then(() => runInAction(() => (this.state = LoadState.SUCCESS)))
            .catch((error) => {
                runInAction(() => (this.state = LoadState.FAILURE));
                console.error(error);
            });
    }
}
