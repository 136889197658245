import clsx from "clsx";
import React from "react";

import styles from "./icons.module.pcss";

export const IconSurrender = ({ size = "s" }) => (
    <div className={clsx(styles.icon, styles[size])}>
        <svg width="100%" version="1.1" fill="var(--plasma-colors-primary)" viewBox="0 -25 254 304">
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="ico-sdatsya.eps">
                    <path d="M74 34c22,1 39,6 59,12 25,9 33,9 59,9 9,0 15,7 15,15l0 82c0,8 -6,15 -15,15 -29,2 -50,-3 -76,-12 -14,-5 -28,-9 -43,-10l0 73c0,8 -6,14 -14,14 -8,0 -14,-6 -14,-14l0 -185c0,-8 6,-14 14,-14 8,0 14,6 14,14z" />
                </g>
            </g>
        </svg>
    </div>
);
