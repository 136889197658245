import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    ActionButton,
    Header,
    Icon,
    RectSkeleton,
    Spinner,
    TextBoxBigTitle,
    TextBoxCaption,
    TextBoxSubTitle,
    TextSkeleton,
} from "components";
import { IconNoAccount } from "components/icons";

import { LoadState, UserStatus } from "models/enums";
import { rank_to_title, user_status_to_title } from "models/helpers";

import styles from "./scene.module.pcss";

export const UsersBlockedScene = observer(({ scene, skeleton = false, notification = null }) => {
    const is_skeleton = skeleton || scene.state === LoadState.LOADING;
    // const is_skeleton = false;

    return (
        <React.Fragment>
            <div className={clsx(styles.view)}>
                <Header
                    title="Заблокированные"
                    controller={scene.controller}
                    skeleton={is_skeleton}
                />

                <div className={clsx(styles.content)}>
                    {skeleton ? (
                        <div className={clsx(styles.users)}>
                            <div className={clsx(styles.body)}>
                                <RectSkeleton width="100%" height="2rem" roundness={24} />
                            </div>
                            <div className={styles.footer}>
                                <RectSkeleton width="2.25rem" height="2.25rem" roundness={32} />
                                <div style={{ width: "2rem" }}>
                                    <TextSkeleton width="3rem" lines={1} size="footnote1" />
                                </div>
                                <RectSkeleton width="2.25rem" height="2.25rem" roundness={32} />
                            </div>
                        </div>
                    ) : scene.users.count === 0 ? (
                        scene.users.is_loading ? (
                            <div />
                        ) : (
                            <div className={clsx(styles.empty)}>
                                <TextBoxBigTitle>
                                    Заблокированные контакты отсутствуют
                                </TextBoxBigTitle>
                                <TextBoxSubTitle>
                                    когда у вас появятся заблокированные контакты, то они
                                    отобразятся здесь
                                </TextBoxSubTitle>
                            </div>
                        )
                    ) : (
                        <div className={clsx(styles.users)}>
                            <div className={clsx(styles.body)}>
                                <div className={clsx(styles.row, styles.header)}>
                                    <div>Никнейм</div>
                                    <div>Рейтинг</div>
                                    <div>Титул</div>
                                    <div>Статус</div>
                                </div>

                                {scene.users.items.map((user, index) => (
                                    <div
                                        key={`user:id:${user.id}`}
                                        className={clsx(styles.row, styles.item, {
                                            [styles.outlined]:
                                                scene.controller &&
                                                scene.controller.name === `users:item:${index}`,
                                        })}
                                        onClick={() =>
                                            scene.controller &&
                                            scene.controller.click(`users:item:${index}`)
                                        }
                                    >
                                        <div>{user.nickname || `ID ${user.id}`}</div>
                                        <div>{user.rating}</div>
                                        <div>{rank_to_title(user.rank)}</div>
                                        <div>{user_status_to_title(user.status)}</div>
                                    </div>
                                ))}
                            </div>

                            <div className={styles.footer}>
                                <ActionButton
                                    name="users:page:prev"
                                    controller={scene.controller}
                                    size="s"
                                    pin="circle-circle"
                                >
                                    <Icon icon="chevronLeft" size="xs" color="inherit" />
                                </ActionButton>

                                <TextBoxCaption>{scene.users.page_number}</TextBoxCaption>

                                <ActionButton
                                    name="users:page:next"
                                    controller={scene.controller}
                                    size="s"
                                    pin="circle-circle"
                                >
                                    <Icon icon="chevronRight" size="xs" color="inherit" />
                                </ActionButton>
                            </div>
                        </div>
                    )}

                    {!skeleton && scene.users.is_spinner && (
                        <div className={clsx(styles.loading)}>
                            <div>
                                <Spinner size="5rem" />
                            </div>
                        </div>
                    )}
                </div>
                <div />
            </div>
        </React.Fragment>
    );
});
