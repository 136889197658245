import { DialogType } from "models/enums";

import AskGameInviteDialog from "./ask-game-invite";
import AskUserInviteDialog from "./ask-user-invite";

import GameSurrenderDialog from "./game-surrender";
import NotificationDialog from "./notification";
import { ProfileDialog } from "./profile";
import { ProfileSettingsDialog } from "./profile-settings";
import { FriendInviteDialog } from "./friend-invite";
import { WaitingPlayerDialog } from "./waiting-player";

export const DIALOG_CLASSES = Object.freeze({
    [DialogType.NOTIFICATION]: NotificationDialog,
    [DialogType.PROFILE]: ProfileDialog,
    [DialogType.PROFILE_SETTINGS]: ProfileSettingsDialog,
    [DialogType.FRIEND_INVITE]: FriendInviteDialog,
    [DialogType.WAITING_PLAYER]: WaitingPlayerDialog,
    [DialogType.GAME_SURRENDER]: GameSurrenderDialog,
});

export const ASK_DIALOG_CLASSES = Object.freeze({
    [DialogType.ASK_GAME_INVITE]: AskGameInviteDialog,
    [DialogType.ASK_USER_INVITE]: AskUserInviteDialog,
});

export {
    DialogType,
    AskGameInviteDialog,
    AskUserInviteDialog,
    NotificationDialog,
    ProfileDialog,
    ProfileSettingsDialog,
    FriendInviteDialog,
    WaitingPlayerDialog,
    GameSurrenderDialog,
};
