import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { ActionButton, Icon } from "components";

import styles from "./notification-button.module.pcss";

export const NotificationButton = observer(
    ({ name = "notification", notification = null, controller = null, skeleton = false }) =>
        notification &&
        notification.is_visible && (
            <ActionButton
                name={name}
                controller={controller}
                size="l"
                pin="square-square"
                skeleton={skeleton}
            >
                <Icon
                    icon={notification.has_notify_for_viewing ? "bellActive" : "bell"}
                    size="s"
                    color="inherit"
                    className={clsx({
                        [styles.ring]: notification.has_notify_for_viewing,
                    })}
                />
            </ActionButton>
        ),
);
