import { makeAutoObservable } from "mobx";

import { NanoEvents } from "lib/nanoevents";

import { Controller, GraphMover, Group } from "models/controller/v2";
import { ControlType } from "models/enums";

import { TUTORIAL_CONTROL_SKIP, TUTORIAL_CONTROL_NEXT } from "models/tutorial.buttons";

export default class Tutorial {
    _app;
    _index;
    _path;
    _isFinished;
    _emitter;
    name;
    steps;

    constructor(app, { name, path = "", steps = [], activeIndex = 0 }) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this.name = name;
        this.steps = steps;
        this._index = Math.max(0, Math.min(activeIndex, steps.length - 1));
        this._path = path;
        this._isFinished = false;
        this._emitter = new NanoEvents();
        this.controller = new Controller(
            new Group("tutorial", {
                nodes: [
                    {
                        type: ControlType.BUTTON,
                        name: TUTORIAL_CONTROL_NEXT,
                        onClick: () => {
                            this.next();
                        },
                    },
                    {
                        type: ControlType.BUTTON,
                        name: TUTORIAL_CONTROL_SKIP,
                        onClick: () => {
                            this.skip();
                        },
                    },
                ],
                mover: new GraphMover({
                    [TUTORIAL_CONTROL_NEXT]: {
                        left: TUTORIAL_CONTROL_SKIP,
                        right: TUTORIAL_CONTROL_SKIP,
                    },
                    [TUTORIAL_CONTROL_SKIP]: {
                        left: TUTORIAL_CONTROL_NEXT,
                        right: TUTORIAL_CONTROL_NEXT,
                    },
                }),
            }),
            {
                onCancel: () => {
                    if (!this._isFinished) {
                        this.next();
                        return true;
                    }
                    return false;
                },
                hasKeyboard: () => !this._app.is_touch_mode,
            },
        );
    }

    get step() {
        return this.steps[this._index];
    }

    get assistantPath() {
        return `${this._path}/${this.step}`;
    }

    get isFinished() {
        return this._isFinished;
    }

    get _assistant() {
        return this._app.assistant;
    }

    on(name, func) {
        return this._emitter.on(name, func);
    }

    once(name, func) {
        return this._emitter.once(name, func);
    }

    hasPrev() {
        return this._index - 1 >= 0;
    }

    hasNext() {
        return this._index + 1 < this.steps.length;
    }

    prev() {
        if (this.hasPrev()) {
            this._index -= 1;
            this._assistant.transition(this.assistantPath);
        }
    }

    next() {
        if (this.hasNext()) {
            this._index += 1;
            this._assistant.transition(this.assistantPath);
        } else {
            this.apply();
        }
    }

    skip() {
        this.apply({ is_skipped: true });
    }

    apply({ isSkipped = false } = {}) {
        if (!this._isFinished) {
            this._assistant.fire_event(
                isSkipped ? "event_tutorial_skipped" : "event_tutorial_finished",
                this.name,
            );
            this._app.user
                .apply_tutorial(this.name)
                .then(() => this._emitter.emit("finished", isSkipped));
            this._isFinished = true;
        }
    }

    cancel() {
        if (!this._isFinished) {
            this._emitter.emit("canceled");
            this._isFinished = true;
        }
    }
}
