import { PieceColor } from "lib/chess/enums";

import { GameType } from "models/enums";
import {
    loadGameInviteState,
    loadUserRank,
    loadUserRelation,
    loadUserStatus,
} from "providers/server/types";

function loadUserObject({
    id,
    status,
    rating = 0,
    rank,
    nickname = null,
    avatar_preset_id: avatarPresetId = 1,
    relation = null,
    modified_at: modifiedAt = 0,
}) {
    return {
        id,
        status: loadUserStatus(status),
        rating,
        nickname,
        avatarPresetId,
        relation: loadUserRelation(relation),
        rank: loadUserRank(rank),
        modifiedAt,
    };
}

function loadRequestObject({
    id,
    state,
    game_id: gameId = null,
    owner_user_id: ownerUserId = null,
    enemy_user_id: enemyUserId = null,
    from_user: fromUser = null,
    to_user: toUser = null,
    modified_at: modifiedAt = 0,
}) {
    let from = null;
    if (fromUser) {
        from = loadUserObject(fromUser);
    } else if (ownerUserId) {
        from = { id: ownerUserId };
    }

    let to = null;
    if (toUser) {
        to = loadUserObject(toUser);
    } else if (enemyUserId) {
        to = { id: enemyUserId };
    }

    return {
        id,
        state: loadGameInviteState(state),
        gameId,
        fromUser: from,
        toUser: to,
        modifiedAt,
    };
}

export default class PlayerVsPlayerRequestAPI {
    constructor(server) {
        this.server = server;
    }

    async create({ color = null, gameType = GameType.WITH_PLAYER, enemyUserId = null } = {}) {
        const response = await this.server.post("/pvp/request/create", {
            color: {
                [null]: null,
                [PieceColor.WHITE]: "WHITE",
                [PieceColor.BLACK]: "BLACK",
            }[color],
            game_type: {
                [GameType.WITH_PLAYER]: "WITH_PLAYER",
                [GameType.WITH_PLAYER_RATING]: "WITH_PLAYER_RATING",
            }[gameType],
            enemy_user_id: enemyUserId,
        });
        return loadRequestObject(response.data || {});
    }

    async retrieve(requestId) {
        const response = await this.server.post("/pvp/request/retrieve", { request_id: requestId });
        return loadRequestObject(response.data || {});
    }

    async accept(requestId) {
        const response = await this.server.post("/pvp/request/accept", { request_id: requestId });
        return loadRequestObject(response.data || {});
    }

    async cancel(requestId) {
        const response = await this.server.post("/pvp/request/cancel", { request_id: requestId });
        return loadRequestObject(response.data || {});
    }

    async viewed(requestId) {
        const response = await this.server.post("/pvp/request/viewed", { request_id: requestId });
        return loadRequestObject(response.data || {});
    }
}
