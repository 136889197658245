import { UserRank, UserStatus } from "./enums";

export const rankToTitle = (rank, short = false) => {
    if (short) {
        return (
            {
                [UserRank.GRANDMASTER]: "Гроссмейстер",
                [UserRank.INTERNATIONAL_MASTER]: "Международный мастер",
                [UserRank.NATIONAL_MASTER]: "Национальный мастер",
                [UserRank.CANDIDATE_MASTER]: "Кандидат в мастера",
                [UserRank.FIRST_GRADE]: "1-ый разряд",
                [UserRank.SECOND_GRADE]: "2-ой разряд",
                [UserRank.THIRD_GRADE]: "3-ий разряд",
                [UserRank.FOURTH_GRADE]: "4-ый разряд",
                [UserRank.FIFTH_GRADE]: "5-ый разряд",
                [UserRank.NEWBIE]: "Новичок",
            }[rank] || "Новичок"
        );
    }
    return (
        {
            [UserRank.GRANDMASTER]: "Гроссмейстер",
            [UserRank.INTERNATIONAL_MASTER]: "Международный мастер",
            [UserRank.NATIONAL_MASTER]: "Национальный мастер",
            [UserRank.CANDIDATE_MASTER]: "Кандидат в мастера",
            [UserRank.FIRST_GRADE]: "Первый разряд",
            [UserRank.SECOND_GRADE]: "Второй разряд",
            [UserRank.THIRD_GRADE]: "Третий разряд",
            [UserRank.FOURTH_GRADE]: "Четвертый разряд",
            [UserRank.FIFTH_GRADE]: "Пятый разряд",
            [UserRank.NEWBIE]: "Новичок",
        }[rank] || "Новичок"
    );
};

export const userStatusToTitle = (status) =>
    ({
        [UserStatus.ONLINE]: "Онлайн",
        [UserStatus.OFFLINE]: "Оффлайн",
        [UserStatus.BUSY]: "В игре",
    }[status] || "Оффлайн");

// eslint-disable-next-line camelcase
export const rank_to_title = rankToTitle;

// eslint-disable-next-line camelcase
export const user_status_to_title = userStatusToTitle;
