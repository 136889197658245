import axios from "axios";
import { makeAutoObservable, runInAction, when } from "mobx";

import { LoadState } from "models/enums";

export class AudioBuffer {
    context;
    name;
    url;
    buffer;
    state;

    constructor(context, { name, url, buffer = null }) {
        makeAutoObservable(this);
        this.context = context;
        this.buffer = buffer;
        this.name = name;
        this.url = url;
        this.state = LoadState.INIT;
    }

    async load() {
        const asset_params = { asset: this.name, asset_type: "audio", url: this.url },
            start_at = new Date();
        try {
            if (this.buffer === null && this.state !== LoadState.LOADING) {
                this.state = LoadState.LOADING;

                const response = await axios.get(this.url, { responseType: "arraybuffer" });
                const buffer = await this.context.decodeAudioData(response.data);

                runInAction(() => {
                    this.buffer = buffer;
                    this.state = LoadState.SUCCESS;
                });
            }
        } catch (error) {
            runInAction(() => {
                this.state = LoadState.FAILURE;
            });
            console.error(error);
        }
    }

    async create_source() {
        if (this.state === LoadState.SUCCESS) {
            const source = this.context.createBufferSource();
            source.buffer = this.buffer;
            return source;
        } else {
            return new Promise((resolve, reject) => {
                if (this.state !== LoadState.LOADING) {
                    this.load();
                }

                // Необходимо среагировать на изменение состояния и отдать результат
                when(
                    () => this.state !== LoadState.LOADING,
                    () => {
                        if (this.state === LoadState.SUCCESS) {
                            const source = this.context.createBufferSource();
                            source.buffer = this.buffer;

                            resolve(source);
                        } else {
                            reject(`Buffer not loaded for "${this.name}"`);
                        }
                    },
                );
            });
        }
    }
}
