import { makeAutoObservable, runInAction } from "mobx";

class UserProfile {
    id;
    nickname;
    avatarPresetId;
    modifiedAt;
    _settings;

    constructor({ id, nickname = null, avatarPresetId = 1, settings = {}, modifiedAt = 0 }) {
        makeAutoObservable(this);
        this.id = id;
        this.nickname = nickname;
        this.avatarPresetId = avatarPresetId;
        this.modifiedAt = modifiedAt;
        this._settings = settings;
    }

    get modified_at() {
        return this.modifiedAt;
    }

    get avatar_preset_id() {
        return this.avatarPresetId;
    }

    get settings() {
        return {
            assistant_channel: {
                usually: true,
                tutorial: true,
                comments: true,
                help: true,
                system: true,
                nomatch: true,
                timelimit: true,
                ...(this._settings.assistant_channel || {}),
            },
        };
    }

    update({ id, nickname, avatarPresetId, settings, modifiedAt }) {
        if (this.modifiedAt < modifiedAt || this.id === null) {
            this.id = id;
            this.nickname = nickname;
            this._settings = settings || {};
            this.avatarPresetId = avatarPresetId;
            this.modifiedAt = modifiedAt;
        }
    }
}

export default class UserStore {
    _app;
    auth_token;
    tutorial;
    profile;
    scene_auto_help_counter;
    _is_tester;

    constructor(app) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this.auth_token = null;
        this._is_tester = false;
        this.tutorial = {};
        this.profile = new UserProfile({ id: null, settings: {} });
        this.scene_auto_help_counter = {};
    }

    get is_tester() {
        return this._app.config.is_tester === true || this._is_tester === true;
    }

    update_info(isTester = null) {
        if (isTester !== null) {
            this._is_tester = isTester;
        }
    }

    update_tutorial(tutorial) {
        this.tutorial = tutorial;
    }

    update_auto_help_counter(autoHelpCounter = {}) {
        this.scene_auto_help_counter = autoHelpCounter;
    }

    retrieve_scene_auto_help(name) {
        return this.scene_auto_help_counter[name] || 0;
    }

    has_scene_auto_help(name) {
        return this.retrieve_scene_auto_help(name) < 3;
    }

    async refresh_auth_token() {
        const token = await this._app.assistant.retrieve_auth_token({ refresh: true });
        runInAction(() => {
            this.auth_token = token;
        });
    }

    async apply_tutorial(name) {
        await this._app.assistant.tutorial_apply(name);
        runInAction(() => {
            this.tutorial[name] = true;
        });
    }

    refreshProfile() {
        this._app.server.profile
            .retrieve()
            .catch(() => this._app.server.profile.retrieve())
            .then(
                ({
                    id,
                    nickname,
                    avatar_preset_id: avatarPresetId,
                    settings,
                    modified_at: modifiedAt,
                }) =>
                    this.profile.update({
                        id,
                        nickname,
                        avatarPresetId,
                        settings,
                        modifiedAt,
                    }),
            );
    }

    updateProfile(
        {
            nickname: newNickname = null,
            avatar_preset_id: newAvatarPresetId = null,
            settings: newSettings = null,
        },
        success = null,
        fail = null,
    ) {
        const params = {};
        if (newNickname !== null) {
            params.nickname = newNickname;
        }
        if (newAvatarPresetId !== null) {
            params.avatar_preset_id = newAvatarPresetId;
        }
        if (newSettings !== null) {
            params.settings = newSettings;
        }

        this._app.server.profile
            .update(params)
            .catch((error) => {
                this._app.server.profile.update(params);
                if (fail) {
                    fail(error);
                }
            })
            .then(
                ({
                    id,
                    nickname,
                    avatar_preset_id: avatarPresetId,
                    settings,
                    modified_at: modifiedAt,
                    $validation: _validation,
                }) => {
                    this.profile.update({
                        id,
                        nickname,
                        avatarPresetId,
                        settings,
                        modifiedAt,
                    });

                    if (success) {
                        success(_validation || {});
                    }
                },
            );
    }

    increase_scene_auto_help(name) {
        const count = this.retrieve_scene_auto_help(name);
        this._app.assistant
            .update_scene_auto_help_counter(name, count + 1)
            .catch((error) => console.error(error));
    }
}
