import clsx from "clsx";
import styled, { css } from "styled-components";
import React from "react";
import { observer } from "mobx-react-lite";

import { PieceColor } from "lib/chess/enums";

import { Body2, Footnote1, UserAvatar, mediaQuery } from "components";
import { IconSupervisorAccount } from "components/icons";
import { GameMode, GameType } from "models/enums";

import styles from "./card-game.module.pcss";

const CardSizes = {
    sberBox: css`
        height: 8.75rem;
    `,
    sberPortal: css`
        height: 7.25rem;
    `,
    mobile: css`
        height: 8rem;
    `,
};

const playerName = (game, color) => {
    // const side = color === PieceColor.BLACK ? game.black : game.white;
    // if (side.is_self) {
    //     return "Вы";
    // } else if (side.is_assistant) {
    //     return "Ассистент";
    // } else if (side.user === null) {
    //     return "Гость";
    // } else if (side.user.nickname) {
    //     return side.user.nickname;
    // } else if (side.user.id !== null) {
    //     return `ID ${side.user.id}`;
    // }
    // return "Игрок";
    if (game.color === color) {
        return "Вы";
    }
    return (
        {
            [GameMode.WITH_ASSISTANT]: "Ассистент",
            [GameMode.WITH_PLAYER]: "Игрок",
            [GameMode.WITH_PLAYER_RATING]: "Игрок",
            [GameMode.HOT_SEAT]: "Гость",
        }[game.mode] || "Игрок"
    );
};

const Caption = styled(Footnote1)`
    color: ${({ color }) => color || "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))"};
`;

const CardRoot = styled.button`
    ${({ size, theme }) =>
        size
            ? CardSizes[size]
            : css`
                  ${mediaQuery("S", theme.deviceScale)(CardSizes.mobile)}
                  ${mediaQuery("M", theme.deviceScale)(CardSizes.sberPortal)}
                  ${mediaQuery("L", theme.deviceScale)(CardSizes.sberBox)}
                  ${mediaQuery("XL", theme.deviceScale)(CardSizes.sberBox)}
              `}
`;

const EnemyIcon = observer(({ game }) => {
    if (game.game_type === GameType.HOT_SEAT) {
        return (
            <div className={clsx(styles.enemyIcon)}>
                <IconSupervisorAccount color="var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))" />
            </div>
        );
    }

    if (game.game_type === GameType.WITH_ASSISTANT) {
        return null;
    }

    let user = null;
    if (game.color === PieceColor.WHITE) {
        user = game.black.user;
    } else if (game.color === PieceColor.BLACK) {
        user = game.white.user;
    }

    if (!user) {
        return null;
    }

    return (
        <div className={clsx(styles.enemyIcon)}>
            <UserAvatar preset={user.avatar_preset_id} />
        </div>
    );
});

export default observer(({ name, game, controller }) => {
    const focused = controller && controller.focusName === name;
    return (
        <CardRoot
            className={clsx(styles.view, { [styles.outlined]: focused })}
            onClick={() => controller && controller.click(name)}
        >
            <div className={clsx(styles.matchInfo)}>
                <Body2>Партия {game.id}</Body2>
                <Caption>Ход {Math.ceil((game.history.length + 1) / 2)}</Caption>
                <EnemyIcon game={game} />
            </div>

            <div className={clsx(styles.players)}>
                <div className={clsx(styles.item)}>
                    <Caption>{playerName(game, PieceColor.WHITE)}</Caption>
                    <div className={clsx(styles.dots)} />
                    <Footnote1>{game.score.white}</Footnote1>
                </div>

                <div className={clsx(styles.item)}>
                    <Caption>{playerName(game, PieceColor.BLACK)}</Caption>
                    <div className={clsx(styles.dots)} />
                    <Footnote1>{game.score.black}</Footnote1>
                </div>
            </div>
        </CardRoot>
    );
});
