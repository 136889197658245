import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { RectSkeleton, Header, Container, Row, Col, TextField, Button } from "components";

import styles from "./scene.module.pcss";

export default observer(({ scene, skeleton = false }) => {
    const isLoading = skeleton || scene.isLoading;

    return (
        <Container style={{ width: "100vw", height: "100vh", display: "flex" }}>
            <Header title="Поиск друзей" controller={scene.controller} skeleton={isLoading} />

            <div className={clsx(styles.content, styles.middle)}>
                <Row>
                    <Col
                        sizeXL={5}
                        offsetXL={3.5}
                        sizeL={5}
                        offsetL={1.5}
                        sizeM={4}
                        offsetM={1}
                        sizeS={4}
                        offsetS={0}
                    >
                        {isLoading ? (
                            <RectSkeleton width="100%" height="3.5rem" roundness={16} />
                        ) : (
                            <TextField
                                field={scene.nickname}
                                name="nickname"
                                controller={scene.controller}
                                label="Введите никнейм или ID"
                                minLength={1}
                                maxLength={16}
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col
                        sizeXL={3}
                        offsetXL={4.5}
                        sizeL={2}
                        offsetL={3}
                        sizeM={2}
                        offsetM={2}
                        sizeS={4}
                        offsetS={0}
                    >
                        {isLoading ? (
                            <RectSkeleton width="100%" height="3.5rem" roundness={16} />
                        ) : (
                            <Button
                                name="search"
                                view="primary"
                                controller={scene.controller}
                                text="Искать"
                                stretch
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </Container>
    );
});
