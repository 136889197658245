import { makeAutoObservable } from "mobx";

import { uuid4 } from "lib/helpers";
import { SceneType } from "models/enums";

const SCENE_TYPE_TO_PATH = {
    [SceneType.HOME]: "/scene/home",
    [SceneType.GAME]: "/scene/game",
    [SceneType.CONTINUE]: "/scene/continue",
    [SceneType.TUTORIAL]: "/scene/tutorial",
    [SceneType.CHOICE_MODE]: "/scene/choice_mode",
    [SceneType.CHOICE_SPEED]: "/scene/choice_speed",
    [SceneType.CHOICE_COLOR]: "/scene/choice_color",
    [SceneType.CHOICE_BOT]: "/scene/choice_bot",
    [SceneType.CHOICE_PLAYER]: "/scene/choice_player",
    [SceneType.CHOICE_PVP_MODE]: "/scene/choice_pvp_mode",
    [SceneType.CHOICE_FRIEND]: "/scene/choice_friend",
    [SceneType.TOP_OF_RANKS]: "/scene/top_of_ranks",
    [SceneType.FRIENDS]: "/scene/friends",
    [SceneType.USERS_BLOCKED]: "/scene/users_blocked",
    [SceneType.USERS_SEARCH]: "/scene/users_search",
    [SceneType.USERS_SEARCH_RESULT]: "/scene/users_search_result",
    [SceneType.USERS_INVITED]: "/scene/users_invited",
    [SceneType.PROFILE_SETTINGS_EDITOR]: "/scene/profile_settings_editor",
};

export default class Window {
    id;
    type;
    data;
    _prefixName;
    _emitter;

    constructor({ id = null, type, data, prefixName = "window-", emitter = null }) {
        makeAutoObservable(this);
        this.id = id || uuid4();
        this.type = type;
        this.data = data;
        this._prefixName = prefixName;
        this._emitter = emitter;
    }

    get name() {
        if (this.data && this.data.name) {
            return this.data.name;
        }
        return `${this._prefixName}${this.id}`;
    }

    get visible() {
        if (this.data && this.data.visible !== null && this.data.visible !== undefined) {
            if (this.data.visible instanceof Function) {
                return !!this.data.visible();
            }
            return !!this.data.visible;
        }
        return true;
    }

    get assistantPath() {
        if (this.data && typeof this.data.assistantPath === "string") {
            return this.data.assistantPath;
        }
        return SCENE_TYPE_TO_PATH[this.type];
    }

    get assistantState() {
        if (this.data) {
            return this.data.assistantState || {};
        }
        return {};
    }

    get controller() {
        if (this.data) {
            return this.data.controller || null;
        }
        return null;
    }

    get isLoading() {
        return !!(this.data && this.data.isLoading);
    }

    on(name, handler) {
        return this._emitter.on(name, handler);
    }

    once(name, handler) {
        return this._emitter.once(name, handler);
    }

    preload() {
        if (this.data.onPreload) {
            try {
                this.data.onPreload();
            } catch (exception) {
                console.error(exception);
            }
        } else if (this.data.on_preload) {
            try {
                this.data.on_preload();
            } catch (exception) {
                console.error(exception);
            }
        }
    }

    activate() {
        if (this.data.onActivate) {
            try {
                this.data.onActivate();
            } catch (exception) {
                console.error(exception);
            }
        } else if (this.data.on_activate) {
            try {
                this.data.on_activate();
            } catch (exception) {
                console.error(exception);
            }
        }
    }

    deactivate() {
        if (this.data.onDeactivate) {
            try {
                this.data.onDeactivate();
            } catch (exception) {
                console.error(exception);
            }
        } else if (this.data.on_deactivate) {
            try {
                this.data.on_deactivate();
            } catch (exception) {
                console.error(exception);
            }
        }
    }

    destroy() {
        if (this.data.onDestroy) {
            try {
                this.data.onDestroy();
            } catch (exception) {
                console.error(exception);
            }
        } else if (this.data.on_destroy) {
            try {
                this.data.on_destroy();
            } catch (exception) {
                console.error(exception);
            }
        }
    }
}
