const x_map = {
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    1: "a",
    2: "b",
    3: "c",
    4: "d",
    5: "e",
    6: "f",
    7: "g",
    8: "h",
};

export const transform = Object.freeze({
    from_human: (value) => ({
        x: x_map[value.charAt(0)],
        y: Math.max(Math.min(Number.parseInt(value.charAt(1)), 8), 1),
    }),
    to_human: ({ x, y }) =>
        `${x_map[Math.max(Math.min(Math.trunc(x), 8), 1)]}${Math.max(
            Math.min(Math.trunc(y), 8),
            1,
        )}`,
    from_index: (index) => {
        index = Math.max(Math.min(index, 63), 0);
        return { x: (index % 8) + 1, y: Math.trunc(index / 8) + 1 };
    },
    to_index: ({ x, y }) =>
        (Math.max(Math.min(Math.trunc(y), 8), 1) - 1) * 8 +
        Math.max(Math.min(Math.trunc(x), 8), 1) -
        1,
});
