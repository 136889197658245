import React from "react";
import { createGlobalStyle } from "styled-components";
import { observer } from "mobx-react-lite";

import { mobile, sberBox, sberPortal } from "@sberdevices/plasma-tokens/typo";

import { DeviceType } from "models/enums";

const Mobile = createGlobalStyle(mobile);
const SberBox = createGlobalStyle(sberBox);
const SberPortal = createGlobalStyle(sberPortal);

export const PlasmaTypo = observer(({ device }) => {
    switch (device) {
        case DeviceType.PORTAL:
            return <SberPortal />;
        case DeviceType.MOBILE:
            return <Mobile />;
    }
    return <SberBox />;
});
