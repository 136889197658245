import { makeAutoObservable } from "mobx";

import { ControlType } from "models/enums";

export default class SwitchGroup {
    _name;
    _nodes;
    _switcher;

    constructor(name, { nodes = [], switcher = null } = {}) {
        makeAutoObservable(this);
        this._name = name;
        this._nodes = nodes;
        this._switcher = switcher;
    }

    // eslint-disable-next-line class-methods-use-this
    get type() {
        return ControlType.GROUP;
    }

    get name() {
        return this._name;
    }

    get focusedElement() {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.focusedElement;
            }
            return this.current;
        }
        return undefined;
    }

    get children() {
        if (this._nodes instanceof Function) {
            return this._nodes() || [];
        }
        return this._nodes || [];
    }

    get current() {
        if (this._switcher instanceof Function) {
            return this._switcher(this.children);
        }
        return undefined;
    }

    click(name) {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                if (this.current.click(name)) {
                    return true;
                }
            } else if (this.current.onClick instanceof Function) {
                this.current.onClick();
                return true;
            }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of this.children) {
            if (
                child &&
                child !== this.current &&
                child.type === ControlType.GROUP &&
                child.click(name)
            ) {
                return true;
            }
        }

        const child = this.children.find((item) => item && item.name === name);
        if (child && child.onClick instanceof Function) {
            child.onClick();
            return true;
        }

        return false;
    }

    focus(name) {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.focus(name);
            }

            if (this.current.name === name) {
                return true;
            }
        }
        return false;
    }

    find(predicate) {
        if (this.current && this.current.type === ControlType.GROUP) {
            const node = this.current.find(predicate);
            if (node !== undefined) {
                return node;
            }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const child of this.children) {
            if (child && child !== this.current && child.type === ControlType.GROUP) {
                const node = child.find(predicate);
                if (node !== undefined) {
                    return node;
                }
            }
        }

        return this.children.find(predicate);
    }

    move(direction) {
        if (this.current && this.current.type === ControlType.GROUP) {
            return this.current.move(direction);
        }
        return false;
    }

    cancel() {
        if (this.current) {
            if (this.current.type === ControlType.GROUP) {
                return this.current.cancel();
            }

            if (this.current.onCancel instanceof Function) {
                return this.current.onCancel();
            }
        }
        return false;
    }
}
