import clsx from "clsx";
import React from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";

import { PieceColor } from "lib/chess/enums";
import { time_to_human } from "lib/helpers";

import { AssistantType } from "models/enums";

import {
    Caption,
    Footnote2,
    Headline3,
    TextBoxTitle,
    UserAvatar,
    gridSizes,
    mediaQuery,
    Badge,
} from "components";

import PieceCaptured from "./piece-captured";

import styles from "./left-panel.module.pcss";

const avatarSizes = {
    S: "sizeS",
    M: "sizeM",
    L: "sizeL",
    XL: "sizeXL",
};

const Avatar = styled.div`
    position: relative;

    ${({ theme, size = "3rem", ...props }) =>
        gridSizes.map((breakpoint) => {
            const bpSize = avatarSizes[breakpoint];
            const rootSize = props[bpSize] || size;
            return mediaQuery(
                breakpoint,
                theme.deviceScale,
            )(
                css`
                    width: ${rootSize};
                    min-width: ${rootSize};
                    height: ${rootSize};
                    min-height: ${rootSize};
                `,
            );
        })}

    & > *:last-child {
        position: absolute;
        display: flex;

        width: 30%;
        height: 30%;
        right: 0;
        bottom: 0;

        border-radius: 50%;
        
        ${({ color }) => {
            if (color === PieceColor.WHITE) {
                return css`
                    background-color: rgba(255, 255, 255, 0.96);
                    border: 0.0625rem solid #080808;
                `;
            } else if (color === PieceColor.BLACK) {
                return css`
                    background-color: #080808;
                    border: 0.0625rem solid rgba(255, 255, 255, 0.96);
                `;
            }
            return css`
                background-color: var(--plasma-colors-background-tertiary);
            `;
        }}
`;

const PanelPieces = ({ color, captured = [] }) => (
    <div
        className={clsx(styles.pieces, {
            [styles.white]: color === PieceColor.WHITE,
            [styles.black]: color === PieceColor.BLACK,
        })}
    >
        {captured.map(
            ({ piece, count }, index) =>
                count > 0 && (
                    <div key={piece} className={clsx(styles.item)}>
                        <PieceCaptured color={color} piece={piece} />
                        <div className={clsx(styles.badge, { [styles.hidden]: count < 2 })}>
                            <Badge text={count} view="primary" size="s" circled />
                        </div>
                    </div>
                ),
        )}
    </div>
);

const SidePanelUser = styled.div`
    & > *:last-child {
        ${({ theme }) =>
            css`
                ${mediaQuery(
                    "S",
                    theme.deviceScale,
                )(
                    css`
                        margin-top: 0.25rem;
                    `,
                )}
                ${mediaQuery(
                    "M",
                    theme.deviceScale,
                )(
                    css`
                        margin-top: 0.25rem;
                    `,
                )}
                ${mediaQuery(
                    "L",
                    theme.deviceScale,
                )(
                    css`
                        margin-top: 0.5rem;
                    `,
                )}
                ${mediaQuery(
                    "XL",
                    theme.deviceScale,
                )(
                    css`
                        margin-top: 0.5rem;
                    `,
                )}
            `}
    }
`;

export const SidePanel = observer(
    ({ color, side, color_captured, assistant, waiting = "auto" }) => {
        const is_wait_user = waiting === "auto" ? !side.is_active : !!waiting;
        return (
            <div style={{ position: "relative" }}>
                <SidePanelUser
                    className={clsx(styles.side_panel, {
                        [styles.waiting]: side.waiting,
                        [styles.inactive]: is_wait_user,
                    })}
                >
                    <div className={clsx(styles.userInfo, { [styles.waiting]: !side.waiting })}>
                        <Avatar
                            sizeXL="2.25rem"
                            sizeL="1.75rem"
                            sizeM="1.5rem"
                            sizeS="1.25rem"
                            color={color}
                        >
                            <UserAvatar
                                preset={side.avatar_preset}
                                is_assistant={side.is_assistant}
                            />
                            <div />
                        </Avatar>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                minWidth: 0,
                                marginLeft: "0.75rem",
                                flexGrow: 1,
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "row", minWidth: 0 }}>
                                <Caption className={clsx(styles.ellipsis, styles.caption)}>
                                    {side.is_assistant
                                        ? {
                                              [AssistantType.SBER]: "Сбер",
                                              [AssistantType.ATHENA]: "Афина",
                                              [AssistantType.JOY]: "Джой",
                                          }[(assistant && assistant.name) || null] || "Ассистент"
                                        : `${side.nickname}`}
                                </Caption>
                                <Caption
                                    style={{ marginLeft: "0.25rem" }}
                                    className={clsx(styles.caption)}
                                >
                                    {side.is_assistant ? "" : `(${side.rating})`}
                                </Caption>
                            </div>
                            <Footnote2>Очки: {side.score}</Footnote2>
                        </div>

                        <div
                            className={clsx(styles.waiting_icon, {
                                [styles.hidden]: !side.waiting,
                            })}
                        />
                    </div>
                    <PanelPieces color={color_captured} captured={side.captured_stat} />
                </SidePanelUser>
                <div
                    className={clsx(styles.side_panel_without_user, {
                        [styles.inactive]: !is_wait_user,
                    })}
                >
                    <TextBoxTitle>Ожидается подключение</TextBoxTitle>
                </div>
            </div>
        );
    },
);

export const LeftPanel = observer(
    ({ board, assistant, use_dialog_mode: useDialogMode = false }) => {
        const useWaiting = board.finished === null || board.finished === undefined ? "auto" : false;
        const userSide = board.side || PieceColor.WHITE;
        let firstState = {
            color: PieceColor.BLACK,
            color_captured: PieceColor.WHITE,
            state: board.black,
        };
        let secondState = {
            color: PieceColor.WHITE,
            color_captured: PieceColor.BLACK,
            state: board.white,
        };

        if (userSide === PieceColor.BLACK) {
            firstState = {
                color: PieceColor.WHITE,
                color_captured: PieceColor.BLACK,
                state: board.white,
            };
            secondState = {
                color: PieceColor.BLACK,
                color_captured: PieceColor.WHITE,
                state: board.black,
            };
        }
        // let firstState = {
        //     color: PieceColor.BLACK,
        //     color_captured: PieceColor.WHITE,
        //     state: board.black,
        // };
        // let secondState = {
        //     color: PieceColor.WHITE,
        //     color_captured: PieceColor.BLACK,
        //     state: board.white,
        // };
        // if (board.flipped) {
        //     firstState = {
        //         color: PieceColor.WHITE,
        //         color_captured: PieceColor.BLACK,
        //         state: board.white,
        //     };
        //     secondState = {
        //         color: PieceColor.BLACK,
        //         color_captured: PieceColor.WHITE,
        //         state: board.black,
        //     };
        // }

        return (
            <div className={clsx(styles.view, { [styles.dialog_mode]: useDialogMode })}>
                <SidePanel
                    color={firstState.color}
                    side={firstState.state}
                    color_captured={firstState.color_captured}
                    assistant={assistant}
                    waiting={useWaiting}
                />

                <div className={clsx(styles.watch)}>
                    <Headline3>{time_to_human(board.common_time)}</Headline3>
                </div>

                <SidePanel
                    color={secondState.color}
                    side={secondState.state}
                    color_captured={secondState.color_captured}
                    assistant={assistant}
                    waiting={useWaiting}
                />
            </div>
        );
    },
);
