export const LoadState = Object.freeze({
    INIT: "init",
    LOADING: "loading",
    SUCCESS: "success",
    FAILURE: "fail",
    get FAIL() {
        return this.FAILURE;
    },
});

export const SceneType = Object.freeze({
    INIT: "init",
    HOME: "home",
    CONTINUE: "continue",
    TUTORIAL: "tutorial",
    GAME: "game",
    CHOICE_MODE: "choice-mode",
    CHOICE_COLOR: "choice-color",
    CHOICE_BOT: "choice-bot",
    CHOICE_SPEED: "choice-speed",
    CHOICE_PLAYER: "choice-player",
    CHOICE_PVP_MODE: "choice-pvp-mode",
    CHOICE_FRIEND: "choice-friend",
    TECH_INFO: "tech-info",
    TOP_OF_RANKS: "top-of-ranks",
    FRIENDS: "friends",
    USERS_BLOCKED: "users-blocked",
    USERS_SEARCH: "users-search",
    USERS_SEARCH_RESULT: "users-search-result",
    USERS_INVITED: "users-invited",
    PROFILE_SETTINGS_EDITOR: "profile-settings-editor",
});

export const DialogType = Object.freeze({
    NOTIFICATION: "notification",
    PROFILE: "profile",
    PROFILE_SETTINGS: "profile-settings",
    FRIEND_INVITE: "friend-invite",
    WAITING_PLAYER: "waiting-player",
    GAME_SURRENDER: "game-surrender",

    ASK_GAME_INVITE: "ask-game-invite",
    ASK_USER_INVITE: "ask-user-invite",
});

export const GameResult = Object.freeze({
    WIN: "win", // deprecated
    LOOSE: "loose", // deprecated

    NONE: "NONE",
    WHITE_WON: "WHITE_WON",
    BLACK_WON: "BLACK_WON",
    DRAW: "DRAW",
    WHITE_SURRENDERED: "WHITE_SURRENDERED",
    BLACK_SURRENDERED: "BLACK_SURRENDERED",
});

export const GameType = Object.freeze({
    HOT_SEAT: "hot-seat",
    WITH_ASSISTANT: "with-assistant",
    WITH_PLAYER: "with-player",
    WITH_PLAYER_RATING: "with-player-rating",

    get WITH_BOT() {
        console.warn("Deprecated for using WITH_BOT, you must use WITH_ASSISTANT");
        return this.WITH_ASSISTANT;
    },
});
export const GameMode = GameType;

export const ControlType = Object.freeze({
    NONE: "none",
    PIECE: "piece",
    SQUARE: "square",
    BUTTON: "button",
    INPUT: "input",
    TAB: "tab",
    HISTORY_ROUND: "history_round",
    VIRTUAL: "virtual",
    GROUP: "group",
    ITEM: "item",
});

export const AssistantType = Object.freeze({
    SBER: "sber",
    ATHENA: "eva",
    JOY: "joy",

    get EVA() {
        console.warn("Deprecated using AssistantType.EVA. You should use AssistantType.ATHENA");
        return this.ATHENA;
    },
});

export const DeviceType = Object.freeze({
    PORTAL: "portal",
    BOX: "box",
    MOBILE: "mobile",
});

export const TutorialType = Object.freeze({
    START_NEW_GAME: "start_new_game",
    CHOICE_GAME_MODE: "choice_game_mode",
    CHOICE_COLOR: "choice_color",
    CHOICE_BOT: "choice_bot",
    GAME_HOT_SEAT_BASIS: "game_hot_seat_basis",
    GAME_WITH_BOT_BASIS: "game_with_bot_basis",
    CONTINUE_BASIS: "continue_basis",
    TOP_OF_RANKS_BASIS: "top_of_ranks_basis",
});

export const AnalyticsEventType = Object.freeze({
    CREATE_GAME: "create_game",
    OPEN_GAME: "open_game",
    CLOSE_GAME: "close_game",
    CHANGE_SCENE: "change_scene",
    CLOSE_APP: "close_app",
});

export const UserRank = Object.freeze({
    GRANDMASTER: "grandmaster",
    INTERNATIONAL_MASTER: "international-master",
    NATIONAL_MASTER: "national-master",
    CANDIDATE_MASTER: "candidate-master",
    FIRST_GRADE: "first_grade",
    SECOND_GRADE: "second_grade",
    THIRD_GRADE: "third_grade",
    FOURTH_GRADE: "fourth_grade",
    FIFTH_GRADE: "fifth_grade",
    NEWBIE: "newbie",
});

export const UserRelation = Object.freeze({
    NONE: "none",
    FRIEND: "friend",
    BLOCKED: "blocked",
});

export const InviteType = Object.freeze({
    INCOMING: "incoming",
    OUTCOMING: "outcoming",
});

export const InviteStatus = Object.freeze({
    INVITED: "invited",
    IGNORED: "ignored",
    CANCELED: "canceled",
    BLOCKED: "blocked",
    ACCEPTED: "accepted",
});

export const GameInviteState = Object.freeze({
    NONE: "none",
    WAITING: "waiting",
    AGREEMENT: "agreement",
    FAILED: "failed",
    CANCELED: "canceled",
    APPROVED: "approved",
});

export const UserStatus = Object.freeze({
    ONLINE: "online",
    OFFLINE: "offline",
    BUSY: "busy",
});
