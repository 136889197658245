import { makeAutoObservable, runInAction } from "mobx";

import { Controller, Group, GraphMover, CarouselGroup, OneOfGroup } from "models/controller/v2";
import { ControlType, DialogType, LoadState, SceneType, TutorialType } from "models/enums";

export default class ContinueScene {
    _app;
    _emitter;
    _games;
    _isActive;
    controller;
    state;
    games;

    constructor(app, _, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this.state = LoadState.INIT;
        this._isActive = false;
        this._games = [];
        this.games = new CarouselGroup("games", {
            nodes: () =>
                this._games.map((game) => ({
                    type: ControlType.ITEM,
                    name: `game:id:${game.id}`,
                    meta: game,
                    onClick: () => {
                        const gameId = game.id;
                        if (gameId && this.tutorial) {
                            this._app.assistant.tutorial_apply(this.tutorial);
                        }
                        this._app.windows.scenes.change(SceneType.GAME, { game_id: gameId });
                        this.close();
                    },
                })),
            direction: "column",
            basis: 2,
        });
        this.controller = new Controller(
            new Group("scene", {
                nodes: [
                    new OneOfGroup("content", {
                        nodes: [
                            this.games,
                            {
                                type: ControlType.BUTTON,
                                name: "create",
                                onClick: () => {
                                    this._app.game.options.reset();
                                    this._app.windows.scenes.change(SceneType.CHOICE_MODE);
                                    this._app.assistant.fire_event("new_game");
                                    this.close();
                                },
                            },
                            {
                                type: ControlType.BUTTON,
                                name: "reload",
                                onClick: () => {
                                    this._reload();
                                },
                            },
                        ],
                        switcher: (nodes) => {
                            const { type, button = {} } = this.content;

                            let { name } = this.games;
                            if (type === "info") {
                                name = button.name;
                            }

                            return nodes.find((item) => item && item.name === name);
                        },
                    }),
                    {
                        type: ControlType.BUTTON,
                        name: "notification",
                        onClick: () => {
                            this._app.windows.dialogs.open(DialogType.NOTIFICATION);
                        },
                    },
                ],
                mover: new GraphMover({
                    notification: { down: "content" },
                    content: { up: "notification" },
                }),
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasSceneKeyboard(),
            },
        );
        this.tutorial = null;
    }

    get isLoading() {
        return this.state === LoadState.LOADING || !this._isActive;
    }

    get content() {
        if (this.isLoading) {
            return { type: "skeleton" };
        }

        if (this.state === LoadState.FAILURE) {
            return {
                type: "info",
                title: "Что-то пошло не так",
                subTitle: "повторите попытку позже",
                button: { name: "reload", text: "Обновить" },
            };
        }

        if (this._games.length === 0) {
            return {
                type: "info",
                title: "Сохранённые игры отсутствуют",
                subTitle:
                    "когда появятся незавершенные матчи, их карточки отобразятся на этой странице",
                button: { name: "create", text: "Новая игра" },
            };
        }

        return { type: "carousel" };
    }

    close() {
        this._emitter.emit("close", this);
    }

    _reload() {
        if (this.state !== LoadState.LOADING) {
            this.state = LoadState.LOADING;
            this._app.server.game
                .list()
                .then((games) =>
                    runInAction(() => {
                        this.state = LoadState.SUCCESS;
                        this._games.replace(
                            // (games || []).filter((game) => game.game_type === GameType.WITH_PLAYER),
                            // (games || []).filter((game) => game.game_over),
                            // (games || []).filter((game) => !game.game_over),
                            games || [],
                        );

                        if (this._games.length > 0 && !this._app.user.tutorial.continue_basis) {
                            this.tutorial = TutorialType.CONTINUE_BASIS;
                            this._app.assistant.fire_event("event_help");
                        }
                    }),
                )
                .catch(() =>
                    runInAction(() => {
                        this.state = LoadState.FAIL;
                    }),
                );
        }
    }

    onPreload() {
        this._reload();
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
    }

    // onDeactivate() {}
    // onDestroy() {}
}
