import { makeAutoObservable } from "mobx";

import { PieceColor } from "lib/chess/enums";

import { Controller, Group, SimpleElement, LinearMover } from "models/controller/v2";
import { ControlType, DialogType, GameMode, SceneType, TutorialType } from "models/enums";

const TUTORIAL_CHOICE_STEP = "choice";
const TUTORIAL_CHOICE_PATH = "/tutorial/choice_color";

const TUTORIAL_START_STEP = "start";
const TUTORIAL_START_PATH = "/tutorial/choice_color/play";

export default class ChoiceColorScene {
    _app;
    _scenes;
    _game;
    _first_activate;
    _isActive;
    tutorial;
    tutorialStep;
    controller;

    constructor(scenes, app, game) {
        makeAutoObservable(this, { _app: false, _scenes: false, _game: false });
        this._app = app;
        this._scenes = scenes;
        this._game = game;
        this._first_activate = true;
        this._isActive = false;
        this.tutorial = null;
        this.tutorialStep = null;
        this.controller = new Controller(
            new Group("root", {
                nodes: [
                    new SimpleElement("notification", {
                        type: ControlType.BUTTON,
                        disabled: () => !!this.tutorial,
                        onClick: (self) => {
                            if (!self.disabled) {
                                this._app.windows.open_dialog(DialogType.NOTIFICATION);
                            }
                        },
                    }),
                    new Group("colors", {
                        nodes: [
                            new SimpleElement("white", {
                                type: ControlType.BUTTON,
                                disabled: () =>
                                    this.tutorial && this.tutorialStep !== TUTORIAL_CHOICE_STEP,
                                onClick: (self) => {
                                    if (!self.disabled) {
                                        this._set_color(PieceColor.WHITE);
                                    }
                                },
                            }),
                            new SimpleElement("random", {
                                type: ControlType.BUTTON,
                                disabled: () =>
                                    (this.tutorial && this.tutorialStep !== TUTORIAL_CHOICE_STEP) ||
                                    this._app.game.options.color !== null,
                                onClick: (self) => {
                                    if (!self.disabled) {
                                        this._set_color();
                                    }
                                },
                            }),
                            new SimpleElement("black", {
                                type: ControlType.BUTTON,
                                disabled: () =>
                                    this.tutorial && this.tutorialStep !== TUTORIAL_CHOICE_STEP,
                                onClick: (self) => {
                                    if (!self.disabled) {
                                        this._set_color(PieceColor.BLACK);
                                    }
                                },
                            }),
                        ],
                        mover: new LinearMover({ direction: "horizontal", looped: true }),
                        disabled: () => this.tutorial && this.tutorialStep !== TUTORIAL_CHOICE_STEP,
                    }),
                    new SimpleElement("play", {
                        type: ControlType.BUTTON,
                        disabled: () => this.tutorial && this.tutorialStep !== TUTORIAL_START_STEP,
                        onClick: (self) => {
                            if (!self.disabled) {
                                this.play();
                            }
                        },
                    }),
                ],
                mover: new LinearMover({ direction: "vertical", looped: true }),
                onCancel: () => {
                    if (this.tutorial && this.tutorialStep !== TUTORIAL_CHOICE_STEP) {
                        this.tutorialStep = TUTORIAL_CHOICE_STEP;
                        this.controller.focus("random");
                        this._game.options.set_color(null);
                        this._app.assistant.transition(TUTORIAL_CHOICE_PATH);
                        return true;
                    }
                    return false;
                },
            }),
            {
                onCancel: () => {
                    this.back();
                },
                hasKeyboard: () => this._app.windows.hasSceneKeyboard(),
            },
        );
    }

    get isLoading() {
        return !this._isActive;
    }

    get color() {
        return this._game.options.color;
    }

    play() {
        switch (this._game.options.mode) {
            case GameMode.WITH_PLAYER:
            case GameMode.WITH_PLAYER_RATING:
                this._app.windows.dialogs.open(DialogType.WAITING_PLAYER, {
                    gameType: this._game.options.mode,
                    color: this.color,
                    enemyUserId: null,
                });
                break;

            default:
                this._game.create_game().then(() => {
                    console.log("Игра создана");
                });
                this._scenes.change(SceneType.GAME);
        }

        if (this.tutorial) {
            this._app.user.apply_tutorial(this.tutorial);
        }
    }

    back() {
        switch (this._game.options.mode) {
            case GameMode.HOT_SEAT:
                this._scenes.change(SceneType.CHOICE_MODE);
                break;

            case GameMode.WITH_BOT:
                this._scenes.change(SceneType.CHOICE_BOT);
                break;

            case GameMode.WITH_PLAYER:
                if (this._game.options.friend_id !== null) {
                    this._scenes.change(SceneType.CHOICE_FRIEND);
                } else {
                    this._scenes.change(SceneType.CHOICE_PVP_MODE);
                }
                break;

            default:
                this._scenes.change(SceneType.CHOICE_MODE);
        }
        this._game.options.set_color(null);
    }

    _set_color(color = null) {
        if (color === PieceColor.WHITE || color === PieceColor.BLACK) {
            this._game.options.set_color(color);
        } else {
            const randomSide = Math.floor(Math.random() * 2);
            this._game.options.set_color(randomSide === 0 ? PieceColor.WHITE : PieceColor.BLACK);
        }

        this.controller.focus(
            {
                [PieceColor.WHITE]: "white",
                [PieceColor.BLACK]: "black",
            }[this._game.options.color] || "random",
        );
        this.controller.focus("play");

        if (this.tutorial) {
            this.tutorialStep = TUTORIAL_START_STEP;
            this._app.assistant.transition(TUTORIAL_START_PATH);
        }
    }

    get assistantState() {
        return {
            is_auto_help: this._app.user.has_scene_auto_help(SceneType.CHOICE_COLOR),
        };
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
        this.tutorial = null;

        const randomElement = this.controller.findByName("random");
        if (randomElement.disabled) {
            this.controller.focus("play");
        } else {
            this.controller.focus("random");
        }

        if (!this._app.user.tutorial.choice_color) {
            this.tutorial = TutorialType.CHOICE_COLOR;
            this.tutorialStep = TUTORIAL_CHOICE_STEP;
            this._app.assistant.transition(TUTORIAL_CHOICE_PATH);
        } else {
            this._app.assistant.fire_event("event_activate_scene", {
                is_first_activate: this._first_activate,
            });
            if (this._first_activate) {
                this._app.user.increase_scene_auto_help(SceneType.CHOICE_COLOR);
            }
        }
        this._first_activate = false;
    }
}
