import { PieceColor } from "lib/chess/enums";

import { ControlType } from "models/enums";

import gameElements from "./elements";
import { TUTORIAL_CONTROL_SKIP, TUTORIAL_CONTROL_NEXT } from "models/tutorial.buttons";

const DIRECTION_TO_VECTOR = {
    left: { x: -1, y: 0 },
    right: { x: 1, y: 0 },
    up: { x: 0, y: 1 },
    down: { x: 0, y: -1 },
};

const FLIPPED_DIRECTION_TO_VECTOR = {
    left: { x: 1, y: 0 },
    right: { x: -1, y: 0 },
    up: { x: 0, y: -1 },
    down: { x: 0, y: 1 },
};

export default (scene, color = PieceColor.WHITE) => ({
    ok: ({ name, type, meta }) => {
        if (type === ControlType.SQUARE) {
            // eslint-disable-next-line no-underscore-dangle
            const { board } = scene._game;
            const { x, y } = meta;
            if (board.mover.piece === null) {
                board.mover.update({ to: { x, y } });
                board.mover.start(board.cell_by_xy({ x, y }));
            } else {
                board.mover.update({ to: { x, y } });
                board.mover.finish();
            }
        } else if (name === TUTORIAL_CONTROL_SKIP) {
            scene.apply_tutorial({ is_skipped: true });
        }
    },
    back: () => false,
    name: { [PieceColor.BLACK]: "4:7", [PieceColor.WHITE]: "5:2" }[color],
    find: (name) => {
        if (name === null) {
            return null;
        }

        // eslint-disable-next-line no-underscore-dangle
        const { board } = scene._game;
        const cellMatch = name.match(/^([1-8]):([1-8])$/i);
        if (cellMatch) {
            const x = Number(cellMatch[1]);
            const y = Number(cellMatch[2]);
            const cell = board.cell_by_xy({ x, y });
            const defaultValue = gameElements[name];
            return {
                ...defaultValue,
                disabled: (!cell.can_move_to || cell.can_move_to.length < 1) && !board.mover.piece,
            };
        }

        return (
            {
                back: {
                    left: TUTORIAL_CONTROL_SKIP,
                    right: TUTORIAL_CONTROL_SKIP,
                    disabled: true,
                },
                [TUTORIAL_CONTROL_NEXT]: { disabled: true },
                [TUTORIAL_CONTROL_SKIP]: {
                    up: board.flipped ? ["1:7", "1:2"] : ["8:7", "8:2"],
                    down: board.flipped ? ["8:7", "8:2"] : ["1:7", "1:2"],
                    left: board.flipped ? ["1:7", "1:2"] : ["8:7", "8:2"],
                    right: board.flipped ? ["8:7", "8:2"] : ["1:7", "1:2"],
                },
            }[name] || null
        );
    },
    move: ({ name, type, meta = {}, ...params }, direction) => {
        if (type === ControlType.SQUARE && !params.disabled) {
            // eslint-disable-next-line no-underscore-dangle
            const TO_VECTOR = scene._game.board.flipped
                ? FLIPPED_DIRECTION_TO_VECTOR
                : DIRECTION_TO_VECTOR;
            const shift = TO_VECTOR[direction] || { x: 0, y: 0 };
            const x = meta.x + shift.x;
            const y = meta.y + shift.y;
            if (x < 1 || x > 8) {
                return TUTORIAL_CONTROL_SKIP;
            }
            return [`${x}:${y}`];
        }
        return params[direction] || null;
    },
});
