import { makeAutoObservable } from "mobx";

export default class OutBoundsMover {
    _mover;
    _outBounds;

    constructor(mover, outBounds) {
        makeAutoObservable(this);
        this._mover = mover;
        this._outBounds = outBounds;
    }

    move(direction, node, children) {
        if (this._mover) {
            let nextNode;
            if (this._mover instanceof Function) {
                nextNode = this._mover(direction, node, children);
            } else if (this._mover.move instanceof Function) {
                nextNode = this._mover.move(direction, node, children);
            }

            if (nextNode !== undefined) {
                return nextNode;
            }
            return this._outBounds(direction, node, children);
        }
        return undefined;
    }
}
