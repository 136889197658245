import { makeAutoObservable } from "mobx";

import { ControlType, SceneType } from "models/enums";
import { TextField } from "models/components";
import { Controller, Group, GraphMover, SimpleElement } from "models/controller/v2";

export default class UsersSearchScene {
    _app;
    _emitter;
    _isActive;
    controller;
    nickname;

    constructor(app, _, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this._isActive = false;
        this.nickname = new TextField({ value: "" });
        this.controller = new Controller(
            new Group("root", {
                nodes: [
                    {
                        type: ControlType.INPUT,
                        name: "nickname",
                        onClick: () => {
                            this.nickname.toggleEdit();
                        },
                        onCancel: () => {
                            if (this.nickname.isEditing) {
                                this.nickname.finishEdit();
                                return true;
                            }
                            return false;
                        },
                    },
                    new SimpleElement("search", {
                        type: ControlType.BUTTON,
                        disabled: () => {
                            return this.nickname.value.length <= 0;
                        },
                        onClick: (self) => {
                            if (!self.disabled) {
                                this._app.windows.scenes.open(SceneType.USERS_SEARCH_RESULT, {
                                    nickname: this.nickname.value,
                                });
                            }
                        },
                    }),
                ],
                mover: new GraphMover(
                    {
                        nickname: { down: "search" },
                        search: { up: "nickname" },
                    },
                    "input",
                ),
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasSceneKeyboard(),
            },
        );
        this.tutorial = null;
    }

    get isLoading() {
        return !this._isActive;
    }

    close() {
        this._emitter.emit("close", this);
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
    }

    // onDeactivate() {}
    // onDestroy() {}
}
