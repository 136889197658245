import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import {
    Row,
    Col,
    Container,
    Header,
    RectSkeleton,
    Carousel,
    CarouselCol,
    CarouselGridWrapper,
    Headline1,
    Footnote1,
    Button,
} from "components";

import CardGame from "./card-game";
import styles from "./scene.module.pcss";

const Content = observer(({ scene }) => {
    const { type } = scene.content;
    if (type === "info") {
        const { title, subTitle = null, button = null } = scene.content;
        return (
            <Row className={clsx(styles.content, styles.middle)}>
                <Col>
                    <div style={{ marginBottom: "2rem" }}>
                        <Headline1>{title}</Headline1>
                        {subTitle && (
                            <Footnote1
                                style={{
                                    marginTop: "0.25rem",
                                    color: "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))",
                                }}
                            >
                                {subTitle}
                            </Footnote1>
                        )}
                    </div>
                    {button && (
                        <Button
                            name={button.name}
                            view="primary"
                            controller={scene.controller}
                            text={button.text}
                        />
                    )}
                </Col>
            </Row>
        );
    }

    if (scene.isLoading || type === "skeleton") {
        return (
            <Row className={clsx(styles.content, styles.middle)}>
                <Col sizeXL={3} sizeL={3} sizeM={2} sizeS={2}>
                    <div className={clsx(styles.column)}>
                        <RectSkeleton width="100%" height="8rem" />
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <CarouselGridWrapper className={clsx(styles.content, styles.middle)}>
            <Carousel
                as={Row}
                index={scene.games.alternativeIndex}
                scrollSnapType="mandatory"
                onIndexChange={(index) => scene.games.setAlternativeIndex(index)}
            >
                {scene.games.grid.map((col, x) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <CarouselCol key={x} sizeXL={3} sizeL={3} sizeM={2} sizeS={2}>
                        <div className={clsx(styles.column)}>
                            {col.map(({ name, index, meta: game }) => (
                                <CardGame
                                    key={game.id}
                                    name={name}
                                    number={index + 1}
                                    controller={scene.controller}
                                    game={game}
                                    scene={scene}
                                />
                            ))}
                        </div>
                    </CarouselCol>
                ))}
            </Carousel>
        </CarouselGridWrapper>
    );
});

// eslint-disable-next-line import/prefer-default-export
export const ContinueScene = observer(({ scene, skeleton = false, notification = null }) => {
    const isLoading = skeleton || scene.isLoading;
    return (
        <Container style={{ width: "100vw", height: "100vh", display: "flex" }}>
            <Header
                title="Продолжить игру"
                controller={scene.controller}
                skeleton={isLoading}
                notification={notification}
            />
            <Content scene={scene} />
        </Container>
    );
});
