import { makeAutoObservable } from "mobx";

const DIRECTION_FOR_HORIZONTAL = { left: -1, right: 1 };
const DIRECTION_FOR_VERTICAL = { up: -1, down: 1 };

export default class LinearMover {
    _looped;
    _direction;
    _checker;

    constructor({ direction = "horizontal", looped = false, checker = null } = {}) {
        makeAutoObservable(this);
        this._looped = looped;
        this._direction = direction;
        this._checker = checker instanceof Function ? checker : (item) => item && !item.disabled;
    }

    get shiftDirection() {
        if (this._direction === "horizontal") {
            return DIRECTION_FOR_HORIZONTAL;
        }
        return DIRECTION_FOR_VERTICAL;
    }

    get isLooped() {
        if (this._looped instanceof Function) {
            return !!this._looped();
        }
        return !!this._looped;
    }

    move(direction, node, children) {
        let nodeIndex = children.findIndex((item) => item && item === node);
        if (nodeIndex === -1 && children.length > 0) {
            nodeIndex = 0;
        }

        if (nodeIndex === -1) {
            return undefined;
        }

        const shift = this.shiftDirection[direction];
        if (shift === undefined || shift === null) {
            return undefined;
        }

        const { isLooped } = this;
        let newIndex = nodeIndex;
        let count = children.length;
        while (count > 0) {
            count -= 1;

            newIndex += shift;
            if (newIndex < 0 && isLooped) {
                newIndex = children.length - 1;
            } else if (newIndex >= children.length && isLooped) {
                newIndex = 0;
            }

            const element = children[newIndex];
            if (this._checker(element)) {
                return element;
            }
        }

        return undefined;
    }
}
