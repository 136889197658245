import { action, makeAutoObservable, runInAction } from "mobx";

import { MoveFlag, PieceColor, PieceType, PieceWeight } from "lib/chess/enums";
import { fen_parser } from "lib/chess/fen";
import { transform as transform_position } from "lib/chess/position";
import { GameMode, GameResult, GameType } from "models/enums";

export class Position {
    x;
    y;

    constructor({ x, y }) {
        makeAutoObservable(this);
        this.x = x;
        this.y = y;
    }

    update({ x = null, y = null }) {
        if (x !== null) {
            this.x = x;
        }
        if (y !== null) {
            this.y = y;
        }
    }
}

export class MoveTo {
    position;

    constructor({ x, y }) {
        makeAutoObservable(this);
        this.position = new Position({ x, y });
    }

    get x() {
        return this.position.x;
    }

    get y() {
        return this.position.y;
    }

    update({ x = null, y = null }) {
        this.position.update({ x, y });
    }
}

export class MovePiece {
    board;
    from_cell;
    position;

    constructor(board) {
        makeAutoObservable(this, { board: false });
        this.board = board;
        this.from_cell = null;
        this.position = new Position({ x: 0, y: 0 });
    }

    get is_active() {
        return !!this.piece;
    }

    get piece() {
        return this.from_cell && this.from_cell.piece;
    }

    get x() {
        return this.position.x;
    }

    get y() {
        return this.position.y;
    }

    get to_cell() {
        const x = Math.trunc(this.position.x),
            y = Math.trunc(this.position.y);
        return this.board.cells.find((item) => item.x === x && item.y === y);
    }

    update({ x = null, y = null, to = { x: null, y: null } }) {
        if (to) {
            this.position.update(to);
        }
        this.position.update({ x, y });
    }

    start(cell) {
        const piece_color = (cell.piece && cell.piece.color) || null;
        if (
            this.from_cell === null &&
            piece_color !== null &&
            piece_color === this.board.current_color &&
            this.board.is_can_move_piece
        ) {
            this.from_cell = cell;
            this.position.update({ x: cell.x, y: cell.y });
            this.board._fire_event("moveStart", { x: cell.x, y: cell.y, piece: cell.piece });
            this.board.multiselect.cancel();
        } else if (!this.board.is_can_move_piece && cell.piece !== null) {
            this.board._fire_event("moveAccessDeny");
        }
    }

    finish() {
        if (this.from_cell !== null) {
            if (this.from_cell !== this.to_cell) {
                this.board.move_piece(this.from_cell, this.to_cell);
            }
            this.from_cell = null;
        }
    }

    cancel() {
        this.from_cell = null;
        this.board._fire_event("moveCancel");
    }
}

class BoardCell {
    x;
    y;
    piece;
    moves;
    _is_alarm;

    constructor({ piece = null, x = 1, y = 1, is_alarm = false } = {}) {
        makeAutoObservable(this);
        this.piece = piece;
        this.moves = [];
        this.x = x;
        this.y = y;
        this._is_alarm = is_alarm;
    }

    get name() {
        return transform_position.to_human({ x: this.x, y: this.y });
    }

    get square() {
        return transform_position.to_index({ x: this.x, y: this.y });
    }

    get index() {
        return this.square;
    }

    get can_move_to() {
        return this.moves.filter(({ to }) => to.x > 0 && to.y > 0 && to.x < 9 && to.y < 9);
    }

    get is_alarm() {
        return this._is_alarm;
    }

    clear() {
        this._is_alarm = false;
        this.piece = null;
        this.moves = [];
    }
}

class BoardPromotion {
    _controller;
    _board;
    from_cell;
    to_cell;
    items;
    is_active;

    constructor(board, controller = null) {
        makeAutoObservable(this, { board: false, _controller: false });
        this._controller = controller;
        this._board = board;
        this.from_cell = null;
        this.to_cell = null;
        this.items = [];
        this.is_active = false;
    }

    reset() {
        this.is_active = false;
        this.from_cell = null;
        this.to_cell = null;
        this.items.replace([]);
    }

    start(from, to, items) {
        if (!this.is_active && from && to && items && items.length > 0) {
            this.from_cell = from;
            this.to_cell = to;
            this.items.replace(
                items.filter((item) => (item.flags & MoveFlag.PROMOTION) === MoveFlag.PROMOTION),
            );
            this.is_active = true;

            this._controller.push({
                ok: ({ name, type, meta }) => {
                    if (name === "close") {
                        this.cancel();
                    } else {
                        this.finish(meta.promotion);
                    }
                    this._controller.pop();
                },
                back: () => {
                    this.cancel();
                    this._controller.pop();
                },
                move: (element, direction) => {
                    const color_for_reversing = this._board.flipped
                            ? PieceColor.WHITE
                            : PieceColor.BLACK,
                        reverse = this.from_cell.piece.color === color_for_reversing,
                        pieces = this.items.map((item) => item.promotion);
                    pieces.push("close");
                    const index_piece = pieces.indexOf(element.meta.promotion || "close");

                    if (direction === "up" || direction === "down") {
                        const index_next =
                            index_piece +
                            {
                                up: reverse ? 1 : -1,
                                down: reverse ? -1 : 1,
                            }[direction];
                        if (index_next < 0) {
                            return pieces[pieces.length - 1];
                        }
                        if (index_next >= pieces.length) {
                            return pieces[0];
                        }
                        return pieces[index_next];
                    }

                    return null;
                },
                name: this.items[0].promotion || "close",
                elements: {
                    close: {},
                    [PieceType.KNIGHT]: { meta: { promotion: PieceType.KNIGHT } },
                    [PieceType.QUEEN]: { meta: { promotion: PieceType.QUEEN } },
                    [PieceType.BISHOP]: { meta: { promotion: PieceType.BISHOP } },
                    [PieceType.KNIGHT]: { meta: { promotion: PieceType.KNIGHT } },
                    [PieceType.ROOK]: { meta: { promotion: PieceType.ROOK } },
                    [PieceType.PAWN]: { meta: { promotion: PieceType.PAWN } },
                },
            });
        }
    }

    finish(promotion) {
        this._board.move_piece(this.from_cell, this.to_cell, promotion);
        this.reset();
    }

    cancel() {
        this.reset();
    }
}

export const BoardState = Object.freeze({
    EMPTY: "empty",
    LOADING: "loading",
    PLAYING: "playing",
    MOVING: "moving",
    SURRENDERING: "surrendering",
    FINISHED: "finished",
});

export class ColorState {
    static PIECES = [
        PieceType.KING,
        PieceType.QUEEN,
        PieceType.BISHOP,
        PieceType.KNIGHT,
        PieceType.ROOK,
        PieceType.PAWN,
    ];
    static PIECE_TO_WEIGHT = Object.freeze({
        [PieceType.PAWN]: PieceWeight.PAWN,
        [PieceType.ROOK]: PieceWeight.ROOK,
        [PieceType.KNIGHT]: PieceWeight.KNIGHT,
        [PieceType.BISHOP]: PieceWeight.BISHOP,
        [PieceType.QUEEN]: PieceWeight.QUEEN,
    });

    _nickname;
    avatar_preset;
    user;
    rating_from;
    rating_to;
    time;
    score;
    // captured;
    waiting;
    is_assistant;
    is_self;
    is_active;
    _capture_piece;

    constructor({
        nickname = "",
        rating_from = 0,
        rating_to = null,
        time = 0,
        score = 0,
        waiting = true,
        is_assistant = false,
        is_self = false,
        avatar_preset = null,
        user = null,
    } = {}) {
        makeAutoObservable(this);
        this._nickname = nickname;
        this.user = user;
        this.rating_from = rating_from;
        this.rating_to = rating_to === null ? rating_from : rating_to;
        this.score = score;
        this.time = time;
        this.waiting = waiting;
        this.is_assistant = is_assistant;
        this.is_self = is_self;
        this.is_active = false;
        this.avatar_preset = avatar_preset;
        // this.captured = {
        //     [PieceType.KING]: 0,
        //     [PieceType.QUEEN]: 0,
        //     [PieceType.BISHOP]: 0,
        //     [PieceType.KNIGHT]: 0,
        //     [PieceType.ROOK]: 0,
        //     [PieceType.PAWN]: 0,
        // };
        this._capture_piece = [];
    }

    get nickname() {
        if (this.user) {
            return this.user.nickname ? this.user.nickname : `ID ${this.user.id}`;
        }
        return this._nickname;
    }

    get human_time() {
        // return time_to_human(this.time);
        return "";
    }

    get user_id() {
        return (this.user && this.user.id) || null;
    }

    get is_friend() {
        return !!(this.user && this.user.is_friend === true);
    }

    get captured_stat() {
        const stat = {
            [PieceType.KING]: 0,
            [PieceType.QUEEN]: 0,
            [PieceType.BISHOP]: 0,
            [PieceType.KNIGHT]: 0,
            [PieceType.ROOK]: 0,
            [PieceType.PAWN]: 0,
        };
        this._capture_piece.forEach((piece) => (stat[piece] += 1));
        const result = [
            PieceType.PAWN,
            PieceType.ROOK,
            PieceType.KNIGHT,
            PieceType.BISHOP,
            PieceType.QUEEN,
            PieceType.KING,
        ].map((piece) => ({ piece, count: stat[piece] || 0 }));

        return result;
    }

    get captured() {
        return this._capture_piece;
    }

    get rating() {
        return this.rating_from;
    }

    get rating_diff() {
        if (this.rating_to !== null) {
            return this.rating_to - this.rating_from;
        }
        return 0;
    }

    reset({
        nickname = "",
        rating_from = 0,
        rating_to = null,
        time = 0,
        waiting = true,
        is_assistant = false,
        is_self = false,
        avatar_preset = null,
        user = null,
    } = {}) {
        this.user = user;
        this._nickname = nickname;
        this.rating_from = rating_from;
        this.rating_to = rating_to === null ? rating_from : rating_to;
        this.time = time;
        this.score = 0;
        this.waiting = waiting;
        this.is_assistant = is_assistant;
        this.is_self = is_self;
        this.avatar_preset = avatar_preset;
        // ColorState.PIECES.forEach(piece => this.captured[piece] = 0);
        this._capture_piece.replace([]);
    }

    capture(piece) {
        if (piece !== null) {
            this.score += ColorState.PIECE_TO_WEIGHT[piece] || 0;
            // this.captured[piece] += 1;
            this._capture_piece.push(piece);
        }
    }
}

export class MultiSelectPieces {
    fromSquares;
    board;

    constructor(board) {
        makeAutoObservable(this);
        this.board = board;
        this.fromSquares = [];
    }

    get isActive() {
        return this.fromSquares.length > 0;
    }

    start({ fromSquares = [] } = {}) {
        this.board.mover.cancel();
        this.fromSquares.replace(
            fromSquares.filter((value, index, self) => self.indexOf(value) === index),
        );
    }

    filter({ from, to, fromPiece, toPiece }) {
        const colorEnemy = this.board.enemy_color;
        const pairs = [];
        const fromSquares = this.fromSquares
            .filter((item) => item)
            .filter((item) => !from || item.square === from.square)
            .filter((item) => !fromPiece || (item.piece && item.piece.type === fromPiece))
            .filter((fromSquare) => {
                if (!to && !toPiece) {
                    return true;
                }

                const toSquares = fromSquare.can_move_to.filter((move) => {
                    const toSquare = this.board.cell_by_xy(move.to);
                    if (to && to.square !== toSquare.square) {
                        return false;
                    }

                    return (
                        !toPiece ||
                        (toSquare.piece &&
                            toSquare.piece.type === toSquare.type &&
                            toSquare.piece.color === colorEnemy)
                    );
                });

                toSquares.forEach(({ to: toSquare }) => {
                    pairs.push({ from: fromSquare, to: toSquare });
                });

                return toSquares.length > 0;
            });

        this.fromSquares.replace(fromSquares || []);

        if (pairs.length === 1) {
            return pairs[0];
        }

        if (fromSquares.length === 1) {
            return { from: fromSquares[0], to: null };
        }

        return undefined;
    }

    cancel() {
        this.fromSquares.replace([]);
    }
}

export class Board {
    game_server;
    _board;
    _mode;
    _color;
    state;

    _flipped;
    mover;
    promotion;
    history;
    color_state;
    try_moving;
    view_round_index;
    _time;
    _inited;
    _moves;
    _game_result;
    check;
    checkmate;
    multiselect;

    constructor(game_server = null, controller, listener = null) {
        makeAutoObservable(this, { apply_move: action.bound });
        this.game_server = game_server;
        this.state = BoardState.EMPTY;
        this._flipped = false;
        this.history = [];
        this.try_moving = null;
        this._inited = false;
        this._listener = listener;
        this.multiselect = new MultiSelectPieces(this);
        this.color_state = {
            [PieceColor.WHITE]: new ColorState(),
            [PieceColor.BLACK]: new ColorState(),
        };
        this.view_round_index = -1;

        this._board = [];
        for (let y = 1; y <= 8; y++) {
            const row = [];
            for (let x = 1; x <= 8; x++) {
                row.push(new BoardCell({ x, y }));
            }
            this._board.push(row);
        }

        this.mover = new MovePiece(this);
        this.promotion = new BoardPromotion(this, controller);
        this._mode = null;
        this._color = null;
        this._time = 0;
        this._time_pause = false;
        this._moves = [];
        this._game_result = GameResult.NONE;
        this.check = false;
        this.checkmate = false;
    }

    get flipped() {
        if (this.game_type === GameMode.HOT_SEAT) {
            return this.history.length % 2 === 0 ? this._flipped : !this._flipped;
        }
        return this._flipped;
    }

    set_game_server(game_server) {
        this.game_server = game_server;
    }

    async reload() {
        const is_init = this.state === BoardState.EMPTY;
        this.state = BoardState.LOADING;
        try {
            const info = await this.game_server.state();
            runInAction(() => {
                this.init(info);
                this.update_moves(info.moves);
                this.update_history(info.history);
                this.update_alarms({ check: info.check });
                if (is_init) {
                    this._flipped =
                        this.game_type !== GameMode.HOT_SEAT
                            ? this._color === PieceColor.BLACK
                            : false;
                    this._inited = true;
                }

                const side = this.history.length % 2 === 0 ? PieceColor.WHITE : PieceColor.BLACK,
                    is_waiting_mode =
                        [
                            GameMode.WITH_BOT,
                            GameMode.WITH_PLAYER,
                            GameMode.WITH_PLAYER_RATING,
                        ].indexOf(this.game_type) !== -1,
                    is_waiting_turn = side !== this.side;
                if (is_waiting_mode && is_waiting_turn) {
                    this.game_server
                        .wait(this.history.length)
                        .then((move) => move && runInAction(() => this.apply_move(move)))
                        .catch(() => this.reload());
                }
            });
        } catch (exception) {
            console.error(exception);
            this.reset();
            setTimeout(() => {
                if (this.state === BoardState.EMPTY) {
                    this.reload();
                }
            }, 3000);
        }
    }

    update_game_status({ id, white = {}, black = {}, pause, result = null }) {
        if (this.game_server && Number(this.game_server.game_id) === id) {
            this.white.is_active = !!white.user.is_active;
            if (white.rating !== null) {
                this.white.rating_from = white.rating.from;
                this.white.rating_to = white.rating.to;
            }

            this.black.is_active = !!black.user.is_active;
            if (black.rating !== null) {
                this.black.rating_from = black.rating.from;
                this.black.rating_to = black.rating.to;
            }

            if (pause) {
                this._time_pause = pause.is_active;
            }
            if (result !== null) {
                this._game_result = result;
            }
        }
    }

    reset() {
        this.state = BoardState.EMPTY;
        this._board.forEach((row) =>
            row.forEach((cell) => {
                cell.piece = null;
            }),
        );
    }

    init({
        id = 0,
        state = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
        mode = GameMode.HOT_SEAT,
        color = PieceColor.WHITE,
        game_over = false,
        history = [],
        time = 0,
        white = {},
        black = {},
        result = GameResult.NONE,
        checkmate = false,
    } = {}) {
        this.state = game_over ? BoardState.FINISHED : BoardState.PLAYING;
        this._game_result = result;
        this._mode = mode;
        this._color = color;
        this.try_moving = null;
        this._time = time;
        this.checkmate = checkmate;

        const parse_name = (side) =>
            side === this._color
                ? "Вы"
                : {
                      [GameMode.WITH_ASSISTANT]: "Ассистент",
                      [GameMode.WITH_PLAYER]: "Игрок",
                      [GameMode.WITH_PLAYER_RATING]: "Игрок",
                      [GameMode.HOT_SEAT]: "Гость",
                  }[mode];

        const {
                id: white_user_id = null,
                rating: white_user_rating = 0,
                avatar_preset_id: white_avatar_preset_id,
            } = (white && white.user) || {},
            {
                id: black_user_id = null,
                rating: black_user_rating = 0,
                avatar_preset_id: black_avatar_preset_id,
            } = (black && black.user) || {};
        this.white.reset({
            user: (white && white.user) || null,
            nickname: white_user_id ? `ID ${white_user_id}` : parse_name(PieceColor.WHITE),
            rating_from: white_user_rating || 0,
            waiting: !game_over && history.length % 2 === 0,
            avatar_preset: white_avatar_preset_id,
            is_assistant: white && white.is_assistant,
            is_self: white && white.is_self,
        });
        this.black.reset({
            user: (black && black.user) || null,
            nickname: black_user_id ? `ID ${black_user_id}` : parse_name(PieceColor.BLACK),
            rating_from: black_user_rating || 0,
            waiting: !game_over && history.length % 2 === 1,
            avatar_preset: black_avatar_preset_id,
            is_assistant: black && black.is_assistant,
            is_self: black && black.is_self,
        });

        history.forEach(({ color, drop }) => this.color_state[color].capture(drop));

        this.cells.forEach((cell) => cell.clear());
        fen_parser(state).pieces.map(({ type, color, x, y }) => {
            this._board[y - 1][x - 1].piece = { type, color };
        });
    }

    get moves() {
        if (this.state === BoardState.PLAYING) {
            return this._moves || [];
        }
        return [];
    }

    get is_inited() {
        return this._inited;
    }

    get mode() {
        // Deprecated
        console.warn("Deprecated field Board.mode use Board.game_type");
        return this._mode;
    }

    get game_type() {
        return this._mode;
    }

    get side() {
        return this._color;
    }

    get white() {
        return this.color_state[PieceColor.WHITE];
    }

    get black() {
        return this.color_state[PieceColor.BLACK];
    }

    get current_side() {
        return this.color_state[this.current_color];
    }

    get enemy_side() {
        return this.color_state[this.enemy_color];
    }

    get cells() {
        const result = [];
        this._board.forEach((row) => row.forEach((cell) => result.push(cell)));
        return result;
    }

    get activeHistoryNumber() {
        if (this.view_round_index < 0 || this.view_round_index >= this.history.length) {
            return undefined;
        }
        return this.view_round_index;
    }

    get view_round() {
        let value = this.view_round_index;
        if (this.view_round_index < 0 || this.view_round_index >= this.history.length) {
            value = this.history.length;
        }
        return {
            number: Math.ceil((value + 1) / 2),
            color: { 0: PieceColor.WHITE, 1: PieceColor.BLACK }[value % 2],
        };
    }

    get view_cells() {
        if (this.view_round_index < 0 || this.view_round_index >= this.history.length) {
            return this.cells;
        }

        const state = this.history[this.view_round_index].state;

        const pieces = {};
        fen_parser(state).pieces.forEach(
            ({ type, color, x, y }) =>
                (pieces[transform_position.to_index({ x, y })] = { color, type }),
        );

        const result = [];
        const current_color = this.view_round_index % 2 === 0 ? PieceColor.WHITE : PieceColor.BLACK;
        this._board.forEach((row) =>
            row.forEach((cell) => {
                const piece = pieces[cell.square] || null;
                result.push(
                    new BoardCell({
                        x: cell.x,
                        y: cell.y,
                        piece,
                        is_alarm:
                            state.check &&
                            cell.piece &&
                            cell.piece.type === PieceType.KING &&
                            cell.piece.color === current_color,
                    }),
                );
            }),
        );
        return result;
    }

    get move_variants() {
        if (this.mover.is_active) {
            return this.mover.from_cell.can_move_to;
        }
        return [];
    }

    get is_playing() {
        return this.state === BoardState.PLAYING;
    }

    get finished() {
        if (this._game_result !== GameResult.NONE) {
            switch (this._mode) {
                case GameMode.HOT_SEAT:
                //    return { result: this._game_result, color: null, game_type: this._mode };
                case GameMode.WITH_ASSISTANT:
                case GameMode.WITH_PLAYER:
                case GameMode.WITH_PLAYER_RATING:
                    return { result: this._game_result, color: null, game_type: this._mode };
            }
        }
        return null;
    }

    get current_color() {
        return this.history.length % 2 === 0 ? PieceColor.WHITE : PieceColor.BLACK;
    }

    get enemy_color() {
        return this.history.length % 2 === 0 ? PieceColor.BLACK : PieceColor.WHITE;
    }

    get common_time() {
        return this._time;
    }

    get wait_time() {
        if (this._time > 0) {
            return this.last_move && this.last_move.created_at
                ? new Date().getTime() - this.last_move.created_at * 1000
                : this._time;
        }
        return 0;
    }

    get last_move() {
        let position = this.view_round_index - 1;
        if (this.view_round_index < 0 || this.view_round_index >= this.history.length) {
            position = this.history.length - 1;
        }
        return position >= 0 ? this.history[position] : null;
    }

    get is_can_move_piece() {
        if (this.game_type === GameType.HOT_SEAT) {
            // нет ограничений на то кто может ходить, так как игра
            // идет на одном устройстве
            return !this.finished && !!(this.white.is_self || this.black.is_self);
        }

        const side = this.color_state[this.current_color];
        return (
            !this.finished &&
            !!(side && side.is_self) &&
            this.white.is_active &&
            this.black.is_active
        );
    }

    increase_time(time = 1000) {
        if (
            this.finished === null &&
            !this._time_pause &&
            this.white.is_active &&
            this.black.is_active
        ) {
            this._time += time;
            this.game_server.set_time(this._time);
        }
    }

    update_view_round(index) {
        if (index < 0 || index >= this.history.length || this.view_round_index === index) {
            this.view_round_index = -1;
            return;
        }
        this.view_round_index = index;
    }

    cell_by_xy({ x, y }) {
        return this._board[y - 1][x - 1];
    }

    update_history(history = []) {
        this.history.replace(history);
    }

    update_moves(moves) {
        this._moves = moves;

        const moves_by_cell = {};
        moves.forEach((move) => {
            const from_square = move.from.square,
                to_square = move.to.square;
            if (!(from_square in moves_by_cell)) {
                moves_by_cell[from_square] = {};
            }

            if (to_square in moves_by_cell[from_square]) {
                moves_by_cell[from_square][to_square].items.push(move);
            } else {
                const type = [
                    [MoveFlag.KING_SIDE_CASTLING, "king_side_castling"],
                    [MoveFlag.QUEEN_SIDE_CASTLING, "queen_side_castling"],
                ].find(([flag, _]) => (move.flags & flag) === flag) || [0, "simple"];

                moves_by_cell[from_square][to_square] = {
                    to: { x: move.to.x, y: move.to.y, square: to_square },
                    items: [move],
                    meta: { type: type[1], color: move.color },
                };
            }
        });

        this.cells.forEach((cell) => {
            const cell_moves = moves_by_cell[cell.square];
            cell.moves.replace(cell_moves ? Object.values(cell_moves) : []);
        });
    }

    update_alarms({ check }) {
        if (check !== this.check) {
            this._fire_event("toggleCheck", { check });
        }
        this.check = check;
        this.cells.forEach((cell) => {
            // Необходимо выставить предупреждение, когда король под ударом
            cell._is_alarm =
                check &&
                cell.piece &&
                cell.piece.type === PieceType.KING &&
                cell.piece.color === this.current_color;
        });
    }

    apply_move({
        done = false,
        move = {},
        check = false,
        stalemate = false,
        checkmate = false,
        game_over = false,
        moves = [],
        drop = PieceType.NONE,
        result = GameResult.NONE,
    }) {
        this.state = BoardState.PLAYING;
        this._game_result = result;
        const from_cell = this.cell_by_xy(move.from),
            to_cell = this.cell_by_xy(move.to);

        if (done) {
            to_cell.piece = from_cell.piece;
            from_cell.clear();
            if ((move.flags & MoveFlag.PROMOTION) === MoveFlag.PROMOTION && move.promotion) {
                to_cell.piece.type = move.promotion;
            }

            if ((move.flags & MoveFlag.EN_PASSANT_CAPTURE) === MoveFlag.EN_PASSANT_CAPTURE) {
                this._board[from_cell.y - 1][to_cell.x - 1].clear();
            }

            if ((move.flags & MoveFlag.KING_SIDE_CASTLING) === MoveFlag.KING_SIDE_CASTLING) {
                this._board[from_cell.y - 1][5].piece = this._board[from_cell.y - 1][7].piece;
                this._board[from_cell.y - 1][7].clear();
            }

            if ((move.flags & MoveFlag.QUEEN_SIDE_CASTLING) === MoveFlag.QUEEN_SIDE_CASTLING) {
                this._board[from_cell.y - 1][3].piece = this._board[from_cell.y - 1][0].piece;
                this._board[from_cell.y - 1][0].clear();
            }

            if (move.drop) {
                this.color_state[move.color].capture(move.drop);
            }

            this.update_moves(moves);

            this.history.push({
                san: `${transform_position.to_human(from_cell)}${transform_position.to_human(
                    to_cell,
                )}`,
                ...move,
            });
            this.update_alarms({ check });
            this.checkmate = checkmate;

            if (game_over) {
                this.state = BoardState.FINISHED;
                this.white.waiting = false;
                this.black.waiting = false;
            } else {
                this.white.waiting = this.history.length % 2 === 0;
                this.black.waiting = this.history.length % 2 === 1;
            }
        }
        this.try_moving = null;
    }

    move_piece(from_cell, to_cell, promotion = null) {
        if (!this.is_can_move_piece) {
            this._fire_event("moveAccessDeny");
            return;
        }

        if (this.is_playing && from_cell && to_cell && this.is_can_move_piece) {
            const info = from_cell.can_move_to.find(
                (item) => item.to.x === to_cell.x && item.to.y === to_cell.y,
            );
            if (info && info.items.length > 0) {
                let move = info.items[0];
                if (info.items.length > 1) {
                    move = info.items.find(
                        (item) =>
                            (item.flags & MoveFlag.PROMOTION) === MoveFlag.PROMOTION &&
                            item.promotion === promotion,
                    );

                    if (!move) {
                        this.promotion.start(from_cell, to_cell, info.items);
                        return;
                    }
                }

                this.state = BoardState.MOVING;
                this.try_moving = move;
                this._fire_event("moveFinish", { drop: to_cell.piece });
                this.game_server
                    .move(
                        { x: from_cell.x, y: from_cell.y },
                        {
                            x: to_cell.x,
                            y: to_cell.y,
                        },
                        promotion,
                    )
                    .then((move) => {
                        if (move) {
                            runInAction(() => this.apply_move(move));
                            const is_waiting_mode =
                                [
                                    GameMode.WITH_BOT,
                                    GameMode.WITH_PLAYER,
                                    GameMode.WITH_PLAYER_RATING,
                                ].indexOf(this.mode) !== -1;

                            if (is_waiting_mode) {
                                runInAction(() => (this.state = BoardState.MOVING));
                                return this.game_server.wait(move.move.number + 1);
                            }
                        }
                        return Promise.resolve(null);
                    })
                    .then(
                        (move) =>
                            move &&
                            runInAction(() => {
                                this._fire_event("moveFinish", {
                                    drop: move.move && move.move.drop,
                                });
                                this.apply_move(move);
                            }),
                    )
                    .catch((error) =>
                        runInAction(() => {
                            this.state = BoardState.PLAYING;
                            console.error(error);
                            this.reload().then(() => console.log("Restored after crash move"));
                        }),
                    );
            }
        }
    }

    try_move({ from = null, to = null, from_piece = null, to_piece = null }) {
        if (!this.is_can_move_piece) {
            this._fire_event("moveAccessDeny");
            return;
        }

        const fromPiece = from_piece;
        const toPiece = to_piece;

        const fromSquare = !from
            ? null
            : {
                  x: from.x,
                  y: from.y,
                  square: (from.y - 1) * 8 + from.x - 1,
              };
        const toSquare = !to
            ? null
            : {
                  x: to.x,
                  y: to.y,
                  square: (to.y - 1) * 8 + to.x - 1,
              };

        if (this._game_result !== GameResult.NONE || this.view_round_index !== -1) {
            return;
        }

        if (this.multiselect.isActive) {
            const move = this.multiselect.filter({
                from: fromSquare,
                to: toSquare,
                fromPiece,
                toPiece,
            });

            if (move) {
                this.mover.cancel();
                if (move.to) {
                    this.move_piece(move.from, move.to);
                } else {
                    this.mover.start(move.from);
                }
                this.multiselect.cancel();
            }

            return;
        }

        const color = this.current_color;
        const color_enemy = this.enemy_color;
        if (this.promotion.is_active && from_piece && from_piece.type) {
            this.promotion.finish(from_piece.type);
            return;
        }

        const from_cells = (this.mover.is_active ? [this.mover.from_cell] : this.cells).filter(
            (cell) => {
                if (from && !(from.x === cell.x && from.y === cell.y)) {
                    return false;
                }
                if (
                    from_piece &&
                    !(
                        cell.piece &&
                        cell.piece.type === from_piece.type &&
                        cell.piece.color === color
                    )
                ) {
                    return false;
                }
                return true;
            },
        );
        if (from_cells.length < 1) {
            return;
        }

        if (to || to_piece) {
            const cell_pairs = [];
            from_cells.forEach((from_cell) => {
                from_cell.can_move_to.forEach((move) => {
                    const to_cell = this.cell_by_xy(move.to);
                    if (to && !(to.x === to_cell.x && to.y === to_cell.y)) {
                        return false;
                    }
                    if (
                        to_piece &&
                        !(
                            to_cell.piece &&
                            to_cell.piece.type === to_piece.type &&
                            to_cell.piece.color === color_enemy
                        )
                    ) {
                        return false;
                    }
                    cell_pairs.push([from_cell, to_cell]);
                });
            });

            if (cell_pairs.length === 1) {
                this.mover.cancel();
                this.move_piece(cell_pairs[0][0], cell_pairs[0][1]);
            } else if (cell_pairs.length > 1 && (fromSquare || toSquare || toPiece)) {
                this.multiselect.start({
                    fromSquares: cell_pairs.map(([from_cell]) => from_cell),
                });
            }
        } else if (from_cells.length === 1) {
            if (this.mover.is_active) {
                this.mover.update({ to: from_cells[0] });
                this.mover.finish();
            } else {
                this.mover.start(from_cells[0]);
            }
        } else if (from_cells.length > 1 && (fromSquare || toSquare || toPiece)) {
            this.multiselect.start({ fromSquares: from_cells });
        }
    }

    set_view_round({ number, color }) {
        const index =
            (number - 1) * 2 +
            ({
                [PieceColor.WHITE]: 0,
                [PieceColor.BLACK]: 1,
            }[color] || 0);
        this.view_round_index = Math.max(Math.min(index, this.history.length - 1), -1);
    }

    async pause() {
        this._time_pause = true;
    }

    async resume() {
        this._time_pause = false;
    }

    _fire_event(name, params = {}) {
        try {
            if (typeof this._listener === "function") {
                this._listener(name, params);
            }
        } catch (exception) {
            console.error(exception);
        }
    }

    flip() {
        this._flipped = !this._flipped;
    }

    async draw() {}

    async surrender() {
        if (this.is_playing || this.state === BoardState.MOVING) {
            this.state = BoardState.SURRENDERING;
            const { done } = await this.game_server.surrender();

            runInAction(() => {
                this.state = BoardState.PLAYING;
                if (done) {
                    this.state = BoardState.FINISHED;
                }
            });
        }
    }
}
