import clsx from "clsx";
import React from "react";

import styles from "./icons.module.pcss";

export const IconFlip = ({ size = "s", color = "var(--plasma-colors-primary)" }) => (
    <div className={clsx(styles.icon, styles[size])}>
        <svg width="100%" version="1.1" fill={color} viewBox="0 0 1500 1500">
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="ico-otmena.eps">
                    <path d="M683 1096c-33,-34 -32,-87 2,-120 34,-33 87,-32 120,2l54 56 109 112c33,34 32,87 -2,120l-112 109 -56 54c-34,33 -87,32 -120,-2 -33,-34 -32,-87 2,-120l20 -19c-141,-12 -267,-74 -362,-168 -105,-105 -170,-250 -170,-411 0,-51 7,-101 20,-150 13,-48 32,-95 58,-140 23,-41 75,-54 116,-31 41,23 54,75 31,116 -18,31 -32,64 -41,99 -9,34 -14,70 -14,106 0,113 46,216 120,291 64,64 149,107 244,118l-20 -21zm118 -883c141,12 267,74 362,168 105,105 170,250 170,411 0,51 -7,101 -20,150 -13,48 -32,95 -58,140 -23,41 -75,54 -116,31 -41,-23 -54,-75 -31,-116 18,-31 32,-64 41,-99 9,-34 14,-70 14,-106 0,-113 -46,-216 -120,-291 -64,-64 -149,-107 -244,-118l20 21c33,34 32,87 -2,120 -34,33 -87,32 -120,-2l-54 -56 -109 -112c-33,-34 -32,-87 2,-120l112 -109 56 -54c34,-33 87,-32 120,2 33,34 32,87 -2,120l-20 19z" />
                </g>
            </g>
        </svg>
    </div>
);
