import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import { Button, Col, Container, Row, Icon } from "components";
import { IconSurrender } from "components/icons";

import styles from "./dialog-exit.module.pcss";

export const DialogExit = observer(({ controller, disabled = false }) => {
    return (
        <Container
            className={clsx(styles.view, { [styles.disabled]: disabled })}
            onClick={() => controller.back()}
        >
            <Row>
                <Col
                    sizeXL={5}
                    offsetXL={3.5}
                    sizeL={5}
                    offsetL={1.5}
                    sizeM={4}
                    offsetM={1}
                    sizeS={4}
                    offsetS={0}
                >
                    <div
                        className={clsx(styles.content)}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                    >
                        <div className={clsx(styles.footer)}>
                            <Button
                                name="pause"
                                contentLeft={<Icon icon="pause" size="xs" color="inherit" />}
                                text="Пауза"
                                view="secondary"
                                controller={controller}
                                size="s"
                            />
                            <Button
                                name="surrender"
                                contentLeft={<IconSurrender size="xs" />}
                                text="Сдаться"
                                view="secondary"
                                controller={controller}
                                size="s"
                            />
                            <Button
                                name="menu"
                                text="Выйти в меню"
                                view="secondary"
                                controller={controller}
                                size="s"
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
