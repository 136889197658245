import clsx from "clsx";
import React from "react";

import styles from "./icons.module.pcss";

export const IconBack = ({
    size = "s",
    color = "var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))",
}) => (
    <div className={clsx(styles.icon, styles[size])}>
        <svg width="100%" version="1.1" fill={color} viewBox="0 -25 254 304">
            <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <g id="ico-nazad.eps">
                    <path d="M43 83l143 0c18,0 33,7 45,19 12,12 19,27 19,45 0,18 -7,33 -19,45 -12,12 -27,19 -45,19l-96 0c-6,0 -11,-5 -11,-11 0,-6 5,-11 11,-11l96 0c12,0 22,-5 29,-12 8,-8 12,-18 12,-29 0,-12 -5,-22 -12,-29 -8,-8 -18,-12 -29,-12l-143 0 20 20c4,4 4,11 0,15 -4,4 -11,4 -15,0l-39 -39c-4,-4 -4,-11 0,-15l39 -39c4,-4 11,-4 15,0 4,4 4,11 0,15l-20 20 0 0z" />
                </g>
            </g>
        </svg>
    </div>
);
