import { makeAutoObservable } from "mobx";

import { Controller } from "models/controller";
import { ControlType, DialogType, GameMode, SceneType } from "models/enums";

export class ChoiceSpeedScene {
    _scenes;
    _game;
    _app;
    controller;

    constructor(scenes, app, game) {
        makeAutoObservable(this, { _scenes: false, _game: false, _app: false });
        this._scenes = scenes;
        this._game = game;
        this._app = app;
        this.controller = new Controller({
            ok: ({ name, type, meta }) => {
                if (type === ControlType.BUTTON) {
                    if (name === "back") {
                        this.back();
                    } else if (name === "notification") {
                        this._app.windows.open_dialog(DialogType.NOTIFICATION);
                    } else {
                        const { duration = 0, additional = 0 } = meta;
                        this._game.options.speed.set_duration(duration);
                        this._game.options.speed.set_additional(additional);
                        this._scenes.change(SceneType.CHOICE_COLOR);
                    }
                }
            },
            back: () => this.back(),
            name: "play",
            elements: {
                //
                "1m": {
                    up: "back",
                    down: "3m",
                    left: "2m+1s",
                    right: "1m+1s",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 60, additional: 0 },
                },
                "1m+1s": {
                    up: "back",
                    down: "5m",
                    left: "1m",
                    right: "2m+1s",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 60, additional: 1 },
                },
                "2m+1s": {
                    up: "back",
                    down: "5m+5s",
                    left: "1m+1s",
                    right: "1m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 120, additional: 1 },
                },
                //
                "3m": {
                    up: "1m",
                    down: "10m",
                    left: "5m+5s",
                    right: "5m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 180, additional: 0 },
                },
                "5m": {
                    up: "1m+1s",
                    down: "20m",
                    left: "3m",
                    right: "3m+2s",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 300, additional: 0 },
                },
                "3m+2s": {
                    up: "1m+1s",
                    down: "30m",
                    left: "5m",
                    right: "5m+5s",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 180, additional: 2 },
                },
                "5m+5s": {
                    up: "2m+1s",
                    down: "15m+10s",
                    left: "3m+2s",
                    right: "3m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 300, additional: 5 },
                },
                //
                "10m": {
                    up: "3m",
                    down: "45m",
                    left: "15m+10s",
                    right: "20m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 600, additional: 0 },
                },
                "20m": {
                    up: "5m",
                    down: "60m",
                    left: "10m",
                    right: "30m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 1200, additional: 0 },
                },
                "30m": {
                    up: "3m+2s",
                    down: "30m+30s",
                    left: "20m",
                    right: "15m+10s",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 1800, additional: 0 },
                },
                "15m+10s": {
                    up: "5m+5s",
                    down: "45m+45s",
                    left: "30m",
                    right: "10m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 900, additional: 10 },
                },
                //
                "45m": {
                    up: "10m",
                    down: "play",
                    left: "45m+45s",
                    right: "60m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 2700, additional: 0 },
                },
                "60m": {
                    up: "20m",
                    down: "play",
                    left: "45m",
                    right: "30m+30s",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 3600, additional: 0 },
                },
                "30m+30s": {
                    up: "30m",
                    down: "play",
                    left: "60m",
                    right: "45m+45s",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 1800, additional: 30 },
                },
                "45m+45s": {
                    up: "15m+10s",
                    down: "play",
                    left: "30m+30s",
                    right: "45m",
                    type: ControlType.BUTTON,
                    disabled: true,
                    meta: { duration: 2700, additional: 45 },
                },
                //
                back: {
                    up: "play",
                    down: "1m",
                    left: "notification",
                    right: "notification",
                    type: ControlType.BUTTON,
                },
                notification: {
                    up: "play",
                    down: "1m",
                    left: "back",
                    right: "back",
                    type: ControlType.BUTTON,
                },
                play: {
                    up: "60m",
                    down: "back",
                    left: null,
                    right: null,
                    type: ControlType.BUTTON,
                },
            },
            has_touch_mode: () => !this._app.windows.hasSceneKeyboard(),
        });
    }

    back() {
        switch (this._game.options.mode) {
            case GameMode.HOT_SEAT:
                this._scenes.change(SceneType.CHOICE_MODE);
                break;

            case GameMode.WITH_BOT:
                this._scenes.change(SceneType.CHOICE_BOT);
                break;

            default:
                this._scenes.change(SceneType.CHOICE_MODE);
        }
    }
}
