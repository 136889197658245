import { makeAutoObservable, action } from "mobx";

import { NanoEvents } from "lib/nanoevents";

export default class Controller {
    _root;
    _emitter;
    _hasKeyboard;

    constructor(root, { onCancel = null, hasKeyboard = false } = {}) {
        makeAutoObservable(this, { focus: action, click: action, move: action, pressKey: action });
        this._root = root;
        this._emitter = new NanoEvents();
        this._hasKeyboard = hasKeyboard instanceof Function ? hasKeyboard : () => !!hasKeyboard;
        Object.entries({ cancel: onCancel }).forEach(([name, func]) => {
            if (func !== null && func !== undefined) {
                this._emitter.on(name, func);
            }
        });
    }

    get focusName() {
        // Необходимо для обратной совместимости
        // с первой реализацией интерфейса контроллера
        return this.focusedName;
    }

    get focusedName() {
        const element = this.focusedElement;
        return element && element.name !== null && element.name !== undefined ? element.name : null;
    }

    get focusedElement() {
        if (this._hasKeyboard()) {
            return this._root.focusedElement;
        }
        return undefined;
    }

    find(predicate) {
        return this._root.find(predicate) || null;
    }

    find_by_name(name) {
        return this.findByName(name);
    }

    findByName(name) {
        return this.find(
            (element) =>
                element !== null &&
                element !== undefined &&
                element.name !== null &&
                element.name !== undefined &&
                element.name === name,
        );
    }

    click(name) {
        const isDone = this._root.click(name);
        if (name === "back" && !isDone) {
            if (this._root.cancel instanceof Function && this._root.cancel()) {
                return true;
            }
            this._emitter.emit("cancel");
            return true;
        }
        return isDone;
    }

    focus(name) {
        return this._root.focus(name);
    }

    move(direction) {
        if (this._hasKeyboard()) {
            return this._root.move(direction);
        }
        return false;
    }

    on(name, func) {
        return {
            on: this.on.bind(this),
            cancel: this._emitter.on(name, func),
        };
    }

    initFocus() {
        if (!this.focusedElement) {
            const element = this.find((item) => item && item.name);
            if (element) {
                this.focus(element.name);
            }
        }
    }

    press_key(key) {
        return this.pressKey(key);
    }

    pressKey(key) {
        switch (key) {
            case "ArrowUp":
            case "UP":
                this.move("up");
                break;

            case "ArrowDown":
            case "DOWN":
                this.move("down");
                break;

            case "ArrowLeft":
            case "LEFT":
                this.move("left");
                break;

            case "ArrowRight":
            case "RIGHT":
                this.move("right");
                break;

            case "Enter":
                this.click(this.focusedName);
                break;

            case "Escape":
            case "BACK":
                this.click("back");
                break;

            case "FORWARD":
                this.click("next");
                break;

            default:
                return false;
        }
        return true;
    }
}
