import { action, makeAutoObservable, reaction } from "mobx";

import { PieceColor } from "lib/chess/enums";

import { Board } from "models/board";
import { GameResult, GameType, SceneType } from "models/enums";
import { SimpleTimer } from "models/simple-timer";

class GameSpeed {
    duration;
    additional;

    constructor() {
        makeAutoObservable(this);
        this.duration = 0;
        this.additional = 0;
    }

    get is_active() {
        return this.duration > 0;
    }

    get name() {
        const add = this.additional > 0 ? `${this.additional}s` : "";
        return `${this.duration}m${add}`;
    }

    set_duration(duration = 0) {
        this.duration = duration;
    }

    set_additional(additional = 0) {
        this.additional = additional;
    }

    reset() {
        this.duration = 0;
        this.additional = 0;
    }
}

class GameBot {
    level;

    constructor() {
        makeAutoObservable(this);
        this.level = 0;
    }

    get is_active() {
        return this.level > 0;
    }

    reset() {
        this.level = 0;
    }

    /*
     * бот отключен 0
     * новичок / easy 1-10
     * опытный игрок / medium 11 - 20
     * профи / hard 21 - 30
     * */
    set_level(level) {
        this.level = Math.max(Math.min(level, 40), 0);
    }
}

class GameOptions {
    game_type;
    color;
    speed;
    bot;
    friend;

    constructor() {
        makeAutoObservable(this);
        this.game_type = GameType.HOT_SEAT;
        this.color = null;
        this.speed = new GameSpeed();
        this.bot = new GameBot();
        this.friend_id = null;
    }

    get mode() {
        return this.game_type;
    }

    reset() {
        this.color = null;
        this.friend_id = null;
        this.game_type = GameType.HOT_SEAT;
        this.speed.reset();
        this.bot.reset();
    }

    set_mode(game_type = GameType.HOT_SEAT) {
        this.set_game_type(game_type);
    }

    set_game_type(game_type = GameType.HOT_SEAT) {
        this.game_type = game_type;
    }

    set_color(color = null) {
        this.color = color;
    }

    set_friend(friend_id = null) {
        this.friend_id = friend_id;
    }
}

export class GameStore {
    _app;
    options;
    board;
    server;
    _pause;

    constructor(app, server) {
        makeAutoObservable(this, { app: false, _update_game_status: action.bound });
        this._app = app;
        this.server = server;
        this.options = new GameOptions();
        this.board = null;
        this._pause = null;
        this._open_game_timer = new SimpleTimer();

        reaction(
            () => (this.board && this.board.check) || false,
            (check, prev) => {
                if (check && !prev) {
                    this._app.audio.sounds.play("check");
                }
            },
        );

        reaction(
            () => {
                const board = this.board;
                if (board) {
                    return { result: board._game_result, side: board.side };
                }
                return { result: null, side: null };
            },
            ({ result, side }, prev) => {
                if (
                    result !== null &&
                    result !== GameResult.NONE &&
                    prev.result === GameResult.NONE
                ) {
                    const white_won =
                        [GameResult.WHITE_WON, GameResult.BLACK_SURRENDERED].indexOf(result) !== -1;
                    const black_won =
                        [GameResult.BLACK_WON, GameResult.WHITE_SURRENDERED].indexOf(result) !== -1;

                    if (
                        (white_won && side === PieceColor.WHITE) ||
                        (black_won && side === PieceColor.BLACK)
                    ) {
                        this._app.audio.sounds.play("game_win");
                    } else if (result === GameResult.DRAW) {
                        this._app.audio.sounds.play("game_win");
                    } else {
                        this._app.audio.sounds.play("game_lose");
                    }
                }
            },
        );

        setInterval(() => this._refresh_game_status(), 1000);
    }

    _board_listener(name, params = {}) {
        // eslint-disable-next-line default-case
        switch (name) {
            case "moveFinish":
                if (params.drop) {
                    this._app.audio.sounds.play("piece_drop");
                } else {
                    this._app.audio.sounds.play("piece_down");
                }
                break;

            case "moveCancel":
                // this._app.audio.sounds.play("piece_down");
                break;

            case "moveStart":
                this._app.audio.sounds.play("piece_up");
                break;

            case "moveAccessDeny":
                this._app.audio.sounds.play("cant_move");
                break;
        }
    }

    async create_game() {
        this.board = new Board(
            null,
            this._app.windows.scenes._scene_by_type[SceneType.GAME].controller,
            (...params) => this._board_listener(...params),
        );

        const game_server = await this.server.game.create({
            mode: this.options.mode,
            bot: { level: this.options.bot.level },
            color: this.options.color,
            speed: {
                duration: this.options.speed.duration,
                additional: this.options.speed.additional,
            },
        });
        this._app.analytics.send_create_game({
            game_id: game_server.game_id,
            game_mode: this.options.mode,
            side: this.options.color,
            bot_level: this.options.bot.level,
            speed_duration: this.options.speed.duration,
            speed_additional: this.options.speed.additional,
        });
        this._app.analytics.send_open_game({ game_id: game_server.game_id });

        this._open_game_timer.restart();
        this.board.set_game_server(game_server);
        await this.board.reload();
    }

    async open_game({ id }) {
        this._app.analytics.send_open_game({ game_id: id });
        this._open_game_timer.restart();
        this.board = new Board(
            this.server.game[id],
            this._app.windows.scenes._scene_by_type[SceneType.GAME].controller,
            (...params) => this._board_listener(...params),
        );
        await this.board.reload();
    }

    get pause_status() {
        return (
            this._pause || {
                is_active: false,
                can_continue: true,
                count: -1,
                until_at_time: null,
                activated_at: null,
            }
        );
    }

    get is_paused() {
        return this.pause_status.is_active;
    }

    close_game() {
        const board = this.board || null,
            game_server = (board && board.game_server) || null;
        if (game_server) {
            this._app.analytics.send_close_game({
                game_id: game_server.game_id,
                finished: board.finished,
                duration: this._open_game_timer.time,
            });
            game_server.leave().catch((error) => console.error(error.message, error));
        }
        this.board = null;
        this._pause = null;
    }

    pause() {
        const board = this.board || null,
            game_server = (board && board.game_server) || null;
        if (game_server) {
            game_server
                .pause()
                .then(this._update_game_status)
                .catch((error) => console.error(error.message, error));
        }
    }

    resume() {
        const board = this.board || null,
            game_server = (board && board.game_server) || null;
        if (game_server) {
            game_server
                .resume()
                .then(this._update_game_status)
                .catch((error) => console.error(error.message, error));
        }
    }

    _refresh_game_status() {
        const board = this.board || null,
            game_server = (board && board.game_server) || null;
        if (board && game_server) {
            game_server
                .retrieve_status()
                .then(this._update_game_status)
                .catch((error) => console.error(error.message, error));
        }
    }

    _update_game_status(game_status) {
        this.board && this.board.update_game_status(game_status);
        this._pause = game_status.pause;
    }
}
