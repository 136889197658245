import { makeAutoObservable, runInAction } from "mobx";

import { Controller, Group, GraphMover, CarouselGroup, OneOfGroup } from "models/controller/v2";
import {
    ControlType,
    DialogType,
    LoadState,
    TutorialType,
    UserRank,
    UserRelation,
} from "models/enums";

import Tutorial from "models/tutorial";

import TopOfRankWithPages from "./top-of-ranks-with-pages";

const RANKS = [
    UserRank.GRANDMASTER,
    UserRank.INTERNATIONAL_MASTER,
    UserRank.NATIONAL_MASTER,
    UserRank.CANDIDATE_MASTER,
    UserRank.FIRST_GRADE,
    UserRank.SECOND_GRADE,
    UserRank.THIRD_GRADE,
    UserRank.FOURTH_GRADE,
    UserRank.FIFTH_GRADE,
    UserRank.NEWBIE,
];

export default class TopOfRanksScene {
    _app;
    _emitter;
    _isActive;
    _rank;
    _personal;
    _topLimit;
    _path;
    _controller;
    state;
    games;
    tutorial;

    constructor(app, _, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this.state = LoadState.INIT;
        this._isActive = false;
        this._rank = UserRank.NEWBIE;
        this._personal = null;
        this._topLimit = 100;
        this._tops = {};
        this._topsCarousel = {};
        this._path = null;
        this.tutorial = null;

        RANKS.forEach((rank) => {
            this._tops[rank] = new TopOfRankWithPages(this._app, {
                rank,
                offset: 0,
                limit: this._topLimit,
                items: [],
            });
            this._topsCarousel[rank] = new CarouselGroup(`top:${rank}`, {
                nodes: () =>
                    this._tops[rank].items.map((position) => ({
                        type: ControlType.ITEM,
                        name: `user:id:${position.user.id}`,
                        meta: position,
                        onClick: () => {
                            const { user } = position;
                            if (user && user.id) {
                                if (user.is_self) {
                                    this._app.windows.dialogs.open(DialogType.PROFILE_SETTINGS);
                                    return true;
                                }

                                if (user.relation === UserRelation.FRIEND) {
                                    const dialog = this._app.windows.dialogs.open(
                                        DialogType.PROFILE,
                                        {
                                            user_id: user.id,
                                            buttons: [
                                                { name: "game:invite", view: "primary" },
                                                { name: "friend:exclude", view: "secondary" },
                                            ],
                                        },
                                    );
                                    dialog.once("game.invite.apply", () => {
                                        this.close();
                                    });
                                } else if (user.relation === UserRelation.BLOCKED) {
                                    this._app.windows.dialogs.open(DialogType.PROFILE, {
                                        user_id: user.id,
                                        buttons: [
                                            { name: "friend:invite", view: "primary" },
                                            { name: "block:cancel", view: "secondary" },
                                        ],
                                    });
                                } else if (user.relation === UserRelation.NONE && !user.is_self) {
                                    this._app.windows.dialogs.open(DialogType.PROFILE, {
                                        user_id: user.id,
                                        buttons: [
                                            { name: "friend:invite", view: "primary" },
                                            // { name: "user:block", view: "secondary" },
                                        ],
                                    });
                                }
                                return true;
                            }
                            return false;
                        },
                    })),
                direction: "row",
                basis: 1,
            });
        });

        this.ranks = new CarouselGroup("ranks", {
            nodes: () =>
                RANKS.map((rank) => ({
                    type: ControlType.ITEM,
                    name: `rank:${rank}`,
                    meta: rank,
                    onClick: () => {
                        this.ranks.focus(`rank:${rank}`);
                        this._rank = rank;
                        this._tops[rank].reload();
                    },
                })),
            direction: "row",
            basis: 1,
            onChangeFocus: (next) => {
                const rank = next.meta;
                if (this._rank !== rank) {
                    this._rank = rank;
                    this._tops[rank].reload();
                }
            },
        });

        this._controller = new Controller(
            new Group("scene", {
                nodes: [
                    {
                        type: ControlType.BUTTON,
                        name: "notification",
                        onClick: () => {
                            this._app.windows.dialogs.open(DialogType.NOTIFICATION);
                        },
                    },
                    this.ranks,
                    new OneOfGroup("top", {
                        nodes: () => [
                            {
                                type: ControlType.BUTTON,
                                name: "reload",
                                onClick: () => {
                                    this._tops[this._rank].reload();
                                },
                            },
                            ...Object.values(this._topsCarousel),
                        ],
                        switcher: (nodes) => {
                            const { type } = this.top;

                            let name;
                            if (type === "top") {
                                name = this.top.name;
                            } else if (type === "empty" || type === "failure") {
                                name = "reload";
                            }

                            return nodes.find((item) => item && item.name === name);
                        },
                    }),
                ],
                mover: new GraphMover({
                    notification: { down: "top", left: "ranks" },
                    ranks: { up: "notification", left: "top", right: "top" },
                    top: { up: "notification", left: "ranks", right: "ranks" },
                }),
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasSceneKeyboard(),
            },
        );
        this.tutorial = null;
    }

    get controller() {
        if (this.tutorial) {
            return this.tutorial.controller;
        }
        return this._controller;
    }

    get isLoading() {
        return this.state === LoadState.LOADING || !this._isActive;
    }

    get top() {
        if (this.isLoading) {
            return { type: "skeleton", rank: this._rank };
        }

        const top = this._tops[this._rank];
        if (!top || top.state === LoadState.FAILURE) {
            return { type: "failure", rank: this._rank };
        }

        if (top && top.isSpinner) {
            return { type: "spinner", rank: top.rank };
        }

        const carousel = this._topsCarousel[top.rank];
        if (top && top.count === 0) {
            return { type: "empty", rank: top.rank, name: carousel.name, carousel, meta: top };
        }
        return { type: "top", rank: top.rank, name: carousel.name, carousel, meta: top };
    }

    get user() {
        if (this._personal && this._personal.user) {
            const { id, nickname, rating, rank } = this._personal.user;
            return {
                number: this._personal.number || 9999,
                nickname: nickname || `ID ${id}`,
                rating,
                rank,
            };
        }
        return null;
    }

    get assistantPath() {
        return this._path;
    }

    close() {
        this._emitter.emit("close", this);
    }

    onPreload() {
        this.state = LoadState.LOADING;
        this._app.server.rating
            .retrieve_top_by_user({ limit: this._topLimit })
            .then(({ self, top }) =>
                runInAction(() => {
                    this.state = LoadState.SUCCESS;
                    this._personal = self;
                    this._rank = self.user.rank || null;
                    const topByRank = this._tops[this._rank];
                    if (topByRank) {
                        topByRank.updateState(top, LoadState.SUCCESS);
                    }
                    const carousel = this._topsCarousel[this._rank];
                    if (carousel) {
                        const userIndex = carousel.children.findIndex(
                            ({ name }) =>
                                name && self && self.user && name === `user:id:${self.user.id}`,
                        );
                        carousel.setAlternativeIndex(userIndex);
                    }
                    this._controller.focus(`rank:${this._rank}`);
                }),
            )
            .catch(() =>
                runInAction(() => {
                    this.state = LoadState.FAIL;
                }),
            );
    }

    onActivate() {
        this._isActive = true;

        this.tutorial = null;
        if (!this._app.user.tutorial.top_of_ranks_basis) {
            this.tutorial = new Tutorial(this._app, {
                name: TutorialType.TOP_OF_RANKS_BASIS,
                path: "/tutorial/top_of_ranks_basis",
                steps: [
                    "position",
                    "self_rating",
                    "rating_list",
                    // "rating_pagination",
                    "rank_tabs",
                ],
            });
            this.tutorial.once("finished", () => {
                runInAction(() => {
                    this.tutorial = null;
                    this._app.assistant.transition("/scene/top_of_ranks");
                });
            });
            this._app.assistant.transition(this.tutorial.assistantPath);
        } else {
            this._app.assistant.fire_event("event_activate_scene");
        }
    }

    onDeactivate() {
        this._isActive = false;
    }

    // onDestroy() {}
}
