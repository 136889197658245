import { makeAutoObservable } from "mobx";

import {
    CarouselGroup,
    Controller,
    GraphMover,
    Group,
    OneOfGroup,
    SimpleElement,
} from "models/controller/v2";
import { TextField } from "models/components";
import { ControlType, SceneType } from "models/enums";

const TAB_NICKNAME = "tab:nickname";
const CONTENT_NICKNAME = "content:nickname";
const FIELD_NICKNAME = "field:nickname";
const SAVE_NICKNAME = "save:nickname";
const ROLLBACK_NICKNAME = "reset:nickname";

const TAB_AVATAR = "tab:avatar";
const CONTENT_AVATAR = "content:avatar";
const CAROUSEL_AVATAR = "carousel:avatar";
const SAVE_AVATAR = "save:avatar";

export default class ProfileSettingsEditorScene {
    _app;
    _emitter;
    _isActive;
    controller;
    tab;
    tabContent;
    avatars;
    nickname;
    avatarPresetId;

    constructor(app, params = {}, { emitter } = {}) {
        makeAutoObservable(this, { _app: false });
        this._app = app;
        this._emitter = emitter;
        this._isActive = false;
        this.nickname = new TextField({
            value: params.nickname || this._app.user.profile.nickname || "",
        });
        this.avatarPresetId =
            params.avatar_preset ||
            params.avatar_preset_id ||
            this._app.user.profile.avatar_preset_id ||
            1;
        this.tab = TAB_NICKNAME;
        this.tabContent = CONTENT_NICKNAME;

        this.avatars = new CarouselGroup(CAROUSEL_AVATAR, {
            nodes: () =>
                [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
                    23, 24, 25,
                ].map(
                    (avatarPresetId) =>
                        new SimpleElement(`avatar:preset:${avatarPresetId}`, {
                            type: ControlType.ITEM,
                            meta: avatarPresetId,
                            disabled: () => this.avatarPresetId === avatarPresetId,
                            onClick: (self) => {
                                if (!self.disabled) {
                                    this.avatarPresetId = avatarPresetId;
                                    this.saveAvatar();
                                }
                            },
                        }),
                ),
            basis: 1,
            direction: "column",
        });

        const avatarContent = new Group(CONTENT_AVATAR, {
            nodes: [
                this.avatars,
                new SimpleElement(SAVE_AVATAR, {
                    type: ControlType.BUTTON,
                    disabled: () => this.avatarPresetId === this._app.user.profile.avatar_preset_id,
                    onClick: (self) => {
                        if (!self.disabled) {
                            this.saveAvatar();
                        }
                    },
                }),
            ],
        });

        const nicknameContent = new Group(CONTENT_NICKNAME, {
            nodes: [
                {
                    type: ControlType.INPUT,
                    name: FIELD_NICKNAME,
                    onClick: () => {
                        this.nickname.toggleEdit();
                    },
                    onCancel: () => {
                        if (this.nickname.isEditing) {
                            this.rollbackNickname();
                            return true;
                        }
                        return false;
                    },
                },
                new SimpleElement(SAVE_NICKNAME, {
                    type: ControlType.BUTTON,
                    disabled: () => {
                        let nickname = this._app.user.profile.nickname || null;
                        nickname = nickname ? nickname.toLocaleLowerCase() : null;
                        return this.nickname.value.toLowerCase() === nickname;
                    },
                    onClick: (self) => {
                        if (!self.disabled) {
                            this.saveNickname();
                        }
                    },
                }),
                {
                    type: ControlType.BUTTON,
                    name: ROLLBACK_NICKNAME,
                    onClick: () => {
                        this.rollbackNickname();
                    },
                },
            ],
            mover: () => {
                this.rollbackNickname();
                return undefined;
            },
        });

        this.controller = new Controller(
            new Group("root", {
                nodes: [
                    new OneOfGroup("content", {
                        nodes: [nicknameContent, avatarContent],
                        switcher: (nodes) =>
                            nodes.find((item) => item && item.name === this.tabContent),
                    }),
                    {
                        type: ControlType.TAB,
                        name: TAB_NICKNAME,
                        onClick: () => {
                            this.tab = TAB_NICKNAME;
                            this.tabContent = CONTENT_NICKNAME;
                            nicknameContent.focus(FIELD_NICKNAME);
                            this.controller.focus("content");
                        },
                    },
                    {
                        type: ControlType.TAB,
                        name: TAB_AVATAR,
                        onClick: () => {
                            this.tab = TAB_AVATAR;
                            this.tabContent = CONTENT_AVATAR;
                            this.controller.focus("content");
                            this.avatars.focus(`avatar:preset:${this.avatarPresetId}`);
                        },
                    },
                ],
                mover: new GraphMover({
                    [TAB_NICKNAME]: { left: TAB_NICKNAME, right: TAB_AVATAR, down: "content" },
                    [TAB_AVATAR]: { left: TAB_NICKNAME, right: TAB_NICKNAME, down: "content" },
                    content: { up: TAB_NICKNAME },
                }),
            }),
            {
                onCancel: () => this.close(),
                hasKeyboard: () => this._app.windows.hasSceneKeyboard(),
            },
        );
    }

    get stateAssistant() {
        return {
            is_auto_help: this._app.user.has_scene_auto_help(SceneType.PROFILE_SETTINGS_EDITOR),
        };
    }

    get isLoading() {
        return !this._isActive;
    }

    close() {
        this._emitter.emit("close", this);
    }

    saveNickname() {
        this._app.user.updateProfile(
            { nickname: this.nickname.value ? this.nickname.value.trim() : this.nickname.value },
            ({ nickname = [] } = {}) => {
                if (nickname && nickname.length > 0) {
                    this.nickname.errors = nickname
                        .map(({ code, message }) => {
                            const codeMessage = {
                                1: "Длинна должна быть от 3 до 16 символов",
                                2: "Используй только буквы, цифры и подчеркивание",
                                3: "Никнейм не уникален",
                                4: "Ненормативная лексика и оскорбительные слова запрещены!",
                            }[code];
                            return codeMessage || message || "Не валидный nickname";
                        })
                        .filter((item) => item !== null);
                } else {
                    this.nickname.errors = [];
                }
            },
        );
    }

    rollbackNickname() {
        const nickname = this._app.user.profile.nickname || null;
        this.nickname.finishEdit();
        this.nickname.value = nickname || "";
        this.nickname.errors = [];
    }

    saveAvatar() {
        this._app.user.updateProfile(
            { avatar_preset_id: this.avatarPresetId },
            ({ avatar_preset_id: avatarPresetId = [] } = {}) => {
                if (avatarPresetId && avatarPresetId.length > 0) {
                    //
                }
            },
        );
    }

    onPreload() {
        this._isActive = false;
    }

    onActivate() {
        this._isActive = true;
    }

    // onDeactivate() {}
    // onDestroy() {}
}
