import { ControlType, SceneType } from "models/enums";

const DEFAULT_ELEMENTS = {
    exit_ok: {
        left: null,
        right: null,
        up: "exit_cancel",
        down: "exit_cancel",
        type: ControlType.BUTTON,
    },
    exit_cancel: {
        left: null,
        right: null,
        up: "exit_ok",
        down: "exit_ok",
        type: ControlType.BUTTON,
    },
    back: {
        left: "exit_ok",
        right: "exit_cancel",
        up: "exit_ok",
        down: "exit_ok",
        type: ControlType.BUTTON,
    },
};

export default (scene) => ({
    ok: ({ name, type, meta }) => {
        if (type === ControlType.BUTTON) {
            switch (name) {
                case "exit_ok":
                    scene._game.close_game();
                    scene._scenes.change(SceneType.HOME);
                    break;

                case "back":
                case "exit_cancel":
                    scene._game.resume();
                    break;

                default:
                    return;
            }
        }
    },
    back: () => {
        scene._game.resume();
    },
    name: "exit_cancel",
    find: (name) => {
        const element = { ...DEFAULT_ELEMENTS[name] };
        if (element) {
            switch (name) {
                case "exit_cancel":
                case "back":
                    element.disabled = !scene._game.pause_status.can_continue;
                    break;
            }
            return element;
        }
        return null;
    },
    has_touch_mode: () => scene._app.is_touch_mode,
});
