import clsx from "clsx";
import React from "react";
import { observer } from "mobx-react-lite";

import styles from "./scene.module.pcss";

export const InitScene = observer(() => (
    <div className={clsx(styles.view)}>
        <div className={clsx(styles.text)}>Загрузка...</div>
        {/*<div className={clsx(styles.progress)}>*/}
        {/*    <div className={clsx(styles.fill)}*/}
        {/*         style={{ right: `${100 - scene.progress.percent}%` }} />*/}
        {/*</div>*/}
    </div>
));
