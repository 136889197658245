import { makeAutoObservable } from "mobx";

export class SimpleTimer {
    _time;
    _start_at;
    _state;
    _auto_start;

    constructor({ auto_start = false, refresh_time = 500 } = {}) {
        makeAutoObservable(this);
        this._time = 0;
        this._start_at = null;
        this._state = "stopped";
        this._auto_start = auto_start;
        this.reset();
        this._interval = setInterval(() => this._refresh(new Date().getTime()), refresh_time);
    }

    _refresh(now = null) {
        if (this._state === "running") {
            this._time += this._start_at ? new Date().getTime() - this._start_at : 0;
            this._start_at = now;
        }
    }

    get time() {
        return this._time + (this._start_at ? new Date().getTime() - this._start_at : 0);
    }

    start() {
        if (this._state === "stopped") {
            this._start_at = new Date().getTime();
            this._state = "running";
        }
    }

    stop() {
        this._refresh();
        this._state = "stopped";
    }

    restart({ now = null } = {}) {
        this.reset();
        this.start();
        if (now != null) {
            this._start_at = now;
        }
    }

    reset() {
        this._time = 0;
        this._start_at = null;
        this._state = "stopped";
        if (this._auto_start) {
            this.start();
        }
    }

    pause() {
        if (this._state === "running") {
            this._refresh();
            this._state = "pause";
        }
    }

    resume() {
        if (this._state === "pause") {
            this._start_at = new Date().getTime();
            this._state = "running";
        }
    }

    destroy() {
        clearInterval(this._interval);
        this._interval = null;
        this._state = null;
    }
}
