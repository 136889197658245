import React from "react";
import { observer } from "mobx-react-lite";

import { ActionButton as PlasmaActionButton } from "@sberdevices/plasma-ui/components/Button";

import { RectSkeleton } from "./index";

export const ActionButton = observer(({ name, controller = null, skeleton = false, ...params }) => {
    if (skeleton) {
        return <RectSkeleton width="2.25rem" height="2.25rem" roundness={8} />;
    }
    const element = (controller && controller.find_by_name(name)) || null;
    const disabled = element ? element.disabled : false;
    const focused = element
        ? element.focused ||
          (name !== null && controller && name === controller.focusName) ||
          (name && controller && name === controller.focusedName)
        : false;
    const onClick =
        element && element.click
            ? () => element.click()
            : () => controller && controller.click(name);
    return (
        <PlasmaActionButton
            onClick={onClick}
            disabled={disabled}
            outlined={focused}
            focused={focused}
            {...params}
        />
    );
});
