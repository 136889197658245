import { ServerException } from "./exceptions";
import { load_user_rank, load_user_relation, load_user_status } from "./types";

export class ProfileAPI {
    constructor(server) {
        this.server = server;
    }

    async retrieve({ user_id = null } = {}) {
        if (user_id === null) {
            const response = await this.server.get("/profile/self", {});
            const { profile = {} } = response.data || {};
            return this._load_self_profile(profile);
        }

        const response = await this.server.post("/profile", { user_id });
        const { profile = {} } = response.data || {};
        return this._load_profile(profile);
    }

    async update({
        user_id = null,
        avatar_preset_id = null,
        nickname = null,
        settings = null,
    } = {}) {
        const response = await this.server.request("post", "/profile/self/update", {
            data: { avatar_preset_id, nickname, settings },
        });
        const { profile = {}, errors = [] } = response.data || {};

        const error = errors.find(({ exception = null }) => exception !== "validation");
        if (error) {
            throw new ServerException(error);
        }

        const validation = { nickname: [], avatar_preset_id: [] };
        errors.forEach(({ exception = null, code = null, field = null, message = "" }) => {
            if (exception === "validation" && field in validation) {
                validation[field].push({ code, message });
            }
        });

        return { ...this._load_self_profile(profile), $validation: validation };
    }

    _load_self_profile({
        id,
        nickname,
        avatar_preset_id,
        rating,
        rank,
        friend_count,
        settings,
        modified_at,
    }) {
        return {
            id,
            nickname,
            avatar_preset_id,
            rating,
            rank: load_user_rank(rank),
            friend_count,
            settings,
            modified_at: modified_at || new Date().getTime() / 1000.0,
        };
    }

    _load_profile({ id, nickname, avatar_preset_id, rating, rank, relation, status, modified_at }) {
        return {
            id,
            nickname,
            avatar_preset_id,
            rating,
            rank: load_user_rank(rank),
            relation: load_user_relation(relation),
            status: load_user_status(status),
            modified_at,
        };
    }
}
