import React from "react";
import { observer } from "mobx-react-lite";

import {
    HeaderRoot as PlasmaHeaderRoot,
    HeaderBack as PlasmaHeaderBack,
    HeaderContent as PlasmaHeaderContent,
    HeaderTitleWrapper as PlasmaHeaderTitleWrapper,
    HeaderTitle as PlasmaHeaderTitle,
    HeaderSubtitle as PlasmaHeaderSubtitle,
} from "@sberdevices/plasma-ui/components/Header";

import { NotificationButton } from "components";

import styles from "./header.module.pcss";

export const Header = observer(
    ({
        controller = null,
        title = null,
        sub_title = null,
        skeleton = false,
        notification = null,
        without_back = false,
        children = null,
    }) => {
        const backName = "back";
        const backElement = (controller && controller.findByName(backName)) || null;
        const backDisabled = backElement ? backElement.disabled : false;
        const backFocused = backElement
            ? backElement.focused || (backName !== null && backName === controller.focusedName)
            : false;

        return (
            <PlasmaHeaderRoot>
                {!without_back && (
                    <PlasmaHeaderBack
                        onClick={() => controller && controller.click("back")}
                        outlined={backFocused}
                        focused={backFocused}
                        disabled={backDisabled || skeleton}
                        skeleton={skeleton}
                    />
                )}

                {title && (
                    <PlasmaHeaderTitleWrapper>
                        {title && <PlasmaHeaderTitle>{title}</PlasmaHeaderTitle>}
                        {sub_title && <PlasmaHeaderSubtitle>{sub_title}</PlasmaHeaderSubtitle>}
                    </PlasmaHeaderTitleWrapper>
                )}

                <PlasmaHeaderContent>
                    <div className={styles.children}>
                        <NotificationButton
                            notification={notification}
                            controller={controller}
                            skeleton={skeleton}
                        />
                        {children}
                    </div>
                </PlasmaHeaderContent>
            </PlasmaHeaderRoot>
        );
    },
);
