import { makeAutoObservable } from "mobx";

import { NanoEvents } from "lib/nanoevents";

import { LoadState, SceneType } from "models/enums";
import {
    ChoiceBotScene,
    ChoiceColorScene,
    ChoiceModeScene,
    ChoicePVPModeScene,
    ChoiceSpeedScene,
    ContinueScene,
    FriendsScene,
    GameScene,
    HomeScene,
    InitScene,
    TechInfoScene,
    TopOfRanksScene,
    UsersBlockedScene,
    UsersInvitedScene,
    UsersSearchScene,
    UsersSearchResultScene,
    ProfileSettingsEditorScene,
} from "models/scenes";
import { SimpleTimer } from "models/simple-timer";
import Scene from "models/window";

const SCENE_TYPE_TO_DATA_CLASS = {
    [SceneType.CONTINUE]: ContinueScene,
    [SceneType.FRIENDS]: FriendsScene,
    [SceneType.TECH_INFO]: TechInfoScene,
    [SceneType.TOP_OF_RANKS]: TopOfRanksScene,
    [SceneType.USERS_SEARCH]: UsersSearchScene,
    [SceneType.USERS_SEARCH_RESULT]: UsersSearchResultScene,
    [SceneType.PROFILE_SETTINGS_EDITOR]: ProfileSettingsEditorScene,
};

const SCENE_TYPE_TO_PATH = {
    [SceneType.HOME]: "/scene/home",
    [SceneType.GAME]: "/scene/game",
    [SceneType.CONTINUE]: "/scene/continue",
    [SceneType.TUTORIAL]: "/scene/tutorial",
    [SceneType.CHOICE_MODE]: "/scene/choice_mode",
    [SceneType.CHOICE_SPEED]: "/scene/choice_speed",
    [SceneType.CHOICE_COLOR]: "/scene/choice_color",
    [SceneType.CHOICE_BOT]: "/scene/choice_bot",
    [SceneType.CHOICE_PLAYER]: "/scene/choice_player",
    [SceneType.CHOICE_PVP_MODE]: "/scene/choice_pvp_mode",
    [SceneType.CHOICE_FRIEND]: "/scene/choice_friend",
    [SceneType.TOP_OF_RANKS]: "/scene/top_of_ranks",
    [SceneType.FRIENDS]: "/scene/friends",
    [SceneType.USERS_BLOCKED]: "/scene/users_blocked",
    [SceneType.USERS_SEARCH]: "/scene/users_search",
    [SceneType.USERS_SEARCH_RESULT]: "/scene/users_search_result",
    [SceneType.USERS_INVITED]: "/scene/users_invited",
    [SceneType.PROFILE_SETTINGS_EDITOR]: "/scene/profile_settings_editor",
};

export default class ScenesStore {
    app;
    server;
    _type;
    _state;
    _on_scene_timer;
    _stack;
    _scene_by_type;

    constructor(app) {
        makeAutoObservable(this, { app: false });
        this._state = LoadState.INIT;
        this._type = SceneType.INIT;
        this.app = app;
        this._on_scene_timer = new SimpleTimer({ auto_start: true });
        this._stack = [];
        this._scene_by_type = {
            [SceneType.INIT]: new Scene({
                type: SceneType.INIT,
                data: new InitScene(this, app, {
                    use_assistant: true,
                    // use_assistant: !process.env.IS_DEV_MODE,
                    show_logo_time: 3000,
                }),
            }),
            [SceneType.GAME]: new Scene({
                type: SceneType.GAME,
                data: new GameScene(this, app, app.game),
            }),
            [SceneType.HOME]: new Scene({
                type: SceneType.HOME,
                data: new HomeScene(this, app, app.game),
            }),
            [SceneType.CHOICE_MODE]: new Scene({
                type: SceneType.CHOICE_MODE,
                data: new ChoiceModeScene(this, app, app.game),
            }),
            [SceneType.CHOICE_COLOR]: new Scene({
                type: SceneType.CHOICE_COLOR,
                data: new ChoiceColorScene(this, app, app.game),
            }),
            [SceneType.CHOICE_SPEED]: new Scene({
                type: SceneType.CHOICE_SPEED,
                data: new ChoiceSpeedScene(this, app, app.game),
            }),
            [SceneType.CHOICE_BOT]: new Scene({
                type: SceneType.CHOICE_BOT,
                data: new ChoiceBotScene(this, app, app.game),
            }),
            [SceneType.CHOICE_PVP_MODE]: new Scene({
                type: SceneType.CHOICE_PVP_MODE,
                data: new ChoicePVPModeScene(this, app, app.game),
            }),
            [SceneType.USERS_BLOCKED]: new Scene({
                type: SceneType.USERS_BLOCKED,
                data: new UsersBlockedScene(this, app),
            }),
            [SceneType.USERS_INVITED]: new Scene({
                type: SceneType.USERS_INVITED,
                data: new UsersInvitedScene(this, app),
            }),
        };
    }

    get assistant() {
        return this.app.assistant;
    }

    get assistantState() {
        if (this.current) {
            return this.current.assistantState || this.current.state || {};
        }
        return {};
    }

    get assistantPath() {
        if (this.currentWindow) {
            return this.currentWindow.assistantPath || SCENE_TYPE_TO_PATH[this.currentWindow.type];
        }
        return undefined;
    }

    get analytics() {
        return this.app.analytics;
    }

    get stack() {
        return this._stack;
    }

    get _scene() {
        return this.currentWindow;
    }

    get currentWindow() {
        if (this._stack.length > 0) {
            return this._stack[this._stack.length - 1];
        }
        return this._scene_by_type[this._type] || null;
    }

    get current() {
        const { data: scene = null } = this._scene || {};
        return scene;
    }

    get controller() {
        const scene = this.currentWindow;
        return scene ? scene.controller : null;
    }

    get type() {
        const { type = null } = this._scene || {};
        return type;
    }

    get is_loading() {
        return this._state === LoadState.LOADING;
    }

    __init__() {
        if (this._state === LoadState.INIT && this._scene) {
            this._scene.preload();
            this._scene.activate();
        }
    }

    open(type, params = {}) {
        if (type in SCENE_TYPE_TO_DATA_CLASS) {
            const DataClass = SCENE_TYPE_TO_DATA_CLASS[type];
            const emitter = new NanoEvents();
            const scene = new Scene({
                type,
                data: new DataClass(this.app, params, { emitter }),
            });
            emitter.on("close", () => this.remove(scene.id));
            this._stack.push(scene);
        }
    }

    remove(id) {
        const scene = this._stack.find((item) => item.id !== id);
        if (scene) {
            scene.destroy();
        }
        this._stack.replace(this._stack.filter((item) => item.id !== id));
    }

    change(type, params = {}) {
        if (type in this._scene_by_type) {
            this._scene_by_type[type].data.params = params;
            this._type = type;
        }
    }
}
