import React from "react";
import { createGlobalStyle } from "styled-components";
import { observer } from "mobx-react-lite";

import { darkEva as darkAthena, darkJoy, darkSber } from "@sberdevices/plasma-tokens/themes";

import { AssistantType } from "models/enums";

import app_background_chess_sber from "assets/images/bg_chess_sber.svg";
import app_background_chess_athena from "assets/images/bg_chess_athena.svg";
import app_background_chess_joy from "assets/images/bg_chess_joy.svg";

import { AvatarsAthena, AvatarsJoy, AvatarsSber } from "./avatars";
import { WatchAthena, WatchJoy, WatchSber } from "./watch";

const mutation = (base, params, key = ":root") => ({ ...base, [key]: { ...base[key], ...params } });

const ThemeSber = createGlobalStyle(
    mutation(darkSber, {
        ...WatchSber,
        ...AvatarsSber,
        "--app-background-chess": `url("${app_background_chess_sber}")`,
        "--assistant-square-move-from-color": "#78D689",
        "--assistant-square-move-to-color": "#78D689",
    }),
);

const ThemeAthena = createGlobalStyle(
    mutation(darkAthena, {
        ...WatchAthena,
        ...AvatarsAthena,
        "--app-background-chess": `url("${app_background_chess_athena}")`,
        "--assistant-square-move-from-color": "#80BDE5",
        "--assistant-square-move-to-color": "#80BDE5",
    }),
);

const ThemeJoy = createGlobalStyle(
    mutation(darkJoy, {
        ...WatchJoy,
        ...AvatarsJoy,
        "--app-background-chess": `url("${app_background_chess_joy}")`,
        "--assistant-square-move-from-color": "#CCA5E6",
        "--assistant-square-move-to-color": "#CCA5E6",
    }),
);

export const PlasmaTheme = observer(({ assistant }) => {
    switch (assistant.name) {
        case AssistantType.ATHENA:
            return <ThemeAthena />;

        case AssistantType.JOY:
            return <ThemeJoy />;

        case AssistantType.SBER:
            return <ThemeSber />;
    }
    return null;
});
