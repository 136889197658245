import axios from "axios";

import PlayerVsPlayerAPI from "./player_vs_player";

import { Game, GameAPI } from "./game";
import { NotificationAPI } from "./notification";
import { FriendAPI } from "./friend";
import { RatingAPI } from "./rating";
import { ProfileAPI } from "./profile";
import { ServerException } from "./exceptions";

export class Server {
    config;
    user;

    constructor(config, user, { host = null, port = null, api_path = "", is_secure = false } = {}) {
        this.config = config;
        this.user = user;
        this.host = host;
        this.port = port;
        this.api_path = api_path;
        if ((port === 443 && is_secure) || (port === 80 && !is_secure)) {
            port = null;
        }
        this.is_secure = is_secure;
        this.game = new Proxy(new GameAPI(this), {
            get: (self, game_id, receiver) => {
                if (game_id in self) {
                    return self[game_id];
                }

                if (Number.isInteger(Number(game_id))) {
                    self[game_id] = new Game(self, game_id);
                }

                return self[game_id];
            },
        });
        this.pvp = new PlayerVsPlayerAPI(this);
        this.friend = new FriendAPI(this);
        this.notification = new NotificationAPI(this);
        this.rating = new RatingAPI(this);
        this.profile = new ProfileAPI(this);
    }

    get auth_token() {
        return this.config.auth_token || this.user.auth_token || "fake-token";
    }

    make_url(path, schema = "http") {
        if (this.host === null) {
            return `${this.api_path || ""}${path}`;
        }
        return `${schema}${this.is_secure ? "s" : ""}://${this.host}${
            this.port ? ":" + this.port : ""
        }${this.api_path}${path}`;
    }

    async _request(method, path, config = {}) {
        return axios.request({
            responseType: "json",
            ...config,
            method,
            url: this.make_url(path),
            headers: { token: this.auth_token },
        });
    }

    async request(method, path, config = {}) {
        try {
            return await this._request(method, path, config);
        } catch (exception) {
            if (exception.response && exception.response.status === 401) {
                await this.user.refresh_auth_token();
                return this._request(method, path, config);
            }
            throw exception;
        }
    }

    async get(path, params = null, config = {}) {
        return this._check_errors(await this.request("get", path, { params: params, ...config }));
    }

    async post(path, data = null, config = {}) {
        return this._check_errors(await this.request("post", path, { data: data, ...config }));
    }

    async delete(path, config = {}) {
        return this._check_errors(await this.request("delete", path, config));
    }

    _check_errors(response) {
        const { errors, errorDto } = response.data;
        if (errors && errors.length > 0) {
            throw new ServerException(errors[0]);
        }
        if (errorDto && errorDto.length > 0) {
            console.warn(
                'Response returns error in deprecated format. Change "errorDto" on "errors" in response for fix this warning.',
            );
            throw new ServerException({ message: errorDto[0] });
        }
        return response;
    }
}
