import { reaction } from "mobx";
import { detectDevice } from "@sberdevices/plasma-ui/utils";

import { DeviceType } from "models/enums";

import { AnalyticsStore } from "models/analytics.store";
import AssistantStore from "models/assistant.store";
import { GameStore } from "models/game.store";
import UserStore from "models/user.store";
import WindowsStore from "models/windows.store";
import { NotificationStore } from "models/notification.store";
import { AudioStore } from "models/audio";

import { Server } from "providers";

export class Application {
    constructor(config = {}) {
        this.config = Object.freeze({
            auth_token: null,
            is_tester: false,
            ...config,
        });
        this.user = new UserStore(this);
        this.server = new Server(this.config, this.user, {
            api_path: process.env.GAME_SERVER_API_PATH,
        });
        this.game = new GameStore(this, this.server);
        this.assistant = new AssistantStore(this);
        this.windows = new WindowsStore(this);
        this.analytics = new AnalyticsStore(this, this.user, {
            use_console: process.env.IS_DEV_MODE,
        });
        this.notification = new NotificationStore(this, { refresh_interval: 3000 });
        this.audio = new AudioStore();
        this._version = process.env.APP_VERSION;
        this._version_build = process.env.APP_VERSION_BUILD;
    }

    get is_touch_mode() {
        return [DeviceType.PORTAL, DeviceType.MOBILE].indexOf(this.device_type) !== -1;
    }

    get device_type() {
        return (
            {
                touch: DeviceType.MOBILE,
                mobile: DeviceType.MOBILE,
                sberPortal: DeviceType.PORTAL,
                sberBox: DeviceType.BOX,
            }[detectDevice()] || DeviceType.BOX
        );
    }

    get version() {
        return `${this._version}.${this._version_build}`;
    }

    on_key_down(key) {
        return !!(this.windows.controller && this.windows.controller.press_key(key));
    }

    update_height() {
        document.documentElement.style.setProperty(
            "--app-height",
            `${Math.min(window.innerHeight, window.innerWidth)}px`,
        );
    }

    init() {
        const IE_KEY_MAP = Object.freeze({
            Down: "ArrowDown",
            Up: "ArrowUp",
            Left: "ArrowLeft",
            Right: "ArrowRight",
            Esc: "Escape",
        });

        window.addEventListener("resize", () => this.update_height());
        window.addEventListener("keydown", (event) => {
            if (event.defaultPrevented) {
                return; // Do nothing if the event was already processed
            }

            if (this.on_key_down(IE_KEY_MAP[event.key] || event.key)) {
                event.stopPropagation();
                event.stopImmediatePropagation();
                event.preventDefault();
            }
        });

        window.history.pushState({}, "");
        window.addEventListener(
            "popstate",
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();

                window.history.pushState({}, "");
                this.on_key_down("Escape");
            },
            true,
        );

        this.windows.__init__();
        this.assistant.__init__();
        this.analytics.__init__();
        this.notification.__init__();
        this.audio.__init__();

        reaction(
            () => {
                if (this.windows && this.windows.controller) {
                    const controller = this.windows.controller;
                    return controller.control || controller.element || null;
                }
            },
            (value, prev) => {
                if (value === null && prev !== null) {
                    this.windows.controller.initFocus();
                }
            },
        );
    }
}
