import { TUTORIAL_CONTROL_SKIP, TUTORIAL_CONTROL_NEXT } from "models/tutorial.buttons";

export default (scene) => ({
    ok: ({ name }) => {
        // eslint-disable-next-line default-case
        switch (name) {
            case TUTORIAL_CONTROL_SKIP:
                scene.apply_tutorial({ is_skipped: true });
                break;

            case TUTORIAL_CONTROL_NEXT:
                scene.next_step_tutorial();
                break;
        }
    },
    back: () => false,
    name: TUTORIAL_CONTROL_NEXT,
    elements: {
        back: { left: TUTORIAL_CONTROL_SKIP, right: TUTORIAL_CONTROL_NEXT, disabled: true },
        [TUTORIAL_CONTROL_NEXT]: { left: TUTORIAL_CONTROL_SKIP, right: TUTORIAL_CONTROL_SKIP },
        [TUTORIAL_CONTROL_SKIP]: { left: TUTORIAL_CONTROL_NEXT, right: TUTORIAL_CONTROL_NEXT },
    },
});
