import React from "react";
import { observer } from "mobx-react-lite";

import { ActionButton } from "components";
import { IconBack } from "components/icons";

export const BackButton = observer(({ controller = null }) => (
    <ActionButton name="back" controller={controller} view="secondary" size="l">
        <IconBack />
    </ActionButton>
));
