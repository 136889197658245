import { GameType } from "models/enums";

import PlayerVsPlayerRequestAPI from "./player_vs_player-request";

export default class PlayerVsPlayerAPI {
    constructor(server) {
        this.server = server;
        this.request = new PlayerVsPlayerRequestAPI(server);
    }

    async create_request({
        color = null,
        game_type: gameType = GameType.WITH_PLAYER,
        enemy_user_id: enemyUserId = null,
    } = {}) {
        const request = await this.request.create({ color, gameType, enemyUserId });
        return this._parseRequestObject(request);
    }

    async retrieve_request(requestId) {
        const request = await this.request.retrieve(requestId);
        return this._parseRequestObject(request);
    }

    async accept_request(requestId) {
        const request = await this.request.accept(requestId);
        return this._parseRequestObject(request);
    }

    async cancel_request(requestId) {
        const request = await this.request.cancel(requestId);
        return this._parseRequestObject(request);
    }

    // eslint-disable-next-line class-methods-use-this
    _parseRequestObject({
        id,
        state,
        gameId = null,
        fromUser = null,
        toUser = null,
        modifiedAt = 0,
    }) {
        return {
            id,
            state,
            game_id: gameId,
            owner_user_id: fromUser ? fromUser.id || null : null,
            enemy_user_id: toUser ? toUser.id || null : null,
            modified_at: modifiedAt,
        };
    }
}
